import VisualiticsCategories from "./VisualiticsCategories";
import VisualiticsLatestTrendingCategory from "./VisualiticsLatestTrendingCategory.";
import VisualiticsContent from "./VisualiticsContent";
import VisualiticsTimeBtns from "./VisualiticsTimeBtns";

//will handle display of data for feed mode and cube mode
//presence of props indicates that the component was rendered by CubeMode
const Visualitics = ({ visualiticsProps = null }) => {
  return (
    <div className="w-full h-full bg-linen dark:bg-nero grid grid-cols-1 grid-rows-visualiticsRows grid-flow-row  pb-px">
      <div className="w-full flex flex-col  bg-sazerac dark:bg-nero items-center space-y-1 pb-1 px-1">
        <VisualiticsCategories />
        <VisualiticsLatestTrendingCategory />
      </div>
      <VisualiticsContent visualiticsContentProps={visualiticsProps} />
      <VisualiticsTimeBtns />
    </div>
  );
};

export default Visualitics;
