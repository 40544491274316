import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { clsx } from "clsx";
import {
  individualRetailInvestorContext,
  contentContext,
  appContext,
} from "common/context";
import { individualRetailInvestorReducer } from "common/exportedFunctions";
import { individualRetailInvestorData } from "common/stateData";
import PhoneTopNavBar from "./PhoneTopNavBar/PhoneTopNavBar";
import { translatedTopicsFunc } from "common/api";
import MainContent from "./MainContent";
import PhoneBottomNavBar from "./PhoneBottomNavBar";
import PortfolioAndEarningsCall from "./PortfolioAndEarningsCall";
import Summary from "./Summary";
import { db, doc, getDoc, setDoc } from "common/firebase-app";
import { useSelector } from "react-redux";
import { format } from "date-fns";

export default function IndividualRetailInvestorContent({ partitionProps }) {
  //destructure props values
  const { name: partitionName } = partitionProps;

  // component's state & dispatch action
  const [
    {
      selectedFeedMenu,
      selectedOptionPhoneBottomNav,
      mode,
      translatedTopics,
      feedData,
      media,
      topics,
      mood,
      section,
      language,
      editListContent,
      skin,
      currentView,
      visualiticsData,
      summary,
      showPreviousIconInCubeMode,
      showMainContent,
    },
    individualRetailInvestorDispatch,
  ] = useReducer(individualRetailInvestorReducer, individualRetailInvestorData);

  // get current layout from content context
  const { currentLayout, generatedPersona } = useContext(contentContext);

  //get current user
  const { currentUser } = useContext(appContext);

  //get data from redux store
  const { impressionData, engagementData, visits } = useSelector(
    (store) => store.app
  );

  // abort controller to cancel http request when component unmounts / re-renders
  const abortControllerRef = useRef(null);

  // Track area of the contents for recording impressions and engagement data
  const trackVisibilityAreaRef = useRef(null);

  //keep track of when to save to db
  const saveToDb = useRef(false);

  //create a text description for each bottom nav option
  const bottomNavDescription = useMemo(() => {
    return {
      portfolio: "Portfolio News & Media Content Curation",
      stories: "Portfolio Content",
      earningscall: "Earnings Calls & Corporate Access",
    };
  }, []);

  //  classes for mobile and desktop layout
  const mobileContainerClasses =
    "bg-mobileFrameBg  bg-no-repeat  w-mobileContainerWidth h-mobileContainerHeight  py-5 pr-4 pl-mobileLeftPadding";
  const desktopContainerClasses =
    "bg-desktopFrameBg bg-no-repeat w-desktopContainerWidth h-desktopContainerHeight py-[15px] pr-4 pl-7";

  //show parent class based on whether currentLayout is mobile or desktop
  const appliedParentClasses = useMemo(
    () =>
      currentLayout === "mobile"
        ? mobileContainerClasses
        : desktopContainerClasses,
    [currentLayout]
  );

  //hold retrieved firebase impression & engament data
  let firebaseData;
  //get persona data and impression-engagement-stats from firebase
  const getFirebaseData = async () => {
    if (currentUser) {
      // uid was used as docId for each collection
      const { uid } = currentUser;
      try {
        //get persona data
        const personaData = await getDoc(doc(db, "personas", uid));

        //get impression-engagement-stats
        const stats = await getDoc(doc(db, "impression-engagement-stats", uid));

        //update local state with firebase data
        firebaseData = stats?.data();

        //destructure events because initially personas were stored to contain events key and not event_1 & event_2
        const {
          persona: { events = {}, ...otherPersonas },
        } = personaData.data();
        let event_1 = events?.event1 ?? otherPersonas?.event_1 ?? [];
        let event_2 = events?.event2 ?? otherPersonas?.event_2 ?? [];

        //update individual retail Investor local state
        individualRetailInvestorDispatch({
          type: "setEditListContent",
          payload: { ...editListContent, ...otherPersonas, event_1, event_2 },
        });

        //turn on save to db
        saveToDb.current = true;
      } catch (error) {
        console.log(error);
        saveToDb.current = true;
      }
    }
  };

  //get persona data and impression-engagement-stats from firebase
  useEffect(() => {
    getFirebaseData();
  }, [currentUser]);

  //update persona data on db
  const updatePersonaData = async () => {
    // update db with recent changes only if saveToDb was set to true
    if (saveToDb.current) {
      // uid is used as docId
      const { uid } = currentUser;
      try {
        await setDoc(doc(db, "personas", uid), {
          persona: { ...editListContent, latest: [], keywords: [] },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  //update db data with current changes
  useEffect(() => {
    updatePersonaData();
  }, [editListContent]);

  //get total of items in array
  const getTotalImpressionOrEngagementData = (dataObj) => {
    return Object.keys(dataObj).reduce((total, keyItem) => {
      //get the value of this key
      const itemValue = dataObj[keyItem];
      //if type value is number, add to the total
      if (typeof itemValue === "number") return total + itemValue;
      //otherwise return total
      return total;
    }, 0);
  };
  //update impression and engagement data to firebase
  const updateUserImpressionEngagementData = async () => {
    // update db with recent changes only if saveToDb was set to true
    if (saveToDb.current) {
      // uid is used as docId
      const { uid } = currentUser;
      //save to local storage first
      localStorage.setItem(
        `${uid}-impression-engagement-stats`,
        JSON.stringify({ impressionData, engagementData, visits })
      );

      try {
        //get today's date
        const today = format(new Date(), "P");
        //old impression data
        const oldImpressionData = firebaseData?.impressionData ?? {};
        //old engagementData
        const oldEngagmentData = firebaseData?.engagementData ?? {};
        //get old cdd visits data
        const oldCddVisitsData = firebaseData?.["cdd-visits"] ?? {};
        //get the total of old impression data
        const previousImpressionTime =
          getTotalImpressionOrEngagementData(oldImpressionData);
        //get the total of old engagement data
        const previousEngagementData =
          getTotalImpressionOrEngagementData(oldEngagmentData);
        //get total local impression time
        const totalLocalImpressionTime =
          getTotalImpressionOrEngagementData(impressionData);
        //get total local engagement data
        const totalLocalEngagementData =
          getTotalImpressionOrEngagementData(engagementData);

        //update firebase with new impression and engagement data
        await setDoc(doc(db, "impression-engagement-stats", uid), {
          impressionData,
          engagementData,
          "cdd-visits": {
            ...oldCddVisitsData,
            [today]: {
              visits,
              impressionTime: totalLocalImpressionTime - previousImpressionTime,
              clicks: totalLocalEngagementData - previousEngagementData,
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  // we will update firebase 5 seconds later after the last redux impression and engagement update,
  useEffect(() => {
    // impression and engagement data updates occur every second whenever there's interaction with feed,
    // such as scrolling or clicking. We don't need to update firebase every second,
    // we will update firebase 3 seconds later after the last local redux update,
    // hence we will use setTimeout
    //create a timeout function
    const timeout = setTimeout(updateUserImpressionEngagementData, 3000); //update after 3 seconds

    //clearing timeout ensures that everytime impression and engagement data changes,
    // previous function call schedule is removed and replaced with a new schedule
    return () => {
      clearTimeout(timeout);
    };
  }, [impressionData, engagementData]);

  // show default content for each partition according to design;
  const showDefaultContent = () => {
    // partitionOne defaults to feeditems as feed menu and portofolio for bottom nav
    // partitionTwo defaults to feeditems as feed menu and stories for bottom nav
    switch (partitionName) {
      case "partitionOne":
        individualRetailInvestorDispatch({
          type: "setSelectedOptionPhoneBottomNav",
          payload: "portfolio",
        });
        break;

      default:
        break;
    }
  };

  // show default content for each partition only during first mount
  useEffect(() => showDefaultContent(), []);

  // get translated topics whenever language changes
  const getTranslatedTopics = async () => {
    const topicsData = await translatedTopicsFunc(language, abortControllerRef);
    const { topics } = topicsData.data;
    individualRetailInvestorDispatch({
      type: "setTranslatedTopics",
      payload: topics,
    });
  };

  //get topics and cancel http request when component unmounts or re-renders
  useEffect(() => {
    const abortControllerRefCopy = abortControllerRef;
    getTranslatedTopics();
    return () => abortControllerRefCopy.current?.abort();
  }, [language]);

  //handle new generated persona
  const handleGeneratedPersona = () => {
    //update only if there's generated persona
    if (Object.keys(generatedPersona).length > 0) {
      individualRetailInvestorDispatch({
        type: "setEditListContent",
        payload: { ...editListContent, ...generatedPersona },
      });
    }
  };

  //use new generated persona data
  useEffect(() => {
    handleGeneratedPersona();
  }, [generatedPersona]);

  // context values for this app
  const contextValues = {
    editListContent,
    language,
    media,
    topics,
    mood,
    section,
    currentView,
    skin,
    individualRetailInvestorDispatch,
    selectedFeedMenu,
    selectedOptionPhoneBottomNav,
    feedData,
    translatedTopics,
    mode,
    visualiticsData,
    showPreviousIconInCubeMode,
    summary,
    trackVisibilityAreaRef,
    partitionName,
  };

  return (
    <individualRetailInvestorContext.Provider value={contextValues}>
      <div className="w-individualWidth mx-auto">
        <div
          className={clsx(
            "w-min flex flex-col items-center mx-auto",
            partitionName === "partitionOne"
              ? "lg:mx-0 lg:ml-auto"
              : "lg:mx-0 lg:mr-auto"
          )}
        >
          <div
            className={clsx(skin === "dark" && "dark", appliedParentClasses)}
          >
            <div
              className={clsx(
                "w-full h-full grid grid-cols-1 grid-rows-feedRows grid-flow-row relative dark:bg-nero rounded-3xl",
                skin === "white" ? "bg-white-smoke" : "bg-sazerac"
              )}
            >
              <PhoneTopNavBar {...{ abortControllerRef }} />
              {showMainContent ? (
                <MainContent ref={trackVisibilityAreaRef} />
              ) : (
                <div />
              )}
              <PhoneBottomNavBar />
              <PortfolioAndEarningsCall />
              <Summary />
            </div>
          </div>
          <p className="text-center text-lg my-4 capitalize">
            {bottomNavDescription[selectedOptionPhoneBottomNav]}
          </p>
        </div>
      </div>
    </individualRetailInvestorContext.Provider>
  );
}
