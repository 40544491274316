import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { individualRetailInvestorContext } from "common/context";
import {
  EarningsCallIcon,
  PortfolioIcon,
  StoriesIcon,
} from "components/CustomIcons/CustomIcons";
export default function PhoneBottomNavBar() {
  //get language from global state
  const { globalLanguage } = useSelector((store) => store.app);

  //extract context values
  const {
    skin,
    selectedOptionPhoneBottomNav,
    individualRetailInvestorDispatch,
  } = useContext(individualRetailInvestorContext);

  //create navigation options
  const bottomNavIcons = [
    {
      name: globalLanguage === "en" ? "Portfolio" : "Innehav",
      icon: <PortfolioIcon />,
      value: "portfolio",
    },
    {
      name: "Stories",
      icon: <StoriesIcon />,
      value: "stories",
    },
    {
      name: "Earnings",
      icon: <EarningsCallIcon />,
      value: "earningscall",
    },
  ];

  // handle options change
  const handleChange = (option) => {
    //show or hide parent's main content based on the option value
    const showMainContent = option === "stories";

    //update the state
    individualRetailInvestorDispatch({
      type: "setBottomNavOptionAndShowMainContent",
      payload: { option, showMainContent },
    });
  };
  return (
    <div
      className={`${
        skin === "white" ? "bg-white-smoke-shade" : "bg-inherit"
      } w-full dark:bg-rhino rounded-b-3xl rounded-t-xl relative z-10`}
    >
      <div className="w-full text-customFontEighteen flex items-center justify-between space-x-1 pt-2 pb-2 px-3">
        {bottomNavIcons.map((item) => (
          <button
            key={item.name}
            onClick={() => handleChange(item.value)}
            className={[
              "flex flex-col items-center justify-center space-y-0.5 font-medium capitalize",
              selectedOptionPhoneBottomNav === item.value
                ? "text-allPorts dark:text-white"
                : "text-gray-500 dark:text-white opacity-40 hover:text-allPorts",
            ].join(" ")}
          >
            <span>{item.icon}</span>
            <span>{item.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
}
