import React from "react";
import EnglandFlag from "assets/englandflag.svg";
import SwedenFlag from "assets/swedishflag.svg";
import GermanyFlag from "assets/germanyflag.svg";
import MultipleLanguages from "assets/globeicon.svg";
import CustomSelectButton from 'components/Buttons/CustomSelectButton';
const languages = [
  {
    label: "Eng",
    value: "en",
    icon: EnglandFlag,
    color: "text-black-russian dark:text-white",
    category: "language",
  },
  {
    label: "Swe",
    value: "sv",
    icon: SwedenFlag,
    color: "text-black-russian dark:text-white",
    category: "language",
  },
  {
    label: "Mul",
    value: 'all',
    icon: MultipleLanguages,
    color: "text-black-russian dark:text-white",
    category: "language",
  },
  {
    label: "Ger",
    value: "ger",
    icon: GermanyFlag,
    color: "text-black-russian dark:text-white",
    category: "language",
    disabled:true
  },
];
const Languages = () => {
  return <CustomSelectButton options={languages} />;
};

export default Languages;
