import React from "react";

const SearchEarningsCall = ({ SearchEarningsCallProps }) => {
  //destructure props
  const { searchWord, earningCallDispatch } = SearchEarningsCallProps;

  // update search word
  const handleChange = (e) => {
    earningCallDispatch({ type: "setSearchWord", payload: e.target.value });
  };

  //wont be submitting the form to anywhere/
  // purpose is to get the search word in order to filter out the earnings calls
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <div className="w-full my-2 text-customFontSix">
      <form
        onSubmit={handleSubmit}
        className="w-full flex items-center justify-between space-x-2"
      >
        <input
          type="text"
          name="search"
          onChange={handleChange}
          value={searchWord}
          className="w-full outline-none  text-black bg-white dark:bg-rhino dark:text-white rounded-full  py-1 px-2"
          placeholder="Search Earnings Call"
        ></input>
        <input
          type="submit"
          value="Search"
          className="rounded-full capitalize cursor-pointer  py-1 px-3  bg-onahau dark:bg-turquoise text-black-russian hover:ring hover:ring-gray-300 font-semibold"
        ></input>
      </form>
    </div>
  );
};

export default SearchEarningsCall;
