import React, {
  memo,
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useContext,
  useState,
} from "react";
import { isEmpty, uniqueId } from "lodash";
import api from "common/api";
import CustomButton from "components/Buttons/CustomButton";
import { useReducer } from "react";
import { individualRetailInvestorContext } from "common/context";
import { useDispatch, useSelector } from "react-redux";
import { getUpdatedClickCounts } from "common/exportedFunctions";
import { setEngagementData } from "features/app/appSlice";
const initialState = {
  allTopics: [],
  buttonsReload: 0,
  topicsCollection: [],
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "setAllTopics": {
      return { ...state, allTopics: payload };
    }
    case "setButtonsReload": {
      return { ...state, buttonsReload: payload };
    }
    case "setTopicsCollection": {
      return { ...state, topicsCollection: [...payload] };
    }
    default:
      return { ...state };
  }
};
function Transcript({ transcriptProps }) {
  //destructure props values
  const {
    audioPlayerRef,
    setIsPlaying,
    audioText,
    companyName = null,
    documentType = null,
    addNewTopicsForImpressionTime = () => {},
    setQuestionAnswerStartTime = () => {},
  } = transcriptProps;

  //get translated topics  from context
  const { translatedTopics, partitionName } = useContext(
    individualRetailInvestorContext
  );

  //get engagment data from redux store
  const { engagementData } = useSelector((store) => store.app);

  //create a local state
  const [{ allTopics, buttonsReload, topicsCollection }, transcriptDispatch] =
    useReducer(reducer, initialState);

  //record all texts
  const textRef = useRef([]);

  //cache current time whenever you play the audio
  const currentTimeCache = useRef([0, 0]);

  //get a dispatch function
  const dispatch = useDispatch();

  //update current time of the audio
  function updateCurrentTimeAndClickCount(start, topicDetails = null) {
    //update current time
    audioPlayerRef.current.audio.current.currentTime = start;
    audioPlayerRef.current.audio.current.play();
    setIsPlaying(true);

    //update click count for this topic
    //record engagement data if the action was form partition2
    if (partitionName === "partitionTwo" && topicDetails) {
      //get updated copy of engagement data
      const newUpdatedEngagementData = getUpdatedClickCounts({
        engagementData,
        companyName,
        topicDisplayName: topicDetails.topic_name,
        topicType: topicDetails.topic_type,
        documentType,
      });

      //update the engagement data on redux store
      dispatch(setEngagementData(newUpdatedEngagementData));
    }
  }

  //get and set all topics
  useEffect(function getAndSetTopics() {
    api
      .topicStatus()
      .then((topics) => {
        transcriptDispatch({ type: "setAllTopics", payload: [...topics] });
      })
      .catch(console.error);
  }, []);

  //get topic details
  const getTopicDetails = useCallback(
    function getTopicDetails(topicId) {
      return allTopics.find((topic) => topic.topic_id === topicId);
    },
    [allTopics]
  );

  //synchronize text as the audio plays
  function synchronizeText() {
    const currentTime = audioPlayerRef.current.audio.current.currentTime;
    const allTexts = textRef.current;
    // prevent restyling if range of currentTime hasn't changed
    const [startTimeCache, endTimeCache] = currentTimeCache.current;
    if (currentTime >= startTimeCache && currentTime < endTimeCache) {
      return;
    }
    for (const { startTime, endTime, element } of allTexts) {
      if (currentTime >= startTime && currentTime < endTime) {
        element.classList.add("text-black", "dark:text-white");
        element.classList.remove("text-black/50", "dark:text-white/50");
        element.scrollIntoView({ behavior: "smooth", block: "nearest" });
        // cache values
        currentTimeCache.current = [startTime, endTime];
        // force re-render topic buttons
        transcriptDispatch({ type: "setButtonsReload", payload: startTime });
      } else {
        element.classList.add("text-black/50", "dark:text-white/50");
        element.classList.remove("text-black", "dark:text-white");
      }
    }
  }

  useEffect(() => {
    const audioPlayerElement = audioPlayerRef.current?.audio?.current;
    audioPlayerElement?.addEventListener("timeupdate", synchronizeText);
    return () =>
      audioPlayerElement?.removeEventListener("timeupdate", synchronizeText);
  }, []);

  const textContent = useMemo(() => {
    let previousSpeaker = null;
    return audioText.map((section) => {
      const {
        sentence,
        start: startTime,
        end: endTime,
        topic_id: topicIdArray,
        speaker = null,
        "q&a": qNaSentence = false,
      } = section;
      //if qNaSentence, add this sentence's start time to parent state
      if (qNaSentence) {
        setQuestionAnswerStartTime(startTime);
      }
      const unqId = uniqueId("earnings-call");
      if (topicIdArray.length > 0) {
        // make incoming array unique
        const incomingArr = [...new Set(topicIdArray)];
        // get the first topic of incoming array
        const firstTopic = incomingArr[0];
        const topicsCollectionCopy = topicsCollection;
        // get last obj of topicsCollectionCopy
        const lastObj = topicsCollectionCopy[topicsCollectionCopy.length - 1];
        const lastTopicArr = lastObj?.topicIdArray;
        const lastTopic = lastTopicArr?.[lastTopicArr.length - 1];
        // prevent adding the first topic of incoming array if it same to last topic of previous array
        if (lastTopic === firstTopic) {
          // slice from the second topic of incoming array
          const incomingSlice = incomingArr.slice(1);
          topicsCollectionCopy.push({
            startTime,
            endTime,
            topicIdArray: incomingSlice,
          });
        } else {
          topicsCollectionCopy.push({
            startTime,
            endTime,
            topicIdArray: incomingArr,
          });
        }
        transcriptDispatch({
          type: "setTopicsCollection",
          payload: topicsCollectionCopy,
        });
      }
      let newSentence = sentence;
      if (speaker !== previousSpeaker) {
        newSentence = `\r\n\r\n${sentence}`; // start a new line if speaker changes
        previousSpeaker = speaker; // update previous speaker
      }
      return (
        <span
          ref={(span) =>
            textRef.current.push({ startTime, endTime, element: span })
          }
          className="whitespace-pre-line cursor-pointer"
          onClick={() => updateCurrentTimeAndClickCount(startTime)}
          key={unqId}
        >
          {newSentence}
        </span>
      );
    });
  }, []);

  const topicButtons = useMemo(() => {
    //store topic iwith their details in order to update impression time
    const topicsWithDetails = [];
    const mappedTopics = topicsCollection.map(
      ({ startTime, endTime, topicIdArray }, outerIndex) => {
        const unqId = uniqueId("topicObj");
        return (
          <div key={unqId} className="w-full">
            {topicIdArray.map((topicId) => {
              const button = uniqueId("button");
              const formattedStartTime = new Date(startTime * 1000)
                .toISOString()
                .substring(11, 19);
              const topicDetails = getTopicDetails(topicId);
              //add this topic details to a list for updating impression time
              if (!isEmpty(topicDetails)) {
                topicsWithDetails.push(topicDetails);
              }
              const buttonText =
                translatedTopics.filter(
                  (topic) => topic.topic_id === topicId
                )[0]?.name ?? topicDetails?.topic_name;
              const buttonType = topicDetails?.topic_type;
              const [startTimeCache] = currentTimeCache.current;
              const isButtonPlaying =
                startTimeCache >= startTime && startTimeCache < endTime;
              return isEmpty(topicDetails) ? null : (
                <div key={button} className="w-full text-center">
                  <p className="w-full font-bold text-customFontSix">
                    {formattedStartTime}
                  </p>
                  <div
                    className="w-full flex items-center justify-center"
                    onClick={() => {
                      updateCurrentTimeAndClickCount(startTime, topicDetails);
                    }}
                  >
                    <div
                      className={[
                        "rounded-full p-0.5 border",
                        isButtonPlaying ? "border-black" : "border-linen",
                      ].join(" ")}
                    >
                      <CustomButton
                        customButtonProps={{
                          type: buttonType,
                          text: buttonText,
                          isClear: false,
                          category: "topics",
                        }}
                      />
                    </div>
                  </div>
                  {outerIndex !== topicsCollection.length - 1 && (
                    <div className="w-0.5 h-8 mx-auto bg-martini"></div>
                  )}
                </div>
              );
            })}
          </div>
        );
      }
    );
    //send the topicWithDetails for updating their impression time
    addNewTopicsForImpressionTime(topicsWithDetails);

    //return mapped topics
    return mappedTopics;
  }, [allTopics, buttonsReload]);

  return (
    <div className="w-full h-full my-1 grid grid-cols-earningsCallText items-center grid-rows-1 grid-flow-col gap-x-1 justify-start text-customFontNineteen text-black dark:text-white">
      <div className="w-full h-full overflow-auto scrollbar-hide">
        {textContent}
      </div>
      {topicButtons.length > 0 && (
        <div className="w-20 py-0.5 bg-inherit h-full overflow-auto scrollbar-hide rounded-md">
          {topicButtons}
        </div>
      )}
    </div>
  );
}

export default memo(Transcript);
