import React, { useEffect, memo } from "react";
import { BsPlayFill, BsPauseFill } from "react-icons/bs";
import { GrRotateLeft, GrRotateRight } from "react-icons/gr";
import AudioPlayer from "react-h5-audio-player";
import "./audioPlayer.css";
import { colorScale } from "common/exportedFunctions";

export default memo(
  React.forwardRef(function AudioPlayerWrapper(
    {
      isPlaying,
      setIsPlaying,
      audioUrl,
      audioText = null,
      isPodcast = false,
      jumpToQuestionAndAnswer = () => {},
    },
    audioPlayerRef
  ) {
    // make progress bar linear - gradient based on sentiment in the  each sentence of the audioText
    const updateProgressBarLook = () => {
      //get progress bar container
      const progressBarsContainers = document.getElementsByClassName(
        "rhap_progress-container"
      );
      // don't update bg-color of progress bar if no audioText passed as prop
      if (!audioText) {
        return;
      }
      // get total duration of audio file
      const audioDuration = audioPlayerRef.current.audio.current.duration;
      // map audioText and for each sentence return end time in percent of  total duration
      // sentiment type as color from the color scale
      const mappedValues = audioText.map(
        ({ end: endTime, sentiment = null }) => {
          // sentiment initialized to null in case we don't have it
          // endTime in percent of totalDuration
          const percentEndTime = Math.round((endTime / audioDuration) * 100);
          // sentiment to color code
          const sentimentColor = sentiment
            ? colorScale(sentiment * 100)
            : "#00000033"; // if sentiment is null, use a return default color
          return `${sentimentColor} ${percentEndTime}%`;
        }
      );
      //join color stops
      const colorStops = mappedValues.join(",");
      progressBarsContainers[0].style.cssText = `background:linear-gradient(to right, ${colorStops});`;
    };
    //When the state variable 'isPlaying' changes, play/pause the actual audio player
    useEffect(() => {
      if (isPlaying) {
        audioPlayerRef.current.audio.current.play();
      } else {
        audioPlayerRef.current.audio.current.pause();
      }
    }, [isPlaying]);

    useEffect(() => {
      const audioPlayerElement = audioPlayerRef.current?.audio?.current;
      audioPlayerElement?.addEventListener(
        "loadedmetadata",
        updateProgressBarLook
      );
      return () =>
        audioPlayerElement?.removeEventListener(
          "loadedmetadata",
          updateProgressBarLook
        );
    }, []);
    const updateAudioPlayerDarkMode = () => {
      //get time elements
      const timeElements = document.getElementsByClassName("rhap_time");
      // progress indicator
      const progressIndicator = document.getElementsByClassName(
        "rhap_progress-indicator"
      );
      // update them
      timeElements[0].classList.add("dark:text-white");
      timeElements[1].classList.add("dark:text-white");
      progressIndicator[0].classList.add("dark:bg-white");
    };
    useEffect(() => {
      if (!isPodcast) updateAudioPlayerDarkMode();
    }, []);
    //Create custom playback controll buttons which link to the state
    const playbackControllButtons = (
      <div className="w-full relative flex items-center justify-center text-customFontSix ">
        <button
          onClick={() => audioPlayerRef.current.handleClickRewind()}
          className="flex items-center justify-center font-bold disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!audioUrl}
        >
          <p className="relative left-5 top-0.5 ">15</p>
          <GrRotateLeft size={30} />
        </button>
        <button
          onClick={() => setIsPlaying(!isPlaying)}
          disabled={!audioUrl}
          className="disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isPlaying ? <BsPauseFill size={32} /> : <BsPlayFill size={32} />}
        </button>
        <button
          onClick={() => audioPlayerRef.current.handleClickForward()}
          className="flex items-center justify-center font-bold disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!audioUrl}
        >
          <GrRotateRight size={30} />
          <p className="relative -left-5 top-0.5 ">15</p>
        </button>
        {/* go to q&a */}
        <button
          onClick={jumpToQuestionAndAnswer}
          className=" absolute  left-0 m-auto font-bold text-xs bg-inherit border rounded-md py-0.5 px-1 uppercase text-black"
        >
          q&a
        </button>
      </div>
    );

    return (
      <div className="w-full">
        <AudioPlayer
          ref={(element) => (audioPlayerRef.current = element)}
          className={`bg-inherit p-0.5 shadow-none text-customFontSix font-bold text-black ${
            isPodcast ? "" : "dark:text-white"
          }`}
          src={audioUrl}
          progressJumpSteps={{ backward: 15000, forward: 15000 }}
          customControlsSection={[playbackControllButtons]}
          customVolumeControls={[]}
          onEnded={() => setIsPlaying(false)}
        />
      </div>
    );
  })
);
