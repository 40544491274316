import React, { useEffect, useContext, useReducer } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { auth, signInWithEmailAndPassword } from "common/firebase-app";
import CustomTextInput from "./CustomTextInput";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { appContext } from "common/context";
import TexelioLogo from "assets/texeliologo.svg";
const initialState = {
  currentForm: "Client Login",
  showLoginForm: false,
  loginStatus: {
    message: "",
    error: "",
  },
};
const reducer = (state, { type, payload }) => {
  switch (type) {
    case "setLoginStatus": {
      return { ...state, loginStatus: { ...state.loginStatus, ...payload } };
    }
    case "setCurrentForm": {
      return { ...state, currentForm: payload };
    }
    case "setShowLoginForm": {
      return { ...state, showLoginForm: payload };
    }
    default:
      return { ...state };
  }
};
const Login = () => {
  const { currentUser } = useContext(appContext);
  const [{ currentForm, showLoginForm, loginStatus }, loginDispatch] =
    useReducer(reducer, initialState);
  let location = useLocation();
  let navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid Email Address").required("Required"),
    password: Yup.string().required("Required"),
  });
  const onSubmit = async ({ email, password }) => {
    try {
      loginDispatch({
        type: "setLoginStatus",
        payload: { message: "Logging in....", error: "" },
      });
      await signInWithEmailAndPassword(auth, email, password);
      loginDispatch({
        type: "setLoginStatus",
        payload: { message: "", error: "" },
      });
      navigate(location.state?.from ?? "/content");
    } catch (error) {
      console.log(error);
      loginDispatch({
        type: "setLoginStatus",
        payload: { message: "", error: error.message },
      });
    }
  };
  const changeForm = () => {
    loginDispatch({
      type: "setCurrentForm",
      payload: currentForm === "Client Login" ? "Admin Login" : "Client Login",
    });
  };
  const { error, message } = loginStatus;
  let renderedComponent = (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <div className="w-14">
        <img className="w-full" src={TexelioLogo} alt="Texelio" />
      </div>
    </div>
  );
  if (showLoginForm) {
    renderedComponent = (
      <div className="w-full h-screen md:w-1/2 mx-auto lg:w-3/12  flex flex-col items-center justify-center text-white px-2">
        <h1 className="w-full text-center font-bold text-customFontThirteen text-white">
          {currentForm}
        </h1>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="w-full">
            <CustomTextInput
              name="email"
              type="email"
              placeholder="Enter Email"
            />
            <CustomTextInput
              name="password"
              type="password"
              placeholder="Enter Password"
            />
            <button
              className="text-customFontFifteen text-white font-semibold rounded-full py-4 my-2 w-full bg-summer-sky cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
              type="submit"
              disabled={currentForm === "Admin Login"}
            >
              {currentForm === "Client Login" ? "Client Login" : "Admin Login"}
            </button>
          </Form>
        </Formik>
        {error && <p className="my-2 text-center text-red-500">{error}</p>}
        {message && (
          <p className="my-2 text-center text-green-500">{message}</p>
        )}
        <div className="w-full  text-customFontFourteen text-white my-2 text-center">
          <div className="w-full flex justify-center items-center">
            <button onClick={changeForm}>
              {currentForm === "Client Login" ? "Admin" : "Client"}
            </button>
            <span className="mx-2">|</span>
            <a
              href="https://www.texelio.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy
            </a>
          </div>
          <p className="my-2.5 w-full ">
            <Link
              to="/resetpassword"
              className="text-blue-500 font-bold hover:underline"
            >
              Forgot password?
            </Link>
          </p>
          <p className="my-2.5 w-full ">
            Don't have an account?{" "}
            <Link
              to="/requestaccess"
              className="font-semibold text-cyan-500 cursor-pointer underline"
            >
              Request Access Code
            </Link>
          </p>
          <p className="my-2.5 w-full ">
            Do you have an Access Code?{" "}
            <Link
              to="/register"
              className="font-semibold text-cyan-500 cursor-pointer underline"
            >
              Sign up
            </Link>
          </p>
        </div>
      </div>
    );
  }
  const checkLoggedUser = () => {
    if (currentUser === undefined) {
      return;
    } else if (currentUser) {
      navigate(location.state?.from ?? "/content");
    } else {
      loginDispatch({ type: "setShowLoginForm", payload: true });
    }
  };
  useEffect(() => {
    checkLoggedUser();
  }, [currentUser]);
  return renderedComponent;
};

export default Login;
