import React, { useContext, useMemo, memo, useCallback } from "react";
import { individualRetailInvestorContext } from "common/context";
import {
  getDataWithPercentageWidth,
  visualiticsBarHandler,
} from "common/exportedFunctions";
import { useSpring, animated } from "@react-spring/web";
import VisualiticsDataDisplay from "./VisualiticsDataDisplay";
const Trending = ({ trendingProps }) => {
  // get context data
  const {
    translatedTopics,
    editListContent,
    currentView,
    individualRetailInvestorDispatch,
    visualiticsData,
  } = useContext(individualRetailInvestorContext);

  //if trendingProps has visualiticsState property, use its searchResults
  // because it indicates the component was rendered by CubeMode
  const { searchResults, loading, message, error } =
    trendingProps?.visualiticsState ?? visualiticsData;

  // animate bar graphs
  const styles = useSpring({
    from: { width: "0%" },
    to: { width: "100%" },
    delay: 500,
    config: { duration: 1500 },
  });

  //handle when a bar is clicked
  const toggleFeed = async (selection) => {
    //filter data based on current view
    const filteredData = await visualiticsBarHandler({
      selection,
      data: editListContent,
      currentView,
      categoryName: visualiticsData.categoryName,
    });

    // update currentView content of editListContent to filteredData
    individualRetailInvestorDispatch({
      type: "setEditListContent",
      payload: filteredData,
    });

    // update selectedFeedMenu to 'feedItems'   in order to be navigated to feeditems.
    individualRetailInvestorDispatch({
      type: "setSelectedFeedMenu",
      payload: "feeditems",
    });
  };
  // categorize positive and negative trends
  const trends = useMemo(() => {
    //object with positve trends and negative trends propertiees
    const trends = { positiveTrends: [], negativeTrends: [] };

    //loop through results and for each result check entity_trend_percentage
    // if percent is at least 1, that's a positive trend
    // less than 0 is a negative trend
    searchResults.forEach((result) => {
      //get percent value of result
      const { entity_trend_percentage } = result;

      // check and group accordingly
      if (entity_trend_percentage > 0) {
        trends.positiveTrends.push(result);
      }
      if (entity_trend_percentage < 0) {
        trends.negativeTrends.push(result);
      }
    });

    //return trends object
    return trends;
  }, [searchResults]);

  // common method to get a list of trending data
  const getTrendingData = useCallback(
    (trendType) => {
      //get data trends based on TrendType
      const data = trends[trendType];

      // modify and map results to contain percentage width
      const dataWithPercentWidth = getDataWithPercentageWidth({
        data,
        itemValueKey: "entity_trend_percentage",
        categoryName: visualiticsData.categoryName,
        translatedTopics,
        itemNameKey: "entity_name",
      });

      //return modified and sliced(10 items) data
      return dataWithPercentWidth;
    },
    [trends]
  );

  //get positive trending data
  const positiveTrendingData = useMemo(() => {
    //get data
    const positiveTrends = getTrendingData("positiveTrends");
    // map each and return their node elements
    return positiveTrends.map((result) => {
      //destructure result values
      const { name, percentageWidth } = result;

      //hide result if it contains 'Quote' topic name
      if (visualiticsData.categoryName === "topic" && name === "Quote")
        return null;

      return (
        <div
          onClick={() => toggleFeed(result)}
          key={result.entity_id}
          className="w-full pb-2 capitalize cursor-pointer text-customFontNineteen"
        >
          <h2 className="mb-1 ml-1">{name}</h2>
          <div style={{ width: `${percentageWidth}%` }}>
            <animated.div
              className="text-customFontSix flex items-center justify-center h-4 text-allPorts bg-onahau dark:bg-turquoise dark:text-black  py-0.5 "
              style={{ ...styles }}
            >
              {parseInt(result.entity_trend_percentage)}%
            </animated.div>
          </div>
        </div>
      );
    });
  }, [getTrendingData]);

  // get negative trending data
  const negativeTrendingData = useMemo(() => {
    //get data
    const negativeTrends = getTrendingData("negativeTrends");

    // map each and return their node elements
    return negativeTrends.map((result) => {
      //destructure result values
      const { name, percentageWidth } = result;

      //hide result if it contains 'Quote' topic name
      if (visualiticsData.categoryName === "topic" && name === "Quote")
        return null;

      return (
        <div
          onClick={() => toggleFeed(result)}
          key={result.entity_id}
          className="w-full pb-2 capitalize cursor-pointer text-customFontNineteen"
        >
          <h2 className="mb-1 mr-1 text-right">{name}</h2>
          <div style={{ width: `${percentageWidth}%` }} className="ml-auto">
            <animated.div
              className="text-customFontSix flex items-center justify-center h-4 text-out-orange bg-your-pink dark:bg-violet-red dark:text-white font-semibold text-center py-0.5 ml-auto "
              style={{ ...styles }}
            >
              {parseInt(result.entity_trend_percentage)}%
            </animated.div>
          </div>
        </div>
      );
    });
  }, [getTrendingData]);

  const hasResults = useMemo(
    () => positiveTrendingData.length > 0 || negativeTrendingData.length > 0,
    [positiveTrendingData, negativeTrendingData]
  );

  return (
    <VisualiticsDataDisplay
      hasResults={hasResults}
      otherProps={{ loading, message, error }}
    >
      <div className="w-full mb-4 ">
        <div className="w-full grid grid-cols-trending auto-rows-auto grid-flow-row  ">
          <div className="w-full row-start-2">{negativeTrendingData}</div>
          <div className="w-full row-span-2 border border-solitude" />
          <div className="w-full col-start-3 ">{positiveTrendingData}</div>
        </div>
      </div>
    </VisualiticsDataDisplay>
  );
};

export default memo(Trending);
