import React, { useState, memo, useRef, useEffect, useReducer } from "react";
import Dropzone from "./Dropzone";
import PodCastsList from "./PodCastsList";
import { podcastContext } from "common/context";
import AudioPlayer from "components/AudioPlayer/AudioPlayerWrapper";
import PodCastTranscriptWrapper from "./PodCastTranscriptWrapper";
// import { collection, db, getDocs } from "common/firebase-app";
import * as firebase from "common/firebase-app";
const initialState = {
  podcasts: {},
  currentPodcastPlaying: "", // will be name of podcast
};
const reducer = (state, { type, payload }) => {
  switch (type) {
    case "setPodcasts": {
      return { ...state, podcasts: { ...payload } };
    }

    case "setCurrentPodcastPlaying": {
      return { ...state, currentPodcastPlaying: payload };
    }
    default:
      return { ...state };
  }
};
const Podcasts = () => {
  const [{ podcasts, currentPodcastPlaying }, podcastDispatch] = useReducer(
    reducer,
    initialState
  );
  const [isPlaying, setIsPlaying] = useState(false);
  const audioPlayerRef = useRef();
  // progress bars ref for each podcast
  const progressBarsRef = useRef({});
  const modifiedName = podcasts[currentPodcastPlaying]?.name.split(".")[0];
  const audioUrl = podcasts[currentPodcastPlaying]?.audio_url;
  const getPodcastsFromDb = async () => {
    try {
      // get podcasts from firestore
      // initialize empty object to hold podcasts
      const podcastsHolder = {};
      const querySnapshot = await firebase.getDocs(
        firebase.collection(firebase.db, "podcasts")
      );
      // loop through each doc and add to podcastHolder
      querySnapshot.forEach((doc) => {
        // doc.id is podcast name, doc.data() contains data
        podcastsHolder[doc.id] = doc.data();
      });
      // update podcasts list
      podcastDispatch({ type: "setPodcasts", payload: { ...podcastsHolder } });
    } catch (error) {
      podcastDispatch({ type: "setPodcasts", payload: {} });
    }
  };
  useEffect(() => {
    getPodcastsFromDb();
  }, []);
  return (
    <podcastContext.Provider
      value={{
        podcasts,
        podcastDispatch,
        isPlaying,
        setIsPlaying,
        currentPodcastPlaying,
        audioPlayerRef,
      }}
    >
      <div className="w-full h-full bg-alice-blue pt-28 pb-22px px-6">
        <div className="max-w-3xl mx-auto ">
          <h1 className="w-full text-center capitalize font-bold underline">
            Podcasts
          </h1>
          <Dropzone dropzoneProps={{ progressBarsRef }} />
          <div className="w-full bg-slate-200 rounded-md my-2 text-customFontEleven pt-2">
            <h1 className="w-full text-center truncate font-semibold capitalize italic">
              {modifiedName ?? "Select podcast to play"}
            </h1>
            <AudioPlayer
              ref={audioPlayerRef}
              {...{
                isPlaying,
                setIsPlaying,
                audioUrl,
                isPodcast: true,
              }}
            />
          </div>
          <div className="w-full h-96 overflow-auto my-2 grid grid-cols-podcastsGridContainer grid-rows-1 gap-x-2">
            <PodCastsList ref={progressBarsRef} />
            <PodCastTranscriptWrapper />
          </div>
        </div>
      </div>
    </podcastContext.Provider>
  );
};

export default memo(Podcasts);
