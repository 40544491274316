import BeatLoader from "react-spinners/BeatLoader";
import { memo, useContext } from "react";
import { individualRetailInvestorContext } from "common/context";

export default memo(function VisualiticsDataDisplay({
  hasResults,
  children,
  otherProps: { loading, message, error },
}) {
  // get context common data
  const {
    visualiticsData: { categoryName, sectionName, period },
  } = useContext(individualRetailInvestorContext);

  // show items , errors or messages
  const results = hasResults ? (
    <>
      {children}
      <div className="w-full text-center">
        <a
          href="https://www.texelio.com/demo"
          target="_blank"
          rel="noreferrer"
          className="rounded-full  px-4 py-1 bg-onahau dark:bg-turquoise text-black-russian text-customFontSix  font-semibold"
        >
          Book a Demo
        </a>
      </div>
    </>
  ) : (
    <>
      {loading && (
        <div className="w-full text-center">
          <BeatLoader size={10} color="#40464F" />
        </div>
      )}
      {message && (
        <p className="w-full text-customFontThree text-center">{message}</p>
      )}
      {error && (
        <p className="w-full text-customFontThree text-red-500 text-center">
          {error}
        </p>
      )}
    </>
  );

  return (
    <div className="w-full">
      <h1 className="w-full capitalize text-center text-customFontOne my-0.5 underline underline-offset-4 decoration-gray-500 ">
        {sectionName} {categoryName + "s"} {period}
      </h1>
      <div className="w-full my-2">{results}</div>
    </div>
  );
});
