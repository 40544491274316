import React, { useContext } from "react";
import LatestSelectedItems from "./LatestSelectedItems";
import OtherViewsSelectedKeywords from "./OtherViewsSelectedKeywords";
import { individualRetailInvestorContext } from "common/context";

const SelectedItems = () => {
  // Return selected items based on current view
  const { currentView } = useContext(individualRetailInvestorContext);

  return currentView === "latest" ? (
    <LatestSelectedItems />
  ) : (
    <OtherViewsSelectedKeywords />
  );
};

export default SelectedItems;
