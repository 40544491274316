import React, { useContext } from "react";
import { individualRetailInvestorContext } from "common/context";
import { clsx } from "clsx";

const VisualiticsLatestTrendingCategory = () => {
  // get context data
  const { visualiticsData, individualRetailInvestorDispatch } = useContext(
    individualRetailInvestorContext
  );

  // create sections
  const sections = [{ name: "latest" }, { name: "trending" }];

  // update section name
  const changeSection = (selectedSectionName) => {
    individualRetailInvestorDispatch({
      type: "setVisualiticsData",
      payload: { ...visualiticsData, sectionName: selectedSectionName },
    });
  };
  return (
    <div className="w-3/4 mx-auto py-0.5 px-1  text-black text-customFontSix font-semibold bg-linen dark:bg-rhino  rounded-full flex justify-between items-center ">
      {sections.map(({ name }) => (
        <button
          key={name}
          onClick={() => changeSection(name)}
          className={clsx(
            "w-full text-center text-black rounded-full  p-1 capitalize whitespace-nowrap",
            visualiticsData.sectionName === name
              ? "bg-onahau dark:bg-turquoise "
              : "dark:text-white"
          )}
        >
          {name}
        </button>
      ))}
    </div>
  );
};

export default VisualiticsLatestTrendingCategory;
