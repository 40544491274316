import React, { useState, useContext, useCallback, useMemo } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { clsx } from "clsx";
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";
import { individualRetailInvestorContext } from "common/context";
import SearchBar from "../SearchBar/SearchBar";
import SelectedItems from "./SelectedItems/SelectedItems";
import ViewControl from "../ViewControl/ViewControl";
import { getNewCurrentViewName } from "common/exportedFunctions";

// top navigation within the individual partition
export default function PhoneTopNavBar({ abortControllerRef }) {
  // state to show / hide menu
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  //get context for individual partition
  const { skin, currentView, individualRetailInvestorDispatch } = useContext(
    individualRetailInvestorContext
  );

  // track engagement data when menu is open
  const toggleHamburgerMenu = useCallback(() => {
    setShowHamburgerMenu((prev) => {
      //For clarity we write out what we're actually going to do
      const shouldWeShowMenu = !prev;
      return shouldWeShowMenu;
    });
  }, []);

  //create an array of views
  const views = useMemo(
    () => ["latest", "investments", "watchlist", "event_1", "event_2"],
    []
  );

  //show or hide view control buttons
  const showPrevIcon = currentView !== "latest";
  const showNextIcon = currentView !== "event_2";

  //handle view changes
  const handleViewChange = useCallback(
    (direction) => {
      //get new view name
      const newViewName = getNewCurrentViewName({
        currentView,
        views,
        direction,
      });
      //update currentView to the new view name
      individualRetailInvestorDispatch({
        type: "setCurrentView",
        payload: newViewName,
      });
    },
    [currentView]
  );

  return (
    <nav className="w-full rounded-t-3xl pt-6  text-customFontOne text-black dark:text-white bg-inherit">
      <div className="w-full relative px-2  ">
        <ViewControl
          viewControlProps={{
            updateViewHandler: handleViewChange,
            currentView,
            showPrevIcon,
            showNextIcon,
          }}
        />
        <div>
          {!showHamburgerMenu && (
            <button
              onClick={toggleHamburgerMenu}
              className="absolute top-0 right-0"
            >
              <AiOutlineMenu size={16} className="mx-2 hover:scale-110 " />
            </button>
          )}
        </div>
      </div>
      <div className="w-full pt-1 px-1.5 pb-px">
        <div className={clsx(showHamburgerMenu ? "w-full" : "hidden")}>
          <HamburgerMenu {...{ toggleHamburgerMenu }} />
        </div>
        <div
          className={clsx(
            "w-full rounded-full px-1",
            skin === "white" && "bg-white"
          )}
        >
          <SearchBar
            {...{
              abortControllerRef,
              allowNew: true,
            }}
          />
        </div>
        <SelectedItems />
      </div>
    </nav>
  );
}
