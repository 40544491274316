const data = {
  "Apple Inc (AAPL)": {
    company_name: "Apple Inc",
    document_type: "EARNINGS CALLS",
    title: "Apple Inc (AAPL)- Earnings Call",
    mediaType: "Earnings Calls",
    audioUrl:
      "https://cdn.texelio.com/earnings_calls/apple_2022_q3_earnings_call_v2.mp3",
    audioText: [
      {
        sentence:
          "Good day, and welcome to the  Apple Q3 FY 2022 earnings conference call. ",
        start: 0,
        end: 5.43,
        topic_id: [],
      },
      {
        sentence: "Today's call is being recorded. ",
        start: 5.43,
        end: 7.25,
        topic_id: [],
      },
      {
        sentence:
          "At this time, for opening remarks and introductions, I would like to turn the call over to Tejas Gala, director of investor relations and corporate finance. ",
        start: 7.25,
        end: 14.28,
        topic_id: [],
      },
      {
        sentence: "Please go ahead.\r\n\r\n ",
        start: 14.28,
        end: 15.849999,
        topic_id: [],
      },
      {
        sentence: "Thank you. ",
        start: 15.849999,
        end: 16.879999,
        topic_id: [],
      },
      {
        sentence: "Good afternoon, and thank you for joining us. ",
        start: 16.879999,
        end: 19.47,
        topic_id: [],
      },
      {
        sentence:
          "Speaking first today is Apple's CEO, Tim Cook; and he'll be followed by CFO, Luca Maestri. ",
        start: 19.47,
        end: 26.17,
        topic_id: [],
      },
      {
        sentence:
          "After that, we'll open the call to questions from analysts.\r\n\r\n ",
        start: 26.17,
        end: 30.57,
        topic_id: [],
      },
      {
        sentence:
          "Please note that some of the information you'll hear during the discussion today will consist of forward-looking statements, including, without limitation, those regarding revenue, gross margin, operating expenses, other income and expense, taxes, capital allocation, and future business outlook, including the potential impact of COVID 19- on the company's business and results of operation. ",
        start: 30.57,
        end: 56.06,
        topic_id: [],
      },
      {
        sentence:
          "These statements involve risks and uncertainties that may cause actual results or trends to differ materially from our forecast. ",
        start: 56.06,
        end: 63.559999,
        topic_id: [
          "82727239-a73d-4956-95af-fe6e845696bd",
          "95ea7b3c-7905-4a80-b3ed-1581e5097c8b",
          "db7dff5d-8cf7-4615-b65a-96d30c6ec0c5",
        ],
      },
      {
        sentence:
          "For more information, please refer to the risk factors discussed in Apple's most recently filed annual report on Form 10-K and the Form 8-K filed with the SEC today, along with the associated press release. ",
        start: 63.559999,
        end: 77.41,
        topic_id: [],
      },
      {
        sentence:
          "Apple assumes no obligation to update any forward-looking statements or information, which speak as of their respective dates. ",
        start: 77.41,
        end: 85.48,
        topic_id: [],
      },
      {
        sentence:
          "I'd now like to turn the call over to Tim for introductory remarks.\r\n\r\n ",
        start: 85.48,
        end: 90.23,
        topic_id: [],
      },
      {
        sentence: "Thank you, Tejas. ",
        start: 90.23,
        end: 91.45,
        topic_id: [],
      },
      {
        sentence: "Good afternoon, everyone, and thank you for joining us. ",
        start: 91.45,
        end: 94.87,
        topic_id: [],
      },
      {
        sentence:
          "Today, Apple is reporting another record June quarter with revenue of $83 billion, which was better than we expected despite supply constraints, strong foreign exchange headwinds, and the impact of our business in Russia. ",
        start: 94.87,
        end: 111.29,
        topic_id: [],
      },
      {
        sentence:
          "We set June quarter records in the Americas, in Europe, and in the rest of Asia Pacific region.\r\n\r\n ",
        start: 111.29,
        end: 118.139999,
        topic_id: [],
      },
      {
        sentence:
          "We also saw June quarter revenue records in both developed and emerging markets with very strong double-digit growth in Brazil, Indonesia, and Vietnam and a near doubling of revenue in India. ",
        start: 118.139999,
        end: 131.37,
        topic_id: ["4fe353b8-78cb-4503-aa88-0b6e2b99fca9"],
      },
      {
        sentence:
          "We saw great enthusiasm for our products and services, resulting in an all-time record for our installed base of active devices. ",
        start: 131.37,
        end: 140.469999,
        topic_id: [],
      },
      {
        sentence:
          "Our supply constraints were less than we anticipated at the beginning of the quarter, coming in slightly below the range we discussed during our last call. ",
        start: 140.469999,
        end: 149.84,
        topic_id: ["a106b425-de0d-4b7c-8e5d-322b819a5e0a"],
      },
      {
        sentence:
          "We know that this is a time of significant challenge around the world for all of us confronting new variants of COVID -19, to those experiencing a prolonged humanitarian crisis in Ukraine and everyone dealing with the consequences of an uncertain economic environment.\r\n\r\n ",
        start: 149.84,
        end: 168.15,
        topic_id: ["93e4997e-df50-4ce0-88be-e74a6db71b28"],
      },
      {
        sentence:
          "We know that much of the world is living through uneasy times, and it is all the more reason why we are working hard to help our customers navigate the world as it is while empowering them to create the world as it can be. ",
        start: 168.15,
        end: 182.61,
        topic_id: [],
      },
      {
        sentence: "Turning to iPhone. ",
        start: 182.61,
        end: 184.33,
        topic_id: [],
      },
      {
        sentence:
          "We set a June quarter record for both revenue and  switchers to iPhone. ",
        start: 184.33,
        end: 189.27,
        topic_id: ["4fe353b8-78cb-4503-aa88-0b6e2b99fca9"],
      },
      {
        sentence:
          "With its advanced performance, capability and ease of use, customers continue to find that iPhone remains the gold standard for smartphones.\r\n\r\n ",
        start: 189.27,
        end: 198.99,
        topic_id: [],
      },
      {
        sentence:
          "And they've been raving about the iPhone 13 lineup's extraordinary camera quality with features like cinematic mode and macro photography to create eye-catching content. ",
        start: 198.99,
        end: 211.54,
        topic_id: [],
      },
      {
        sentence:
          "We were also proud to celebrate the 15th anniversary of iPhone, a device that continues to change the world in profound ways with each new innovation. ",
        start: 211.54,
        end: 222.84,
        topic_id: [],
      },
      {
        sentence:
          "Last month, Apple unleashed a wave of innovation, including the completely redesigned MacBook Air and a new 13-inch MacBook Pro. ",
        start: 222.84,
        end: 232.66,
        topic_id: [],
      },
      {
        sentence:
          "Both of these systems are powered by M2, our next generation of Apple silicon for Mac.\r\n\r\n ",
        start: 232.66,
        end: 239.4,
        topic_id: [],
      },
      {
        sentence:
          "M2 delivers a faster CPU, GPU, and neural engine along with higher memory bandwidth and new capabilities like Pro Res acceleration. ",
        start: 239.4,
        end: 250.32,
        topic_id: [],
      },
      {
        sentence:
          "And it continues the tremendous pace of innovation in Apple silicon for the Mac. ",
        start: 250.32,
        end: 256.11,
        topic_id: [],
      },
      {
        sentence:
          "We continue to have supply constraints with Mac, but we're encouraged by the strong response from customers to our incredible lineup. iPad, like Mac, continued to see strong demand during the June quarter despite ongoing supply constraints.\r\n\r\n ",
        start: 256.11,
        end: 273.6,
        topic_id: ["a106b425-de0d-4b7c-8e5d-322b819a5e0a"],
      },
      {
        sentence:
          "Customers and developers have been especially excited about the new features we're bringing to iPad with iPadOS 16. ",
        start: 273.6,
        end: 282.21,
        topic_id: [],
      },
      {
        sentence:
          "This update was one of the many announcements we made at a truly extraordinary WWDC, where we shared a range of new features that give customers more control of their experience than ever before. ",
        start: 282.21,
        end: 296.059999,
        topic_id: [],
      },
      {
        sentence:
          "This includes the ability to edit or delete sent messages, a new way of organizing apps on iPad and Mac, and an all-new customizable lock screen on iPhone and so much more. ",
        start: 296.059999,
        end: 309.03,
        topic_id: [],
      },
      {
        sentence:
          "Today, iOS 16, iPadOS 16 , MacOS Ventura, and watchOS 9  are all in public beta, and we couldn't be more excited to see what our community of developers creates with them.\r\n\r\n ",
        start: 309.03,
        end: 324.709999,
        topic_id: [],
      },
      {
        sentence:
          "We unveiled new innovations and accessibility such as door detection and live captions that support users with disabilities with navigation, health, communication, and more. ",
        start: 324.709999,
        end: 337.77,
        topic_id: [],
      },
      {
        sentence:
          "We also announced Apple Pay Later, which gives customers more flexibility to make purchases with their Apple devices. ",
        start: 337.77,
        end: 345.52,
        topic_id: [],
      },
      {
        sentence:
          "And with our next generation of CarPlay, we're improving the driving experience with deeper integration into vehicle hardware, allowing drivers to control their music, change the temperature and monitor their fuel levels, all from a single integrated platform. ",
        start: 345.52,
        end: 363.36,
        topic_id: [],
      },
      {
        sentence:
          "In the Wearables, Home, and Accessories category, the innovation infused across our products continues to win over new customers.\r\n\r\n ",
        start: 363.36,
        end: 371.679999,
        topic_id: [],
      },
      {
        sentence:
          "Apple Watch remains a great way for health-conscious customers to track their overall wellness and fitness. ",
        start: 371.679999,
        end: 377.789999,
        topic_id: [],
      },
      {
        sentence:
          "And we're bringing them even more data about their workouts, sleep cycles, and medications with updates soon to arrive on watchOS 9. ",
        start: 377.789999,
        end: 388.02,
        topic_id: [],
      },
      {
        sentence:
          "We were also pleased to get FDA approval for a new feature that will let users with irregular heart rhythms track the time they spend in Afib. ",
        start: 388.02,
        end: 398.33,
        topic_id: [],
      },
      {
        sentence: "Turning to services.\r\n\r\n ",
        start: 398.33,
        end: 400.25,
        topic_id: [],
      },
      {
        sentence:
          "Customers continue to engage enthusiastically with our content across news, fitness, music, gaming, and more. ",
        start: 400.25,
        end: 409.2,
        topic_id: [],
      },
      {
        sentence:
          "Services revenue rose to $19.6 billion, a June quarter record and a 12% increase year over year, which was in line with our expectations. ",
        start: 409.2,
        end: 420.96,
        topic_id: [],
      },
      {
        sentence:
          "We're proud of how Apple TV+ productions like Severance and Black Bird have captured the popular imagination, and we're looking forward to more exceptional content developed by extraordinary creators throughout the year. ",
        start: 420.96,
        end: 435.95,
        topic_id: [],
      },
      {
        sentence:
          "In two and a half years since launch, Apple TV+ has now earned 250 wins and over 1 ,100 award nominations and counting.\r\n\r\n ",
        start: 435.95,
        end: 445.99,
        topic_id: [],
      },
      {
        sentence:
          "Just this month, we learned that Apple TV+ earned 52 Emmy Award nominations across 13 titles. ",
        start: 445.99,
        end: 454.07,
        topic_id: [],
      },
      {
        sentence:
          "In our last call, I mentioned Friday Night Baseball on Apple TV+, which is already delighting baseball fans. ",
        start: 454.07,
        end: 462.17,
        topic_id: [],
      },
      {
        sentence:
          "And last month, we announced a 10-year deal to present Major League soccer matches around the world, giving global soccer fans a whole new way of viewing their favorite sport. ",
        start: 462.17,
        end: 473.49,
        topic_id: [],
      },
      {
        sentence:
          "One of the best parts of WWDC was welcoming developers to Apple Park while continuing to connect with developers all over the world.\r\n\r\n ",
        start: 473.49,
        end: 483.309999,
        topic_id: [],
      },
      {
        sentence:
          "This year, we had an incredible group of developers and more opportunities to learn from one another than ever before. ",
        start: 483.309999,
        end: 490.78,
        topic_id: [],
      },
      {
        sentence:
          "It was a truly special experience and a reminder of the economic miracle the App Store represents. ",
        start: 490.78,
        end: 498.33,
        topic_id: [],
      },
      {
        sentence:
          "We are proud of the fact that the iOS app economy supports more than 2.2 million jobs here in the United States and many more around the world. ",
        start: 498.33,
        end: 508.37,
        topic_id: [],
      },
      {
        sentence:
          "It's been wonderful to see earnings by small developers more than double over the past two years.\r\n\r\n ",
        start: 508.37,
        end: 514.349999,
        topic_id: [],
      },
      {
        sentence:
          "And as we're supporting developers, we're also doing our part to protect customers. ",
        start: 514.349999,
        end: 519.9,
        topic_id: [],
      },
      {
        sentence:
          "In 2021, we prevented nearly $1.5 billion in fraudulent transactions by stopping over 1.6 million risky and vulnerable apps and app updates. ",
        start: 519.9,
        end: 533.74,
        topic_id: ["888a6b26-0386-4a75-b728-0f50a24e4bad"],
      },
      {
        sentence: "Now I want to turn to retail. ",
        start: 533.74,
        end: 536.09,
        topic_id: [],
      },
      {
        sentence:
          "This quarter, we opened the doors to Apple's first store in the Hubei province in China, welcoming the community to a beautiful new space.\r\n\r\n ",
        start: 536.09,
        end: 545.19,
        topic_id: [],
      },
      {
        sentence:
          "And earlier today, we opened Apple Brompton Road, our fifth store in Central London. ",
        start: 545.19,
        end: 551.4,
        topic_id: [],
      },
      {
        sentence:
          "We also expanded today at Apple Creative Studios to reach even more young creatives from underrepresented communities to help them realize their potential and bring their best ideas to life. ",
        start: 551.4,
        end: 565.7,
        topic_id: [],
      },
      {
        sentence:
          "I'd like to take this opportunity to express my appreciation to our team members working in Apple Stores, customer care centers, and channel partner stores and to  our Apple Care teams for their incredible work supporting customers wherever they are. ",
        start: 565.7,
        end: 583.07,
        topic_id: [],
      },
      {
        sentence:
          "Creating innovative products and services that enrich people's lives is our mission.\r\n\r\n ",
        start: 583.07,
        end: 588.37,
        topic_id: [],
      },
      {
        sentence:
          "Leading with our values and everything we do gives that mission purpose. ",
        start: 588.37,
        end: 593.869999,
        topic_id: [],
      },
      {
        sentence:
          "That includes a commitment to the environment where we continue our aggressive pursuit of our 2030 goals. ",
        start: 593.869999,
        end: 601.42,
        topic_id: [],
      },
      {
        sentence:
          "It includes our focus on diversity and inclusion, where we are committed as ever to making progress. ",
        start: 601.42,
        end: 609.42,
        topic_id: ["4bf0b630-7968-4e43-be8f-f53a4a5ca6fb"],
      },
      {
        sentence:
          "And it includes our work to promote racial equity and justice.\r\n\r\n ",
        start: 609.42,
        end: 613.4,
        topic_id: ["4bf0b630-7968-4e43-be8f-f53a4a5ca6fb"],
      },
      {
        sentence:
          "We recently announced that the Global Equity Innovation Hub, in partnership with Cal State Northridge, will provide new community grants to Hispanic-serving institutions to help the next generation of creators and innovators build skills and pursue high-demand careers in STEM. ",
        start: 613.4,
        end: 632.06,
        topic_id: [],
      },
      {
        sentence:
          "We also celebrated the graduation of the inaugural class of our Developer Academy in Detroit from a program designed to give students the skills they need to pursue jobs in the thriving iOS app economy. ",
        start: 632.06,
        end: 646.379999,
        topic_id: [],
      },
      {
        sentence:
          "Leading with our values also means leading with a steadfast commitment to privacy and security. ",
        start: 646.379999,
        end: 652.67,
        topic_id: [
          "888a6b26-0386-4a75-b728-0f50a24e4bad",
          "a5327b06-9291-48d9-9c78-5e4e7d532abb",
        ],
      },
      {
        sentence:
          "Last month, we announced the introduction of passkey, a next-generation credential that's intended to replace passwords.\r\n\r\n ",
        start: 652.67,
        end: 660.389999,
        topic_id: ["888a6b26-0386-4a75-b728-0f50a24e4bad"],
      },
      {
        sentence:
          "A passkey can't be phished nor can it be stolen by hackers in a data breach because the information is stored on your device and your device alone. ",
        start: 660.389999,
        end: 671.07,
        topic_id: ["888a6b26-0386-4a75-b728-0f50a24e4bad"],
      },
      {
        sentence:
          "And as part of our effort to combat targeted attacks against the highest risk targets like journalists and human rights activists, we introduced Lockdown Mode, which is designed to protect those most at risk of sophisticated digital attacks. ",
        start: 671.07,
        end: 687.12,
        topic_id: ["93e4997e-df50-4ce0-88be-e74a6db71b28"],
      },
      {
        sentence:
          "And we're committed to doing our part to address the housing crisis across California. ",
        start: 687.12,
        end: 692.48,
        topic_id: [],
      },
      {
        sentence:
          "To date, we have deployed more than $1.3 billion to a number of initiatives, including ones that provide financial assistance to low and moderate-income first-time home buyers develop new affordable housing and help support vulnerable populations.\r\n\r\n ",
        start: 692.48,
        end: 712.38,
        topic_id: [],
      },
      {
        sentence:
          "This quarter has ultimately been a reflection of our resilience and our optimism. ",
        start: 712.38,
        end: 717.969999,
        topic_id: [],
      },
      {
        sentence:
          "As we look forward, we're clear-eyed about the uncertainty in the macro environment. ",
        start: 717.969999,
        end: 723.35,
        topic_id: [],
      },
      {
        sentence:
          "Yet we remain ever focused on the same vision that has guided us from the beginning. ",
        start: 723.35,
        end: 729.03,
        topic_id: [],
      },
      {
        sentence:
          "We strive every day to be a place where imagination ignites innovation like nowhere else, where good people come together to achieve great things, where customers are the center of everything we do.\r\n\r\n ",
        start: 729.03,
        end: 742.42,
        topic_id: [],
      },
      {
        sentence:
          "And we'll continue to execute on that vision as we always have, led by a focus on excellence and a desire to leave the world better than we found it. ",
        start: 742.42,
        end: 753.13,
        topic_id: [],
      },
      {
        sentence: "And with that, I'll turn it over to Luca.\r\n\r\n\r\n ",
        start: 753.13,
        end: 756.49,
        topic_id: [],
      },
      {
        sentence: "Thank you, Tim, and good afternoon, everyone. ",
        start: 756.49,
        end: 759.28,
        topic_id: [],
      },
      {
        sentence:
          "We are very pleased to report June quarter financial results that continue to demonstrate our ability to innovate across hardware, software, and services while operating our business effectively during very challenging economic circumstances. ",
        start: 759.28,
        end: 775.32,
        topic_id: [],
      },
      {
        sentence:
          "We set a June quarter revenue record of $83 billion, up 2% year over year despite supply constraints, over 300 basis points of foreign exchange headwinds and the impact of our business in Russia. ",
        start: 775.32,
        end: 789.71,
        topic_id: ["a106b425-de0d-4b7c-8e5d-322b819a5e0a"],
      },
      {
        sentence:
          "Around the world, we set new June quarter records in  the Americas, in Europe, and rest of Asia Pacific.\r\n\r\n ",
        start: 789.71,
        end: 797.94,
        topic_id: [],
      },
      {
        sentence:
          "On the product side, revenue was $63.4 billion with a June quarter revenue record for iPhone. ",
        start: 797.94,
        end: 805.0600000000001,
        topic_id: ["4fe353b8-78cb-4503-aa88-0b6e2b99fca9"],
      },
      {
        sentence:
          "During the quarter our,  installed base of active devices continue to grow well, thanks to our unmatched levels of customer satisfaction and loyalty and reached an all-time high for all major product categories and geographic segments. ",
        start: 805.0600000000001,
        end: 821.19,
        topic_id: ["6db5784d-8a16-4bae-aa1b-21316ebadb84"],
      },
      {
        sentence:
          "Our Services set a June quarter revenue record of $19.6 billion, up 12% over a year ago, with all-time revenue records in the Americas and the rest of Asia Pacific and June quarter records in Europe and Greater China. ",
        start: 821.19,
        end: 838.44,
        topic_id: ["4fe353b8-78cb-4503-aa88-0b6e2b99fca9"],
      },
      {
        sentence:
          "We also achieved June quarter revenue records in each major Services category, including all-time revenue records for Music, Cloud Services, Apple Care, and Payment Services.\r\n\r\n ",
        start: 838.44,
        end: 851.87,
        topic_id: ["4fe353b8-78cb-4503-aa88-0b6e2b99fca9"],
      },
      {
        sentence:
          "Company gross margin was 43.3%, down 40 basis points from last quarter as seasonal loss of leverage and unfavorable foreign exchange were partially offset by favorable mix. ",
        start: 851.87,
        end: 865.029999,
        topic_id: [],
      },
      {
        sentence:
          "Products gross margin was 34.5%, down 190 basis points sequentially, mainly driven by seasonal loss of leverage, mix, and FX. ",
        start: 865.029999,
        end: 876.009999,
        topic_id: [],
      },
      {
        sentence:
          "Services gross margin was 71.5%, down 110 basis points sequentially due to a different mix and foreign exchange. ",
        start: 876.009999,
        end: 887.2,
        topic_id: [],
      },
      {
        sentence:
          "Net income was $19 4. billion and diluted earnings per share were $1.20, while operating cash flow of $22.9 billion was a June quarter record.\r\n\r\n ",
        start: 887.2,
        end: 901.039999,
        topic_id: [],
      },
      {
        sentence:
          "Let me now get into more detail for each of our revenue categories. iPhone revenue grew 3% year over year to a June quarter record of 40 $.7 billion despite foreign exchange headwinds as customer response to our iPhone 13 family continue to be strong. ",
        start: 901.039999,
        end: 917.49,
        topic_id: ["4fe353b8-78cb-4503-aa88-0b6e2b99fca9"],
      },
      {
        sentence:
          "We set June quarter records in both developed and emerging markets. ",
        start: 917.49,
        end: 921.3,
        topic_id: [],
      },
      {
        sentence:
          "And the iPhone active installed base reached a new all-time high across all geographies as a result of this level of sales performance combined  with unmatched customer loyalty.\r\n\r\n ",
        start: 921.3,
        end: 932.44,
        topic_id: [],
      },
      {
        sentence:
          "In fact, the latest survey of U.S. consumers from 451 Research indicates iPhone customer satisfaction of 98%. ",
        start: 932.44,
        end: 941.88,
        topic_id: [],
      },
      {
        sentence:
          "We also attracted a record number of switchers  for  the June quarter, with strong double-digit year-over-year growth. ",
        start: 941.88,
        end: 949.509999,
        topic_id: [],
      },
      {
        sentence:
          "For Mac, we generated revenue of $7.4 billion despite supply constraints and negative effects.\r\n\r\n ",
        start: 949.509999,
        end: 956.57,
        topic_id: [],
      },
      {
        sentence:
          "We continue to be excited about our long-term opportunity with Mac and redefining the PC experience with our relentless innovation. ",
        start: 956.57,
        end: 965.16,
        topic_id: [],
      },
      {
        sentence:
          "Our investment focus on Mac has  helped  drive  significant growth in our installed base, which reached an all-time high during the June quarter as nearly half of the customers purchasing a Mac were  new to the product. iPad revenue was $7.2 billion, down 2% year over year due  to supply constraints and negative foreign exchange. ",
        start: 965.16,
        end: 986.96,
        topic_id: [],
      },
      {
        sentence:
          "Customer response to our  iPad lineup continue to be strong across consumer, education, and enterprise markets around the world.\r\n\r\n ",
        start: 986.96,
        end: 994.91,
        topic_id: [],
      },
      {
        sentence:
          "And the iPad installed base reached a new all-time high, with over half of the customers during the quarter being new to the product. ",
        start: 994.91,
        end: 1001.53,
        topic_id: [],
      },
      {
        sentence:
          "Wearables, Home, and Accessories revenue was $8.1 billion, down 8% year over year as we faced foreign exchange headwinds, different launch timing for Home and Accessories products and supply constraints as well as the overall macroeconomic environment. ",
        start: 1001.53,
        end: 1019.44,
        topic_id: [],
      },
      {
        sentence:
          "Despite this, our installed base of devices in the category hit a new all-time record, thanks to very strong customer loyalty and high new tool rates. ",
        start: 1019.44,
        end: 1030.15,
        topic_id: [],
      },
      {
        sentence:
          "For example, Apple Watch continues to extend its reach, with over two-thirds of customers purchasing an Apple Watch during the quarter being new to the product.\r\n\r\n ",
        start: 1030.15,
        end: 1040.88,
        topic_id: [],
      },
      {
        sentence:
          "Services had a June quarter revenue record of $19 6. billion, up 12% despite almost 500 basis points of  FX headwinds as well as impacts from our  business in Russia and the macroeconomic environment. ",
        start: 1040.88,
        end: 1055.87,
        topic_id: [],
      },
      {
        sentence:
          "We set June quarter revenue records in both developed and emerging markets and set all-time records in many countries around the world, including the U.S., Mexico, Brazil, Korea, and India. ",
        start: 1055.87,
        end: 1068.6,
        topic_id: ["4fe353b8-78cb-4503-aa88-0b6e2b99fca9"],
      },
      {
        sentence:
          "The record level of performance of our services portfolio during the June quarter reflects the strength of our ecosystem on many fronts: First, our installed base has continued to grow, reaching an all-time high across each geographic segment and major product category. ",
        start: 1068.6,
        end: 1084.82,
        topic_id: [],
      },
      {
        sentence:
          "We also saw increased customer engagement with our Services during the quarter.\r\n\r\n ",
        start: 1084.82,
        end: 1090.139999,
        topic_id: [],
      },
      {
        sentence:
          "Our transacting accounts, paid accounts, and accounts with paid subscriptions all grew double digits year over year. ",
        start: 1090.139999,
        end: 1097.719999,
        topic_id: [],
      },
      {
        sentence: "And paid subscriptions showed  very strong growth. ",
        start: 1097.719999,
        end: 1101.32,
        topic_id: [],
      },
      {
        sentence:
          "We now have more than 860 million paid subscriptions across the services on  our platform, which is up more than 160 million during the last 12 months alone. ",
        start: 1101.32,
        end: 1113.12,
        topic_id: [],
      },
      {
        sentence:
          "And finally, we continue to improve the breadth and the quality of our  current Services offerings, from a constant flow of new content on Apple TV and+  Apple Arcade to great new features we recently announced for iCloud  and  Apple Music, which we believe our customers will love.\r\n\r\n ",
        start: 1113.12,
        end: 1130.94,
        topic_id: [],
      },
      {
        sentence:
          "In the enterprise market, our customers are increasingly investing in Apple products as a strategy to attract and retain talent. ",
        start: 1130.94,
        end: 1138.42,
        topic_id: [],
      },
      {
        sentence:
          "Bank of America is providing iPhones to all of its financial advisors so they can instantly access client information and  provide timely wealth management advice from anywhere. ",
        start: 1138.42,
        end: 1149.809999,
        topic_id: [],
      },
      {
        sentence:
          "Wipro, another large global enterprise customer, is investing in MacBook Air with M1 as a competitive advantage when recruiting new graduates globally, thanks to its superior performance and lower total cost of ownership. ",
        start: 1149.809999,
        end: 1165.45,
        topic_id: [],
      },
      {
        sentence:
          "And with  the  new M2 chip powering MacBook Air and the  13-inch MacBook Pro, we expect more customers to make Mac available to their entire workforce.\r\n\r\n ",
        start: 1165.45,
        end: 1178.0,
        topic_id: [],
      },
      {
        sentence: "Let me now turn to our cash position. ",
        start: 1178.0,
        end: 1180.42,
        topic_id: [],
      },
      {
        sentence:
          "We ended the quarter with $179 billion in cash and marketable securities. ",
        start: 1180.42,
        end: 1186.08,
        topic_id: [],
      },
      {
        sentence:
          "We repaid $3 billion in maturing debt while increasing commercial paper by $4 billion, leaving us with total debt of $120 billion. ",
        start: 1186.08,
        end: 1195.06,
        topic_id: ["1a60df40-8b57-42e5-aff0-01aeaa1d28b3"],
      },
      {
        sentence:
          "As a result, net cash was nearly $60 billion at the end of the quarter.\r\n\r\n ",
        start: 1195.06,
        end: 1200.04,
        topic_id: [],
      },
      {
        sentence:
          "We returned over $28 billion to shareholders during the June quarter. ",
        start: 1200.04,
        end: 1203.839999,
        topic_id: [],
      },
      {
        sentence:
          "This included $3.8 billion in dividends and equivalents and $21.7 billion through open market repurchases of 143 million Apple shares. ",
        start: 1203.839999,
        end: 1214.53,
        topic_id: ["a3bc4d2a-f18e-4ebe-a870-27bd3eed0672"],
      },
      {
        sentence:
          "We continue to believe there is great value in our stock and maintain our target of reaching a net cash neutral position over time. ",
        start: 1214.53,
        end: 1223.949999,
        topic_id: [],
      },
      {
        sentence:
          "As we move ahead into the September quarter, I'd like to review our outlook, which includes the types of forward-looking information that Tejas referred to at the beginning of the call.\r\n\r\n ",
        start: 1223.949999,
        end: 1235.44,
        topic_id: ["95ea7b3c-7905-4a80-b3ed-1581e5097c8b"],
      },
      {
        sentence:
          "Given the continued uncertainty around the world in the near term, we are not providing revenue guidance but we are sharing some directional insights based on the assumption that the macroeconomic outlook and COVID-related impacts to our business do not worsen from what we are projecting today for the current quarter. ",
        start: 1235.44,
        end: 1254.349999,
        topic_id: ["82727239-a73d-4956-95af-fe6e845696bd"],
      },
      {
        sentence:
          "Overall, we believe our year-over-year revenue growth will accelerate during  the September quarter compared to the June quarter despite approximately 600 basis points of negative year-over-year impact from foreign exchange. ",
        start: 1254.349999,
        end: 1269.599999,
        topic_id: [],
      },
      {
        sentence:
          "On the product side, we expect supply constraints to be lower than what we experienced during the June quarter. ",
        start: 1269.599999,
        end: 1276.23,
        topic_id: [],
      },
      {
        sentence:
          "Specifically related to services, we expect revenue to grow but decelerate from the June quarter due to macroeconomic factors and foreign exchange.\r\n\r\n ",
        start: 1276.23,
        end: 1287.34,
        topic_id: ["a106b425-de0d-4b7c-8e5d-322b819a5e0a"],
      },
      {
        sentence: "We expect gross margin to be between 41.5% and 42.5%. ",
        start: 1287.34,
        end: 1292.67,
        topic_id: [],
      },
      {
        sentence:
          "We expect opex to be between $12.9 billion and 13 $.1 billion. ",
        start: 1292.67,
        end: 1298.72,
        topic_id: [],
      },
      {
        sentence:
          "We expect OI E& to be around negative [Inaudible] impact from the mark-to-market of minority investments and our tax rate to be around 16%. ",
        start: 1298.72,
        end: 1311.15,
        topic_id: [],
      },
      {
        sentence:
          "Finally, today, our board of directors has declared  a cash dividend of $0.23 per share of common stock, payable on August 11, 2022, to shareholders of record as of August 8, 2022.\r\n\r\n ",
        start: 1311.15,
        end: 1325.259999,
        topic_id: [],
      },
      {
        sentence: "With that, let's open the call to questions.\r\n\r\n ",
        start: 1325.259999,
        end: 1329.48,
        topic_id: [],
      },
      {
        sentence: "Thank you, Luca Operator. [ instructions ] ",
        start: 1329.48,
        end: 1333.689999,
        topic_id: [],
      },
      {
        sentence:
          "Operator, may we have the first question, please?\r\n\r\n\r\n ",
        start: 1333.689999,
        end: 1339.64,
        topic_id: [],
      },
      {
        sentence:
          "We'll take our first question from Amit Daryanani with Evercore.\r\n\r\n\r\n ",
        start: 1339.64,
        end: 1347.339999,
        topic_id: [],
      },
      {
        sentence: "I guess two from my side. ",
        start: 1347.339999,
        end: 1349.609999,
        topic_id: [],
      },
      {
        sentence: "Maybe to start on the gross margin discussion, Luca. ",
        start: 1349.609999,
        end: 1352.68,
        topic_id: [],
      },
      {
        sentence:
          "You said that implying  gross margin will be down I think, 130 140,  basis points sequentially and  down a bit year over  year as well in September. ",
        start: 1352.68,
        end: 1360.16,
        topic_id: [],
      },
      {
        sentence: "Maybe just what other puts and takes are in.\r\n\r\n ",
        start: 1360.16,
        end: 1362.86,
        topic_id: [],
      },
      {
        sentence:
          "And then very specifically, can you actually  just call it what the FX headwinds are embedded in the September quarter gross margin that,  would  be helpful.\r\n\r\n ",
        start: 1362.86,
        end: 1369.14,
        topic_id: [],
      },
      { sentence: "Yes, Amit. ", start: 1369.14, end: 1371.64, topic_id: [] },
      {
        sentence: "We're guiding 41.5% to 42.5%. ",
        start: 1371.64,
        end: 1375.61,
        topic_id: [],
      },
      {
        sentence:
          "On a sequential basis, the decline is expected to be driven by, as you mentioned, foreign exchange but also mix, which will be partially offset by better leverage. ",
        start: 1375.61,
        end: 1387.86,
        topic_id: [],
      },
      {
        sentence:
          "We expect foreign exchange impact on a sequential basis to be 50 basis points.\r\n\r\n ",
        start: 1387.86,
        end: 1396.33,
        topic_id: ["95ea7b3c-7905-4a80-b3ed-1581e5097c8b"],
      },
      {
        sentence:
          "If you look at it from a year-over-year standpoint, we are in the ballpark of a year ago in spite of the fact that  foreign exchange is going to be 130 basis points negative to a year ago. ",
        start: 1396.33,
        end: 1412.44,
        topic_id: [],
      },
      {
        sentence:
          "So clearly, foreign exchange is something that is affecting us but we think we're navigating that fairly well.\r\n\r\n\r\n ",
        start: 1412.44,
        end: 1423.26,
        topic_id: [],
      },
      { sentence: "Fair enough. ", start: 1423.26, end: 1423.81, topic_id: [] },
      {
        sentence: "And then if I could just ask Tim the question. ",
        start: 1423.81,
        end: 1427.4900000000002,
        topic_id: [],
      },
      {
        sentence:
          "There's a lot of macro worries and high inflation impacting consumer demand. ",
        start: 1427.4900000000002,
        end: 1432.86,
        topic_id: [],
      },
      {
        sentence:
          "Certainly doesn't seem to be very visible in your performance and your expectations.\r\n\r\n ",
        start: 1432.86,
        end: 1436.48,
        topic_id: [],
      },
      {
        sentence:
          "So I'm wondering if you talk about, are you seeing any implications from recession fears or inflation fears to your end demand? ",
        start: 1436.48,
        end: 1443.24,
        topic_id: [],
      },
      {
        sentence:
          "And really just related to that, variables decline was notable. ",
        start: 1443.24,
        end: 1447.63,
        topic_id: [],
      },
      {
        sentence:
          "Is that where  you would typically see initial signs of consumer softening perhaps?\r\n\r\n\r\n ",
        start: 1447.63,
        end: 1454.48,
        topic_id: [],
      },
      {
        sentence: "Thanks for the question. ",
        start: 1454.48,
        end: 1455.929999,
        topic_id: [],
      },
      {
        sentence:
          "If you -- I'm not an economist and so I'll sort of narrow my comments to what we saw in the business. ",
        start: 1455.929999,
        end: 1463.93,
        topic_id: [],
      },
      {
        sentence:
          "And if you look at the June quarter, we do believe that we saw macroeconomic headwinds that impacted our business and  our results. ",
        start: 1463.93,
        end: 1475.16,
        topic_id: [],
      },
      {
        sentence:
          "And so one of those is clearly the FX, which Luca has mentioned, that was over 300 basis points on year-over-year growth rates.\r\n\r\n ",
        start: 1475.16,
        end: 1486.08,
        topic_id: [],
      },
      {
        sentence:
          "When you look at the product categories, on iPhone, there was no obvious evidence of macroeconomic impact during the June quarter besides FX, obviously. ",
        start: 1486.08,
        end: 1500.92,
        topic_id: [],
      },
      {
        sentence:
          "Mac and iPad were so gated by supply that we didn't have enough product to test the demand. ",
        start: 1500.92,
        end: 1509.74,
        topic_id: ["a106b425-de0d-4b7c-8e5d-322b819a5e0a"],
      },
      {
        sentence:
          "And Wearables, Home, and Accessories, as you mentioned and  as Luca mentioned, we did see some impact there that we would attribute to a  macroeconomic environment. ",
        start: 1509.74,
        end: 1523.58,
        topic_id: [],
      },
      {
        sentence:
          "When you then look at Services, there were some Services that were impacted, for example, like digital advertising was clearly impacted by the macroeconomic environment.\r\n\r\n ",
        start: 1523.58,
        end: 1535.64,
        topic_id: [],
      },
      {
        sentence:
          "And so it's a mixed bag in terms of what we believe that we saw. ",
        start: 1535.64,
        end: 1543.349999,
        topic_id: [],
      },
      {
        sentence: "Overall, we are very happy with the results. ",
        start: 1543.349999,
        end: 1547.440001,
        topic_id: [],
      },
      {
        sentence:
          "And when you think about the number of challenges in the quarter, we feel really good about the growth that we put up for the quarter.\r\n\r\n ",
        start: 1547.440001,
        end: 1569.47,
        topic_id: [],
      },
      {
        sentence:
          "We'll take our next question from Harsh Kumar with Piper Sandler.\r\n\r\n ",
        start: 1569.47,
        end: 1573.78,
        topic_id: [],
      },
      { sentence: "Yes. ", start: 1573.78, end: 1575.8, topic_id: [] },
      {
        sentence: "First of all, congratulations. ",
        start: 1575.8,
        end: 1577.259999,
        topic_id: [],
      },
      {
        sentence:
          "As these are tough times, you guys are putting up tremendous results so we appreciate that as investors. ",
        start: 1577.259999,
        end: 1586.47,
        topic_id: [],
      },
      {
        sentence:
          "My question is, when I look at your services business, I see a $20 billion business on a quarterly basis.\r\n\r\n ",
        start: 1586.47,
        end: 1595.4,
        topic_id: [],
      },
      {
        sentence:
          "And you keep adding -- as a company, you keep adding very interesting and transformative  features such as payments, something like that every year. ",
        start: 1595.4,
        end: 1602.86,
        topic_id: [],
      },
      {
        sentence:
          "So I'm struggling to find a good way to think about how to model the growth of this business, considering that you add innovative features. ",
        start: 1602.86,
        end: 1611.05,
        topic_id: [],
      },
      {
        sentence:
          "So now that it's fairly matured as a business, what would be a good way for us to think about as investors to model the Services business? ",
        start: 1611.05,
        end: 1619.75,
        topic_id: [],
      },
      {
        sentence: "And I do have a follow-up.\r\n\r\n ",
        start: 1619.75,
        end: 1622.62,
        topic_id: [],
      },
      {
        sentence:
          "Well, as you know, we don't provide guidance past the current quarter. ",
        start: 1622.62,
        end: 1626.85,
        topic_id: [],
      },
      {
        sentence:
          "But I think the way to think about it, certainly the way we think about it is that there's a number of levers in our services business to take into account. ",
        start: 1626.85,
        end: 1637.41,
        topic_id: [],
      },
      {
        sentence: "The first one is installed base. ",
        start: 1637.41,
        end: 1638.84,
        topic_id: [],
      },
      {
        sentence:
          "Installed base is the engine for our company and it continues to grow.\r\n\r\n ",
        start: 1638.84,
        end: 1643.15,
        topic_id: [],
      },
      {
        sentence:
          "As I mentioned, it has reached an all-time high across every geographic segment, across every product category. ",
        start: 1643.15,
        end: 1650.62,
        topic_id: [],
      },
      {
        sentence: "And so that's very important. ",
        start: 1650.62,
        end: 1651.809999,
        topic_id: [],
      },
      {
        sentence: "Then the second lever is the customer engagement. ",
        start: 1651.809999,
        end: 1655.209999,
        topic_id: [],
      },
      {
        sentence:
          "And we know that our customers are getting more and  more  engaged  over time.\r\n\r\n ",
        start: 1655.209999,
        end: 1659.4,
        topic_id: [],
      },
      {
        sentence:
          "Transacting accounts, paid accounts, paid subscriptions are growing, so the level of engagement continues to grow. ",
        start: 1659.4,
        end: 1666.309999,
        topic_id: [],
      },
      {
        sentence:
          "And then as you mentioned, the breadth and the quality of the services that we offer tends to grow over time. ",
        start: 1666.309999,
        end: 1673.219999,
        topic_id: [],
      },
      {
        sentence:
          "So these are all things that tend to help us over the long term. ",
        start: 1673.219999,
        end: 1677.62,
        topic_id: [],
      },
      {
        sentence:
          "If you go back and  you look at our growth rates over a number of quarters now, they've always been very  good.\r\n\r\n ",
        start: 1677.62,
        end: 1686.24,
        topic_id: [],
      },
      {
        sentence:
          "Of course, the macro environment can have an impact on this business. ",
        start: 1686.24,
        end: 1692.6,
        topic_id: [],
      },
      {
        sentence:
          "Tim has mentioned, for example, digital advertising can be affected at times. ",
        start: 1692.6,
        end: 1699.45,
        topic_id: [],
      },
      {
        sentence:
          "During COVID, some of the compares have been a bit lumpy because there have  been  lockdowns and reopenings and  so on. ",
        start: 1699.45,
        end: 1707.07,
        topic_id: [],
      },
      {
        sentence:
          "So it's very difficult to talk about a steady-state growth rate  for our services business.\r\n\r\n ",
        start: 1707.07,
        end: 1714.2,
        topic_id: [],
      },
      {
        sentence:
          "But when we look at the entirety of what we are doing in the Services space, we feel very good about the future of  the business.\r\n\r\n ",
        start: 1714.2,
        end: 1723.98,
        topic_id: [],
      },
      {
        sentence: "Luca, very helpful. ",
        start: 1723.98,
        end: 1725.049999,
        topic_id: [],
      },
      {
        sentence:
          "And for  my follow-up, valuations have come down in the last 18 months or so for things and companies and targets that you might look at. ",
        start: 1725.049999,
        end: 1734.89,
        topic_id: [],
      },
      {
        sentence:
          "I guess, particularly in the services area, would there be an appetite on behalf of Apple to accelerate the growth of its Services business by looking at external products to acquire?\r\n\r\n ",
        start: 1734.89,
        end: 1747.49,
        topic_id: ["a1e7266d-5a95-434b-9b01-3da1f418db66"],
      },
      {
        sentence:
          "We always look and we ask ourselves if it's -- how strategic it is, and we never buy just to buy or buy just for revenue purposes. ",
        start: 1747.49,
        end: 1760.69,
        topic_id: [],
      },
      {
        sentence: "But we would buy something that is strategic for us. ",
        start: 1760.69,
        end: 1765.58,
        topic_id: [],
      },
      {
        sentence:
          "To date, we have concentrated on smaller IP and people acquisitions. ",
        start: 1765.58,
        end: 1774.9,
        topic_id: ["a1e7266d-5a95-434b-9b01-3da1f418db66"],
      },
      {
        sentence:
          "But I wouldn't rule anything out for the future, and obviously, we are constantly surveilling the market.\r\n\r\n ",
        start: 1774.9,
        end: 1793.4,
        topic_id: [],
      },
      {
        sentence:
          "We'll take our next question from Erik Woodring with Morgan Stanley.\r\n\r\n ",
        start: 1793.4,
        end: 1796.29,
        topic_id: [],
      },
      {
        sentence: "I have two as well. ",
        start: 1796.29,
        end: 1802.1,
        topic_id: [],
      },
      {
        sentence:
          "Maybe Tim, if I start with you, I think there's a debate in the market that if you look back over time, there's been a three-year cadence to  iPhone cycles. ",
        start: 1802.1,
        end: 1811.219999,
        topic_id: [],
      },
      {
        sentence: "We're two years into your 5G iPhone  evolution. ",
        start: 1811.219999,
        end: 1814.23,
        topic_id: [],
      },
      {
        sentence: "You're on track to grow units in '21 and '22 .\r\n\r\n ",
        start: 1814.23,
        end: 1818.23,
        topic_id: [],
      },
      {
        sentence:
          "That implies there could be some pressure next year as upgrade rates slow. ",
        start: 1818.23,
        end: 1821.99,
        topic_id: [],
      },
      {
        sentence: "But your comments really suggest no slowdown. ",
        start: 1821.99,
        end: 1824.48,
        topic_id: [],
      },
      {
        sentence:
          "You're seeing double-digit growth in customers new to iPhone . ",
        start: 1824.48,
        end: 1827.01,
        topic_id: [],
      },
      {
        sentence:
          "So can  you just walk us through some of the  various factors you believe are driving this continued iPhone strength? ",
        start: 1827.01,
        end: 1832.48,
        topic_id: [],
      },
      {
        sentence: "And then I have a follow-up.\r\n\r\n ",
        start: 1832.48,
        end: 1835.56,
        topic_id: [],
      },
      {
        sentence:
          "Today, the product and the innovation within the product that is driving it. ",
        start: 1835.56,
        end: 1840.72,
        topic_id: [],
      },
      {
        sentence:
          "And of  course , the other key variables are some things that Luca mentioned earlier where the size of the installed base has been growing significantly. ",
        start: 1840.72,
        end: 1851.179999,
        topic_id: [],
      },
      {
        sentence:
          "We also, just in this quarter, the June quarter, set a June quarter record for switchers with strong double-digit growth And.  ",
        start: 1851.179999,
        end: 1861.64,
        topic_id: [],
      },
      {
        sentence:
          "so this is fueling the additional installed base even more.\r\n\r\n ",
        start: 1861.64,
        end: 1867.759999,
        topic_id: [],
      },
      {
        sentence:
          "And we continue to execute across some significant geographies where there's a very low penetration of iPhone. ",
        start: 1867.759999,
        end: 1880.47,
        topic_id: [],
      },
      {
        sentence:
          "Some of those were called out in the opening remarks between Indonesia and Vietnam and India, where we did quite well, and iPhone  tends  to  be the engine for those markets, particularly at the beginning of creating the market there for Apple products. ",
        start: 1880.47,
        end: 1902.139999,
        topic_id: [],
      },
      {
        sentence:
          "And so we're really looking at all of these things from the installed base to the number of switchers to the geographic distribution. ",
        start: 1902.139999,
        end: 1912.82,
        topic_id: [],
      },
      {
        sentence:
          "Of course, the most important thing for us is to maintain an incredible customer satisfaction and loyalty from the customers.\r\n\r\n ",
        start: 1912.82,
        end: 1922.179999,
        topic_id: [],
      },
      {
        sentence:
          "And we're really pleased that it's currently at 98% for the latest iPhones. ",
        start: 1922.179999,
        end: 1928.03,
        topic_id: [],
      },
      {
        sentence: "And so those are the things that underpin it. ",
        start: 1928.03,
        end: 1932.42,
        topic_id: [],
      },
      {
        sentence: "5G has been an accelerant. ",
        start: 1932.42,
        end: 1934.9399999999998,
        topic_id: [],
      },
      {
        sentence:
          "And the 5G penetration, particularly if you look at it globally, is still quite low.\r\n\r\n ",
        start: 1934.9399999999998,
        end: 1942.639999,
        topic_id: [],
      },
      {
        sentence:
          "In some geographies, it's obviously higher, but around the world, 5G penetration is still low And.  ",
        start: 1942.639999,
        end: 1948.98,
        topic_id: [],
      },
      {
        sentence: "so I think there's reason to be optimistic.\r\n\r\n ",
        start: 1948.98,
        end: 1956.38,
        topic_id: [],
      },
      {
        sentence: "OK, that's helpful. ",
        start: 1956.38,
        end: 1957.24,
        topic_id: [],
      },
      {
        sentence: "And then maybe, Luca, for you. ",
        start: 1957.24,
        end: 1960.57,
        topic_id: [],
      },
      {
        sentence:
          "As we move from the June to the September quarter, maybe can you dig a level deeper and kind of help us understand some of the moving pieces in the services business? ",
        start: 1960.57,
        end: 1969.25,
        topic_id: [],
      },
      {
        sentence:
          "Meaning where do you think we could see an acceleration or maybe a deceleration? ",
        start: 1969.25,
        end: 1974.15,
        topic_id: [],
      },
      {
        sentence: "And should we still expect double-digit growth? ",
        start: 1974.15,
        end: 1976.82,
        topic_id: [],
      },
      {
        sentence:
          "If you could just frame that for us, that would  be great.\r\n\r\n ",
        start: 1976.82,
        end: 1980.709999,
        topic_id: [],
      },
      {
        sentence: "Yes, Erik. ",
        start: 1980.709999,
        end: 1982.739999,
        topic_id: [],
      },
      {
        sentence:
          "I mentioned in my prepared remarks that we expect some deceleration from the 12% that we've had in the June  quarter. ",
        start: 1982.739999,
        end: 1993.96,
        topic_id: ["82727239-a73d-4956-95af-fe6e845696bd"],
      },
      {
        sentence:
          "Keep in mind, we're going to see, on a year-over-year basis, 600 basis points, 6% impact from foreign exchange so that is a big element for us. ",
        start: 1993.96,
        end: 2007.61,
        topic_id: ["82727239-a73d-4956-95af-fe6e845696bd"],
      },
      {
        sentence:
          "Also keep in mind that we're still lapping the impact of  our business in Russia in these numbers.\r\n\r\n ",
        start: 2007.61,
        end: 2016.55,
        topic_id: [],
      },
      {
        sentence:
          "And Tim mentioned that there are some pockets of weakness, primarily in digital advertising that we will need to work through. ",
        start: 2016.55,
        end: 2027.94,
        topic_id: [],
      },
      {
        sentence:
          "But at the same time, our Services business a year ago grew a lot and so also the compare is a bit challenging. ",
        start: 2027.94,
        end: 2035.67,
        topic_id: [],
      },
      {
        sentence:
          "So we don't have a very  specific number to give out today. ",
        start: 2035.67,
        end: 2043.13,
        topic_id: [],
      },
      {
        sentence: "Of course, we expect to grow.\r\n\r\n ",
        start: 2043.13,
        end: 2044.66,
        topic_id: [],
      },
      {
        sentence: "We will see how the quarter develops.\r\n\r\n ",
        start: 2044.66,
        end: 2056.35,
        topic_id: [],
      },
      {
        sentence:
          "We'll take our next question from Richard Kramer with Arete Research.\r\n\r\n ",
        start: 2056.35,
        end: 2061.62,
        topic_id: [],
      },
      {
        sentence:
          "Tim, you cited growth in Apple apps in the past, and clearly, the privacy policies you've taken have really reshaped the mobile ad market. ",
        start: 2061.62,
        end: 2072.6099999999997,
        topic_id: ["a5327b06-9291-48d9-9c78-5e4e7d532abb"],
      },
      {
        sentence:
          "Can you give us a  sense  of how you see Apple's role as an ad network and perhaps helping developers to monetize not just app sales but also growing ad monetization over time?\r\n\r\n ",
        start: 2072.6099999999997,
        end: 2086.1,
        topic_id: [],
      },
      { sentence: "Yes. ", start: 2086.1, end: 2086.33, topic_id: [] },
      {
        sentence: "Richard, we view privacy as  a fundamental human right. ",
        start: 2086.33,
        end: 2090.1,
        topic_id: ["93e4997e-df50-4ce0-88be-e74a6db71b28"],
      },
      {
        sentence:
          "And so what we try to do with all of our features on privacy is put the decision back at the user where we believe it belongs as to whether they want to share their data or not. ",
        start: 2090.1,
        end: 2108.68,
        topic_id: ["a5327b06-9291-48d9-9c78-5e4e7d532abb"],
      },
      {
        sentence:
          "And so that was what was behind application tracking transparency and a number of other features.\r\n\r\n ",
        start: 2108.68,
        end: 2115.9,
        topic_id: [],
      },
      {
        sentence:
          "We're trying to empower the user to own their data and make their own choices. ",
        start: 2115.9,
        end: 2121.57,
        topic_id: [],
      },
      {
        sentence:
          "In terms of us selling ads , we have a search ad business across the App Store that we believe represents a great way for discovery for small and large developers. ",
        start: 2121.57,
        end: 2138.059999,
        topic_id: [],
      },
      {
        sentence: "And so I see that we play a role in that.\r\n\r\n ",
        start: 2138.059999,
        end: 2142.2799990000003,
        topic_id: [],
      },
      {
        sentence: "And then maybe my follow-up for Luca. ",
        start: 2142.2799990000003,
        end: 2149.76,
        topic_id: [],
      },
      {
        sentence:
          "Can you give us a sense, especially now that you're launching Pay Later, what steps you might be taking to improve affordability of Apple products? ",
        start: 2149.76,
        end: 2158.26,
        topic_id: [],
      },
      {
        sentence:
          "We know that it's  going to be a tight time economically for people around the world. ",
        start: 2158.26,
        end: 2163.65,
        topic_id: [],
      },
      {
        sentence:
          "And how do you see the evolution of various payment plans out of the -- you see in the U.S. now into other markets, especially emerging markets?\r\n\r\n ",
        start: 2163.65,
        end: 2174.65,
        topic_id: [],
      },
      { sentence: "Yes. ", start: 2174.65, end: 2174.969999, topic_id: [] },
      {
        sentence:
          "I mean, obviously, affordability is a very important topic for us It's.  ",
        start: 2174.969999,
        end: 2179.82,
        topic_id: [],
      },
      {
        sentence: "been for many years. ",
        start: 2179.82,
        end: 2182.32,
        topic_id: [],
      },
      {
        sentence:
          "Buy now, pay later is the latest that we are doing on this front.\r\n\r\n ",
        start: 2182.32,
        end: 2186.65,
        topic_id: [],
      },
      {
        sentence:
          "Fundamentally, we are working on two major initiatives for affordability. ",
        start: 2186.65,
        end: 2194.789999,
        topic_id: [],
      },
      {
        sentence:
          "One is installment plans and installment plans have become more widespread around the world, not only here in the United States but in most markets, particularly in emerging markets. ",
        start: 2194.789999,
        end: 2206.32,
        topic_id: [],
      },
      {
        sentence:
          "Incredibly important in terms of reducing the affordability threshold. ",
        start: 2206.32,
        end: 2210.61,
        topic_id: [],
      },
      {
        sentence: "And trading programs.\r\n\r\n ",
        start: 2210.61,
        end: 2212.73,
        topic_id: [],
      },
      {
        sentence: "Trading programs are available in a number of markets. ",
        start: 2212.73,
        end: 2216.26,
        topic_id: [],
      },
      {
        sentence: "We can do better in other markets. ",
        start: 2216.26,
        end: 2220.02,
        topic_id: [],
      },
      {
        sentence:
          "They're incredibly important because the residual value of our products  is  a huge differentiator for our users. ",
        start: 2220.02,
        end: 2229.04,
        topic_id: [],
      },
      {
        sentence:
          "After they use our devices, they can bring them back and they retain much more value than other platforms.\r\n\r\n ",
        start: 2229.04,
        end: 2235.2,
        topic_id: [],
      },
      {
        sentence:
          "And therefore, it's important for us to raise that awareness. ",
        start: 2235.2,
        end: 2238.58,
        topic_id: [],
      },
      {
        sentence:
          "And so we will continue to expand those programs around  the world. ",
        start: 2238.58,
        end: 2242.41,
        topic_id: [],
      },
      {
        sentence:
          "So installments and trade-ins, very, very important on affordability.\r\n\r\n ",
        start: 2242.41,
        end: 2255.6,
        topic_id: [],
      },
      {
        sentence:
          "We'll take our next question from David Vogt with UBS.\r\n\r\n ",
        start: 2255.6,
        end: 2262.41,
        topic_id: [],
      },
      {
        sentence:
          "I just wanted to circle back on sort of the macro and sort of the demand signals that you're seeing versus sort of the supply chains that you're facing. ",
        start: 2262.41,
        end: 2269.33,
        topic_id: [],
      },
      {
        sentence:
          "I know that there's been a couple of U.S. carriers that have talked about some of their customers having some difficulty paying bills. ",
        start: 2269.33,
        end: 2275.57,
        topic_id: [],
      },
      {
        sentence:
          "And you  mentioned in your prepared remarks that you saw sort of the record number of switchers in the quarter.\r\n\r\n ",
        start: 2275.57,
        end: 2280.059999,
        topic_id: [],
      },
      {
        sentence:
          "So just wanted to kind of get a sense for what you're seeing in that particular channel without naming a specific customer. ",
        start: 2280.059999,
        end: 2286.08,
        topic_id: [],
      },
      {
        sentence:
          "And are you seeing any sort of issues from a spin-down effect maybe because customers are having some difficulty because of inflation? ",
        start: 2286.08,
        end: 2293.52,
        topic_id: [],
      },
      {
        sentence: "And then I have a follow-up on Macs.\r\n\r\n ",
        start: 2293.52,
        end: 2296.559999,
        topic_id: [],
      },
      {
        sentence:
          "On the -- from an aggregate point of view, looking at it worldwide, looking at the data on iPhone for the June quarter, there's not obvious evidence in there that there's a macroeconomic headwind. ",
        start: 2296.559999,
        end: 2314.16,
        topic_id: [],
      },
      {
        sentence: "I'm not saying that there's not one. ",
        start: 2314.16,
        end: 2315.719999,
        topic_id: [],
      },
      {
        sentence:
          "I'm saying that the data doesn't show it where we can clearly see that in the Wearables, Home and Accessories area. ",
        start: 2315.719999,
        end: 2325.1,
        topic_id: [],
      },
      {
        sentence: "And so I would differentiate those two.\r\n\r\n ",
        start: 2325.1,
        end: 2333.53,
        topic_id: [],
      },
      { sentence: "Great. ", start: 2333.53, end: 2333.94, topic_id: [] },
      {
        sentence:
          "And then on the Mac business, I know that you are severely supply chain constrained. ",
        start: 2333.94,
        end: 2339.25,
        topic_id: ["7f7379c1-148d-4668-8def-f8b959653f0c"],
      },
      {
        sentence:
          "But is there a way to kind of think about the impact of the market overall on the Mac business versus the supply chain? ",
        start: 2339.25,
        end: 2346.71,
        topic_id: ["7f7379c1-148d-4668-8def-f8b959653f0c"],
      },
      {
        sentence:
          "It sounds like -- I guess, it sounds like it's almost effectively 100% supply chain constrained, but we're obviously hearing, like I'm sure you guys are seeing, anecdotal evidence and  some quantifiable evidence that  the broader PC market is slowing. ",
        start: 2346.71,
        end: 2358.42,
        topic_id: ["7f7379c1-148d-4668-8def-f8b959653f0c"],
      },
      {
        sentence:
          "And I think about 90 days ago, you were pretty confident with the new M2 chip that you could continue to grow throughout a potential drawdown in that particular market.\r\n\r\n ",
        start: 2358.42,
        end: 2367.73,
        topic_id: [],
      },
      {
        sentence: "Do you still feel that way? ",
        start: 2367.73,
        end: 2368.849999,
        topic_id: [],
      },
      {
        sentence:
          "And if you can kind of share how you're thinking about the different sort of components of your growth versus the market.\r\n\r\n ",
        start: 2368.849999,
        end: 2375.029999,
        topic_id: [],
      },
      {
        sentence: "Yes, I wouldn't want to project into this quarter. ",
        start: 2375.029999,
        end: 2378.7,
        topic_id: [],
      },
      {
        sentence:
          "But for last quarter, what we saw was the -- when the COVID restrictions hit in the Shanghai corridor, we lost the primary source of supply for Mac units. ",
        start: 2378.7,
        end: 2396.56,
        topic_id: ["a106b425-de0d-4b7c-8e5d-322b819a5e0a"],
      },
      {
        sentence:
          "And that was either running at a reduced rate or down completely for the majority of the quarter. ",
        start: 2396.56,
        end: 2406.2,
        topic_id: [],
      },
      {
        sentence:
          "And so it was a very big impact to the Mac business.\r\n\r\n ",
        start: 2406.2,
        end: 2413.02,
        topic_id: [],
      },
      {
        sentence:
          "We felt good, frankly, that we were able to, by the end of the quarter, get this back to where we were down 10 points. ",
        start: 2413.02,
        end: 2425.9,
        topic_id: [],
      },
      {
        sentence:
          "But the negative 10 I would classify as being driven by supply. ",
        start: 2425.9,
        end: 2432.21,
        topic_id: [],
      },
      {
        sentence:
          "And of course, FX feeds into this as well because of  the translation issues around the world. ",
        start: 2432.21,
        end: 2441.33,
        topic_id: [],
      },
      {
        sentence:
          "There's also some impact because of the business in Russia.\r\n\r\n ",
        start: 2441.33,
        end: 2444.65,
        topic_id: [],
      },
      {
        sentence:
          "But those are the three kind of reasons that I would tell you. ",
        start: 2444.65,
        end: 2453.91,
        topic_id: [],
      },
      {
        sentence:
          "In terms of testing the demand, you can't really test the demand unless you have the supply. ",
        start: 2453.91,
        end: 2461.19,
        topic_id: [],
      },
      {
        sentence:
          "And we were so far from that last quarter that we have an estimate of what we believe demand was. ",
        start: 2461.19,
        end: 2471.41,
        topic_id: [],
      },
      {
        sentence: "But it is an estimate.\r\n\r\n ",
        start: 2471.41,
        end: 2476.66,
        topic_id: [],
      },
      {
        sentence: "We recognize how the industry is doing. ",
        start: 2476.66,
        end: 2479.549999,
        topic_id: [],
      },
      {
        sentence:
          "We think that we've got a great story with the Mac with getting M1 out and now M2 out. ",
        start: 2479.549999,
        end: 2489.24,
        topic_id: [],
      },
      {
        sentence:
          "We have a very, very strong offering for the back-to-school season and we'll see how we do this quarter. ",
        start: 2489.24,
        end: 2497.599999,
        topic_id: [],
      },
      {
        sentence: "We'll report back in October.\r\n\r\n ",
        start: 2497.599999,
        end: 2508.67,
        topic_id: [],
      },
      {
        sentence:
          "We'll take our next question from Ben Bollin with Cleveland Research.\r\n\r\n ",
        start: 2508.67,
        end: 2518.859999,
        topic_id: [],
      },
      {
        sentence:
          "Tim, I was hoping you could share a little bit more about how you're thinking about the supply headwinds. ",
        start: 2518.859999,
        end: 2523.71,
        topic_id: [],
      },
      {
        sentence:
          "You said less severe or less worse supply challenges into September. ",
        start: 2523.71,
        end: 2530.62,
        topic_id: ["a106b425-de0d-4b7c-8e5d-322b819a5e0a"],
      },
      {
        sentence:
          "I'm interested when you think you find balance across products. ",
        start: 2530.62,
        end: 2534.719999,
        topic_id: [],
      },
      {
        sentence:
          "And also, any thoughts on how or when that might influence replenishment of  supply into your retail channels?\r\n\r\n ",
        start: 2534.719999,
        end: 2545.33,
        topic_id: [],
      },
      {
        sentence:
          "To give you a little more color on what we saw in the June quarter, we came in slightly below, from a constraint point of view, the $4 billion number that we had put, at the four to eight are the low end of that range. ",
        start: 2545.33,
        end: 2564.57,
        topic_id: [],
      },
      {
        sentence:
          "And the majority of that constraint last quarter was coming out of the COVID restrictions that occurred, that resulted in plant closures and plants running at less than full utilization for some amount of the quarter, in some cases, the majority of the quarter. ",
        start: 2564.57,
        end: 2587.77,
        topic_id: [],
      },
      {
        sentence:
          "And then the other component that is the minority part of it is the silicon shortage that has affected our results for several quarters now. ",
        start: 2587.77,
        end: 2601.07,
        topic_id: [],
      },
      {
        sentence:
          "If you look into the future, the silicon shortage, we're not forecasting when that will end.\r\n\r\n ",
        start: 2601.07,
        end: 2611.25,
        topic_id: [],
      },
      {
        sentence:
          "We think that in the aggregate, our constraint numbers for the September quarter will be less than they were in the June quarter. ",
        start: 2611.25,
        end: 2623.389999,
        topic_id: [],
      },
      {
        sentence:
          "But of the two -- there are these two components, and of course, we're optimistic about the COVID restriction piece of this.\r\n\r\n ",
        start: 2623.389999,
        end: 2637.7299999999996,
        topic_id: [],
      },
      {
        sentence: "OK. ",
        start: 2637.7299999999996,
        end: 2639.0,
        topic_id: [],
      },
      {
        sentence: "The other item. ",
        start: 2639.0,
        end: 2640.59,
        topic_id: [],
      },
      {
        sentence:
          "Tim, any thoughts on how you're thinking strategy is evolving with respect to progress in AR, VR in your existing products? ",
        start: 2640.59,
        end: 2651.15,
        topic_id: [],
      },
      {
        sentence:
          "Anything you're learning about content or how you're thinking about that opportunity?\r\n\r\n ",
        start: 2651.15,
        end: 2656.2,
        topic_id: [],
      },
      {
        sentence:
          "We're thrilled right now to have over 14,000 ARKit apps in the App Store. ",
        start: 2656.2,
        end: 2664.51,
        topic_id: [],
      },
      {
        sentence:
          "And they're providing incredible AR experiences for millions of people. ",
        start: 2664.51,
        end: 2668.91,
        topic_id: [],
      },
      {
        sentence: "And that's utilizing iPhone and iPad. ",
        start: 2668.91,
        end: 2673.97,
        topic_id: [],
      },
      {
        sentence:
          "And of  course  we,  are in the business of innovation so we're always exploring new and emerging technologies.\r\n\r\n ",
        start: 2673.97,
        end: 2683.33,
        topic_id: [],
      },
      {
        sentence: "But I wouldn't want to say anything beyond that.\r\n\r\n ",
        start: 2683.33,
        end: 2696.02,
        topic_id: [],
      },
      {
        sentence:
          "We'll take our next question from Wamsi  Mohan with Bank of America.\r\n\r\n ",
        start: 2696.02,
        end: 2702.6,
        topic_id: [],
      },
      {
        sentence:
          "Luca, you mentioned revenues to accelerate year over year overall in September versus your June growth rate. ",
        start: 2702.6,
        end: 2710.11,
        topic_id: ["95ea7b3c-7905-4a80-b3ed-1581e5097c8b"],
      },
      {
        sentence:
          "Would you say that it would be reasonable to assume normal quarter-on-quarter seasonality of about $7 billion or so? ",
        start: 2710.11,
        end: 2719.27,
        topic_id: [],
      },
      {
        sentence:
          "Or would you say there are additional puts and takes this time around that could drive upside and downside to that? ",
        start: 2719.27,
        end: 2724.52,
        topic_id: [],
      },
      {
        sentence:
          "I know you noted 600 bps year over year on FX as potentially one of those. ",
        start: 2724.52,
        end: 2729.52,
        topic_id: [],
      },
      {
        sentence:
          "But maybe help us think through, on a sequential basis, how much of a normal versus abnormal seasonality we should expect? ",
        start: 2729.52,
        end: 2740.29,
        topic_id: [],
      },
      {
        sentence: "And I have a follow-up.\r\n\r\n ",
        start: 2740.29,
        end: 2741.15,
        topic_id: [],
      },
      {
        sentence:
          "Yes, Wamsi, as you know, as we said earlier, we're not providing guidance because of all the uncertainty out there. ",
        start: 2741.15,
        end: 2751.3499989999996,
        topic_id: [],
      },
      {
        sentence: "But we have given a few data points. ",
        start: 2751.3499989999996,
        end: 2755.9,
        topic_id: [],
      },
      {
        sentence:
          "So one of them, which you've mentioned, approximately 600 basis points of negative foreign exchange. ",
        start: 2755.9,
        end: 2763.5,
        topic_id: [],
      },
      {
        sentence:
          "I mean, you do a rough math, it's around $5 billion.\r\n\r\n ",
        start: 2763.5,
        end: 2768.12,
        topic_id: [],
      },
      {
        sentence:
          "That's a big number right there that is going to affect us, that we are having some impact from the situation in Russia and that is obviously different  from normal seasonality as well. ",
        start: 2768.12,
        end: 2784.94,
        topic_id: [],
      },
      {
        sentence:
          "Our supply constraints, as Tim just said, are going to be lower than what we've seen in the June quarter but they're still going to be there. ",
        start: 2784.94,
        end: 2792.98,
        topic_id: [
          "7f7379c1-148d-4668-8def-f8b959653f0c",
          "a106b425-de0d-4b7c-8e5d-322b819a5e0a",
        ],
      },
      {
        sentence:
          "So when you look at those three headwinds and you combine them with the acceleration that we talked about, we feel that that is pretty remarkable.\r\n\r\n ",
        start: 2792.98,
        end: 2809.8,
        topic_id: [],
      },
      { sentence: "OK, Luca. ", start: 2809.8, end: 2810.91, topic_id: [] },
      {
        sentence:
          "And Tim, I wanted to follow up on your comment about the macro impact that you've seen on wearables. ",
        start: 2810.91,
        end: 2817.8999999999996,
        topic_id: [],
      },
      {
        sentence:
          "Your wearables portfolio is probably at the lowest ASP range across your product portfolio. ",
        start: 2817.8999999999996,
        end: 2826.199999,
        topic_id: [],
      },
      {
        sentence:
          "As you're giving this guidance or directional guidance here, how much of an impact are you assuming in potentially any macro-related slowdowns across the rest of the portfolio? ",
        start: 2826.199999,
        end: 2839.57,
        topic_id: ["82727239-a73d-4956-95af-fe6e845696bd"],
      },
      {
        sentence:
          "Why would investors not think that it would be prudent to assume  some sort of creep-up of some of these -- some of the hesitation maybe that the macro environment is driving, particularly as it pertains to your higher ASP products?\r\n\r\n ",
        start: 2839.57,
        end: 2858.95,
        topic_id: [],
      },
      { sentence: "Yes. ", start: 2858.95, end: 2859.089999, topic_id: [] },
      {
        sentence:
          "Let me expand a little bit on Wearables, Home, and Accessories so that I clearly communicate what we saw. ",
        start: 2859.089999,
        end: 2869.01,
        topic_id: [],
      },
      {
        sentence:
          "We saw sort of a cocktail of headwinds on Wearables, Home, and Accessories. ",
        start: 2869.01,
        end: 2875.79,
        topic_id: [],
      },
      {
        sentence: "We saw FX, which we've  talked about.\r\n\r\n ",
        start: 2875.79,
        end: 2879.469999,
        topic_id: [],
      },
      {
        sentence: "We saw supply constraints, which we've talked about. ",
        start: 2879.469999,
        end: 2883.9100000000003,
        topic_id: [],
      },
      {
        sentence:
          "Of course, there was an impact from the business in Russia. ",
        start: 2883.9100000000003,
        end: 2889.17,
        topic_id: [],
      },
      {
        sentence:
          "But in addition to those things, which -- those things affected all the products to some degree, we also had a different launch timing for certain home and accessory products. ",
        start: 2889.17,
        end: 2903.17,
        topic_id: [],
      },
      {
        sentence:
          "Like in the year-ago quarter, I think, had AirTag in it.\r\n\r\n ",
        start: 2903.17,
        end: 2907.73,
        topic_id: [],
      },
      {
        sentence:
          "That's just one example of something that announced last year that didn't announce this -- that we didn't have a comparable announcement this year. ",
        start: 2907.73,
        end: 2917.54,
        topic_id: [],
      },
      {
        sentence:
          "And in -- so in addition to those four items, we believe, based on the data, that there was also a macroeconomic environment hit. ",
        start: 2917.54,
        end: 2927.79,
        topic_id: [],
      },
      {
        sentence:
          "And whether or not that is because they're lower ASPs versus the higher ASPs of a phone, I can't tell you that. ",
        start: 2927.79,
        end: 2939.99,
        topic_id: [],
      },
      {
        sentence:
          "I can just tell you that looking at the numbers, there does appear to be headwinds in addition to the four items that we can articulate and we believe those to be macroeconomic headwinds.\r\n\r\n ",
        start: 2939.99,
        end: 2963.52,
        topic_id: [],
      },
      {
        sentence:
          "We'll take our next question from Samik Chatterjee with J.P. Morgan.\r\n\r\n ",
        start: 2963.52,
        end: 2968.34,
        topic_id: [],
      },
      { sentence: "Great. ", start: 2968.34, end: 2968.75, topic_id: [] },
      {
        sentence: "And congrats on the results in  this tough macro. ",
        start: 2968.75,
        end: 2974.47,
        topic_id: [],
      },
      {
        sentence:
          "I guess I wanted to start with China smartphone market here a bit. ",
        start: 2974.47,
        end: 2979.48,
        topic_id: [],
      },
      {
        sentence:
          "Tim, I thought you said in response to earlier  question that you haven't really seen a material impact from  the macro on iPhone yet.\r\n\r\n ",
        start: 2979.48,
        end: 2988.3,
        topic_id: [],
      },
      {
        sentence:
          "But wondering, did you see an impact of the COVID lockdowns there on demand itself? ",
        start: 2988.3,
        end: 2993.88,
        topic_id: [],
      },
      {
        sentence: "Or was there a  snapback fall in that? ",
        start: 2993.88,
        end: 2996.01,
        topic_id: [],
      },
      {
        sentence:
          "If you  can comment about the sort of exit run rate that you saw in that market, following the COVID  shutdowns ending there. ",
        start: 2996.01,
        end: 3004.089999,
        topic_id: [],
      },
      {
        sentence: "And I have a  follow-up.\r\n\r\n ",
        start: 3004.089999,
        end: 3006.059999,
        topic_id: [],
      },
      {
        sentence: "Yes, both things are true. ",
        start: 3006.059999,
        end: 3007.95,
        topic_id: [],
      },
      {
        sentence:
          "We did see a lower demand based on the COVID lockdowns in the cities the COVID lockdowns  affected. ",
        start: 3007.95,
        end: 3019.57,
        topic_id: ["71abe621-58f5-460d-b2c0-b9fe840926e6"],
      },
      {
        sentence:
          "And we did see a rebound in those same cities toward the end of the quarter in the June time frame. ",
        start: 3019.57,
        end: 3029.33,
        topic_id: [],
      },
      {
        sentence:
          "And in particular, in the run-up to June 18, which as you know, is a major shopping holiday in China.\r\n\r\n ",
        start: 3029.33,
        end: 3038.79,
        topic_id: [],
      },
      {
        sentence:
          "We think that the net of that was still a negative, but some of it did rebound by June time frame. ",
        start: 3038.79,
        end: 3051.9,
        topic_id: [],
      },
      {
        sentence:
          "The restrictions begin to come off toward the beginning of June, if my memory is correct.\r\n\r\n ",
        start: 3051.9,
        end: 3061.33,
        topic_id: [],
      },
      { sentence: "OK. ", start: 3061.33, end: 3061.62, topic_id: [] },
      {
        sentence:
          "And for my follow-up, I know you said you don't want to sort of predict the macro here or be an economist. ",
        start: 3061.62,
        end: 3066.53,
        topic_id: [],
      },
      {
        sentence:
          "But if I go back and look at the sort of opex for the last few years, you've been increasing that by a double-digit percentage. ",
        start: 3066.53,
        end: 3074.49,
        topic_id: [],
      },
      {
        sentence:
          "And just given the uncertainty that you've talked about in the macro further on this call a lot, how are  you thinking about sort of that investment base going forward? ",
        start: 3074.49,
        end: 3083.7,
        topic_id: [],
      },
      {
        sentence:
          "Are you trying to look at areas that where you can sort of pull back? ",
        start: 3083.7,
        end: 3088.87,
        topic_id: [],
      },
      {
        sentence:
          "I mean, just in terms of how you're preparing for the uncertainty is  I  guess  the question.\r\n\r\n ",
        start: 3088.87,
        end: 3095.77,
        topic_id: [],
      },
      {
        sentence: "We believe in investing through the downturn. ",
        start: 3095.77,
        end: 3098.45,
        topic_id: [],
      },
      {
        sentence:
          "And so we'll continue to hire people and invest in areas, but we are being more deliberate in doing so in recognition of the realities of the environment.\r\n\r\n ",
        start: 3098.45,
        end: 3121.640001,
        topic_id: [],
      },
      {
        sentence:
          "We'll take our  next question from Jim Suva with Citigroup.\r\n\r\n ",
        start: 3121.640001,
        end: 3131.359999,
        topic_id: [],
      },
      {
        sentence:
          "While I'm calling you on my iPhone 13 Max Pro and loving it, I just wanted to ask you, though, with replacement cycles, have you noticed any change now that we've been through like two and a half years of COVID where  people upgrading at a different rate and kind of post-COVID, hopefully, upgrade cycles or replacement cycles, how we should kind of think about that? ",
        start: 3131.359999,
        end: 3151.3,
        topic_id: [],
      },
      {
        sentence:
          "Obviously, when I drop and break my phone, I replace it immediately. ",
        start: 3151.3,
        end: 3155.02,
        topic_id: [],
      },
      {
        sentence: "But a normal replacement, have they changed at all? ",
        start: 3155.02,
        end: 3158.889999,
        topic_id: [],
      },
      {
        sentence: "Any insights from that would be great.\r\n\r\n ",
        start: 3158.889999,
        end: 3161.219999,
        topic_id: [],
      },
      {
        sentence:
          "It's challenging to measure the replacement cycle at any point in time with exact precision, and so I'm going to punt on the question a bit. ",
        start: 3161.219999,
        end: 3173.28,
        topic_id: [],
      },
      {
        sentence:
          "However, our key task is to make a product that everybody loves and that they want to trade in their current phone to get. ",
        start: 3173.28,
        end: 3185.1,
        topic_id: [],
      },
      {
        sentence:
          "And so that's what we are focused on is innovating like crazy and giving somebody something that they really want and see themselves using.\r\n\r\n ",
        start: 3185.1,
        end: 3199.57,
        topic_id: [],
      },
      {
        sentence: "OK, that makes sense. ",
        start: 3199.57,
        end: 3201.25,
        topic_id: [],
      },
      {
        sentence:
          "Well, then maybe I can ask Luca a question more on the gross margins. ",
        start: 3201.25,
        end: 3205.59,
        topic_id: [],
      },
      {
        sentence:
          "As you look ahead, the supply chain issues, expedited shipping and all of that, do you think probably the September quarter is kind of the worst of FX and all those headwinds and things? ",
        start: 3205.59,
        end: 3217.65,
        topic_id: ["7f7379c1-148d-4668-8def-f8b959653f0c"],
      },
      {
        sentence:
          "Or is there a little bit of timing delays due to your contractual purchase commitments that you do, that maybe your suppliers are looking at higher costs and you're benefiting from some lower contracts or maybe that has already caught up? ",
        start: 3217.65,
        end: 3231.4,
        topic_id: [],
      },
      {
        sentence:
          "If you could give us some insights on the  kind  of longer-term nature of the directions or the gross margin impacts.\r\n\r\n ",
        start: 3231.4,
        end: 3238.43,
        topic_id: [],
      },
      {
        sentence:
          "Jim, I would say we provide guidance for the current quarter. ",
        start: 3238.43,
        end: 3243.22,
        topic_id: [],
      },
      {
        sentence:
          "But if we look ahead, there's always a couple of elements in gross margin that are  a bit outside of our control and we need to be mindful of that. ",
        start: 3243.22,
        end: 3253.549999,
        topic_id: [],
      },
      {
        sentence: "One of them is the foreign exchange environment. ",
        start: 3253.549999,
        end: 3255.809999,
        topic_id: [],
      },
      {
        sentence:
          "That is having an impact already for the September quarter, had an impact on June.\r\n\r\n ",
        start: 3255.809999,
        end: 3264.55,
        topic_id: [],
      },
      {
        sentence:
          "And obviously, strong dollar tends to be a headwind for us. ",
        start: 3264.55,
        end: 3268.54,
        topic_id: [],
      },
      {
        sentence:
          "As you know, we have a hedging program and so we mitigate that impact. ",
        start: 3268.54,
        end: 3272.63,
        topic_id: [],
      },
      {
        sentence:
          "But over time, those hedges roll off and so  it becomes more challenging for us. ",
        start: 3272.63,
        end: 3279.17,
        topic_id: [],
      },
      {
        sentence:
          "We'll see what happens with foreign exchange rates over time.\r\n\r\n ",
        start: 3279.17,
        end: 3283.67,
        topic_id: [],
      },
      {
        sentence: "That is going to be a variable that we need to track. ",
        start: 3283.67,
        end: 3287.559999,
        topic_id: [],
      },
      {
        sentence:
          "The other one that has  an impact on the aggregate gross margin is our mix of  products and services. ",
        start: 3287.559999,
        end: 3296.76,
        topic_id: [],
      },
      {
        sentence:
          "As you know, they have different margin profiles for very different reasons, different businesses, even different accounting treatment at times. ",
        start: 3296.76,
        end: 3307.44,
        topic_id: [],
      },
      {
        sentence:
          "And so that is also something that we will need to track over time.\r\n\r\n ",
        start: 3307.44,
        end: 3313.55,
        topic_id: [],
      },
      {
        sentence:
          "What matters to us, I think it goes back to Tim was saying earlier, is we want to make sure that people love our products and services, and we want all of them to be equally successful in the marketplace. ",
        start: 3313.55,
        end: 3326.87,
        topic_id: [],
      },
      {
        sentence:
          "Certainly, as you've seen over the last year, we've had a significant expansion in gross margins in spite of very difficult economic circumstances from COVID to inflation, interest rates going up and our margins have expanded. ",
        start: 3326.87,
        end: 3343.7,
        topic_id: [],
      },
      {
        sentence:
          "From a commodity standpoint, I think you were asking a question around components. ",
        start: 3343.7,
        end: 3348.459999,
        topic_id: [],
      },
      {
        sentence: "Commodities are behaving OK.\r\n\r\n ",
        start: 3348.459999,
        end: 3350.63,
        topic_id: [],
      },
      {
        sentence:
          "We're seeing some price pressure on some silicon components. ",
        start: 3350.63,
        end: 3355.2,
        topic_id: [],
      },
      {
        sentence:
          "But other than that, we've -- actually commodities are behaving well.\r\n\r\n ",
        start: 3355.2,
        end: 3363.96,
        topic_id: [],
      },
      {
        sentence: "Congratulations to you and all your team members.\r\n\r\n ",
        start: 3363.96,
        end: 3366.84,
        topic_id: [],
      },
      {
        sentence: "Thank you.\r\n\r\n ",
        start: 3366.84,
        end: 3372.93,
        topic_id: [],
      },
      {
        sentence:
          "We'll take our next question from Krish Sankar  with Cowen and Company.\r\n\r\n ",
        start: 3372.93,
        end: 3378.56,
        topic_id: [],
      },
      {
        sentence: "And, Tim, I apologize, it's also macro-related. ",
        start: 3378.56,
        end: 3382.33,
        topic_id: [],
      },
      {
        sentence:
          "You mentioned that it impacted digital advertising within Services. ",
        start: 3382.33,
        end: 3385.91,
        topic_id: [],
      },
      {
        sentence:
          "I'm just kind of curious, if the macro does worsen, do you worry about subscriber growth, App Store purchases, etc.? ",
        start: 3385.91,
        end: 3392.8,
        topic_id: [],
      },
      {
        sentence:
          "And conversely, are there any parts of the Service business that you consider recession-proof, like maybe a buy now, pay later, or something else? ",
        start: 3392.8,
        end: 3400.690001,
        topic_id: [],
      },
      {
        sentence: "And then I have  a quick follow-up for Luca.\r\n\r\n ",
        start: 3400.690001,
        end: 3405.3999999999996,
        topic_id: [],
      },
      {
        sentence:
          "We have incorporated all of our thoughts in the guidance that Luca gave, which says that we think in the aggregate, we're going to accelerate revenues in the September quarter as compared to the June quarter and will decelerate on the Services side. ",
        start: 3405.3999999999996,
        end: 3426.39,
        topic_id: ["82727239-a73d-4956-95af-fe6e845696bd"],
      },
      {
        sentence:
          "And so we see the digital advertising cloud, if you will, continuing in the current quarter.\r\n\r\n ",
        start: 3426.39,
        end: 3443.69,
        topic_id: [],
      },
      { sentence: "Got it. ", start: 3443.69, end: 3444.17, topic_id: [] },
      { sentence: "Got it. ", start: 3444.17, end: 3444.77, topic_id: [] },
      {
        sentence: "Very helpful. ",
        start: 3444.77,
        end: 3445.26,
        topic_id: [],
      },
      {
        sentence:
          "And then a quick follow-up on the lockdown in China during the June quarter.\r\n\r\n ",
        start: 3445.26,
        end: 3450.0,
        topic_id: [],
      },
      {
        sentence:
          "Do you actually see any noticeable negative effects on your App Store revenue for the region or any positive effects like maybe more gaming downloads?\r\n\r\n ",
        start: 3450.0,
        end: 3458.48,
        topic_id: [],
      },
      {
        sentence: "China had very good results on services last quarter. ",
        start: 3458.48,
        end: 3469.29,
        topic_id: [],
      },
      {
        sentence:
          "And so they grew strong double digit better than the company average, and they set a new June quarter revenue record during the quarter.\r\n\r\n ",
        start: 3469.29,
        end: 3486.559999,
        topic_id: [],
      },
      {
        sentence: "Thank you. ",
        start: 3486.559999,
        end: 3487.41,
        topic_id: [],
      },
      {
        sentence:
          "A replay of today's call will be available for two weeks as a webcast on apple.com/investor and via telephone. ",
        start: 3487.41,
        end: 3496.45,
        topic_id: [],
      },
      {
        sentence:
          "The numbers for the telephone replay are 888 ( 203)  1112- or (719) 457-0820. ",
        start: 3496.45,
        end: 3506.82,
        topic_id: [],
      },
      {
        sentence: "Please enter confirmation code 8820355.\r\n\r\n ",
        start: 3506.82,
        end: 3511.98,
        topic_id: [],
      },
      {
        sentence:
          "These replays will be available by approximately 5 p.m. Pacific Time today. ",
        start: 3511.98,
        end: 3516.72,
        topic_id: [],
      },
      {
        sentence:
          "Members of the press with additional questions can contact Josh Rosenstock at (408) 862-1142. ",
        start: 3516.72,
        end: 3524.62,
        topic_id: [],
      },
      {
        sentence:
          "Financial analysts can contact me with additional questions at (669) 227-2402.\r\n\r\n ",
        start: 3524.62,
        end: 3532.17,
        topic_id: [],
      },
    ],
  },
  Carnegie: {
    company_name: "Carnegie",
    document_type: "PODCASTS",
    title: "Carnegie Private Banking Pod - Då ökar vi aktievikten igen",
    mediaType: "Podcast",
    audioUrl: "https://cdn.texelio.com/podcast/peter_jonas.mp3",
    audioText: [
      {
        sentence:
          "Så ytterligare lite sämre makrostatistik och att vinstförväntningarna kommer ner. ",
        start: 0.0,
        end: 6.16,
        topic_id: [],
        english_sentence:
          "So some more worse macro statistics and earnings expectations coming down. ",
      },
      {
        sentence:
          "Det är några pusselbitar som vi letar efter för att mer långsiktigt bli mer positiva.\r\n\r\n",
        start: 6.16,
        end: 10.96,
        topic_id: [],
        english_sentence:
          "These are some of the pieces of the puzzle we are looking for to become more positive in the longer term. ",
      },
      {
        sentence:
          "Det här är Investera och agera, en podcast från Carnegie Private Banking. ",
        start: 10.96,
        end: 16.4,
        topic_id: [],
        english_sentence:
          "This is Invest and Act, a podcast from Carnegie Private Banking. ",
      },
      {
        sentence:
          "Hej och välkommen till ett nytt avsnitt av Investera och agera med mig Jonas Elavsson,",
        start: 16.4,
        end: 22.4,
        topic_id: [],
        english_sentence:
          "Hello and welcome to a new episode of Invest and Act with me Jonas Elavsson,",
      },
      {
        sentence:
          "en börsredaktör här på Carnegie Private Banking och tillika chefredaktör för vårt kundmagasin Insikt. ",
        start: 22.4,
        end: 28.16,
        topic_id: [],
        english_sentence:
          "a stock market editor here at Carnegie Private Banking and also editor-in-chief of our client magazine Insikt. ",
      },
      {
        sentence:
          "Idag tänkte vi diskutera lite grann vad som händer på marknaden just nu och även då diskutera vår",
        start: 28.16,
        end: 33.28,
        topic_id: [],
        english_sentence:
          "Today we wanted to discuss a little bit what is happening in the market right now and also discuss our",
      },
      {
        sentence:
          "färska allokering och strategirapport med titel En kritisk fas. ",
        start: 33.28,
        end: 38.4,
        topic_id: [],
        english_sentence:
          "fresh allocation and strategy report entitled A Critical Phase. ",
      },
      {
        sentence:
          "Till vår hjälp så har vi då vår chef för portföljförvaltning Peter Nelsson som gästar podden. ",
        start: 38.4,
        end: 44.4,
        topic_id: [],
        english_sentence:
          "To help us, we have our Head of Portfolio Management Peter Nelsson who is a guest on the podcast. ",
      },
      {
        sentence: "Välkommen Peter!",
        start: 44.4,
        end: 45.84,
        topic_id: [],
        english_sentence: "Welcome Peter!",
      },
      {
        sentence: "Tack Jonas!",
        start: 45.84,
        end: 46.96,
        topic_id: [],
        english_sentence: "Tack Jonas!",
      },
      {
        sentence:
          "Och for the record då får vi säga att när vi spelar in idag så är det onsdagen den 19 oktober.\r\n\r\n",
        start: 46.96,
        end: 50.88,
        topic_id: [],
        english_sentence:
          "And for the record, when we record today, it's Wednesday, October 19. ",
      },
      {
        sentence:
          "Vi sitter här på eftermiddagen och börsen inledde upp här på förmiddagen men är i skrivande stund lite grann ner. ",
        start: 50.88,
        end: 56.56,
        topic_id: ["ee5d5187-0a39-4db9-a7aa-aafcec2386c8"],
        english_sentence:
          "We are sitting here in the afternoon and the stock market started up this morning but is at the time of writing a little bit down. ",
      },
      {
        sentence:
          "Men man måste ändå konstatera att börsen är mycket piggare nu sen tvärvändningen här i ungefär en vecka sedan i torsdags när",
        start: 56.56,
        end: 62.96,
        topic_id: ["34df3923-c5c9-420b-822c-e013ccc3dbf0"],
        english_sentence:
          "But one must still note that the stock market is much more buoyant now since the reversal here in about a week ago last Thursday when",
      },
      {
        sentence:
          "inflationssiffrorna i USA där och även Sverige då presenterades och när börsen har följ och sen rusade. ",
        start: 62.96,
        end: 68.72,
        topic_id: ["6a052f25-d98b-4884-bd9d-1a8fd8f2889e"],
        english_sentence:
          "the inflation figures in the US where and also Sweden then were presented and when the stock market has follow and then rocketed. ",
      },
      {
        sentence:
          "Och vi kan väl konstatera att vi sett en del ljusglimtar och bland annat den här US-hängen i Storbritannien där man har dragit tillbaka",
        start: 68.72,
        end: 74.48,
        topic_id: [],
        english_sentence:
          "And we can say that we have seen some bright spots, including this US hanging in the UK where they have withdrawn",
      },
      {
        sentence:
          "de enormt ofinansierade skattesänkningarna men kanske viktigare som vi kanske kommer in på lite här idag då det är väl",
        start: 74.48,
        end: 80.24,
        topic_id: [],
        english_sentence:
          "the hugely unfunded tax cuts but perhaps more importantly as we might get into a bit here today as it is well",
      },
      {
        sentence:
          "rapportperioden som man ändå får säga har börjat bra med styrkebesked från inte minst amerikanska investmentbanker och",
        start: 80.24,
        end: 86.0,
        topic_id: [],
        english_sentence:
          "the reporting period, which one must say has started well with messages of strength from not least US investment banks and",
      },
      {
        sentence:
          "ur svensk synvinkel då verkstad med Sandvik igår som var överträffade på nästan alla punkter och drog med sig hela sektorn och",
        start: 86.0,
        end: 92.4,
        topic_id: [],
        english_sentence:
          "from a Swedish point of view when the workshop with Sandvik yesterday was outperformed on almost all points and pulled the whole sector along and",
      },
      {
        sentence:
          "Atlas Copco kom idag vid lunchtid som väl också egentligen var väldigt bra kanske kan komma tillbaka till det med.\r\n\r\n",
        start: 92.4,
        end: 98.16,
        topic_id: [],
        english_sentence:
          "Atlas Copco came today at lunchtime which was also really very good maybe can come back to it with. ",
      },
      {
        sentence:
          "Vi har även sett bankrapporter, Handelsbanken som var väldigt stark så att det har dragit med sig banksektorn så det har ju varit positivt och",
        start: 98.16,
        end: 105.2,
        topic_id: [],
        english_sentence:
          "We have also seen bank reports, Handelsbanken which was very strong so that has pulled the banking sector along so that has been positive and",
      },
      {
        sentence:
          "vi har även sett att råvaror generellt får man nog säga har kommit ner, inte minst olja men framförallt kanske naturgas och elpriser och det är ju då",
        start: 105.2,
        end: 112.24,
        topic_id: ["2d8cdf10-4e6d-4bbc-8716-a1717535bb7f"],
        english_sentence:
          "we have also seen that commodities in general have come down, not least oil but especially perhaps natural gas and electricity prices and that is when",
      },
      {
        sentence:
          "positivt för konsumenterna och för inflationen och så där tänkte jag ta in Peter då.\r\n\r\n",
        start: 112.24,
        end: 118.64,
        topic_id: [],
        english_sentence:
          "positive for consumers and for inflation and so I thought I'd bring Peter in then. ",
      },
      {
        sentence:
          "Börsen har stigit någonstans i rundast länge 7% sen torsdagsbotten där i förra veckan. ",
        start: 118.64,
        end: 125.04,
        topic_id: ["34df3923-c5c9-420b-822c-e013ccc3dbf0"],
        english_sentence:
          "The stock market has risen somewhere in the range of 7% since Thursday's bottom there last week. ",
      },
      {
        sentence:
          "Är det ett lättnadsrally, ett så kallat björnmarknadsrally vi ser eller är det någon mer varaktig vändning som vi faktiskt ser här?",
        start: 125.04,
        end: 132.72,
        topic_id: [],
        english_sentence:
          "Is it a relief rally, a so-called bear market rally we are seeing or is there a more lasting turnaround that we are actually seeing here?",
      },
      {
        sentence:
          "Vi är ju inne på att det här är mer av ett centralt lättnadsrally som du pratar om och då har jag nämnt några av anledningarna här ju.\r\n\r\n",
        start: 132.72,
        end: 139.12,
        topic_id: [],
        english_sentence:
          "We're talking about this being more of a central relief rally that you're talking about, and I've mentioned some of the reasons here. ",
      },
      {
        sentence:
          "Man ska väl lägga till också då att börsen hade gått ner ganska mycket innan, inte minst september var ju en svag månad så att sentimentet har ju varit ganska negativt. ",
        start: 139.12,
        end: 150.0,
        topic_id: ["9a85004f-361e-44e0-a131-d720a646be7f"],
        english_sentence:
          "One should also add that the stock market had gone down quite a lot before, not least September was a weak month, so sentiment has been quite negative. ",
      },
      {
        sentence:
          "Vi har sett att positioneringen är ganska negativ och då är ju ofta börsen lite mottaglig för att även kunna studsa uppåt. ",
        start: 150.0,
        end: 157.04,
        topic_id: [],
        english_sentence:
          "We have seen that the positioning is quite negative and then the stock market is often a bit susceptible to bounce upwards. ",
      },
      {
        sentence:
          "Det är typiskt så att får man ganska kraftiga studsar uppåt när vi har sådana här bear markets.\r\n\r\n",
        start: 157.04,
        end: 162.16,
        topic_id: ["ee5d5187-0a39-4db9-a7aa-aafcec2386c8"],
        english_sentence:
          "It is typical that you get quite strong bounces upwards when we have these bear markets. ",
      },
      {
        sentence:
          "Det har vi sett det här året också ju, i mars hade vi en ganska kraftig uppgång, i juli hade vi en kraftig uppgång och nu har vi verkligen fått en ganska hyfsad uppgång även i oktober. ",
        start: 162.16,
        end: 170.32,
        topic_id: [],
        english_sentence:
          "We've seen that this year as well, in March we had a pretty strong rise, in July we had a strong rise and now we've really had a pretty decent rise in October as well. ",
      },
      {
        sentence:
          "Men vi tror fortfarande att det är snarare då ett rally i en bear market. ",
        start: 170.32,
        end: 175.6,
        topic_id: [],
        english_sentence:
          "But we still think it is rather then a rally in a bear market. ",
      },
      {
        sentence:
          "Är det kanske lite fördröjd effekt, man ser ju att den här nedgången som man förväntat sig att den tar lite längre tid är väntat eftersom vi har sett lite starka rapporter väntat, är det det?",
        start: 175.6,
        end: 184.56,
        topic_id: [],
        english_sentence:
          "Is it maybe a bit of a delayed effect, you can see that this downturn that you expected to take a bit longer is expected because we've seen some strong reports expected, is that it?",
      },
      {
        sentence: "Det kommer lite längre fram bara att det mildras?",
        start: 184.56,
        end: 187.92,
        topic_id: [],
        english_sentence:
          "It comes a little further down the road just that it mitigates?",
      },
      {
        sentence: "Ja, typ något sådant skulle jag tro.\r\n\r\n",
        start: 187.92,
        end: 190.24,
        topic_id: [],
        english_sentence: "Yes, something like that I would think. ",
      },
      {
        sentence:
          "Vad krävs då för mer varaktig vändning, vad var det för synd där?",
        start: 190.24,
        end: 195.76,
        topic_id: [],
        english_sentence:
          "What then is required for a more lasting turnaround, what was the sin there?",
      },
      {
        sentence:
          "Vi har varit inne på det här ganska länge att vi behöver se någon typ av att inflationen toppar ur och det har vi ju kanske trott att vi har sett tecken på tidigare men nu fick vi ju siffror här om veckan som betyder på att det fortfarande är väldigt höga siffror. ",
        start: 195.76,
        end: 210.72,
        topic_id: ["6a052f25-d98b-4884-bd9d-1a8fd8f2889e"],
        english_sentence:
          "We've been talking about this for quite some time, that we need to see some kind of inflation peaking out, and we may have thought we've seen signs of that before, but now we've got numbers here this week that mean it's still very high numbers. ",
      },
      {
        sentence:
          "Någon typ av att vi kan se toppen för inflationen och att vi då kan tro att centralbackarna börjar backa lite, det är ju en viktig pusselbit och den tycker vi inte riktigt att vi har sett än.\r\n\r\n",
        start: 210.72,
        end: 222.08,
        topic_id: [],
        english_sentence:
          "Something like we can see the peak of inflation and then we can think that the central banks are starting to back off a little bit, that's an important piece of the puzzle and we don't think we've really seen that yet. ",
      },
      {
        sentence:
          "Och sen så har vi även pratat om att vi tror att vi kanske behöver få ner prognosen ännu mer både på vinster och på tillväxt, det har börjat ske men det finns nog mer att göra där. ",
        start: 222.08,
        end: 232.32,
        topic_id: [],
        english_sentence:
          "And then we've also talked about the fact that we think we might need to bring the forecast down even more on both profits and growth, that's started to happen but there's probably more to do there. ",
      },
      {
        sentence:
          "Så ytterligare lite sämre makrostatistik och att vinstförväntningarna kommer ner, det är väl några pusselbitar som vi letar efter för att mer långsiktigt bli mer positiva. ",
        start: 232.32,
        end: 241.68,
        topic_id: [],
        english_sentence:
          "So some further weaker macro data and earnings expectations coming down, those are probably some of the pieces of the puzzle that we are looking for to be more positive in the longer term. ",
      },
      {
        sentence:
          "Samtidigt lite grann om man tittar på det sista så har det varit lite oväntat starka skjut i USA om man tittar på industrier och att en tendens är kanske att man har tagit mot data lite mer positivt ändå, att vinster kanske inte behöver revideras ner lika mycket och att förväntningarna på inflation kanske ändå är på väg neråt.\r\n\r\n",
        start: 241.68,
        end: 258.4,
        topic_id: [],
        english_sentence:
          "At the same time, a little bit if you look at the latest, there have been some unexpectedly strong surges in the US if you look at industries and that a tendency is perhaps that data has been taken a little bit more positively anyway, that profits may not need to be revised down as much and that inflation expectations may still be on the way down. ",
      },
      {
        sentence:
          "Alltså om du tittar på centralbankerna, så lyckas de tämja inflationen utan att döda tillväxten eller vad tror du?",
        start: 258.4,
        end: 267.04,
        topic_id: [],
        english_sentence:
          "So if you look at the central banks, they manage to tame inflation without killing growth or what do you think?",
      },
      {
        sentence:
          "Det beror på vad man menar med döden naturligtvis, vi tror att tillväxten måste komma ner och det är det de vill i ostra komma, så vill de ju såklart inte knäcka ekonomin helt, det är ju den här balansakten som är så svår. ",
        start: 267.04,
        end: 279.52,
        topic_id: [],
        english_sentence:
          "It depends on what you mean by death of course, we think that growth has to come down and that's what they want in the east, so of course they don't want to break the economy completely, it's this balancing act that's so difficult. ",
      },
      {
        sentence:
          "Faktum är att tittar man på Bank of England och även Svenska Riksbanken, de räknar faktiskt nu med negativ tillväxt nästa år 2023, så om det sen är att döda tillväxten eller inte det kan man väl diskutera, men de behöver nog komma en bit till på det här spåret tror jag. ",
        start: 279.52,
        end: 296.48,
        topic_id: ["82727239-a73d-4956-95af-fe6e845696bd"],
        english_sentence:
          "In fact, if you look at the Bank of England and also the Riksbank, they are actually forecasting negative growth next year 2023, so whether that is killing growth or not is debatable, but they probably need to get a bit further on this track I think. ",
      },
      {
        sentence:
          "De vill ju få upp arbetslösheten också lite grann, det är ju fortfarande något som vi inte har sett egentligen, så det är ändå mer jobb att göra att just få ner tillväxten, det är något som behövs i örelsen tänket.\r\n\r\n",
        start: 296.48,
        end: 306.24,
        topic_id: [],
        english_sentence:
          "They also want to get unemployment up a little bit, it's still something that we haven't really seen, so there's still more work to be done to just get growth down, it's something that's needed in the eagle's mind. ",
      },
      {
        sentence:
          "Ja, det låter som att det nu är lite mer återhållsvanor, men om du ska ge någon bäst guess då, när är det tid bedöma att vi kan se någon vändning då, kanske i första inflationstakten eller vad ska vi leta efter?",
        start: 306.24,
        end: 318.48,
        topic_id: [],
        english_sentence:
          "Well, it sounds like there's now a bit more restraint, but if you're going to give any best guess then, when is the time to judge that we might see some turnaround then, maybe in the first rate of inflation or what should we be looking for?",
      },
      {
        sentence:
          "Det är jättesvårt det här, men vi hoppas ju att vi någonstans här under vintern, inom kanske sex månader, börjar få de här signalerna som vi letar efter, men man får ju följa det här successivt och agera därefter, men någonstans inom sex månader, det är väl den bästa gissningen just nu.\r\n\r\n",
        start: 318.48,
        end: 334.4,
        topic_id: [],
        english_sentence:
          "This is very difficult, but we hope that somewhere here in the winter, within maybe six months, we will start to get the signals that we are looking for, but we will have to follow this gradually and act accordingly, but somewhere within six months, that is probably the best guess right now. ",
      },
      {
        sentence:
          "Men om man tänker sig ett annat scenario här då, vad händer om centralbankerna accepterar en någorlunda hög inflationstakt då i stället, ger upp lite grann kanske den här kampen, i alla fall högre inflation än vi haft historiskt och slår av lite grann på räntehöjningstakten eller kanske slår av på höjningen i huvud taget, alltså är det ett troligt scenario och vad ska man äga då i så fall?",
        start: 334.4,
        end: 357.92,
        topic_id: [],
        english_sentence:
          "But if you think of another scenario here, what if the central banks accept a reasonably high inflation rate instead, give up a little bit maybe this fight, at least higher inflation than we've had historically and turn down a little bit on the rate of interest rate increases or maybe turn down the rate of increases at all, so is that a likely scenario and what should you own in that case?",
      },
      {
        sentence:
          "Det är en jätteintressant fråga och absolut inte lätt att svara på för det är många rörliga parametrar i det här, men en del tror ju nog att vi kommer dit någon gång under nästa år om det här fortsätter, att ekonomin blir så pass svag och att man kanske inte vill höja mer och därmed just det som du pratar om att man accepterar kanske en liten höjning i inflation, så vi kan mycket väl hamna där någon gång under nästa år. ",
        start: 357.92,
        end: 379.52,
        topic_id: [],
        english_sentence:
          "It's a very interesting question and certainly not easy to answer because there are a lot of moving parameters in this, but some people think that we will get there sometime next year if this continues, that the economy will be so weak and that they may not want to raise more and therefore just what you are talking about accepting maybe a small increase in inflation, so we may well get there sometime next year. ",
      },
      {
        sentence:
          "Rimligen är det ju den amerikanska centralbanken som borde komma dit först och då tror väl vi att rimligt snabbt är väl ändå att om vi ska räkna med en varaktigt hög inflation då kan nog långränta stiga ytterligare en bit, däremot borde då kortränta högre stanna av, så att en brantare räntekurva, det kan ju vara positivt för en del sektorer, bank till exempel som redan nu har gynnats lite grann borde kunna vara fortsatt en bra tillgång och sen tror vi ändå att det är realtillgångar som på något vis kan skydda sig mot det här med inflation lite grann. ",
        start: 379.52,
        end: 409.44,
        topic_id: ["6a052f25-d98b-4884-bd9d-1a8fd8f2889e"],
        english_sentence:
          "The US Federal Reserve should probably get there first, and then we think that it is reasonable to assume that if we are to expect sustained high inflation, then borrowing limits can probably rise a little further, but short-term interest rates should then stop higher, so that a steeper yield curve can be positive for some sectors, such as banks, which have already benefited a little bit, should continue to be a good asset, and then we still think that real assets can somehow protect themselves against this inflation a little bit. ",
      },
      {
        sentence:
          "Lite bättre än kanske både aktier och räntebärande, så jag tror också att man ska fundera på valuta, om just Fed skulle börja med det här att sluta lite med räntehöjningarna, det borde vara ett minutstecken för dollarn som har varit jättestark så där skulle det kunna bli ganska stora effekter, men som sagt det här är en jättesvår fråga.\r\n\r\n",
        start: 409.44,
        end: 428.32,
        topic_id: [],
        english_sentence:
          "A little bit better than maybe both equities and fixed income, so I think you also have to think about currency, if the Fed in particular were to start with this to stop raising rates a little bit, that should be a minute sign for the dollar which has been really strong so there could be quite a big impact there, but as I say this is a really hard question. ",
      },
      {
        sentence:
          "Ja intressant, vi växlar spår lite grann i min hand här eller framför mig så har vi då den senaste strategirapporten som jag hoppas också att våra kunder har fått i sin brevlåda och om inte annat så finns det en online här för våra kunder och vi har titeln en kritisk fas, alltså vi kanske börja där, varför heter den en kritisk fas eller vad är det som är kritiskt?",
        start: 428.32,
        end: 449.2,
        topic_id: [],
        english_sentence:
          "Well interesting, we're switching tracks a little bit in my hand here or in front of me we have the latest strategy report which I hope our customers have also received in their mailbox and if nothing else there's one online here for our customers and we have the title a critical phase, so maybe we start there, why is it called a critical phase or what is critical about it?",
      },
      {
        sentence:
          "Ja det var väl det du var inne på lite tidigare, det här med att kan man tämja inflationen utan att gå för hårt åt tillväxten, det är ju den kritiska fasen vi är i, att balansakten här som centralbankerna har framför sig så det är ju det enda det anspelar på, vi tror att vi måste gå in i den här kritiska fasen, just att de behöver få bort inflationen eller få ner inflationen och därmed då lite sämre tillväxt. ",
        start: 449.2,
        end: 472.56,
        topic_id: ["6a052f25-d98b-4884-bd9d-1a8fd8f2889e"],
        english_sentence:
          "Well, that's what you were talking about a little bit earlier, that you can tame inflation without going too hard on growth, that's the critical phase that we're in, that the balancing act that the central banks have in front of them, so that's the only thing it alludes to, we think that we need to go into this critical phase, that they need to get inflation out or get inflation down and then a little bit of lower growth as a result. ",
      },
      {
        sentence:
          "Sen anspelar det ju lite grann också på det här med energifrågan, där vi också har en kritisk fas just nu, hur ska det liksom kommande året även på längre sikt energifrågan lösas, så det är väl där vi har de kritiska faserna. ",
        start: 472.56,
        end: 488.56,
        topic_id: [],
        english_sentence:
          "Then it also alludes a little to the energy issue, where we also have a critical phase right now, how to solve the energy issue in the coming year, even in the longer term, so that's where we have the critical phases. ",
      },
      {
        sentence:
          "Jaha och du är då chef för vår portföljförvaltning, jag ska komma in lite grann när man tittar lite översickligt på vår allokering kan man ju säga att vi är ganska fortsatt försiktiga till börsen och har ju varit så en tid, och faktum är att vi gick ju från överviktig aktie till neutral redan vid årsskiftet faktiskt då börserna toppade och under våren sen så har vi då sänkt hela vägen till undervikt som vi har just nu då, men om vi fortsätter hand då så har ju det där varit helt rätt och aktien har ju tappat ganska mycket.\r\n\r\n",
        start: 488.56,
        end: 518.48,
        topic_id: [],
        english_sentence:
          "Well, and you're the head of our portfolio management, I'll come in a little bit when you look at our allocation a little bit, you can say that we're still quite cautious about the stock market and have been for some time, and the fact is that we went from an overweight stock to neutral already at the beginning of the year when the stock markets peaked and then in the spring we have reduced all the way to underweight which we have right now, but if we continue with our hand then that has been quite right and the stock has lost quite a lot. ",
      },
      {
        sentence:
          "Hur ser vår allokering ut nu idag jämfört med tidigare, vad är några förändringar som vi presenterar i vår rapport nu?",
        start: 518.48,
        end: 526.48,
        topic_id: [],
        english_sentence:
          "How does our allocation look now compared to before, what are some changes that we present in our report now?",
      },
      {
        sentence:
          "Alltså det är ju faktiskt ganska likt, eftersom vi har haft det de senaste månaderna eller under sommaren eftersom vi tycker att scenariot är ganska likt, så vi är ju fortfarande underviktade i aktier och vi gillar ju hellre utländska aktier än svenska aktier och aktsläget och vi tycker att man då ska ha en övervikt mot alternativa tillgångar,",
        start: 526.48,
        end: 546.48,
        topic_id: [],
        english_sentence:
          "Well, it's actually quite similar, because we've had it over the last few months or over the summer because we think the scenario is quite similar, so we're still underweight in equities and we like foreign equities rather than Swedish equities and the stock situation and we think that you should have an overweight towards alternative assets then,",
      },
      {
        sentence:
          "vilket vi har dragit en lans för under större delen av året. Sen ska ju ändå sägas då att jag tycker att, och vi är neutrala mot räntebärande kan man väl lägga till också, men det man ändå vill säga är ju att ränteuppgången har ju gjort att det nu börjar ju se mer intressant ut med räntebäranden igen, så att vi letar efter lägen att kunna bli lite mer positiva faktiskt även mot räntebärande och det gäller väl också så att säga börsen. ",
        start: 546.48,
        end: 570.48,
        topic_id: ["38496941-a81e-40c3-99e2-d704c02f20af"],
        english_sentence:
          "which we have been promoting for most of this year. It should also be said that I think, and we are neutral towards interest-bearing securities, one might well add, but what I would still like to say is that the rise in interest rates has meant that interest-bearing securities are now starting to look more interesting again, so we are looking for ways of becoming a little more positive towards interest-bearing securities, and that also applies to the stock market, so to speak. ",
      },
      {
        sentence:
          "Vi tror ju att nästa steg är att vi ska så att säga vikta upp aktieportföljerna igen, men vi tycker ju uppenbarligen att det är fortfarande lite för tidigt för att göra den manövern, så att det är relativt likt det som vi har haft de senaste månaderna.\r\n\r\n",
        start: 570.48,
        end: 583.48,
        topic_id: [],
        english_sentence:
          "We think the next step is to sort of rebalance the equity portfolios, but obviously we think it's still a bit too early to make that move, so it's relatively similar to what we've had in recent months. ",
      },
      {
        sentence:
          "Du säger kanske att det är dags för aktier, eller vad skulle du kunna få göra att öka vikten i aktierna, vad väntar du på där?",
        start: 583.48,
        end: 593.48,
        topic_id: [],
        english_sentence:
          "You might say it's time for stocks, or what could you do to increase the weight in stocks, what are you waiting for there?",
      },
      {
        sentence:
          "Det är ju det här med också centralbankerna och till förväntningarna på vinster och tillväxt, det är ju de frågetecknaderna som vi vill kunna bocka av. ",
        start: 593.48,
        end: 602.48,
        topic_id: [],
        english_sentence:
          "This is also the issue of central banks and expectations of profits and growth, and these are the questions that we want to be able to answer. ",
      },
      {
        sentence:
          "Alternativt är ju att vi skulle få ytterligare så att säga större nedgångar ifrån, att det helt enkelt blir för billigt någonstans, men då tycker vi nog att det borde gå ner åtminstone kanske 10% till för då börjar man få så pass bra odds för att man kanske ändå ska bli lite mer positiv. ",
        start: 602.48,
        end: 619.48,
        topic_id: [],
        english_sentence:
          "The alternative is that we would get further, so to speak, larger declines, that it simply becomes too cheap somewhere, but then we think it should go down at least maybe 10% more because then you start to get such good odds that you might still become a little more positive. ",
      },
      {
        sentence:
          "Men grund och botten är ju det här med att få ner vinstförväntningarna och att centralbankerna kan tona ner retoriken lite grann, det är väl framförallt där vi letar efter de signalerna. ",
        start: 619.48,
        end: 629.48,
        topic_id: [],
        english_sentence:
          "But basically, this thing about getting earnings expectations down and central banks being able to tone down the rhetoric a little bit, that's probably where we're looking for those signals in the first place. ",
      },
      {
        sentence:
          "Valutan har ju verkligen kommit i spel i år, framförallt om man kanske ser på vad som är det viktigaste, eller en av de viktigaste är väl den starka dollarn då, svaga kronorna, eller båda två höger på såhär.\r\n\r\n",
        start: 629.48,
        end: 639.48,
        topic_id: [],
        english_sentence:
          "The currency has really come into play this year, especially if you look at what is the most important, or one of the most important is probably the strong dollar then, weak krona, or both right on like this. ",
      },
      {
        sentence:
          "Vad har det gett för effekter då, är det här peaknivåer vi ser på, vänder dollarn här eller vad tror vi?",
        start: 639.48,
        end: 645.48,
        topic_id: [],
        english_sentence:
          "What has been the impact then, are we looking at peak levels, is the dollar turning here or what do we think?",
      },
      {
        sentence:
          "Ja, som svensk kan man nästan hoppas att det är peaknivåer på dollarn, men risken är väl att, svaret bor ju på vilket risk sentiment vi kommer ha i marknaden, om det fortsätter lite risk off, dålig riskvilja, då finns det ju risk att kronan fortsätter vara ganska svag mot dollarn. ",
        start: 645.48,
        end: 661.48,
        topic_id: [],
        english_sentence:
          "Yes, as a Swede you can almost hope that the dollar will peak, but the risk is that, the answer depends on the risk sentiment we will have in the market, if it continues to be a bit risk off, poor risk appetite, then there is a risk that the krona will continue to be quite weak against the dollar. ",
      },
      {
        sentence:
          "Vi har ju valt att satsa på utländska tillgång istället för svenska och då tjänar man ju lite grann på en svag krona, det är ju en diversifiering man kan göra i portföljen, så vi ligger ju kvar med den synen fortfarande, men sen är det ju anmärkningsvärt hur mycket kronan har tappat mot dollarn, och såklart när pendeln svänger om, då kan det ju gå rätt fort åt andra hållet, men återigen, vi tror inte att vi är riktigt där än.\r\n\r\n",
        start: 661.48,
        end: 689.48,
        topic_id: [],
        english_sentence:
          "We have chosen to invest in foreign assets instead of Swedish assets, and then you benefit a little bit from a weak krona, it's a diversification you can do in the portfolio, so we still have that view, but then it's remarkable how much the krona has lost against the dollar, and of course when the pendulum swings, then it can go quite quickly in the other direction, but again, we don't think we're quite there yet. ",
      },
      {
        sentence:
          "Okej, vi kan runda av kanske lite aktiedelen här, men du kanske ändå har någon eller några favoritaktier i din portfölj just nu, vad är det du tjänar mest för där?",
        start: 689.48,
        end: 699.48,
        topic_id: [],
        english_sentence:
          "Okay, we can round off maybe a little bit the stock part here, but you might still have one or a few favorite stocks in your portfolio right now, what are you earning the most for there?",
      },
      {
        sentence:
          "Ja, det blir lite mer defensivt eftersom vi inte har så tryckt på någon köpknapp än, så då håller vi oss till sektorer och bolag som vi tror kan stå emot i hyfsat värde i det här klimatet, Astra är en aktie vi har gillat i portföljen under större delen av hela året, den finns fortfarande kvar som ett innehav, och som inte är så konjunkturberoende, där vi tror att de kan växa bra ändå. ",
        start: 699.48,
        end: 723.48,
        topic_id: [],
        english_sentence:
          "Yes, it's a bit more defensive because we haven't pressed any buy buttons yet, so we're sticking to sectors and companies that we think can hold up reasonably well in this climate, Astra is a stock we've liked in the portfolio for most of the year, it's still a holding, and it's not so cyclical, where we think they can grow well anyway. ",
      },
      {
        sentence:
          "Sen kan jag även nämna AAK, som också är åt dagligvaruhållet, så det passar väl också ganska bra in i det mark och klimatet vi har, så det är också ett innehav i portföljen som jag tycker passar rätt bra just nu. ",
        start: 723.48,
        end: 735.48,
        topic_id: [],
        english_sentence:
          "Then I can also mention AAK, which is also in the grocery business, so it also fits in quite well with the land and climate we have, so it's also a holding in the portfolio that I think fits quite well right now. ",
      },
      {
        sentence:
          "Specialfettbolaget, som vi brukar säga. Om vi då tittar på räntebärande, som vi har neutral i just nuvikt, vad finns det du vill lyfta fram där?",
        start: 735.48,
        end: 745.48,
        topic_id: [],
        english_sentence:
          "The special fat company, as we say. If we then look at fixed income, which we have neutral in right now weight, what is there that you want to highlight there?",
      },
      {
        sentence:
          "Vi har gjort lite justeringar där, vi har minskat på exponeringar mot nordiska krediter, företagsobligationer, det har att göra med att det är en del fastighetsexponeringar där och det är ju en sektor som är i het luften och som är ganska riskfylld, och dessutom är det en ganska illikvid marknad, den nordiska kreditmarknaden, så vi har dragit ner exponeringen däremot. ",
        start: 745.48,
        end: 763.48,
        topic_id: ["38496941-a81e-40c3-99e2-d704c02f20af"],
        english_sentence:
          "We have made some adjustments there, we have reduced exposures to Nordic credits, corporate bonds, it has to do with the fact that there are some real estate exposures there and it is a sector that is in hot air and that is quite risky, and also it is a rather illiquid market, the Nordic credit market, so we have reduced the exposure on the other hand. ",
      },
      {
        sentence:
          "I stället har vi ökat upp mot statsräntor och det man kallar för globala investment grade obligationer, så lite säkrare saker, och det innebär att vi har faktiskt förlängt durationen, alltså löptiderna på de investeringar vi har, eftersom vi tror kanske att huvuddelen av ränteuppgången ändå är bakom oss, så det är de förändringar vi gjort i ränteportföljen.\r\n\r\n",
        start: 763.48,
        end: 783.48,
        topic_id: ["38496941-a81e-40c3-99e2-d704c02f20af"],
        english_sentence:
          "Instead, we've moved up towards government bonds and what you call global investment grade bonds, so a little bit safer things, and that means we've actually extended the duration, the maturities of the investments that we have, because we think maybe the bulk of the rise in interest rates is behind us anyway, so those are the changes that we've made in the fixed income portfolio. ",
      },
      {
        sentence:
          "Alternativa investeringar är ett annat tillgångsslag som vi har, alltså vad gör vi där som ändå har vår övervikt i?",
        start: 783.48,
        end: 791.48,
        topic_id: [],
        english_sentence:
          "Alternative investments are another asset class that we have, so what do we do there that we still have our overweight in?",
      },
      {
        sentence:
          "Några saker vi har hittat de senare tiden, dels har vi adderat trendföljare till portföljen, det är ju kvantstrategier som helt enkelt agerar på långa trender och de har börjat uppstå igen i marknaden, kan även tjäna pengarna när det går ner då, huvudsaken är att det rör sig åt ett håll egentligen, så det har vi adderat till portföljen. ",
        start: 791.48,
        end: 811.48,
        topic_id: [],
        english_sentence:
          "Some things we've found recently, we've added trend followers to the portfolio, they're quant strategies that simply act on long trends and they've started to emerge again in the market, can even make money when it goes down, the main thing is that it moves in one direction really, so we've added that to the portfolio. ",
      },
      {
        sentence:
          "Vi har även tagit in en kinesisk long short förvaltare, som både säljer och köper aktier, och som är marknadsneutral, så det spelar inte så stor roll vart marknaden som helhet går, utan vi har hittat bra och dåliga aktier och spelat dem emot varandra, och där tror vi att den kinesiska marknaden kan vara intressant, så det har vi adderat till portföljen. ",
        start: 811.48,
        end: 829.48,
        topic_id: [],
        english_sentence:
          "We've also brought in a Chinese long-short manager, who both sells and buys stocks, and who is market neutral, so it doesn't really matter where the market as a whole goes, but we've found good and bad stocks and played them against each other, and there we think the Chinese market can be interesting, so we've added that to the portfolio. ",
      },
      {
        sentence:
          "Och sen tror vi fortsatt mycket på det som vi kallar för distressed debt, och det är ju ett tema som är bra just i stökiga tider, duktiga förvaltare som kan hitta stressade bolag, men där som ändå kommer klara sig, där man kan köpa in obligationer med stora rabatter, så det är väl de tre jag skulle lyfta fram som intressanta inslag i den alternativa portföljen i nuläget. ",
        start: 829.48,
        end: 849.48,
        topic_id: [],
        english_sentence:
          "And then we continue to believe very much in what we call distressed debt, and that's a theme that's good particularly in troubled times, good managers who can find stressed companies, but where they're still going to make it, where you can buy bonds at big discounts, so those are the three I would highlight as interesting elements in the alternative portfolio at the moment. ",
      },
      {
        sentence:
          "Ja, det låter intressant, och vi gjorde ju faktiskt en djupdykning i det här området alternativa investeringar för en dryg månad ungefär, då vi hade en av våra förvaltare, Niklas Lindeka, och även Peter var också med. Så den rekommenderar jag lyssna på, det var från den 15 september. Men i övrigt så sätter jag er punkt där. Stort tack Peter för att du kom till Polen idag.\r\n\r\n",
        start: 849.48,
        end: 871.48,
        topic_id: [],
        english_sentence:
          "Yes, it sounds interesting, and we actually did a deep dive into this area of alternative investments a little over a month ago, when we had one of our managers, Niklas Lindeka, and Peter was also there. So I recommend listening to that, it was from September 15. But otherwise, I'll put your point there. Thank you very much Peter for coming to Poland today. ",
      },
      {
        sentence:
          "Är du intresserad av topprankad aktieanalys och unika investeringsmöjligheter? Gå då in på Carnegie.se-privatebanking och lär dig mer om vad du får som private banking kund hos oss. ",
        start: 871.48,
        end: 885.48,
        topic_id: [],
        english_sentence:
          "Are you interested in top-ranked stock analysis and unique investment opportunities? Then visit Carnegie.se-privatebanking and learn more about what you get as a private banking client with us. ",
      },
    ],
  },
  Levler: {
    title: "Levler",
    mediaType: "Podcast",
    audioUrl:
      "https://cdn.texelio.com/podcast/demo81eb531e/ff6bb80c_7-hur-fasen-tankte-han.mp3",
    audioText: [
      {
        sentence:
          "Jag tror att det ingår, man ska inte vara så rädd för saker och ting. ",
        start: 0.0,
        end: 3.36,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "I think that's part of it, you shouldn't be so afraid of things.",
        conceptList: null,
      },
      {
        sentence:
          "Rädda pojkar får aldrig kyssa snygga flickor brukar det heta. ",
        start: 3.36,
        end: 15.52,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Scared boys never get to kiss pretty girls, as the saying goes.",
        conceptList: null,
      },
      {
        sentence: "Är det bara såhär man gör? ",
        start: 15.52,
        end: 19.28,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Is this the only way to do it?",
        conceptList: null,
      },
      {
        sentence: "\n\nÅh herregud, Jocke satte på play. ",
        start: 19.28,
        end: 22.4,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Oh my God, Jocke turned on play.",
        conceptList: [
          {
            name: "Jocke Smällkarameller",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.5416263342,
            positions: [{ start: 13.0, end: 18.0 }],
          },
        ],
      },
      {
        sentence: "Eller vad det heter, rec heter det. ",
        start: 22.4,
        end: 24.4,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Or whatever it's called, rec it's called.",
        conceptList: null,
      },
      {
        sentence: "Såklart, inspelning. ",
        start: 24.4,
        end: 26.0,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Of course, recording.",
        conceptList: null,
      },
      {
        sentence: "Yes, välkommen till podden! ",
        start: 26.0,
        end: 28.28,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Yes, welcome to the podcast!",
        conceptList: null,
      },
      {
        sentence: "Level up med Jocke och Louise. ",
        start: 28.28,
        end: 30.68,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Level up with Jocke and Louise.",
        conceptList: [
          {
            name: "Jocke Smällkarameller",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.5261950493,
            positions: [{ start: 13.0, end: 18.0 }],
          },

          {
            name: "Louise",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9997256398,
            positions: [{ start: 23.0, end: 29.0 }],
          },
        ],
      },
      {
        sentence: "\n\nJa, vi har startat! ",
        start: 30.68,
        end: 32.36,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Yes, we have started!",
        conceptList: null,
      },
      {
        sentence: "\n\nHur är läget? ",
        start: 32.36,
        end: 33.4,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "What's up?",
        conceptList: null,
      },
      {
        sentence: "\n\nDet är jättebra, hur är det med dig? ",
        start: 33.4,
        end: 35.84,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "That's great, how are you?",
        conceptList: null,
      },
      {
        sentence: "\n\nDet är bra. ",
        start: 35.84,
        end: 36.88,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "That's good.",
        conceptList: null,
      },
      {
        sentence: "Fullt ös i Levelefabriken. ",
        start: 36.88,
        end: 38.84,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Full house in the Levelefabriken.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nTre veckor till jul och det är bara hundra saker som ska stängas innan jul. ",
        start: 38.84,
        end: 44.76,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "Three weeks until Christmas and there are only a hundred things to close before Christmas.",
        conceptList: null,
      },
      {
        sentence: "Men det är väl skitbra. ",
        start: 44.76,
        end: 47.72,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "But that's great.",
        conceptList: null,
      },
      {
        sentence: "Du, vi har för första gången en gäst i podden. ",
        start: 47.72,
        end: 52.84,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Hey, for the first time we have a guest on the podcast.",
        conceptList: null,
      },
      {
        sentence: "\n\nMen vi har inte släppt in honom än. ",
        start: 52.84,
        end: 53.72,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "But we haven't let him in yet.",
        conceptList: null,
      },
      {
        sentence: "Han får inte komma än. ",
        start: 53.72,
        end: 55.76,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "He can't come yet.",
        conceptList: null,
      },
      {
        sentence: "\n\nJag tog en klunk och en cola. ",
        start: 55.76,
        end: 58.44,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "I took a sip and a coke.",
        conceptList: null,
      },
      {
        sentence: "\n\nFörlåt. ",
        start: 58.44,
        end: 59.0,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Sorry.",
        conceptList: null,
      },
      {
        sentence: "Men temat är att starta på finansiering. ",
        start: 59.0,
        end: 62.12,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "But the theme is to start on funding.",
        conceptList: null,
      },
      {
        sentence:
          "Vi ska snacka med Söderpartners grundare och koncernchef –som äger en del av Levler. ",
        start: 62.12,
        end: 70.48,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "We'll talk to the founder and CEO of Söderpartners - who owns part of Levler.",
        conceptList: [
          {
            name: "Söderpartners",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.822253406,
            positions: [{ start: 18.0, end: 31.0 }],
          },

          {
            name: "Levler",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.8822699189,
            positions: [{ start: 77.0, end: 83.0 }],
          },
        ],
      },
      {
        sentence: "Inte han, utan Söderpartners gör det. ",
        start: 70.48,
        end: 72.72,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Not he, but the Southern Partners do.",
        conceptList: [
          {
            name: "Söderpartners",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.8606396914,
            positions: [{ start: 15.0, end: 28.0 }],
          },
        ],
      },
      {
        sentence: "En väldigt stor del. ",
        start: 72.72,
        end: 73.68,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "A very large part.",
        conceptList: null,
      },
      {
        sentence: "Det gör de, absolut. ",
        start: 73.68,
        end: 76.28,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "They do, absolutely.",
        conceptList: null,
      },
      {
        sentence: "Större delen av vad vi vill. ",
        start: 76.28,
        end: 78.24,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Most of what we want.",
        conceptList: null,
      },
      {
        sentence:
          "Jag tänkte snacka med honom om hur han tänkte när han gav oss pengar. ",
        start: 78.24,
        end: 83.28,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "I was going to talk to him about how he thought about giving us money.",
        conceptList: null,
      },
      {
        sentence: "Det är ju knasigt. ",
        start: 83.28,
        end: 84.88,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "That's weird.",
        conceptList: null,
      },
      {
        sentence: "\n\nBra fråga. ",
        start: 84.88,
        end: 86.0,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Good question.",
        conceptList: null,
      },
      {
        sentence: "Det tänkte vi snacka med honom om en liten stund. ",
        start: 86.0,
        end: 91.64,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "We were going to talk to him about that for a moment.",
        conceptList: null,
      },
      {
        sentence: "Men först måste vi kolla vad som har hänt sen sist. ",
        start: 91.64,
        end: 94.16,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "But first we need to see what's happened since the last time.",
        conceptList: null,
      },
      {
        sentence: "Vad händer på börsen, Jocke? ",
        start: 94.16,
        end: 96.08,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "What's happening on the stock market, Jocke?",
        conceptList: [
          {
            name: "Jocke Smällkarameller",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.5386477709,
            positions: [{ start: 22.0, end: 27.0 }],
          },
        ],
      },
      {
        sentence: "Börsen mår väl hyfsat bra. ",
        start: 96.08,
        end: 99.04,
        speaker: "SPEAKER_01",
        topic_id: ["34df3923-c5c9-420b-822c-e013ccc3dbf0"],
        eng_sentence: "The stock market is doing reasonably well.",
        conceptList: null,
      },
      {
        sentence:
          "Framför allt storbolagen har återhämtat sig snabbt under hösten. ",
        start: 99.04,
        end: 103.92,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "Large companies in particular have recovered quickly during the autumn.",
        conceptList: null,
      },
      {
        sentence: "Varför då, kan man ju undra. ",
        start: 103.92,
        end: 106.04,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Why then, one might wonder.",
        conceptList: null,
      },
      {
        sentence:
          "Och tecken på att inflationen börjar sjunka är det som styr nu. ",
        start: 106.04,
        end: 113.4,
        speaker: "SPEAKER_01",
        topic_id: ["6a052f25-d98b-4884-bd9d-1a8fd8f2889e"],
        eng_sentence:
          "And signs that inflation is starting to fall are the order of the day.",
        conceptList: null,
      },
      {
        sentence:
          "Sen har vi kanske inte riktigt sett det i statistikerna, men det är helt klart så. ",
        start: 113.4,
        end: 121.28,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "Then we may not have really seen it in the statistics, but it's clearly there.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nRiksbanken är lite lugnare och kanske inte lika trigger happy längre. ",
        start: 121.28,
        end: 125.76,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "The Riksbank is a little calmer and perhaps not as trigger happy anymore.",
        conceptList: null,
      },
      {
        sentence: "–Men höjde ändå räntan. ",
        start: 125.76,
        end: 127.04,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "-But still raised the interest rate.",
        conceptList: null,
      },
      {
        sentence: "Även om den svenska höjde. ",
        start: 127.04,
        end: 128.28,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Although the Swedish raised.",
        conceptList: null,
      },
      {
        sentence:
          "Men man blickar framåt och då känner man framför allt –att den amerikanska är lite mjukare i sitt tonläge. ",
        start: 128.28,
        end: 135.32,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "But you look ahead and you feel above all -that the American is a little softer in his tone.",
        conceptList: null,
      },
      {
        sentence: "Det är väl det som gör att börserna stiger. ",
        start: 135.32,
        end: 138.36,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "I guess that's what makes the stock markets go up.",
        conceptList: null,
      },
      {
        sentence: "Samtidigt som bolagen fortsatt går hyfsat bra. ",
        start: 138.36,
        end: 142.08,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "At the same time, the companies continue to do reasonably well.",
        conceptList: null,
      },
      {
        sentence: "Har du nån åsikt om att Ingves i sitt sista besked höjde? ",
        start: 142.08,
        end: 147.16,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "Do you have any opinion on the fact that Ingves in his last message raised?",
        conceptList: [
          {
            name: "Ingves",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.7136084437,
            positions: [{ start: 24.0, end: 30.0 }],
          },
        ],
      },
      {
        sentence:
          "\n\nJag tycker att Ingves var en av de sämsta riksbankscheferna vi har haft. ",
        start: 147.16,
        end: 151.96,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "I think Ingves was one of the worst Governors we have had.",
        conceptList: [
          {
            name: "Ingves",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.7386342883,
            positions: [{ start: 15.0, end: 21.0 }],
          },
        ],
      },
      {
        sentence: "–Har du livet i 17 år? ",
        start: 151.96,
        end: 154.32,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "-Have you lived for 17 years?",
        conceptList: null,
      },
      {
        sentence: "Nej, men lite. ",
        start: 154.32,
        end: 155.72,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "No, but a little.",
        conceptList: null,
      },
      {
        sentence: "Jag tycker inte att han har ett oförtjänt gott rykte. ",
        start: 155.72,
        end: 160.96,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "I don't think he has an undeserved good reputation.",
        conceptList: null,
      },
      {
        sentence: "Jag tycker inte att han har gjort ett bra jobb. ",
        start: 160.96,
        end: 165.08,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "I don't think he's done a good job.",
        conceptList: null,
      },
      {
        sentence: "Vad skulle han ha gjort? ",
        start: 165.08,
        end: 168.0,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "What would he have done?",
        conceptList: null,
      },
      {
        sentence: "Han har bara följt ECB eller den europeiska centralbanken. ",
        start: 168.0,
        end: 172.72,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "He has only followed the ECB or the European Central Bank.",
        conceptList: [
          {
            name: "ECB",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.8303816319,
            positions: [{ start: 19.0, end: 22.0 }],
          },
        ],
      },
      {
        sentence: "Han skulle inte kunna ha gjort så mycket. ",
        start: 172.72,
        end: 174.92,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "He couldn't have done much.",
        conceptList: null,
      },
      {
        sentence:
          "Han har tagit det i alldeles för hårt varje gång när vinden har vänt. ",
        start: 174.92,
        end: 179.4,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "He's taken it too hard every time the wind has shifted.",
        conceptList: null,
      },
      {
        sentence: "Jag är övertygad om att han gör det igen. ",
        start: 179.4,
        end: 184.4,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "I'm sure he'll do it again.",
        conceptList: null,
      },
      {
        sentence: "Han har varit för aggressiv under de här åren. ",
        start: 184.4,
        end: 186.84,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "He has been too aggressive over these years.",
        conceptList: null,
      },
      {
        sentence: "Det tror jag kommer att bli hans eftermäla. ",
        start: 186.84,
        end: 190.92,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "I think that will be his legacy.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nSen tycker jag också att han lägger sig i finanspolitiken och det är inte hans roll. ",
        start: 190.92,
        end: 195.76,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Then I also think that he interferes in fiscal policy and that is not his role.",
        conceptList: null,
      },
      {
        sentence: "Han har blivit nån slags larger than life. ",
        start: 195.76,
        end: 199.28,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "He has become some kind of larger than life.",
        conceptList: null,
      },
      {
        sentence: "Jag kanske använder uttrycket fel. ",
        start: 199.28,
        end: 200.72,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Maybe I'm using the expression wrong.",
        conceptList: null,
      },
      {
        sentence:
          "Men han har tagit på sig och är ju enormt mycket mer frispråkig. ",
        start: 200.72,
        end: 205.48,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "But he has taken on a lot more and is much more outspoken.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nHan har åsikter om saker som inte är Riksbanken –på ett sätt som tidiga Riksbankschefer aldrig skulle drömma om. ",
        start: 205.48,
        end: 212.28,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "He has opinions on things that are not the Riksbank - in a way that early Riksbank governors would never dream of.",
        conceptList: [
          {
            name: "Riksbanken",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.8787022233,
            positions: [{ start: 37.0, end: 47.0 }],
          },
        ],
      },
      {
        sentence: "Hur tror du nu? ",
        start: 212.28,
        end: 213.76,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Hur tror du nu?",
        conceptList: null,
      },
      {
        sentence: "Det här är kanske inte för gemene man skitgubbligt snabbt. ",
        start: 213.76,
        end: 216.96,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "This may not be for the average person very fast.",
        conceptList: null,
      },
      {
        sentence: "\n\nMen ändå, Erik Thedén blir nästa. ",
        start: 216.96,
        end: 218.88,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "But still, Erik Thedén will be next.",
        conceptList: [
          {
            name: "Erik Thedén",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9995172024,
            positions: [{ start: 10.0, end: 21.0 }],
          },
        ],
      },
      {
        sentence: "Har du förhoppning? ",
        start: 218.88,
        end: 220.52,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Do you have hope?",
        conceptList: null,
      },
      {
        sentence: "Ja, Erik Thedén känner man igen. ",
        start: 220.52,
        end: 222.88,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Yes, Erik Thedén is familiar.",
        conceptList: [
          {
            name: "Erik Thedén",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9990366697,
            positions: [{ start: 4.0, end: 15.0 }],
          },
        ],
      },
      {
        sentence: "Han var vd på Stockholmsbörsen förr i tiden. ",
        start: 222.88,
        end: 226.72,
        speaker: "SPEAKER_01",
        topic_id: ["8b5c285a-dd56-4f33-babd-494ffc18dcf1"],
        eng_sentence: "He used to be CEO of the Stockholm Stock Exchange.",
        conceptList: [
          {
            name: "Stockholmsbörsen",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.7033445835,
            positions: [{ start: 14.0, end: 30.0 }],
          },
        ],
      },
      {
        sentence:
          "\n\nNu har han varit generaldirektör på Finansinspektionen. ",
        start: 226.72,
        end: 231.16,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "Now he has been Director General of the Financial Supervisory Authority.",
        conceptList: [
          {
            name: "Finansinspektionen",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.5240781903,
            positions: [{ start: 36.0, end: 54.0 }],
          },
        ],
      },
      {
        sentence:
          "\n\nHan är också en ganska offensiv generaldirektör på Finansinspektionen. ",
        start: 231.16,
        end: 235.68,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "He is also a rather offensive Director General at the Financial Supervisory Authority.",
        conceptList: [
          {
            name: "Finansinspektionen",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.5131170154,
            positions: [{ start: 51.0, end: 69.0 }],
          },
        ],
      },
      {
        sentence: "Det ska bli spännande att se hur han blir på Riksbanken. ",
        start: 235.68,
        end: 238.56,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "It will be interesting to see how he turns out at the Riksbank.",
        conceptList: [
          {
            name: "Riksbanken",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.7580181956,
            positions: [{ start: 45.0, end: 55.0 }],
          },
        ],
      },
      {
        sentence:
          "Han är inte den traditionella nationalekonomen som man kanske tänker sig. ",
        start: 238.56,
        end: 243.52,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "He is not the traditional economist you might think.",
        conceptList: null,
      },
      {
        sentence:
          "Det kan bli bra, men jag hoppas att han inte sitter i 16-17 år. ",
        start: 243.52,
        end: 251.12,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "It could be good, but I hope he doesn't serve 16-17 years.",
        conceptList: null,
      },
      {
        sentence: "\n\nSveriges mäktigaste tjänstemän sitter så länge. ",
        start: 251.12,
        end: 254.28,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Sweden's most powerful officials sit so long.",
        conceptList: [
          {
            name: "Sveriges",
            conceptType: "ENTITY",
            entityType: "LOC",
            score: 0.851465404,
            positions: [{ start: 0.0, end: 8.0 }],
          },
        ],
      },
      {
        sentence: "Det är liksom Putin-varning på det. ",
        start: 254.28,
        end: 256.96,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "It's like Putin-warning on that.",
        conceptList: null,
      },
      {
        sentence: "Man ska inte sitta så länge. ",
        start: 256.96,
        end: 259.0,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "You shouldn't sit that long.",
        conceptList: null,
      },
      {
        sentence: "Nu sticker vi ur takarna. ",
        start: 259.0,
        end: 261.2,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Let's get out of here.",
        conceptList: null,
      },
      {
        sentence: "Det är som Putin-varning på Riksbanken. ",
        start: 261.2,
        end: 263.56,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "It's like Putin-warning at the Riksbank.",
        conceptList: [
          {
            name: "Riksbanken",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.7075346112,
            positions: [{ start: 28.0, end: 38.0 }],
          },
        ],
      },
      {
        sentence:
          "Jag tror att jag tar mig ifrån det här ämnet fortast möjligt nu. ",
        start: 263.56,
        end: 267.24,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "I think I'll get off this subject as soon as possible now.",
        conceptList: null,
      },
      {
        sentence: "Jag har varit i Göteborg förra veckan. ",
        start: 267.24,
        end: 269.92,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "I have been in Gothenburg last week.",
        conceptList: [
          {
            name: "Göteborg",
            conceptType: "ENTITY",
            entityType: "LOC",
            score: 0.9989743233,
            positions: [{ start: 16.0, end: 24.0 }],
          },
        ],
      },
      {
        sentence:
          "Det är ju alltid skitnice, men jag har inte rest så jättemycket sen pandemin. ",
        start: 269.92,
        end: 274.36,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "It's always skitnice, but I haven't travelled much since the pandemic.",
        conceptList: null,
      },
      {
        sentence: "Nu i Göteborg fick jag träffa 300 servopartnerskunder. ",
        start: 274.36,
        end: 278.16,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "Now in Gothenburg I got to meet 300 servo partner customers.",
        conceptList: [
          {
            name: "Göteborg",
            conceptType: "ENTITY",
            entityType: "LOC",
            score: 0.9991198182,
            positions: [{ start: 5.0, end: 13.0 }],
          },
        ],
      },
      {
        sentence: "Jag fick också träffa en massa rådgivare där. ",
        start: 278.16,
        end: 281.32,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "I also got to meet a lot of advisors there.",
        conceptList: null,
      },
      {
        sentence: "Det var nice. ",
        start: 281.32,
        end: 282.4,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "That was nice.",
        conceptList: null,
      },
      {
        sentence:
          "Dessutom har jag alltid ett digitalt event om energipolitik. ",
        start: 282.4,
        end: 285.24,
        speaker: "SPEAKER_02",
        topic_id: ["6d2ff9f7-f420-4181-bce1-fa1467717c8a"],
        eng_sentence:
          "In addition, I always have a digital event on energy policy.",
        conceptList: null,
      },
      {
        sentence: "Det har varit mycket event. ",
        start: 285.24,
        end: 286.64,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "There have been many events.",
        conceptList: null,
      },
      {
        sentence:
          "Nu i veckan får jag träffa en massa kvinnor i finansbranschen. ",
        start: 286.64,
        end: 290.0,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "This week I get to meet a lot of women in finance.",
        conceptList: null,
      },
      {
        sentence: "Det är roligt, och det gör jag för servopartnersräkning. ",
        start: 290.0,
        end: 292.84,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "It's fun, and I do it for the servo partner bill.",
        conceptList: null,
      },
      {
        sentence:
          "Men jag passar alltid på att klämma in lite information om lövlor. ",
        start: 292.84,
        end: 297.28,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "But I always take the opportunity to squeeze in a little information about foliage.",
        conceptList: null,
      },
      {
        sentence:
          "Det som är så kul med det är att det alltid blir så bra mottagande. ",
        start: 297.28,
        end: 301.68,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "What's so fun about it is that it's always so well received.",
        conceptList: null,
      },
      {
        sentence:
          "Alltid efter de här eventen får jag något mejl från någon i publiken som har testat eller som rekommenderar det själv till folk. ",
        start: 301.68,
        end: 309.44,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "Always after these events I get an email from someone in the audience who has tried it or who recommends it to people.",
        conceptList: null,
      },
      {
        sentence: "Det är alltid så himla kul, tycker jag. ",
        start: 309.44,
        end: 312.28,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "It's always so much fun, I think.",
        conceptList: null,
      },
      {
        sentence: "Väldigt positivt. ",
        start: 312.28,
        end: 314.8,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Very positive.",
        conceptList: null,
      },
      {
        sentence: "Härligt. ",
        start: 314.8,
        end: 315.64,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Lovely.",
        conceptList: null,
      },
      {
        sentence: "Det är skönt. ",
        start: 315.64,
        end: 319.0,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "It's nice.",
        conceptList: null,
      },
      {
        sentence: "Men du, Levler då? ",
        start: 319.0,
        end: 320.84,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "What about you, Levler?",
        conceptList: [
          {
            name: "Jocke Smällkarameller",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9522387981,
            positions: [{ start: 8.0, end: 13.0 }],
          },
        ],
      },
      {
        sentence:
          "Jag tänkte på det, vad har hänt just på Levler den senaste veckan? ",
        start: 320.84,
        end: 325.04,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "I was thinking about it, what has just happened on Levler this past week?",
        conceptList: [
          {
            name: "Levler",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.9100074172,
            positions: [{ start: 40.0, end: 46.0 }],
          },
        ],
      },
      {
        sentence: "Det har kanske varit lite lugnare faktiskt. ",
        start: 325.04,
        end: 329.84,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Maybe it's been a bit quieter actually.",
        conceptList: null,
      },
      {
        sentence: "Inte i det vi har att göra, men utåt. ",
        start: 329.84,
        end: 333.16,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Not in what we do, but outwardly.",
        conceptList: null,
      },
      {
        sentence: "Dock har vi släppt två versioner av appen. ",
        start: 333.16,
        end: 338.16,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "However, we have released two versions of the app.",
        conceptList: null,
      },
      {
        sentence: "Vi poddade sist, det är inte dåligt. ",
        start: 338.16,
        end: 341.04,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "We podcasted last time, that's not bad.",
        conceptList: null,
      },
      {
        sentence: "Vi har alltså version 19 ute. ",
        start: 341.04,
        end: 342.8,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "So we have version 19 out.",
        conceptList: null,
      },
      {
        sentence: "Sen kan man nu flytta från det till oss. ",
        start: 342.8,
        end: 344.84,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Then you can now move from that to us.",
        conceptList: null,
      },
      {
        sentence:
          "Jag vet inte om jag har sagt det, men man gör inte det i appen än. ",
        start: 344.84,
        end: 348.52,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "I don't know if I've said it, but you don't do it in the app yet.",
        conceptList: null,
      },
      {
        sentence: "\n\nDet kommer man kunna göra snart. ",
        start: 348.52,
        end: 351.72,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "This will be possible soon.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nMen man kan slå en pling eller mejla helloatlevel.se så hjälper vi till. ",
        start: 351.72,
        end: 356.64,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "But you can give us a call or email helloatlevel.se and we'll help.",
        conceptList: null,
      },
      {
        sentence: "–Just det. ",
        start: 356.64,
        end: 356.88,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "-Just that.",
        conceptList: null,
      },
      {
        sentence: "Det är ju kanon. ",
        start: 356.88,
        end: 357.88,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "That's great.",
        conceptList: null,
      },
      {
        sentence: "\n\nMan får också 500 spänn. ",
        start: 357.88,
        end: 359.72,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "You also get 500 bucks.",
        conceptList: null,
      },
      {
        sentence: "–Det är ju sjukt bra. ",
        start: 359.72,
        end: 361.48,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "-It's really good.",
        conceptList: null,
      },
      {
        sentence: "Om man flyttar ett fonder till oss. ",
        start: 361.48,
        end: 363.16,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "If you move a fund to us.",
        conceptList: null,
      },
      {
        sentence: "\n\n–Det såg Jocke jätteförvånad ut. ",
        start: 363.16,
        end: 366.76,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "-Jockey looked really surprised.",
        conceptList: [
          {
            name: "Jocke Smällkarameller",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.5406146646,
            positions: [{ start: 9.0, end: 14.0 }],
          },
        ],
      },
      {
        sentence: "\n\nJag har missat den detaljen. ",
        start: 366.76,
        end: 369.92,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "I missed that detail.",
        conceptList: null,
      },
      {
        sentence: "\n\nHan bara, oj... ",
        start: 369.92,
        end: 372.32,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Han bara, oj...",
        conceptList: null,
      },
      {
        sentence: "Men det är bra. ",
        start: 372.32,
        end: 373.84,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "But it's good.",
        conceptList: null,
      },
      {
        sentence: "Det är bara en sån grej. ",
        start: 373.84,
        end: 375.76,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "It's just one of those things.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nVi måste upplysa Gustav när han kommer till podden att han får 500 spänn. ",
        start: 375.76,
        end: 380.28,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "We have to inform Gustav when he comes to the podcast that he gets 500 bucks.",
        conceptList: [
          {
            name: "Gustav Rentsåg",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9993914366,
            positions: [{ start: 17.0, end: 23.0 }],
          },
        ],
      },
      {
        sentence: "\n\nJa, det lyser med sin frånvaro. ",
        start: 380.28,
        end: 383.96,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Yes, it is conspicuous by its absence.",
        conceptList: null,
      },
      {
        sentence: "Vart är alla miljoner? ",
        start: 383.96,
        end: 386.4,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Where are all the millions?",
        conceptList: null,
      },
      {
        sentence: "Sen har vi sagt att vi söker en dot... ",
        start: 386.4,
        end: 388.84,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Then we said we were looking for a dot...",
        conceptList: null,
      },
      {
        sentence: "Vi ska fråga nån det. ",
        start: 388.84,
        end: 391.52,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "We'll ask someone.",
        conceptList: null,
      },
      {
        sentence: "Vi söker en dotnetare och en Java-utvecklare. ",
        start: 391.52,
        end: 394.56,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "We are looking for a dotnetter and a Java developer.",
        conceptList: null,
      },
      {
        sentence: "De facto. ",
        start: 394.56,
        end: 395.84,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "De facto.",
        conceptList: null,
      },
      {
        sentence: "Om ni är sugna på det. ",
        start: 395.84,
        end: 397.84,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "If you're up for it.",
        conceptList: null,
      },
      {
        sentence: "Vi bara växer, så det är kul. ",
        start: 397.84,
        end: 399.56,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "We're just growing, so it's fun.",
        conceptList: null,
      },
      {
        sentence: "Vi är fortfarande ett lite tajt team. ",
        start: 399.56,
        end: 403.64,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "We're still a bit of a tight team.",
        conceptList: null,
      },
      {
        sentence: "Det är ingen stor massa människor. ",
        start: 403.64,
        end: 406.84,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "It's not a big mass of people.",
        conceptList: null,
      },
      {
        sentence:
          "Vill du jobba på den coolaste finansstart-appen som finns, så hör av dig. ",
        start: 406.84,
        end: 412.88,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "If you want to work on the coolest finance startup app around, get in touch.",
        conceptList: null,
      },
      {
        sentence: "\n\nVill du inte det, så kan du börja jobba på Levler. ",
        start: 412.88,
        end: 417.68,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "If you don't want that, you can start working at Levler.",
        conceptList: [
          {
            name: "Levler",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.9470210671,
            positions: [{ start: 43.0, end: 49.0 }],
          },
        ],
      },
      {
        sentence: "Vad tråkigt. ",
        start: 417.68,
        end: 418.32,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "What a shame.",
        conceptList: null,
      },
      {
        sentence: "Vad ska man mer säga? ",
        start: 418.32,
        end: 420.16,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "What else can I say?",
        conceptList: null,
      },
      {
        sentence:
          "\n\nJag vet att vi har tagit en hel del framsteg i byggandet av aktiehandel. ",
        start: 420.16,
        end: 428.44,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "I know we've made a lot of progress in building stock trading.",
        conceptList: null,
      },
      {
        sentence: "Det känns skönt kul. ",
        start: 428.44,
        end: 430.28,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "It feels good fun.",
        conceptList: null,
      },
      {
        sentence: "Nu börjar det knacka på dörren. ",
        start: 430.28,
        end: 432.8,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Now there's a knock at the door.",
        conceptList: null,
      },
      {
        sentence: "Nu är Gustav här. ",
        start: 432.8,
        end: 435.52,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Now Gustav is here.",
        conceptList: [
          {
            name: "Gustav Rentsåg",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9997510314,
            positions: [{ start: 6.0, end: 12.0 }],
          },
        ],
      },
      {
        sentence: "\n\nDå ska vi bjuda in honom. ",
        start: 435.52,
        end: 439.92,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Then we'll invite him.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nDå har vi Gustav Rentsåg, koncernchef och grundare –i Söderberg Partners Studio. ",
        start: 439.92,
        end: 444.32,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "Then we have Gustav Rentsåg, CEO and founder -in Söderberg Partners Studio.",
        conceptList: [
          {
            name: "Gustav Rentsåg",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9996088147,
            positions: [{ start: 10.0, end: 24.0 }],
          },

          {
            name: "Söderberg Partners Studio",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.9725689292,
            positions: [{ start: 54.0, end: 79.0 }],
          },
        ],
      },
      {
        sentence: "Välkommen. ",
        start: 444.32,
        end: 445.72,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Welcome.",
        conceptList: null,
      },
      {
        sentence: "Hur känns det att vara här? ",
        start: 445.72,
        end: 447.36,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "How does it feel to be here?",
        conceptList: null,
      },
      {
        sentence: "\n\nDet känns bra. ",
        start: 447.36,
        end: 448.56,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "It feels good.",
        conceptList: null,
      },
      {
        sentence: "Inte alla damer blir intervjuade så. ",
        start: 448.56,
        end: 451.44,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Not all ladies get interviewed like that.",
        conceptList: null,
      },
      {
        sentence: "\n\nDin stol knakar. ",
        start: 451.44,
        end: 452.96,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Din chair knakar.",
        conceptList: null,
      },
      {
        sentence: "Vad är det som händer? ",
        start: 452.96,
        end: 454.64,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "What's going on?",
        conceptList: null,
      },
      {
        sentence: "\n\nDen här podden är inte högkvalitativ. ",
        start: 454.64,
        end: 458.48,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "This podcast is not high quality.",
        conceptList: null,
      },
      {
        sentence: "Även stolarna... ",
        start: 458.48,
        end: 460.28,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Even the chairs...",
        conceptList: null,
      },
      {
        sentence: "Du tycker det, kanske? ",
        start: 460.28,
        end: 462.2,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "You think so, maybe?",
        conceptList: null,
      },
      {
        sentence: "Jag är ensam om att tycka det. ",
        start: 462.2,
        end: 464.68,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "I'm the only one who thinks so.",
        conceptList: null,
      },
      {
        sentence: "\n\nInte ens stolarna håller kvaliteten. ",
        start: 464.68,
        end: 467.6,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Even the chairs are not of good quality.",
        conceptList: null,
      },
      {
        sentence: "Hur som Gustav, du håller kvaliteten. ",
        start: 467.6,
        end: 471.0,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Anyway Gustav, you keep the quality.",
        conceptList: [
          {
            name: "Gustav Rentsåg",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9993861914,
            positions: [{ start: 8.0, end: 14.0 }],
          },
        ],
      },
      {
        sentence: "\n\nDu är här för att svara på hur fasen tänkte du. ",
        start: 471.0,
        end: 475.84,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "You're here to answer how the hell were you thinking.",
        conceptList: null,
      },
      {
        sentence: "Det är grejen. ",
        start: 475.84,
        end: 477.36,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "That's the thing.",
        conceptList: null,
      },
      {
        sentence: "Vi vill veta varför du har investerat i det här. ",
        start: 477.36,
        end: 480.48,
        speaker: "SPEAKER_00",
        topic_id: ["679c2cd9-dce3-4c51-a28e-379dfb5a801f"],
        eng_sentence: "We want to know why you've invested in this.",
        conceptList: null,
      },
      {
        sentence:
          "Den första frågan är, varför har Söder Partners inte investerat i att bygga en sparplattform tidigare? ",
        start: 480.48,
        end: 488.8,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "The first question is, why has Söder Partners not invested in building a savings platform before?",
        conceptList: [
          {
            name: "Söder Partners",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.9820394516,
            positions: [{ start: 33.0, end: 47.0 }],
          },
        ],
      },
      {
        sentence: "Det tror jag många undrar. ",
        start: 488.8,
        end: 489.64,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "I think many people wonder.",
        conceptList: null,
      },
      {
        sentence:
          'Det beror lite på definitionen av en sparplattform, men man kan säga att vi har... -"Do it yourself". ',
        start: 489.64,
        end: 495.4,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          'It depends a bit on the definition of a savings platform, but you could say that we have... - "Do it yourself".',
        conceptList: null,
      },
      {
        sentence: '"Do it yourself". ',
        start: 495.4,
        end: 496.72,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: '"Do it yourself".',
        conceptList: null,
      },
      {
        sentence:
          "Vi har sen start byggt allt som behövs som grund för en sparplattform. ",
        start: 496.72,
        end: 502.2,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Since the beginning, we have built everything needed as the basis for a savings platform.",
        conceptList: null,
      },
      {
        sentence:
          "Däremot är det själva sparplattformen, för vi har varit rådgivningsföretag. ",
        start: 502.2,
        end: 506.56,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "On the other hand, it is the savings platform itself, because we have been an advisory firm.",
        conceptList: null,
      },
      {
        sentence:
          "Att vi inte har gjort det tidigare har att göra med att vi har varit rådgivningsföretag Och man ska alltid tänka, varför ska man bli bättre än någon? ",
        start: 506.56,
        end: 514.84,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "The reason we haven't done it before is because we've been an advisory company And you should always think, why should you be better than someone?",
        conceptList: null,
      },
      {
        sentence: "Hur ska vi slå de andra om vi ska göra det? ",
        start: 514.84,
        end: 517.6,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "How are we going to beat the others if we're going to do that?",
        conceptList: null,
      },
      {
        sentence:
          "För det finns ingen poäng att bli nummer tre eller nummer två för all del. ",
        start: 517.6,
        end: 520.6,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Because there's no point in being number three or number two by any means.",
        conceptList: null,
      },
      {
        sentence: "Och då har vi, med tiden har vi ju två saker hänt. ",
        start: 520.6,
        end: 523.92,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "And then we have, over time we have two things happened.",
        conceptList: null,
      },
      {
        sentence:
          "Dels har vi blivit så stora att vi har faktiskt större volymer än våra huvudkonkurrenter. ",
        start: 523.92,
        end: 528.48,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "On the one hand, we have become so big that we actually have higher volumes than our main competitors.",
        conceptList: null,
      },
      {
        sentence: "Om man tänker på nätverkare som är kända. ",
        start: 528.48,
        end: 530.48,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "If you think about networkers who are famous.",
        conceptList: null,
      },
      {
        sentence: "Vi har mer volymer i fonden än vad de har. ",
        start: 530.48,
        end: 532.6,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "We have more volumes in the fund than they do.",
        conceptList: null,
      },
      {
        sentence:
          "Då har vi som ett förutsättning för att göra nånting sannt. ",
        start: 532.6,
        end: 534.84,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Then we have as a prerequisite to do something true.",
        conceptList: null,
      },
      {
        sentence: "Men, sen räcker inte det. ",
        start: 534.84,
        end: 537.64,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "But, then that's not enough.",
        conceptList: null,
      },
      {
        sentence: "För man kan ha alla förutsättningar i världen. ",
        start: 537.64,
        end: 539.6,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Because you can have all the conditions in the world.",
        conceptList: null,
      },
      {
        sentence: "Det behövs något mer också. ",
        start: 539.6,
        end: 540.92,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Something more is needed too.",
        conceptList: null,
      },
      {
        sentence: "All right. ",
        start: 540.92,
        end: 542.24,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "All right.",
        conceptList: null,
      },
      {
        sentence: "\n\nOch vad var det nu, då? ",
        start: 542.24,
        end: 545.2,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "And what was it now?",
        conceptList: null,
      },
      {
        sentence: "\n\nNu undrar man ju vad det är. ",
        start: 545.2,
        end: 548.08,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Now one wonders what it is.",
        conceptList: null,
      },
      {
        sentence: "\n\nVad är det som träffar? ",
        start: 548.08,
        end: 549.24,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "What's the hit?",
        conceptList: null,
      },
      {
        sentence: "\n\nJag tror att det är nästa fråga. ",
        start: 549.24,
        end: 553.48,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "I think that's the next question.",
        conceptList: null,
      },
      {
        sentence: "Vad är det som gjorde att just nu var det en bra tidpunkt? ",
        start: 553.48,
        end: 556.24,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "What made now a good time?",
        conceptList: null,
      },
      {
        sentence: "\n\nDet var egentligen... ",
        start: 556.24,
        end: 557.56,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "It was actually...",
        conceptList: null,
      },
      {
        sentence: "Det spelar ingen roll vilka bra idéer du har. ",
        start: 557.56,
        end: 559.4,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "It doesn't matter what good ideas you have.",
        conceptList: null,
      },
      {
        sentence: "Det måste vara rätt människor bakom spakarna. ",
        start: 559.4,
        end: 562.2,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "It has to be the right people behind the levers.",
        conceptList: null,
      },
      {
        sentence: "Den här kombinationen uppfyller det. ",
        start: 562.2,
        end: 565.08,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "This combination fulfils it.",
        conceptList: null,
      },
      {
        sentence: "Du har erfarenhet att bygga subpartersvarumärke. ",
        start: 565.08,
        end: 567.84,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "You have experience in building subcontractor brands.",
        conceptList: null,
      },
      {
        sentence:
          "Det har fungerat och vi vill ha ett nytt varumärke för annars blir det vad är rådgivning, vad är inte, mixtrad. ",
        start: 567.84,
        end: 574.48,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "It has worked and we want a new brand because otherwise it becomes what is advice, what is not, mixtrad.",
        conceptList: null,
      },
      {
        sentence:
          "Och sen, Jocke, är det någon som vet, med din erfarenhet, bakgrund och också väldigt duktig att synas, satt och höra så alla vill veta vad du tycker, så tyckte jag då finns ju förutsättningarna på ett högre plan för att göra något. ",
        start: 574.48,
        end: 588.2,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "And then, Jocke, is there someone who knows, with your experience, background and also very good at being seen, sat and heard so everyone wants to know what you think, then I thought the conditions are there on a higher level to do something.",
        conceptList: [
          {
            name: "Jocke Smällkarameller",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.7901306152,
            positions: [{ start: 9.0, end: 14.0 }],
          },
        ],
      },
      {
        sentence:
          "Och sen i er tur har ju ni också presenterat och lyckats dra till väldigt mycket duktig kompetens, väldigt teknik, vilket är högst centralt. ",
        start: 588.2,
        end: 595.88,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "And then in your turn you have also presented and managed to attract a lot of talented skills, a lot of technology, which is very important.",
        conceptList: null,
      },
      {
        sentence:
          "Så då fanns ju alla komponenter, skalan, logiken varför oss fanns och nummer två, teamet som kunde göra det här fanns. ",
        start: 595.88,
        end: 603.56,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "So then all the components, the scale, the logic of why we were there and number two, the team that could do this was there.",
        conceptList: null,
      },
      {
        sentence: "Varför inte? ",
        start: 603.56,
        end: 604.12,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Why not?",
        conceptList: null,
      },
      {
        sentence: "Ungefär så. ",
        start: 604.12,
        end: 605.12,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Something like that.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nMen det är ju ingen liten investering man gör när man ska göra en sån här grej? ",
        start: 605.12,
        end: 610.16,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "But it's not a small investment when you're doing something like this?",
        conceptList: null,
      },
      {
        sentence: "\n\nNej, allt blir ju relativt. ",
        start: 610.16,
        end: 612.12,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "No, everything is relative.",
        conceptList: null,
      },
      {
        sentence:
          "Men jag skulle säga att den är förhållandevis liten i förhållande till vad det hade varit om vi hade behövt göra det här hundra procent själva. ",
        start: 612.12,
        end: 620.88,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "But I would say it's relatively small compared to what it would have been if we had to do this 100% ourselves.",
        conceptList: null,
      },
      {
        sentence:
          "Om vi inte hade de här skalfördelarna att vi redan kan återanvända en massa saker vi redan har. ",
        start: 620.88,
        end: 625.04,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "If we didn't have these economies of scale, we could already reuse a lot of things we already have.",
        conceptList: null,
      },
      {
        sentence:
          "Om vi inte hade en stor kundbas som delvis inte är ett hand om idag för vi har inget bra erbjudande för det är inte värt för dem att betala för råd genom att vi är för små. ",
        start: 625.04,
        end: 632.96,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "If we didn't have a large customer base that partly isn't a handful today because we don't have a good offer because it's not worth it for them to pay for advice because we're too small.",
        conceptList: null,
      },
      {
        sentence:
          "Om vi inte hade det då hade inte den logiken funnits och vi inte hade haft den här kickstarten. ",
        start: 632.96,
        end: 638.56,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "If we didn't have it then there wouldn't have been that logic and we wouldn't have had this kick start.",
        conceptList: null,
      },
      {
        sentence:
          "Så att i förhållande till det så tycker jag att det här är väldigt goda möjligheter att få en bra avkastning på den investering vi gör. ",
        start: 638.56,
        end: 645.24,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "So in relation to that, I think this is a very good opportunity to get a good return on the investment we make.",
        conceptList: null,
      },
      {
        sentence: "Men det är klart ingenting är gratis om det är bra. ",
        start: 645.24,
        end: 649.56,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "But of course nothing is free if it is good.",
        conceptList: null,
      },
      {
        sentence: "\n\nDet är ganska skönt. ",
        start: 649.56,
        end: 652.52,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "It's quite nice.",
        conceptList: null,
      },
      {
        sentence: "Gustaf är bra på att ge mandatförtroende. ",
        start: 652.52,
        end: 655.56,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Gustaf is good at giving mandate confidence.",
        conceptList: [
          {
            name: "Gustav Rentsåg",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9883140326,
            positions: [{ start: 0.0, end: 6.0 }],
          },
        ],
      },
      {
        sentence: "Var kommer det ifrån? ",
        start: 655.56,
        end: 657.84,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Where does it come from?",
        conceptList: null,
      },
      {
        sentence: "För det är ju hela Söderberg Partners bygge på det. ",
        start: 657.84,
        end: 661.72,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Because that's what Söderberg Partners is built on.",
        conceptList: [
          {
            name: "Söderberg Partners",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.9591992497,
            positions: [{ start: 19.0, end: 37.0 }],
          },
        ],
      },
      {
        sentence:
          "\n\nDet är faktiskt så att den som lärde mig det, tro det eller ej, nu får man ju vara lite försiktig för det finns ju en del mindre saker, men jag lärde avskräckande saker. ",
        start: 661.72,
        end: 669.72,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "In fact, the person who taught me that, believe it or not, now you have to be a little careful because there are some minor things, but I learned some discouraging things.",
        conceptList: null,
      },
      {
        sentence: "Det var Carnegie. ",
        start: 669.72,
        end: 671.04,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "It was Carnegie.",
        conceptList: [
          {
            name: "Carnegie",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.9908474684,
            positions: [{ start: 8.0, end: 16.0 }],
          },
        ],
      },
      {
        sentence:
          "Jag började under helt annat, väldigt kort, mer stelt och konstigt absolut inte för mig, så kom jag till Carnegie som var väldigt decentraliserat. ",
        start: 671.04,
        end: 678.84,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "I started out under a completely different, very short, more rigid and strangely certainly not for me, then I came to Carnegie which was very decentralised.",
        conceptList: [
          {
            name: "Carnegie",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.9982686043,
            positions: [{ start: 105.0, end: 113.0 }],
          },
        ],
      },
      {
        sentence:
          "De tryckte ner besluten på affärsavdelningar i bolag och gav väldigt stor frihet, inte minst till mig, vilket jag fortfarande är förvånad över ska jag tillägga. ",
        start: 678.84,
        end: 688.96,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "They pushed decisions down to the business departments of companies and gave a lot of freedom, not least to me, which I'm still surprised about, I might add.",
        conceptList: null,
      },
      {
        sentence:
          "Men den friheten att få bygga själv och känna att man kan påverka och inflytande är fantastisk och den skapar sån energi som är oslagbar. ",
        start: 688.96,
        end: 697.68,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "But the freedom to build yourself and feel that you can influence and influence is fantastic and it creates such energy that is unbeatable.",
        conceptList: null,
      },
      {
        sentence: "Så det är någonting jag lärde mig därifrån. ",
        start: 697.68,
        end: 699.92,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "So that's something I learned from there.",
        conceptList: null,
      },
      {
        sentence:
          "Sen lärde jag mig saker att se upp med, bland annat compliance, ordning och redafrågor, Se upp med... ",
        start: 699.92,
        end: 711.88,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Then I learned things to watch out for, including compliance, order and tidiness issues, watch out for...",
        conceptList: null,
      },
      {
        sentence: "Eller att man ska ha koll på det. ",
        start: 711.88,
        end: 713.2,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Or that you should keep an eye on it.",
        conceptList: null,
      },
      {
        sentence: "\n\nHa stenkoll. ",
        start: 713.2,
        end: 714.12,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Be sure.",
        conceptList: null,
      },
      {
        sentence: "Se upp med... ",
        start: 714.12,
        end: 714.96,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Beware of...",
        conceptList: null,
      },
      {
        sentence: "Om man inte har koll, kom ihåg det. ",
        start: 714.96,
        end: 716.04,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "If you don't know, remember that.",
        conceptList: null,
      },
      {
        sentence:
          "Vi kör ju på med Levler rätt fristående från Söderberg -...även om Söderpartners äger en ganska stor del. ",
        start: 716.04,
        end: 722.16,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "We are running Levler quite independently from Söderberg -...even though Söderpartners owns a rather large part.",
        conceptList: [
          {
            name: "Söderberg",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.862267375,
            positions: [{ start: 45.0, end: 54.0 }],
          },

          {
            name: "Söderpartners",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.9496865273,
            positions: [{ start: 67.0, end: 80.0 }],
          },
        ],
      },
      {
        sentence:
          "\n\nVar det viktigt för dig, Jocke, att du fick det mandatet från Gustaf? ",
        start: 722.16,
        end: 726.32,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Was it important to you, Jocke, that you got that mandate from Gustaf?",
        conceptList: [
          {
            name: "Jocke Smällkarameller",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.6384048462,
            positions: [{ start: 25.0, end: 30.0 }],
          },

          {
            name: "Gustav Rentsåg",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9991436005,
            positions: [{ start: 62.0, end: 68.0 }],
          },
        ],
      },
      {
        sentence: "\n\nDet är viktigt att man får... ",
        start: 726.32,
        end: 728.76,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "It is important to get...",
        conceptList: null,
      },
      {
        sentence: "Det ligger i det Gustaf säger. ",
        start: 728.76,
        end: 729.96,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "It's in what Gustaf says.",
        conceptList: [
          {
            name: "Gustav Rentsåg",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9997584224,
            positions: [{ start: 17.0, end: 23.0 }],
          },
        ],
      },
      {
        sentence: "Att man får förtroendet. ",
        start: 729.96,
        end: 731.08,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "That you get the confidence.",
        conceptList: null,
      },
      {
        sentence:
          "Att man får möjligheten att faktiskt göra nåt, att man får ta de beslut som krävs och hitta de människor man tror på och få göra det fullt ut. ",
        start: 731.08,
        end: 743.2,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "That you get the opportunity to actually do something, that you get to make the decisions and find the people you believe in and get to do it fully.",
        conceptList: null,
      },
      {
        sentence:
          "Allting handlar ju om att få göra saker fullt ut och jag hade aldrig gjort det här om jag inte hade provat det på sparekonomgigget för det fick jag också göra fullt ut. ",
        start: 743.2,
        end: 751.08,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "Everything is about doing things to the fullest and I would never have done this if I hadn't tried it at the savings economy gig because I got to do that to the fullest too.",
        conceptList: null,
      },
      {
        sentence:
          "Och det var därför det gick bra här på Söderbergpartner, det var därför jag älskar att jobba här. ",
        start: 751.08,
        end: 755.24,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "And that's why I did well here at Söderbergpartner, that's why I love working here.",
        conceptList: [
          {
            name: "Söderbergpartner",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.6026007533,
            positions: [{ start: 39.0, end: 55.0 }],
          },
        ],
      },
      {
        sentence: "Det är väldigt viktigt att man får göra saker fullt ut. ",
        start: 755.24,
        end: 758.52,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "It's very important that you get to do things fully.",
        conceptList: null,
      },
      {
        sentence:
          "För annars blir det alltid tattande, det blir alltid halvdant. ",
        start: 758.52,
        end: 763.28,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "Because otherwise it will always be a tattling, it will always be half-hearted.",
        conceptList: null,
      },
      {
        sentence: "Det måste ju vara passion. ",
        start: 763.28,
        end: 765.56,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "It must be passion.",
        conceptList: null,
      },
      {
        sentence: "Om man ska göra något som är nummer ett. ",
        start: 765.56,
        end: 768.92,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "If you're going to do something that's number one.",
        conceptList: null,
      },
      {
        sentence: "Annars blir man ju mellanmjölk. ",
        start: 768.92,
        end: 770.8,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Otherwise, you'll be middle milk.",
        conceptList: null,
      },
      {
        sentence: "Mellanmjölk är ju så sjukt tråkigt. ",
        start: 770.8,
        end: 773.24,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Intermediate milk is so boring.",
        conceptList: null,
      },
      {
        sentence: "Det vill man ju inte vara. ",
        start: 773.24,
        end: 774.84,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "You don't want to be.",
        conceptList: null,
      },
      {
        sentence: "Det är nästan hellre sämst än att vara mellanmjölk. ",
        start: 774.84,
        end: 778.8,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "It's almost preferable to being middle milk.",
        conceptList: null,
      },
      {
        sentence: "Och då måste man få prova fullt ut. ",
        start: 778.8,
        end: 781.04,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "And then you have to try it out fully.",
        conceptList: null,
      },
      {
        sentence: "Och det får man ju göra. ",
        start: 781.04,
        end: 782.4,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "And that's what you have to do.",
        conceptList: null,
      },
      {
        sentence: "Jag tycker det är magiskt. ",
        start: 782.4,
        end: 784.36,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "I think it's magical.",
        conceptList: null,
      },
      {
        sentence:
          "Jag tror att det är därför många gillar att jobba i Söderberg Partner -...som många gillar att jobba med Gustav. ",
        start: 784.36,
        end: 792.68,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "I think that's why many people like to work in Söderberg Partner -...as many people like to work with Gustav.",
        conceptList: [
          {
            name: "Söderberg Partner",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.9067590237,
            positions: [{ start: 52.0, end: 69.0 }],
          },

          {
            name: "Gustav Rentsåg",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9432154298,
            positions: [{ start: 105.0, end: 111.0 }],
          },
        ],
      },
      {
        sentence: "Man får verkligen prova fullt ut. ",
        start: 792.68,
        end: 794.2,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "You really get to try it to the full.",
        conceptList: null,
      },
      {
        sentence: "Annars hade det inte varit värt att göra det. ",
        start: 794.2,
        end: 797.24,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Otherwise it wouldn't have been worth doing.",
        conceptList: null,
      },
      {
        sentence:
          "Då är det bättre att bara sitta på ett kontor på Storbank och ha det gött. ",
        start: 797.24,
        end: 801.24,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "Then it's better to just sit in an office at Storbank and have a good time.",
        conceptList: [
          {
            name: "Storbank",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.7491388917,
            positions: [{ start: 49.0, end: 57.0 }],
          },
        ],
      },
      {
        sentence:
          "\n\nNej, de jobbar väl hårt där också, men jag tror att då hamnar du... ",
        start: 801.24,
        end: 806.8,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "No, I guess they work hard there too, but I think that would put you...",
        conceptList: null,
      },
      {
        sentence:
          "Då hamnar du lättare i en förvaltande roll om du inte kan påverka lika mycket. ",
        start: 806.8,
        end: 812.36,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Then you're more likely to end up in a management role if you can't influence as much.",
        conceptList: null,
      },
      {
        sentence:
          "Har du möjlighet att påverka fullt ut, då vill det också utveckla. ",
        start: 812.36,
        end: 815.84,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "If you have the opportunity to make a full impact, then it also wants to develop.",
        conceptList: null,
      },
      {
        sentence: "Det får man göra här, vilket är helt magiskt. ",
        start: 815.84,
        end: 818.76,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "You get to do that here, which is absolutely magical.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nMen Gustav, Levler är ju en av jäkligt många satsningar –som ni har gjort inom ramet Söderpartners. ",
        start: 818.76,
        end: 826.6,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "But Gustav, Levler is one of a hell of a lot of initiatives - that you have made within the framework of Söderpartners.",
        conceptList: [
          {
            name: "Gustav Rentsåg",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9295250773,
            positions: [{ start: 4.0, end: 10.0 }],
          },

          {
            name: "Levler",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.8577085733,
            positions: [{ start: 12.0, end: 18.0 }],
          },

          {
            name: "Söderpartners",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.6908050776,
            positions: [{ start: 85.0, end: 98.0 }],
          },
        ],
      },
      {
        sentence:
          "Oftast förvärvar ni, eller så är det här nånting vi startar från grunden. ",
        start: 826.6,
        end: 830.48,
        speaker: "SPEAKER_02",
        topic_id: ["a1e7266d-5a95-434b-9b01-3da1f418db66"],
        eng_sentence:
          "Usually you acquire, or this is something we start from scratch.",
        conceptList: null,
      },
      {
        sentence: "Alltså, hur... ",
        start: 830.48,
        end: 833.32,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Well, how...",
        conceptList: null,
      },
      {
        sentence: "Vad ska man säga? ",
        start: 833.32,
        end: 834.4,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "What to say?",
        conceptList: null,
      },
      {
        sentence:
          "När anser du att ett sånt här projekt har nått den framgång du förväntar dig –när du tog beslutet om investeringen? ",
        start: 834.4,
        end: 840.96,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "When do you consider that a project like this has achieved the success you expect - when you made the investment decision?",
        conceptList: null,
      },
      {
        sentence: "\n\nDen frågan kan man svara på 1 000 sätt. ",
        start: 840.96,
        end: 844.76,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "There are 1,000 ways to answer that question.",
        conceptList: null,
      },
      {
        sentence:
          "Men ytterst handlar det om att det måste bli en lönsam och framgångsrikt business. ",
        start: 844.76,
        end: 849.12,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "But ultimately it's about making it a profitable and successful business.",
        conceptList: null,
      },
      {
        sentence: "Men jag sätter alltid högre mål än så. ",
        start: 849.12,
        end: 851.92,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "But I always set higher goals than that.",
        conceptList: null,
      },
      {
        sentence: "Jag har stora förhoppningar om Levler kan bli... ",
        start: 851.92,
        end: 854.6,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "I have high hopes that Alevle can be...",
        conceptList: [
          {
            name: "Levler",
            conceptType: "ENTITY",
            entityType: "MISC",
            score: 0.3312703073,
            positions: [{ start: 31.0, end: 37.0 }],
          },
        ],
      },
      {
        sentence:
          "Jag tror att det här kan bli en ledande sparplattform för väldigt många individer. ",
        start: 854.6,
        end: 860.0,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "I think this can be a leading savings platform for a lot of individuals.",
        conceptList: null,
      },
      {
        sentence: "Inte för alla. ",
        start: 860.0,
        end: 860.76,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Not for everyone.",
        conceptList: null,
      },
      {
        sentence: "Jag tror inte att er målgrupp är alla. ",
        start: 860.76,
        end: 863.04,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "I don't think your target audience is everyone.",
        conceptList: null,
      },
      {
        sentence:
          "Men för väldigt många som vill ha det enkelt och få bra saker –till marknadens lägsta priser. ",
        start: 863.04,
        end: 870.0,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "But for a lot of people who want simplicity and good things - at the lowest prices on the market.",
        conceptList: null,
      },
      {
        sentence: "Det är attraktivt för många. ",
        start: 870.0,
        end: 872.12,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "It is attractive to many.",
        conceptList: null,
      },
      {
        sentence: "Det är en jättemarknad. ",
        start: 872.12,
        end: 874.44,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "It's a huge market.",
        conceptList: null,
      },
      {
        sentence: "Konsten är att få folk att förstå hur bra ni är. ",
        start: 874.44,
        end: 877.72,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "The art is to make people understand how good you are.",
        conceptList: null,
      },
      {
        sentence: "Ni blir bra, det är jag inte orolig för. ",
        start: 877.72,
        end: 879.36,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "You'll be fine, I'm not worried about that.",
        conceptList: null,
      },
      {
        sentence: "Det svåra är att få människor att titta sig omkring. ",
        start: 879.36,
        end: 882.48,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "The hard part is getting people to look around.",
        conceptList: null,
      },
      {
        sentence:
          "Men tillgången till hela Söderparts-nätverk –kommer också att hjälpa er. ",
        start: 882.48,
        end: 890.48,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "But access to the entire South Party network - will also help you.",
        conceptList: [
          {
            name: "Söderparts",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.9886085391,
            positions: [{ start: 25.0, end: 35.0 }],
          },

          {
            name: "Parts",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.7324437499,
            positions: [{ start: 36.0, end: 41.0 }],
          },
        ],
      },
      {
        sentence:
          "\n\nDet här har tagit ett tag från idé till att vi lanserade det –även om jag tror att vi har gjort det på rekordsnabb tid. ",
        start: 890.48,
        end: 897.12,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "This has taken a while from idea to launch - although I think we've done it in record time.",
        conceptList: null,
      },
      {
        sentence:
          "Så var det ändå vår 2021 som vi tog beslutet, kan man säga. ",
        start: 897.12,
        end: 901.24,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "So it was still our 2021 that we made the decision, you could say.",
        conceptList: null,
      },
      {
        sentence: "Sen vår. ",
        start: 901.24,
        end: 902.16,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Late spring.",
        conceptList: null,
      },
      {
        sentence: "Då såg inte marknaden riktigt ut som den gör nu. ",
        start: 902.16,
        end: 905.36,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "Back then, the market didn't look quite like it does now.",
        conceptList: null,
      },
      {
        sentence: "Sen har vi haft en jävligt stökig marknad det här året. ",
        start: 905.36,
        end: 909.6,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Then we've had one hell of a messy market this year.",
        conceptList: null,
      },
      {
        sentence: "Hur känner du för det? ",
        start: 909.6,
        end: 910.88,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "How do you feel about it?",
        conceptList: null,
      },
      {
        sentence: "Känner du att det fortfarande var ett bra beslut? ",
        start: 910.88,
        end: 913.12,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Do you still feel it was a good decision?",
        conceptList: null,
      },
      {
        sentence: "Ångrar du dig egentligen, men vågar inte säga det? ",
        start: 913.12,
        end: 916.76,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Do you really regret it, but don't dare say so?",
        conceptList: null,
      },
      {
        sentence: "\n\nHade vi inte haft det fundamentet att det är så rätt ",
        start: 916.76,
        end: 919.4,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Had we not had the foundation that it is so right",
        conceptList: null,
      },
      {
        sentence:
          "Då hade jag kanske varit orolig för att det ju alltid som i vissa idéer passar en hajp, men det här är ingen sådan idé. ",
        start: 919.4,
        end: 926.16,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Then I might have been worried that, as in some ideas, there is always a rush, but this is not one of those ideas.",
        conceptList: null,
      },
      {
        sentence:
          "Det är en långsiktig idé som bygger på att det handlar om människor som vill göra det själv och fångar sparandet i väldigt goda villkor. ",
        start: 926.16,
        end: 933.08,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "It's a long-term idea based on people wanting to do it themselves and captures savings in very good terms.",
        conceptList: null,
      },
      {
        sentence: "Och det behovet har ju inte plötsligt försvunnit. ",
        start: 933.08,
        end: 935.92,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "And that need has not suddenly disappeared.",
        conceptList: null,
      },
      {
        sentence: "Det finns ju där och kommer bara öka. ",
        start: 935.92,
        end: 938.32,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "It's there and it's only going to increase.",
        conceptList: null,
      },
      {
        sentence: "Jag skulle nästan vända på det. ",
        start: 938.32,
        end: 939.92,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "I would almost turn it around.",
        conceptList: null,
      },
      {
        sentence: "Jag tror att möjligheten är större egentligen. ",
        start: 939.92,
        end: 942.44,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "I think the opportunity is greater really.",
        conceptList: null,
      },
      {
        sentence: "Nu sitter kunder hos många banker och ingen ränta. ",
        start: 942.44,
        end: 946.8,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Now customers sit with many banks and no interest.",
        conceptList: null,
      },
      {
        sentence: "Ni har ränta att erbjuda. ",
        start: 946.8,
        end: 948.6,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "You have interest to offer.",
        conceptList: null,
      },
      {
        sentence:
          "Ni har egentligen mer att erbjuda nu relativt vad det var då. ",
        start: 948.6,
        end: 952.88,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "You really have more to offer now than you did then.",
        conceptList: null,
      },
      {
        sentence:
          "Människor blir lite stillasitta när det rör sig, men det är bara temporär krusning. ",
        start: 952.88,
        end: 958.08,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "People get a little still when it moves, but it's only temporary ripple.",
        conceptList: null,
      },
      {
        sentence: "Sen börjar rörelsen, och då kan den bli kraftfullare. ",
        start: 958.08,
        end: 961.0,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Then the movement begins, and then it can become more powerful.",
        conceptList: null,
      },
      {
        sentence: "Man måste vara långsiktig i den här typen av game. ",
        start: 961.0,
        end: 963.88,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "You have to be long-term in this kind of game.",
        conceptList: null,
      },
      {
        sentence:
          "Den mån, den här turbulensen, gör att kunderna rör sig mindre och kommer tillbaka. ",
        start: 963.88,
        end: 970.36,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "The moon, this turbulence, makes customers move less and come back.",
        conceptList: null,
      },
      {
        sentence: "Det är som fastighetsbranschen. ",
        start: 970.36,
        end: 972.0,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "It's like the real estate business.",
        conceptList: null,
      },
      {
        sentence:
          "Om du tar husförsäljningen i Sverige ner kraftigt, men det är bara temporärt... ",
        start: 972.0,
        end: 977.48,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "If you take house sales in Sweden down sharply, but it's only temporary...",
        conceptList: [
          {
            name: "Sverige",
            conceptType: "ENTITY",
            entityType: "LOC",
            score: 0.9977770448,
            positions: [{ start: 29.0, end: 36.0 }],
          },
        ],
      },
      {
        sentence:
          "\n\nSen lossnar det efter ett tag och då säljs det mer än nånsin. ",
        start: 977.48,
        end: 981.32,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "Then it comes off after a while and then it sells more than ever.",
        conceptList: null,
      },
      {
        sentence:
          "Men vi känner krasch av att han har börjat sälja laddboxar till elbilar. ",
        start: 981.32,
        end: 984.32,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "But we feel the crash of him selling charging boxes for electric cars.",
        conceptList: null,
      },
      {
        sentence: "Han bara, det går inte att sälja det enda huset. ",
        start: 984.32,
        end: 987.04,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "He just, it's not possible to sell the only house.",
        conceptList: null,
      },
      {
        sentence: "\n\nHan är snart tillbaka och jag har båda åkt. ",
        start: 987.04,
        end: 989.96,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "He'll be back soon and I've both gone.",
        conceptList: null,
      },
      {
        sentence: "\n\nSå är det. ",
        start: 989.96,
        end: 990.68,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "That's how it is.",
        conceptList: null,
      },
      {
        sentence: "\n\nDå har han en dubbelt så stor business. ",
        start: 990.68,
        end: 992.36,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Then he has twice the business.",
        conceptList: null,
      },
      {
        sentence: "Vad tycker du om vårt namn? ",
        start: 992.36,
        end: 995.08,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "What do you think of our name?",
        conceptList: null,
      },
      {
        sentence:
          "Jag tänkte att från början hette det Jocke Smällkarameller. ",
        start: 995.08,
        end: 998.68,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "I thought it was originally called Jocke's Pop Tarts.",
        conceptList: [
          {
            name: "Jocke Smällkarameller",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.821596086,
            positions: [{ start: 37.0, end: 58.0 }],
          },
        ],
      },
      {
        sentence: "Det var de här två killarnas idé. ",
        start: 998.68,
        end: 1001.84,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "It was these two guys' idea.",
        conceptList: null,
      },
      {
        sentence: "\n\n–Nöjd med Levler? ",
        start: 1001.84,
        end: 1003.84,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "-Satisfied with Levler?",
        conceptList: [
          {
            name: "Jocke Smällkarameller",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.5645177364,
            positions: [{ start: 10.0, end: 15.0 }],
          },
        ],
      },
      {
        sentence: "Det var väl inte Smällkarameller. ",
        start: 1003.84,
        end: 1007.2,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "I guess it wasn't Pop Tarts.",
        conceptList: [
          {
            name: "Smällkarameller",
            conceptType: "ENTITY",
            entityType: "MISC",
            score: 0.5605279803,
            positions: [{ start: 17.0, end: 32.0 }],
          },
        ],
      },
      {
        sentence: "Nej, jag är inte så bra på att veta vad bra varumärken är. ",
        start: 1007.2,
        end: 1012.48,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "No, I'm not very good at knowing what good brands are.",
        conceptList: null,
      },
      {
        sentence: "Men level up låter ju aldrig fel, att man tar nästa steg. ",
        start: 1012.48,
        end: 1016.68,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "But leveling up never sounds wrong, taking the next step.",
        conceptList: null,
      },
      {
        sentence:
          "Så utifrån att ett varumärke säger vad man gör, så är det bra varumärke. ",
        start: 1016.68,
        end: 1021.92,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "So based on the fact that a brand says what it does, it's a good brand.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nDet hette ju nåt annat från början, men det får du ta en helt annan podd. ",
        start: 1021.92,
        end: 1028.76,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "It was originally called something else, but you'll have to get a completely different podcast for that.",
        conceptList: null,
      },
      {
        sentence: "\n\nMan lägger ju aldrig så mycket tid på det. ",
        start: 1028.76,
        end: 1031.48,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "You never spend that much time on it.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nVi la ju aldrig så mycket tid och kraft på att fundera kring ett namn. ",
        start: 1031.48,
        end: 1037.56,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "We never put so much time and effort into thinking about a name.",
        conceptList: null,
      },
      {
        sentence:
          "Det blev jättebra, men oavsett... 700 namn har vi gått igenom i Åpia. ",
        start: 1037.56,
        end: 1042.76,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "It turned out great, but regardless... 700 names we have gone through in Åpia.",
        conceptList: [
          {
            name: "Åpia",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.5696917772,
            positions: [{ start: 64.0, end: 68.0 }],
          },
        ],
      },
      {
        sentence: "\n\nMan vill ju verkligen hitta det där... ",
        start: 1042.76,
        end: 1046.84,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "You really want to find that...",
        conceptList: null,
      },
      {
        sentence: "Nu känns ju Levler asbra. ",
        start: 1046.84,
        end: 1048.6,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Now Leveler feels great.",
        conceptList: [
          {
            name: "Levler",
            conceptType: "ENTITY",
            entityType: "MISC",
            score: 0.5108272433,
            positions: [{ start: 12.0, end: 18.0 }],
          },
        ],
      },
      {
        sentence: "Om man ska ha nåt tips till nån som lyssnar... ",
        start: 1048.6,
        end: 1052.8,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "If you have any advice for someone listening...",
        conceptList: null,
      },
      {
        sentence: "Jag ska starta ett bolag. ",
        start: 1052.8,
        end: 1053.88,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "I'm starting a company.",
        conceptList: null,
      },
      {
        sentence: "Fundera inte för mycket på det här med namn. ",
        start: 1053.88,
        end: 1055.72,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Don't think too much about this name thing.",
        conceptList: null,
      },
      {
        sentence: "När man väl börjar använda namnen, så blir det bra. ",
        start: 1055.72,
        end: 1059.0,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Once you start using the names, it's fine.",
        conceptList: null,
      },
      {
        sentence: "Det är som ett barn. ",
        start: 1059.0,
        end: 1060.44,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "It's like a child.",
        conceptList: null,
      },
      {
        sentence: "Löper man till Greta, så blir det Greta. ",
        start: 1060.44,
        end: 1064.48,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "If you run to Greta, it will be Greta.",
        conceptList: [
          {
            name: "Greta",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9992849231,
            positions: [{ start: 15.0, end: 20.0 }],
          },

          {
            name: "Greta",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9981356859,
            positions: [{ start: 34.0, end: 39.0 }],
          },
        ],
      },
      {
        sentence: "\n\n–Oj, slänger Greta under bussen? ",
        start: 1064.48,
        end: 1066.4,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "-Oh, is Greta throwing under the bus?",
        conceptList: [
          {
            name: "Greta",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9984633923,
            positions: [{ start: 13.0, end: 18.0 }],
          },
        ],
      },
      {
        sentence: "Nej, absolut inte. ",
        start: 1066.4,
        end: 1068.12,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Nej, absolut inte.",
        conceptList: null,
      },
      {
        sentence: "Hon lyssnar säkert. ",
        start: 1068.12,
        end: 1068.84,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "I'm sure she's listening.",
        conceptList: null,
      },
      {
        sentence:
          "Men över min döda kropp hade jag fått äta Jockeys smällkarameller. ",
        start: 1068.84,
        end: 1073.48,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "But over my dead body, I had to eat Jockey's bonbons.",
        conceptList: [
          {
            name: "Jocke Smällkarameller",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.3123009801,
            positions: [{ start: 42.0, end: 49.0 }],
          },
        ],
      },
      {
        sentence: "Lite tid och eftertänk är ändå bra. ",
        start: 1073.48,
        end: 1076.36,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "A little time and thought is still good.",
        conceptList: null,
      },
      {
        sentence: "\n\n–Ni hade vant er med det också. ",
        start: 1076.36,
        end: 1078.4,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "-We had got used to it too.",
        conceptList: null,
      },
      {
        sentence: "Kanske. ",
        start: 1078.4,
        end: 1079.32,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Kanske.",
        conceptList: null,
      },
      {
        sentence: "\n\nDet låter lite som goldre gummar. ",
        start: 1079.32,
        end: 1081.92,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "It sounds a bit like goldre gummar.",
        conceptList: null,
      },
      {
        sentence: "Det du säger har väldigt stor poäng. ",
        start: 1081.92,
        end: 1084.68,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "What you say has a very good point.",
        conceptList: null,
      },
      {
        sentence: "Varumärket är lite som idén. ",
        start: 1084.68,
        end: 1088.16,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "The brand is a bit like the idea.",
        conceptList: null,
      },
      {
        sentence:
          "Idéer och varumärken är nödvändiga utan att komma nånstans. ",
        start: 1088.16,
        end: 1092.44,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Ideas and brands are necessary without getting anywhere.",
        conceptList: null,
      },
      {
        sentence:
          "Det som avgör genomförandet sen är att förverkliga det man vill göra. ",
        start: 1092.44,
        end: 1096.76,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "What determines the implementation afterwards is to realise what you want to do.",
        conceptList: null,
      },
      {
        sentence:
          "Då tål det nästan vilket varumärke som helst om man gör det bra. ",
        start: 1096.76,
        end: 1101.36,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Then it can withstand almost any brand if you do it well.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nKan man ha ett bolag som heter Ingvar Kamprad Ämtaryda Gunnary? ",
        start: 1101.36,
        end: 1106.32,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "Can you have a company called Ingvar Kamprad Ämtaryda Gunnary?",
        conceptList: [
          {
            name: "Ingvar Kamprad",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.704225421,
            positions: [{ start: 31.0, end: 45.0 }],
          },

          {
            name: "Ämtaryda Gunnary",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.7334406376,
            positions: [{ start: 46.0, end: 62.0 }],
          },
        ],
      },
      {
        sentence: "–Klas Ohlsson. ",
        start: 1106.32,
        end: 1107.28,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "-Klas Ohlsson.",
        conceptList: [
          {
            name: "Klas Ohlsson",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.92590487,
            positions: [{ start: 1.0, end: 13.0 }],
          },
        ],
      },
      {
        sentence: "Så blir det världens största bolag. ",
        start: 1107.28,
        end: 1110.96,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "So it becomes the world's largest company.",
        conceptList: null,
      },
      {
        sentence: "–Klas Ohlsson. ",
        start: 1110.96,
        end: 1112.32,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "-Klas Ohlsson.",
        conceptList: [
          {
            name: "Klas Ohlsson",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.8896990418,
            positions: [{ start: 1.0, end: 13.0 }],
          },
        ],
      },
      {
        sentence: "\n\nDet tror jag är överskattat. ",
        start: 1112.32,
        end: 1113.88,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "I think that's overrated.",
        conceptList: null,
      },
      {
        sentence: "Det var bara inget Louise i det namnet. ",
        start: 1113.88,
        end: 1116.08,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "There was just no Louise in that name.",
        conceptList: null,
      },
      {
        sentence: "Jag har fått in det också. ",
        start: 1116.08,
        end: 1117.56,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "I've got that in too.",
        conceptList: null,
      },
      {
        sentence: "\n\nP-W-Z-C. PricewaterhouseCoopers, Öhrling. ",
        start: 1117.56,
        end: 1122.92,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "P-W-Z-C. PricewaterhouseCoopers, Öhrling.",
        conceptList: [
          {
            name: "PricewaterhouseCoopers",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.9409461021,
            positions: [{ start: 9.0, end: 31.0 }],
          },

          {
            name: "Öhrling",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.3024948537,
            positions: [{ start: 33.0, end: 40.0 }],
          },
        ],
      },
      {
        sentence: "Du har nog haft nåt på nåt. ",
        start: 1122.92,
        end: 1125.72,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "You must have had something on something.",
        conceptList: null,
      },
      {
        sentence: "Herregud. ",
        start: 1125.72,
        end: 1126.2,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Oh, my God.",
        conceptList: null,
      },
      {
        sentence: "\n\nGustav, jag tänkte på en fråga. ",
        start: 1126.2,
        end: 1130.4,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Gustav, I thought of a question.",
        conceptList: [
          {
            name: "Gustav Rentsåg",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9992274046,
            positions: [{ start: 0.0, end: 6.0 }],
          },
        ],
      },
      {
        sentence:
          "Du träffar massvis av intressanta människor i fransk bransch. ",
        start: 1130.4,
        end: 1136.88,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "You'll meet lots of interesting people in the French industry.",
        conceptList: null,
      },
      {
        sentence: "Ledare, ägare, stora och höga chefer. ",
        start: 1136.88,
        end: 1139.84,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Leaders, owners, big and tall executives.",
        conceptList: null,
      },
      {
        sentence: "Har du fått nån kommentar som bryr sig? ",
        start: 1139.84,
        end: 1142.4,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Did you get any comments that care?",
        conceptList: null,
      },
      {
        sentence: "\n\nDet finns väl alltid när vi gör nåt. ",
        start: 1142.4,
        end: 1146.6,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "I guess there's always that when we do something.",
        conceptList: null,
      },
      {
        sentence: "Dels folk som tycker att det är välkomnare och kul. ",
        start: 1146.6,
        end: 1150.08,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Partly people who think it's more welcoming and fun.",
        conceptList: null,
      },
      {
        sentence: "Vi samarbetar med många av våra konkurrenter. ",
        start: 1150.08,
        end: 1154.2,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "We cooperate with many of our competitors.",
        conceptList: null,
      },
      {
        sentence: "Man ska inte vara så rädd för saker och ting. ",
        start: 1154.2,
        end: 1157.12,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "You shouldn't be so afraid of things.",
        conceptList: null,
      },
      {
        sentence:
          "Rädda pojkar får aldrig kyssa snygga flickor, brukar det heta. ",
        start: 1157.12,
        end: 1161.12,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Scared boys must never kiss pretty girls, they say.",
        conceptList: null,
      },
      {
        sentence:
          "Många är väldigt rädda för det som händer när vi drar igång det här. ",
        start: 1161.12,
        end: 1165.68,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Many people are very afraid of what will happen when we start this.",
        conceptList: null,
      },
      {
        sentence:
          "Nån tänker att de kommer att flytta all business vi har hos mig på grund av det här. ",
        start: 1165.68,
        end: 1170.36,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Someone thinks they're going to move all the business we have with me because of this.",
        conceptList: null,
      },
      {
        sentence: "Riktigt så är det inte. ",
        start: 1170.36,
        end: 1171.96,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "That's not really the case.",
        conceptList: null,
      },
      {
        sentence: "Det ena kan komplettera det andra. ",
        start: 1171.96,
        end: 1175.16,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "One can complement the other.",
        conceptList: null,
      },
      {
        sentence: "Jag har mött båda. ",
        start: 1175.16,
        end: 1176.8,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "I have met both.",
        conceptList: null,
      },
      {
        sentence: "De som tycker det är kul och de som blir nervösa. ",
        start: 1176.8,
        end: 1180.04,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Those who enjoy it and those who get nervous.",
        conceptList: null,
      },
      {
        sentence:
          "Särskilt de vi samarbetar med som har liknande affärsmodeller. ",
        start: 1180.04,
        end: 1183.88,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Especially those we work with who have similar business models.",
        conceptList: null,
      },
      {
        sentence: "Jag tror inte på monopol. ",
        start: 1183.88,
        end: 1185.44,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "I don't believe in monopolies.",
        conceptList: null,
      },
      {
        sentence: "Det finns plats för alla om man gör det bra. ",
        start: 1185.44,
        end: 1188.12,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "There is room for everyone if you do it well.",
        conceptList: null,
      },
      {
        sentence: "\n\n–Har de själva varit lite rädda? ",
        start: 1188.12,
        end: 1191.68,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "-Have they themselves been a little afraid?",
        conceptList: null,
      },
      {
        sentence: "\n\nInte om de gör det bra. ",
        start: 1191.68,
        end: 1194.16,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Not if they do it well.",
        conceptList: null,
      },
      {
        sentence:
          "Så länge de producerar nåt som är väldigt bra, så är det inga problem. ",
        start: 1194.16,
        end: 1200.08,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "As long as they produce something that is very good, there is no problem.",
        conceptList: null,
      },
      {
        sentence:
          "Om ni är mycket bättre på allting, då kan det bli ett rejält problem. ",
        start: 1200.08,
        end: 1202.88,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "If you are much better at everything, then it can be a real problem.",
        conceptList: null,
      },
      {
        sentence:
          "Då är inte problemet samarbete med Söderberg, då har de ett problem i kärnan. ",
        start: 1202.88,
        end: 1206.24,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Then the problem is not cooperation with Söderberg, then they have a problem at the core.",
        conceptList: [
          {
            name: "Söderberg",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9511435032,
            positions: [{ start: 35.0, end: 44.0 }],
          },
        ],
      },
      {
        sentence: "Ni kan ju göra dem bättre också. ",
        start: 1206.24,
        end: 1208.8,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "You can make them better too.",
        conceptList: null,
      },
      {
        sentence: "\n\nTruvén spelar du att räkna med om fem år på marknaden? ",
        start: 1208.8,
        end: 1212.12,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "Truvén you play to count on in five years on the market?",
        conceptList: null,
      },
      {
        sentence: "\n\nJag hade inte suttit här annars. ",
        start: 1212.12,
        end: 1213.92,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "I wouldn't be sitting here otherwise.",
        conceptList: null,
      },
      {
        sentence: "Inte vi heller. ",
        start: 1213.92,
        end: 1214.76,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Neither do we.",
        conceptList: null,
      },
      {
        sentence: "Även Sverige. ",
        start: 1214.76,
        end: 1215.72,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Even Sweden.",
        conceptList: [
          {
            name: "Sverige",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.9874546528,
            positions: [{ start: 5.0, end: 12.0 }],
          },
        ],
      },
      {
        sentence: "Allt annat går över. ",
        start: 1215.72,
        end: 1217.08,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Everything else will pass.",
        conceptList: null,
      },
      {
        sentence: "Det är klart det går. ",
        start: 1217.08,
        end: 1221.04,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Of course it's possible.",
        conceptList: null,
      },
      {
        sentence: "Vi har lite småaktörförsöker. ",
        start: 1221.04,
        end: 1224.24,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "We have some small actor attempts.",
        conceptList: null,
      },
      {
        sentence: "De har inte riktigt samma förutsättningar som er. ",
        start: 1224.24,
        end: 1226.2,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "They don't have quite the same conditions as you.",
        conceptList: null,
      },
      {
        sentence: "Framför allt inte samma tid. ",
        start: 1226.2,
        end: 1227.56,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Especially not the same time.",
        conceptList: null,
      },
      {
        sentence:
          "Det kanske är bra, men då har de inte samma skala eller upphandlingskraft. ",
        start: 1227.56,
        end: 1234.24,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "That might be good, but then they don't have the same scale or procurement power.",
        conceptList: null,
      },
      {
        sentence:
          "Vi kan spela många fler spel än vad de andra kan, bland utmanarna. ",
        start: 1234.24,
        end: 1237.96,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "We can play many more games than the others can, among the challengers.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nÄr det här en del, inte just läbler, utan att jobba med nya idéer och köpbolag? ",
        start: 1237.96,
        end: 1248.28,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "Is this part, not just reading, but working with new ideas and buying companies?",
        conceptList: null,
      },
      {
        sentence: "Är det en del av ditt jobb som du tycker är skoj? ",
        start: 1248.28,
        end: 1251.48,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Is there a part of your job that you find fun?",
        conceptList: null,
      },
      {
        sentence: "\n\nJa, det är bland det roliga som finns. ",
        start: 1251.48,
        end: 1255.32,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Yes, that's one of the fun things.",
        conceptList: null,
      },
      {
        sentence:
          "Nya idéer, särskilt idéer som kommer från noll, som skapas. ",
        start: 1255.32,
        end: 1258.56,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "New ideas, especially ideas that come from scratch, being created.",
        conceptList: null,
      },
      {
        sentence: "En hel del är ganska okänt. ",
        start: 1258.56,
        end: 1260.4,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "A lot is quite unknown.",
        conceptList: null,
      },
      {
        sentence:
          "Det finns flera i er situation –som har skapat bolag som går väldigt bra i dag. ",
        start: 1260.4,
        end: 1265.08,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "There are several in your situation - who have created companies that are doing very well today.",
        conceptList: null,
      },
      {
        sentence:
          "Vi har nåt som heter North Technology –som började med en leverans till oss själva av rådgivningssystem –som säljer i dag till banker och försäkringsbolag –och har väldigt god tillväxt och lönsamhet. ",
        start: 1265.08,
        end: 1277.6,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "We have something called North Technology - which started out as a supply to ourselves of consulting systems - which today sells to banks and insurance companies - and has very good growth and profitability.",
        conceptList: [
          {
            name: "North Technology",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.9922250509,
            positions: [{ start: 21.0, end: 37.0 }],
          },
        ],
      },
      {
        sentence: "Det är riktigt roligt. ",
        start: 1277.6,
        end: 1278.84,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "It's really fun.",
        conceptList: null,
      },
      {
        sentence: "Vi bidrar genom att bli en väldigt bra kund i det fallet. ",
        start: 1278.84,
        end: 1282.76,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "We contribute by becoming a very good customer in that case.",
        conceptList: null,
      },
      {
        sentence: "Sen backar vi väldigt bra entreprenörer. ",
        start: 1282.76,
        end: 1285.48,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Then we back very good entrepreneurs.",
        conceptList: null,
      },
      {
        sentence: "Jag tycker det är roligt. ",
        start: 1285.48,
        end: 1286.68,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "I think it's funny.",
        conceptList: null,
      },
      {
        sentence: "Det påminner lite om att se barn växa upp. ",
        start: 1286.68,
        end: 1288.92,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "It's a bit like watching children grow up.",
        conceptList: null,
      },
      {
        sentence:
          "De här som skulle bli bra i den idrott där man inte var bra själv, och så blir de det. ",
        start: 1288.92,
        end: 1295.96,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "These who would be good in the sport where you were not good yourself, and so they are.",
        conceptList: null,
      },
      {
        sentence: "Konstigt. ",
        start: 1295.96,
        end: 1297.72,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Constigt.",
        conceptList: null,
      },
      {
        sentence: "\n\nFinns det nån bransch du inte skulle... ",
        start: 1297.72,
        end: 1302.88,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Is there any industry you wouldn't...",
        conceptList: null,
      },
      {
        sentence:
          "Kan en idé vara så bra att det inte spelar nån roll vilken bransch det är? ",
        start: 1302.88,
        end: 1306.28,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "Can an idea be so good that it doesn't matter what industry it is?",
        conceptList: null,
      },
      {
        sentence:
          "Jag skulle vända på det och säga att jag har nästan aldrig mött på en idé som inte går att förverkla med rätt entreprenörer. ",
        start: 1306.28,
        end: 1315.56,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "I would turn that around and say that I have almost never come across an idea that cannot be realised with the right entrepreneurs.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nSå jag skulle vända på det och säga att det finns hur många bra idéer som helst som aldrig blir bra för det är fel människor som ska genomföra det. ",
        start: 1315.56,
        end: 1321.84,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "So I would turn that around and say that there are so many good ideas that never get good because the wrong people are going to implement them.",
        conceptList: null,
      },
      {
        sentence:
          "Så nästan alla idéer går att lyckas med, de är inte helt jävla koko. ",
        start: 1321.84,
        end: 1325.12,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "So almost all ideas are possible, they're not completely fucked up.",
        conceptList: null,
      },
      {
        sentence:
          "Det finns ju kanske undantag, men det kräver väldigt mycket av entreprenörerna. ",
        start: 1325.12,
        end: 1331.24,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "There may be exceptions, but it requires a lot from the contractors.",
        conceptList: null,
      },
      {
        sentence: "Man måste gå in med hela kroppen och drömma bolaget. ",
        start: 1331.24,
        end: 1334.88,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "You have to go in with your whole body and dream the company.",
        conceptList: null,
      },
      {
        sentence: "Man måste ändra allt hela tiden och iterera fram saker. ",
        start: 1334.88,
        end: 1338.24,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "You have to change everything all the time and iterate on things.",
        conceptList: null,
      },
      {
        sentence: "Det blir aldrig som man trodde. ",
        start: 1338.24,
        end: 1340.44,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "It never turns out the way you thought it would.",
        conceptList: null,
      },
      {
        sentence: "Idén är att lära, men genomförandet är nyckeln. ",
        start: 1340.44,
        end: 1343.96,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "The idea is to learn, but the implementation is key.",
        conceptList: null,
      },
      {
        sentence:
          "Jag tror att det inte finns några idéer som inte går att lyckas med. ",
        start: 1343.96,
        end: 1348.4,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "I believe that there are no ideas that cannot succeed.",
        conceptList: null,
      },
      {
        sentence: "Man skulle nästan ha en tävling på det där. ",
        start: 1348.4,
        end: 1350.84,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "You could almost have a competition on that.",
        conceptList: null,
      },
      {
        sentence:
          "Slå vad om att lyckas med vilken idé som helst, vilket jag tror. ",
        start: 1350.84,
        end: 1355.32,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Bet you can succeed with any idea, which I think you can.",
        conceptList: null,
      },
      {
        sentence: "\n\nDet går alltid att göra allt bättre. ",
        start: 1355.32,
        end: 1358.52,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "There is always room for improvement.",
        conceptList: null,
      },
      {
        sentence: "Behöver Sverige en ny sparplattform? ",
        start: 1358.52,
        end: 1360.56,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Does Sweden need a new savings platform?",
        conceptList: [
          {
            name: "Sverige",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.5805212855,
            positions: [{ start: 8.0, end: 15.0 }],
          },
        ],
      },
      {
        sentence: "Ja, det går att göra bättre. ",
        start: 1360.56,
        end: 1363.68,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Yes, it can be done better.",
        conceptList: null,
      },
      {
        sentence: "\n\nJag fick frågan här i veckan. ",
        start: 1363.68,
        end: 1366.04,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "I was asked this week.",
        conceptList: null,
      },
      {
        sentence: "Det har startat en ny dating-app. ",
        start: 1366.04,
        end: 1368.4,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "It has launched a new dating app.",
        conceptList: null,
      },
      {
        sentence: "Den heter Hinge. ",
        start: 1368.4,
        end: 1370.2,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "It is called Hinge.",
        conceptList: [
          {
            name: "Hinge",
            conceptType: "ENTITY",
            entityType: "MISC",
            score: 0.6284424067,
            positions: [{ start: 10.0, end: 15.0 }],
          },
        ],
      },
      {
        sentence: "Behövs det en till dating-app? ",
        start: 1370.2,
        end: 1372.16,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Do we need another dating app?",
        conceptList: null,
      },
      {
        sentence: "Ja, uppenbarligen. ",
        start: 1372.16,
        end: 1373.12,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Yes, obviously.",
        conceptList: null,
      },
      {
        sentence: "Tinder har spelat ut sin roll. ",
        start: 1373.12,
        end: 1374.16,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Tinder has played out its role.",
        conceptList: [
          {
            name: "Tinder",
            conceptType: "ENTITY",
            entityType: "MISC",
            score: 0.5890666246,
            positions: [{ start: 0.0, end: 6.0 }],
          },
        ],
      },
      {
        sentence: "Oseriös. ",
        start: 1374.16,
        end: 1375.12,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Rogue.",
        conceptList: null,
      },
      {
        sentence: "\n\nVilken är Tinder i sparplattformsbranschen? ",
        start: 1375.12,
        end: 1379.8,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "What is Tinder in the savings platform industry?",
        conceptList: [
          {
            name: "Tinder",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.6405533552,
            positions: [{ start: 10.0, end: 16.0 }],
          },
        ],
      },
      {
        sentence: "Det vågar jag inte säga nu. ",
        start: 1379.8,
        end: 1381.64,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "I don't dare say that now.",
        conceptList: null,
      },
      {
        sentence: "Men jag har en tanke. ",
        start: 1381.64,
        end: 1384.44,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "But I have a thought.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nGustav, nu sitter du här lite seriös i svart kostym –som Söder Partners. ",
        start: 1384.44,
        end: 1389.68,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "Gustav, now you sit here a bit serious in a black suit - like Söder Partners.",
        conceptList: [
          {
            name: "Gustav Rentsåg",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9902652502,
            positions: [{ start: 0.0, end: 6.0 }],
          },

          {
            name: "Söder Partners",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.9701045752,
            positions: [{ start: 57.0, end: 71.0 }],
          },
        ],
      },
      {
        sentence:
          "Om du kliver ur Söder Partners, för du tycker att företagen är kul –och investerar i en massa grejer privat, vilken är din knasigaste grej? ",
        start: 1389.68,
        end: 1401.52,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "If you get out of Söder Partners, because you think the companies are fun -and invest in a lot of stuff privately, what's your craziest thing?",
        conceptList: [
          {
            name: "Söder Partners",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.9566036463,
            positions: [{ start: 16.0, end: 30.0 }],
          },
        ],
      },
      {
        sentence: "Det finns många saker, men det knasigaste var ju Knasie. ",
        start: 1401.52,
        end: 1407.4,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "There are many things, but the weirdest was Knasie.",
        conceptList: null,
      },
      {
        sentence: "\n\nVilken affärsidé är längst ifrån Söderberg? ",
        start: 1407.4,
        end: 1411.08,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Which business idea is furthest from Söderberg?",
        conceptList: [
          {
            name: "Söderberg",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.8156305552,
            positions: [{ start: 33.0, end: 42.0 }],
          },
        ],
      },
      {
        sentence:
          "\n\nAllt jag gör som inte Söderberg är långt ifrån Söderberg. ",
        start: 1411.08,
        end: 1415.92,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "Everything I do that is not Söderberg is far from Söderberg.",
        conceptList: null,
      },
      {
        sentence: "\n\nAnnars får jag inte göra det. ",
        start: 1415.92,
        end: 1419.04,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Otherwise I'm not allowed to do it.",
        conceptList: null,
      },
      {
        sentence: "Men jag gillar saker som har med människor att göra. ",
        start: 1419.04,
        end: 1426.68,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "But I like things that have to do with people.",
        conceptList: null,
      },
      {
        sentence: "Nyckelmedarbetaren är väldigt avgörande. ",
        start: 1426.68,
        end: 1432.28,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "The key employee is very crucial.",
        conceptList: null,
      },
      {
        sentence:
          "Om man tar ett område som jag har haft förmånen av fått investera i det inom kirurgi och anledningen till det är att en sådan verksamhet, om du har fem kirurger så behövs det ungefär kanske 60 personer runt det för att få allt att funka, men det är väldigt ont om kirurger. ",
        start: 1432.28,
        end: 1458.04,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "If you take an area that I've had the privilege of investing in it in surgery and the reason for that is that such an operation, if you have five surgeons, you need about maybe 60 people around it to make it all work, but there are very few surgeons.",
        conceptList: null,
      },
      {
        sentence:
          "Kan du då få alla de här människorna att trivas, Det är en vara som bygger på människor och brinner för vad de gör, då går det väldigt bra. ",
        start: 1458.04,
        end: 1466.36,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "If you can make all these people feel good, it's a product that's built on people and passionate about what they do, then it's going very well.",
        conceptList: null,
      },
      {
        sentence:
          "I det fall konkurrerar man mycket med landstinget som är väldigt dåliga hos oss på att fokusera på de viktiga individerna och få alla att trivas. ",
        start: 1466.36,
        end: 1475.16,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "In that case, you are competing with the county council, which is very bad at focusing on the important individuals and making everyone feel good.",
        conceptList: null,
      },
      {
        sentence:
          "Så det finns enorma möjligheter om inte politikerna i det fallet pajar det vid en dumhet. ",
        start: 1475.16,
        end: 1481.16,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "So there are huge opportunities unless the politicians in that case blow it on a stupidity.",
        conceptList: null,
      },
      {
        sentence: "\n\nMen sen har du läroböcker och gym och grejer. ",
        start: 1481.16,
        end: 1485.6,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "But then you have textbooks and gyms and stuff.",
        conceptList: null,
      },
      {
        sentence: "\n\nJag ska inte tona ner den här siffran. ",
        start: 1485.6,
        end: 1491.48,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "I'm not going to downplay this number.",
        conceptList: null,
      },
      {
        sentence: "Men nej, men... ",
        start: 1491.48,
        end: 1492.6,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "But no, but...",
        conceptList: null,
      },
      {
        sentence: "Åter tillbaka till det jag sa. ",
        start: 1492.6,
        end: 1494.44,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Back to what I was saying.",
        conceptList: null,
      },
      {
        sentence: "Det finns nästan inga dåliga idéer. ",
        start: 1494.44,
        end: 1496.8,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "There are almost no bad ideas.",
        conceptList: null,
      },
      {
        sentence: "Gillar jag människorna, då kan jag satsa på vad som helst. ",
        start: 1496.8,
        end: 1500.36,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "If I like the people, then I can go for anything.",
        conceptList: null,
      },
      {
        sentence: "Fattar du många som lyssnar? ",
        start: 1500.36,
        end: 1502.2,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Do you get many people listening?",
        conceptList: null,
      },
      {
        sentence: "\n\nJag hade en jobbarkompis, John. ",
        start: 1502.2,
        end: 1504.84,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "I had a work friend, John.",
        conceptList: [
          {
            name: "John",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.999463141,
            positions: [{ start: 26.0, end: 30.0 }],
          },
        ],
      },
      {
        sentence: "Hans bror är kirurg. ",
        start: 1504.84,
        end: 1506.88,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "His brother is a surgeon.",
        conceptList: null,
      },
      {
        sentence:
          "Han skar upp min pappa när han gjorde en trippelbypass för väldigt länge sen. ",
        start: 1506.88,
        end: 1511.96,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "He cut up my dad when he did a triple bypass a long time ago.",
        conceptList: null,
      },
      {
        sentence: "Men det är ganska kul. ",
        start: 1511.96,
        end: 1513.8,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "But it's kind of fun.",
        conceptList: null,
      },
      {
        sentence: "Han gick från att vara kirurg till att bli chefkirurg. ",
        start: 1513.8,
        end: 1516.76,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "He went from being a surgeon to becoming chief surgeon.",
        conceptList: null,
      },
      {
        sentence: "Då frågade han bara skillnaden. ",
        start: 1516.76,
        end: 1518.6,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Then he just asked the difference.",
        conceptList: null,
      },
      {
        sentence:
          "Han sa att han gick från att skära i människor till att skära ner på personal. ",
        start: 1518.6,
        end: 1522.24,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "He said he went from cutting people to cutting staff.",
        conceptList: null,
      },
      {
        sentence: "Nej, vad hemskt. ",
        start: 1522.24,
        end: 1523.96,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "No, that's terrible.",
        conceptList: null,
      },
      {
        sentence: "Det var ganska kul. ",
        start: 1523.96,
        end: 1525.32,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "It was quite fun.",
        conceptList: null,
      },
      {
        sentence: "\n\nSorgligt. ",
        start: 1525.32,
        end: 1525.88,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Sad.",
        conceptList: null,
      },
      {
        sentence: "\n\nGick det bra för din pappa? ",
        start: 1525.88,
        end: 1528.68,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Was your dad okay?",
        conceptList: null,
      },
      {
        sentence: "Ja. ",
        start: 1528.68,
        end: 1530.16,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Ja.",
        conceptList: null,
      },
      {
        sentence: "Eller hur menar du? ",
        start: 1530.16,
        end: 1533.0,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Or how do you mean?",
        conceptList: null,
      },
      {
        sentence: "Operationen gick bra. ",
        start: 1533.0,
        end: 1533.88,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "The operation went well.",
        conceptList: null,
      },
      {
        sentence: "Tack. ",
        start: 1533.88,
        end: 1534.92,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Tack.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nGustav, en väldigt viktig fråga som vi känner är var är våra miljoner? ",
        start: 1534.92,
        end: 1540.4,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "Gustav, a very important question that we feel is where are our millions?",
        conceptList: [
          {
            name: "Gustav Rentsåg",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9989571571,
            positions: [{ start: 0.0, end: 6.0 }],
          },
        ],
      },
      {
        sentence: "Var är dina stolar? ",
        start: 1540.4,
        end: 1542.4,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Where are your chairs?",
        conceptList: null,
      },
      {
        sentence: "\n\nDu borde ju vara Levlers största kund. ",
        start: 1542.4,
        end: 1547.0,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "You should be Levler's biggest customer.",
        conceptList: [
          {
            name: "Levler",
            conceptType: "ENTITY",
            entityType: "ORG",
            score: 0.5015286207,
            positions: [{ start: 17.0, end: 23.0 }],
          },
        ],
      },
      {
        sentence: "Kan man tycka, men eftersom jag behöver rådgivning... ",
        start: 1547.0,
        end: 1552.72,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "You might think so, but since I need advice...",
        conceptList: null,
      },
      {
        sentence: "Orimligt! ",
        start: 1552.72,
        end: 1553.36,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Unreal!",
        conceptList: null,
      },
      {
        sentence: "Jag behöver personlig rådgivning. ",
        start: 1553.36,
        end: 1556.96,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "I need personal advice.",
        conceptList: null,
      },
      {
        sentence: "\n\nVi kan ge dig 700 spänn. 500 förflyttade fonder. ",
        start: 1556.96,
        end: 1560.48,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "We can give you 700 bucks. 500 transferred funds.",
        conceptList: null,
      },
      {
        sentence: "300 spänn om du startar månadssparande. ",
        start: 1560.48,
        end: 1562.48,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "300 bucks if you start monthly savings.",
        conceptList: null,
      },
      {
        sentence: "\n\nJag får tänka igenom det här nu. ",
        start: 1562.48,
        end: 1565.2,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "I'll have to think about this now.",
        conceptList: null,
      },
      {
        sentence: "\n\nJag vet att du är lite kund. ",
        start: 1565.2,
        end: 1566.8,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "I know you're a bit of a customer.",
        conceptList: null,
      },
      {
        sentence: "Nu gick jag till ett GDPR-klaver, men han sitter i rummet. ",
        start: 1566.8,
        end: 1569.28,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Now I went to a GDPR slave, but he's in the room.",
        conceptList: null,
      },
      {
        sentence: "Vad tycker du om tjänsten? ",
        start: 1569.28,
        end: 1571.8,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "What do you think of the service?",
        conceptList: null,
      },
      {
        sentence:
          "\n\nJag tycker att det är bra, för man ska absolut pröva sina egna saker. ",
        start: 1571.8,
        end: 1577.56,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "I think that's good, because you should definitely try your own things.",
        conceptList: null,
      },
      {
        sentence: "Men jag tycker att det funkar bra. ",
        start: 1577.56,
        end: 1581.36,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "But I think it works well.",
        conceptList: null,
      },
      {
        sentence:
          "Det jag gillar speciellt är att det är fruktansvärt enkelt. ",
        start: 1581.36,
        end: 1584.4,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "What I particularly like is that it's terribly simple.",
        conceptList: null,
      },
      {
        sentence: "Och det ser väldigt tilltalande ut. ",
        start: 1584.4,
        end: 1586.92,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "And it looks very appealing.",
        conceptList: null,
      },
      {
        sentence:
          "Lätt att navigera, lätt att se, lätt att plocka ihop och köpa det man vill. ",
        start: 1586.92,
        end: 1591.16,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Easy to navigate, easy to see, easy to pick up and buy what you want.",
        conceptList: null,
      },
      {
        sentence: "Det är väl Cindy Dietel. ",
        start: 1591.16,
        end: 1592.92,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "That would be Cindy Dietel.",
        conceptList: [
          {
            name: "Cindy Dietel",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9959818721,
            positions: [{ start: 11.0, end: 23.0 }],
          },
        ],
      },
      {
        sentence:
          "Man kan ha exakt samma idé som er –men får man inte den känslan, så blir det inte värt nånting. ",
        start: 1592.92,
        end: 1598.72,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "You can have exactly the same idea as you - but if you don't get that feeling, it's not worth anything.",
        conceptList: null,
      },
      {
        sentence:
          "Det är det hårdaste spelet ni spelar där konkurrenterna är bra. ",
        start: 1598.72,
        end: 1603.0,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "It's the toughest game you play where the competition is good.",
        conceptList: null,
      },
      {
        sentence:
          "Det här kräver extremt mycket, men jag tycker att det är så långt så bra. ",
        start: 1603.0,
        end: 1607.6,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "This is extremely demanding, but I think it's so far so good.",
        conceptList: null,
      },
      {
        sentence:
          "Jag har stärkt oddsen för att den här investeringen ska bli väldigt bra -...efter att jag har varit kund. ",
        start: 1607.6,
        end: 1614.64,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "I have strengthened the odds that this investment will be very good -...after I have been a customer.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nHur kan de passa på att ge en stor eloge till våra UXar -...som verkligen jobbar med varenda liten detalj? ",
        start: 1614.64,
        end: 1621.64,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "How can they take the opportunity to pay tribute to our UXers -...who really work on every little detail?",
        conceptList: null,
      },
      {
        sentence: "Speciellt när vi bygger aktier. ",
        start: 1621.64,
        end: 1622.96,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Especially when we build shares.",
        conceptList: null,
      },
      {
        sentence:
          "De sitter med pyttig grej. –för att det ska bli riktigt schysst. ",
        start: 1622.96,
        end: 1627.48,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "They sit with neat stuff. -to make it really cool.",
        conceptList: null,
      },
      {
        sentence: "–Är det nåt du saknar, Gustav? ",
        start: 1627.48,
        end: 1630.32,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "-Are you missing something, Gustav?",
        conceptList: [
          {
            name: "Gustav Rentsåg",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9974249601,
            positions: [{ start: 23.0, end: 29.0 }],
          },
        ],
      },
      {
        sentence: "\n\nDet är USA-aktier jag ser fram emot. ",
        start: 1630.32,
        end: 1633.4,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "It's US stocks I'm looking forward to.",
        conceptList: null,
      },
      {
        sentence: "Där har ni största chans att göra skillnad. ",
        start: 1633.4,
        end: 1638.32,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "That's where you have the best chance of making a difference.",
        conceptList: null,
      },
      {
        sentence:
          "De stora sparplattformarna, som ni vet, ska för sig alla med nåt. ",
        start: 1638.32,
        end: 1645.08,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "The big savings platforms, as you know, are all going for something.",
        conceptList: null,
      },
      {
        sentence: "Trading, superproffs, vanliga sparare... ",
        start: 1645.08,
        end: 1655.28,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Trading, super professionals, ordinary savers...",
        conceptList: null,
      },
      {
        sentence:
          "Göra nåt som är för den mer vanliga spararen, som kanske inte behöver 2 000 rappar och allt vad det kan vara som ett proffs vill ha, då tror jag att ni kommer att bli väldigt attraktiva. ",
        start: 1655.28,
        end: 1669.44,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Do something that's for the more ordinary saver, who may not need 2,000 raps and whatever it is that a professional wants, then I think you're going to be very attractive.",
        conceptList: null,
      },
      {
        sentence:
          "Att en vara ska passa alla är svårt, så ni har en jätteuppning tror jag. ",
        start: 1669.44,
        end: 1673.88,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "It's hard to make one product fit all, so I think you have a great setup.",
        conceptList: null,
      },
      {
        sentence: "Bra! ",
        start: 1673.88,
        end: 1674.84,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Bra!",
        conceptList: null,
      },
      {
        sentence:
          "\n\nHar du nåt råd till mig och Louise när vi har hållit på i ett och ett halvt år? ",
        start: 1674.84,
        end: 1679.84,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "Do you have any advice for me and Louise after a year and a half?",
        conceptList: [
          {
            name: "Louise",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.999157548,
            positions: [{ start: 28.0, end: 34.0 }],
          },
        ],
      },
      {
        sentence: "\n\nDet är som en annan chef sa. ",
        start: 1679.84,
        end: 1682.52,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "It's like another manager said.",
        conceptList: null,
      },
      {
        sentence: "Det är bara att steppa upp ett varv till. ",
        start: 1682.52,
        end: 1684.96,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Just step it up a notch.",
        conceptList: null,
      },
      {
        sentence: "\n\nTackar, tackar. ",
        start: 1684.96,
        end: 1688.88,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Thank you, thank you.",
        conceptList: null,
      },
      {
        sentence: "Det ska nog klara dem. ",
        start: 1688.88,
        end: 1690.76,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "It'll do them.",
        conceptList: null,
      },
      {
        sentence: "Step up. ",
        start: 1690.76,
        end: 1691.24,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Step up.",
        conceptList: null,
      },
      {
        sentence: "Level up. ",
        start: 1691.24,
        end: 1691.92,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Level up.",
        conceptList: null,
      },
      {
        sentence: "Det är bara att gå in och skrika till allihopa där inne. ",
        start: 1691.92,
        end: 1695.8,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Just go inside and shout to everyone inside.",
        conceptList: null,
      },
      {
        sentence: "Keep it up. ",
        start: 1695.8,
        end: 1697.56,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Keep it up.",
        conceptList: null,
      },
      {
        sentence: "Fortsätt. ",
        start: 1697.56,
        end: 1698.28,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Go on.",
        conceptList: null,
      },
      {
        sentence: "Det ska vi. ",
        start: 1698.28,
        end: 1699.32,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "We will.",
        conceptList: null,
      },
      {
        sentence: "Nåt annat du vill tillägga, Jocke? ",
        start: 1699.32,
        end: 1702.0,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Anything else you want to add, Jocke?",
        conceptList: [
          {
            name: "Jocke Smällkarameller",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.5070589185,
            positions: [{ start: 28.0, end: 33.0 }],
          },
        ],
      },
      {
        sentence: "\n\nSka vi tillägga nånting? ",
        start: 1702.0,
        end: 1704.24,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Should we add something?",
        conceptList: null,
      },
      {
        sentence:
          "Nej, men jag tror på en sak som jag inte har trott på förut. ",
        start: 1704.24,
        end: 1708.48,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "No, but I do believe in something that I haven't believed in before.",
        conceptList: null,
      },
      {
        sentence:
          "Om jag har suttit med på nåt efter ett och ett halvt år, så är det så här... ",
        start: 1708.48,
        end: 1715.2,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "If I've been in on something after a year and a half, it's like this...",
        conceptList: null,
      },
      {
        sentence: "Man pratade mycket om inkubations... ",
        start: 1715.2,
        end: 1718.16,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "There was a lot of talk about incubation...",
        conceptList: null,
      },
      {
        sentence: "Just det. ",
        start: 1718.16,
        end: 1718.56,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "That's right.",
        conceptList: null,
      },
      {
        sentence: "Man skulle ha nåt... ",
        start: 1718.56,
        end: 1719.92,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "You would have something...",
        conceptList: null,
      },
      {
        sentence:
          "\n\nAlla som startar nåt ska sitta tillsammans långt ifrån en annan verksamhet. ",
        start: 1719.92,
        end: 1727.24,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Everyone who starts something should sit together far away from another business.",
        conceptList: null,
      },
      {
        sentence: "\n\nMan pratade mycket om sånt förut. ",
        start: 1727.24,
        end: 1729.68,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "There was a lot of talk about that before.",
        conceptList: null,
      },
      {
        sentence: "Jag bara... ",
        start: 1729.68,
        end: 1729.96,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Jag bara...",
        conceptList: null,
      },
      {
        sentence:
          "Men inom aktiemarknaden pratar man mycket om att likviditet föder likviditet. ",
        start: 1729.96,
        end: 1735.92,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "But in the stock market, there is a lot of talk about liquidity breeding liquidity.",
        conceptList: null,
      },
      {
        sentence: "Att de aktier som börjar snurra blir en snabbhållseffekt. ",
        start: 1735.92,
        end: 1739.76,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "That the stocks that start spinning become a quick-fix effect.",
        conceptList: null,
      },
      {
        sentence:
          "Det hade funkat om vi inte gjorde det i en organisation som är van att starta bolag. ",
        start: 1739.76,
        end: 1747.24,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "It would have worked if we didn't do it in an organisation that is used to starting companies.",
        conceptList: null,
      },
      {
        sentence: "Det hade inte funkat att göra det i en klassisk bankmiljö. ",
        start: 1747.24,
        end: 1751.84,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "It wouldn't have worked in a traditional banking environment.",
        conceptList: null,
      },
      {
        sentence: "Man måste ha det. ",
        start: 1751.84,
        end: 1753.8,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "You have to have it.",
        conceptList: null,
      },
      {
        sentence: "Jag har ett graft underskattat det. ",
        start: 1753.8,
        end: 1756.12,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "I have a graft underestimated it.",
        conceptList: null,
      },
      {
        sentence:
          "Att man måste nästan vara i en miljö där de beroenden du har runt omkring dig också är vana vid att det finns kusiner från landet som kommer utifrån och bråkar eller som det blir. ",
        start: 1756.12,
        end: 1771.12,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "That you almost have to be in an environment where the dependents you have around you are also used to there being cousins from the country who come from out of town and fight or whatever.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nMan är inte alltid besvärligast, bara lite besvärligast. ",
        start: 1771.12,
        end: 1774.68,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "You're not always the most difficult, just a little.",
        conceptList: null,
      },
      {
        sentence: "Folk vet att man har ett mindset som är annorlunda. ",
        start: 1774.68,
        end: 1778.48,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "People know that you have a mindset that is different.",
        conceptList: null,
      },
      {
        sentence:
          "Och det där tror jag är graft underskattat innan jag själv började med någonting och inser att hade vi gjort det här i någon annan typ av miljö ",
        start: 1778.48,
        end: 1786.04,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "And that I think is grossly underestimated before I started anything myself and realize that had we done this in any other kind of environment",
        conceptList: null,
      },
      {
        sentence: "Då hade vi fortfarande pratat namn. ",
        start: 1786.04,
        end: 1788.8,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "At that time we were still talking names.",
        conceptList: null,
      },
      {
        sentence: "Jag tror att man underskattar det väldigt mycket. ",
        start: 1788.8,
        end: 1793.52,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "I think it is very much underestimated.",
        conceptList: null,
      },
      {
        sentence:
          "Att vara i en miljö där inte bara ägare utan också miljödom du har dig runt omkring är vana vid att det startas nya projekt och bolag. ",
        start: 1793.52,
        end: 1801.32,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence:
          "Being in an environment where not only owners but also environmentalists are used to new projects and companies being started.",
        conceptList: null,
      },
      {
        sentence: "Man är också vana att göra dem framgångsrika. ",
        start: 1801.32,
        end: 1803.96,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "They are also used to making them successful.",
        conceptList: null,
      },
      {
        sentence: "Och det här ska bli koncernens mest framgångsrika bolag. ",
        start: 1803.96,
        end: 1810.92,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "And this will be the Group's most successful company.",
        conceptList: null,
      },
      {
        sentence: "\n\nJa, det kommer att bli det största. ",
        start: 1810.92,
        end: 1812.8,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "Yes, it will be the biggest.",
        conceptList: null,
      },
      {
        sentence:
          "Det blir så stort att vi kanske på nåt inverterat sätt köper hela koncernen. ",
        start: 1812.8,
        end: 1819.04,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence:
          "It will be so big that we might buy the whole group in some inverted way.",
        conceptList: null,
      },
      {
        sentence: "Det ser vi fram emot. ",
        start: 1819.04,
        end: 1819.88,
        speaker: "SPEAKER_01",
        topic_id: [],
        eng_sentence: "We look forward to it.",
        conceptList: null,
      },
      {
        sentence: "\n\nDå har vi alla lyckats. ",
        start: 1819.88,
        end: 1821.24,
        speaker: "SPEAKER_02",
        topic_id: [],
        eng_sentence: "Then we have all succeeded.",
        conceptList: null,
      },
      {
        sentence:
          "\n\nSupertack, Gustav, för att du var med oss i Level Up med Joakim Riks. ",
        start: 1821.24,
        end: 1839.8,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence:
          "Super thanks, Gustav, for joining us in Level Up with Joakim Riks.",
        conceptList: [
          {
            name: "Gustav Rentsåg",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.9976317883,
            positions: [{ start: 11.0, end: 17.0 }],
          },

          {
            name: "Level Up",
            conceptType: "ENTITY",
            entityType: "MISC",
            score: 0.9893775582,
            positions: [{ start: 44.0, end: 52.0 }],
          },

          {
            name: "Joakim Riks",
            conceptType: "ENTITY",
            entityType: "PER",
            score: 0.8454179764,
            positions: [{ start: 57.0, end: 68.0 }],
          },
        ],
      },
      {
        sentence: "Hejdå allihopa! ",
        start: 1839.8,
        end: 1845.56,
        speaker: "SPEAKER_00",
        topic_id: [],
        eng_sentence: "Goodbye everyone!",
        conceptList: null,
      },
    ],
  },
};
export default data;
