import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { appContext } from "common/context";
import TexelioLogo from "assets/texeliologo.svg";
const LandingPage = () => {
  const { currentUser } = useContext(appContext);
  const navigate = useNavigate();
  const checkLoggedUser = () => {
    if (currentUser === undefined) {
      return;
    } else if (currentUser) {
      navigate("/content");
    } else {
      navigate("/login");
    }
  };
  useEffect(() => {
    checkLoggedUser();
  }, [currentUser]);
  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <div className="w-14">
        <img className="w-full" src={TexelioLogo} alt="Texelio" />
      </div>
    </div>
  );
};

export default LandingPage;
