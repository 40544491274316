import React, { memo, useMemo, useContext } from "react";
import { individualRetailInvestorContext } from "common/context";
import {
  getDataWithPercentageWidth,
  visualiticsBarHandler,
} from "common/exportedFunctions";
import { useSpring, animated } from "@react-spring/web";
import VisualiticsDataDisplay from "./VisualiticsDataDisplay";

const Latest = ({ latestProps }) => {
  // get context data
  const {
    translatedTopics,
    currentView,
    editListContent,
    visualiticsData,
    individualRetailInvestorDispatch,
  } = useContext(individualRetailInvestorContext);

  //if latestProps has visualiticsState property, use its searchResults
  // because it indicates the component was rendered by CubeMode
  const { searchResults, loading, message, error } =
    latestProps?.visualiticsState ?? visualiticsData;

  // animate bar graphs
  const styles = useSpring({
    from: { width: "0%" },
    to: { width: "100%" },
    delay: 500,
    config: { duration: 1500 },
  });

  const toggleFeed = async (selection) => {
    //filter data based on current view
    const filteredData = await visualiticsBarHandler({
      selection,
      data: editListContent,
      currentView,
      categoryName: visualiticsData.categoryName,
    });

    // update currentView content of editListContent to filteredData
    individualRetailInvestorDispatch({
      type: "setEditListContent",
      payload: filteredData,
    });

    // update selectedFeedMenu to 'feedItems'   in order to be navigated to feeditems.
    individualRetailInvestorDispatch({
      type: "setSelectedFeedMenu",
      payload: "feeditems",
    });
  };

  // create an array of 10 latest items mapped to their nodes
  const latestItems = useMemo(() => {
    // modify and map results to contain percentage width
    const dataWithPercentWidth = getDataWithPercentageWidth({
      data: searchResults,
      itemValueKey: "entity_count",
      categoryName: visualiticsData.categoryName,
      translatedTopics,
      itemNameKey: "entity_name",
    });

    // map each and return their node elements
    return dataWithPercentWidth.map((result) => {
      //destructure result values
      const { name, percentageWidth } = result;

      //hide result if it contains 'Quote' topic name
      if (visualiticsData.categoryName === "topic" && name === "Quote")
        return null;

      //return result
      return (
        <div
          onClick={() => toggleFeed(result)}
          key={result.entity_id}
          className="w-full pb-1.5 capitalize cursor-pointer text-customFontNineteen"
        >
          <h2 className="mb-1">{name}</h2>
          <div style={{ width: `${percentageWidth}%` }}>
            <animated.div
              className="text-customFontSix flex items-center justify-center h-4 text-allPorts bg-onahau dark:bg-turquoise dark:text-black  py-0.5 "
              style={{ ...styles }}
            >
              {result.entity_count}
            </animated.div>
          </div>
        </div>
      );
    });
  }, [searchResults]);

  // check if latest items is empty
  const hasResults = useMemo(() => latestItems?.length > 0, [latestItems]);

  return (
    <VisualiticsDataDisplay
      hasResults={hasResults}
      otherProps={{ loading, message, error }}
    >
      <div className="w-full mb-4">{latestItems}</div>
    </VisualiticsDataDisplay>
  );
};

export default memo(Latest);
