import CustomSelectButton from "components/Buttons/CustomSelectButton";
const media = [
  {
    label: "All Media",
    value: "all",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "media",
  },
  {
    label: "News",
    value: "news",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "media",
  },
  {
    label: "Press Release",
    value: "pr",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "media",
  },
  {
    label: "Earning Calls",
    value: "earningcalls",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "media",
    disabled: true,
  },
];

const MediaFilter = () => {
  return <CustomSelectButton options={media} />;
};

export default MediaFilter;
