import { individualRetailInvestorContext } from "common/context";
import React, { useContext } from "react";
import { useMemo } from "react";
import EditableItem from "./EditableItem";
import { uniqueId } from "lodash";

export default function EditListContentArea({ editListContentProps }) {
  //destructure props
  const { listName } = editListContentProps;

  //get data from context
  const { editListContent } = useContext(individualRetailInvestorContext);

  //get content based on listName
  let content;
  if (listName === "events") {
    // add event_1 and event_2
    content = [editListContent["event_1"], editListContent["event_2"]];
  } else {
    content = editListContent[listName];
  }

  //map through contents and return node elements
  let mappedContent;
  if (listName === "events") {
    // handle events
    mappedContent = content.map((event, index) => {
      //hide if empty event
      if (!event.length) return null;

      // create a unique for the events group
      const uniqueGroupKey = uniqueId("event");
      return (
        <EditableItem key={uniqueGroupKey} itemId={index} listName={listName}>
          <div className="w-40 border border-gray-500 p-0.5  ">
            <h1 className="my-1 font-semibold underline underline-offset-2">
              Event Group
            </h1>
            {event.map((section, index) => {
              //construct the name
              const name = section.name.split("(")[0];
              return (
                <p
                  key={section.id}
                  className="before:content-['-'] before:mr-0.5 my-1 whitespace-nowrap overflow-x-hidden text-ellipsis"
                >
                  {name}
                </p>
              );
            })}
          </div>
        </EditableItem>
      );
    });
  } else {
    //assign the mapped nodes
    mappedContent = content.map((item) => (
      <EditableItem key={item.id} itemId={item.id} listName={listName}>
        <h1 className="w-full  whitespace-nowrap overflow-x-hidden text-ellipsis  hover:bg-blue-100 ">
          {item.name}
        </h1>
      </EditableItem>
    ));
  }

  //render the mappedContent
  const renderedContent = useMemo(() => mappedContent, [content]);

  // hide it if there is no content
  if (!renderedContent.length) return null;

  return (
    <div className="w-full bg-linen dark:bg-rhino text-customFontOne text-black-russian dark:text-white p-0.5">
      <ul className="w-full ">{renderedContent}</ul>
    </div>
  );
}
