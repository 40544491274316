import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import ViewControl from "../ViewControl/ViewControl";
import { getNewCurrentViewName } from "common/exportedFunctions";
import EditList from "./EditList";
const EditListContainer = () => {
  // store the name of the current visible list
  const [currentListName, setCurrentListName] = useState("investments");

  // store refs for all individual editList containers
  const listRefs = useRef([]);

  //create an array of edit list names
  const editListNames = useMemo(
    () => ["investments", "watchlist", "events"],
    []
  );

  //show or hide view control buttons
  const showPrevIcon = currentListName !== "investments";
  const showNextIcon = currentListName !== "events";

  //handle list name change
  const handleListNameChange = useCallback(
    (direction) => {
      // get new list name
      const newListName = getNewCurrentViewName({
        currentView: currentListName,
        views: editListNames,
        direction,
      });

      //update the current list name
      setCurrentListName(newListName);
    },
    [currentListName]
  );

  // scroll the desired list into visible area whenever control buttons are clicked
  const scrollListIntoView = () => {
    // get element container node
    const containerElement = listRefs.current[currentListName];

    //scroll into the visible area
    containerElement.scrollIntoView({
      behavior: "smooth",
      inline: "start",
      block: "nearest",
    });
  };

  // scroll the desired list into visible area whenever control buttons are clicked
  useEffect(() => scrollListIntoView(), [currentListName]);
  return (
    <div className="w-full py-6">
      <ViewControl
        viewControlProps={{
          updateViewHandler: handleListNameChange,
          currentView: currentListName,
          showPrevIcon,
          showNextIcon,
        }}
      />
      <div className="w-full flex flex-nowrap overflow-y-auto overflow-x-hidden scrollbar-hide">
        {editListNames.map((listName) => (
          <div key={listName} className="min-w-full">
            <EditList ref={listRefs} content={listName} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(EditListContainer);
