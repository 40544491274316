import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { individualRetailInvestorContext } from "common/context";
const CustomButton = (props) => {
  // get content from context
  const { editListContent, currentView, individualRetailInvestorDispatch } =
    useContext(individualRetailInvestorContext);

  //destructure props values
  const {
    text,
    id,
    isClear,
    type = "",
    category = "",
  } = props.customButtonProps;

  // set default color and background
  let color = "black";
  let backgroundColor = "white";

  // show different colors for topic buttons
  if (category === "topics") {
    if (type === "EQUITY") {
      color = "#42A4D0";
      backgroundColor = "#FEF2E6";
    } else {
      color = "#05AE51";
      backgroundColor = "#FEF2E6";
    }
  }

  // remove item from the list
  const removeItemHandler = () => {
    //if isClear is true, then item is from the SelectedItems component
    if (isClear) {
      //if currentView is not 'latest', then we only clear the keywords property of editListContent
      // as it's only item rendered in other views
      if (currentView !== "latest") {
        //set value of keywords property to empty array
        individualRetailInvestorDispatch({
          type: "setEditListContent",
          payload: { ...editListContent, keywords: [] },
        });

        //exit the function
        return;
      }

      //handle clearance in 'latest' view
      //create a copy of selected items
      const selectedItemsCopy = editListContent["latest"];

      // filter out the removed item buy checking if has a customOption property
      // customOption indicates that it's a keyword, hence should be removed entirely
      const filteredItems = selectedItemsCopy.filter((item) => {
        //check for customOption property, i.e if is keyword
        if (item?.customOption && item.id === id) {
          //remove it by returning false
          return false;
        } else {
          //return item whose id is not equal to the one we are removing
          return item.id !== id;
        }
      });

      // update 'latest' view to contain filtered items
      individualRetailInvestorDispatch({
        type: "setEditListContent",
        payload: { ...editListContent, latest: filteredItems },
      });
    }
  };
  return (
    <button
      onClick={removeItemHandler}
      style={{ color, backgroundColor }}
      className="py-0.5 px-1 border-2 border-sazerac my-px max-w-customButton grid grid-rows-1 gap-x-1 grid-cols-customButton grid-flow-col items-center capitalize outline-none  rounded-full  font-bold text-customFontSix "
    >
      {isClear && (
        <span>
          <AiOutlineClose size={12} className="text-black" />
        </span>
      )}
      <span className="whitespace-nowrap overflow-hidden text-ellipsis">
        {text}
      </span>
    </button>
  );
};

export default CustomButton;
