import React, { useState, memo, useRef, useMemo, useContext } from "react";
import { useEffect } from "react";
import { fetchLatestOrTrendingVisualitics, searchFeedItems } from "common/api";
import Visualitics from "components/RetailInvestors/Visualitics/Visualitics";
import FeedItems from "components/RetailInvestors/FeedItems/FeedItems";
import { individualRetailInvestorContext } from "common/context";

//This component will manage it's own data because it is specific to just one view based on
//content props it receives. Therefore it cannot be used to show data of another view.
const CubeFaceContent = ({ faceName, allFaces }) => {
  // store feedItems state data
  const [feedState, setFeedState] = useState({
    searchResults: [],
    postOrigin: "posts",
    loading: false,
    message: "",
    error: "",
  });

  // get context data from IndividualRetailInvestorContent
  //will use context's categoryName, sectionName and afterTime
  //because they are controls in the visualitics
  const {
    visualiticsData,
    media,
    selectedFeedMenu,
    editListContent,
    language,
    mood,
    topics,
    section,
    currentView,
  } = useContext(individualRetailInvestorContext);

  //store visualitics state data
  const [visualiticsState, setVisualiticsState] = useState({
    searchResults: [],
    afterTime: visualiticsData.afterTime,
    loading: false,
    message: "",
    error: "",
  });

  //abort controller to cancel http requests
  const abortControllerRef = useRef(null);

  //determine if the faceName is equal to currentView name
  const isFaceCurrentView = useMemo(
    () => faceName === currentView,
    [currentView]
  );

  //get index of currentView
  const currentViewIndex = allFaces.indexOf(currentView);

  //get index of this component's name
  const myNameIndex = allFaces.indexOf(faceName);

  //get the difference between myNameIndex and currentViewIndex
  //this will be used to determine direction and degree of rotation of this component
  const indexesDifference = myNameIndex - currentViewIndex;

  //Multiply by 90 to get rotation degrees
  const degreesRotated = indexesDifference * 90;

  // get selected data to send along in the API call
  //use faceName as property key
  const selectedData = useMemo(
    () => [...editListContent[faceName], ...editListContent["keywords"]],
    [editListContent, faceName]
  );

  //construct language Arr based on selected language
  const languageArr = language === "all" ? [] : [`${language}`];

  //construct request body data
  const requestBodyData = {
    topicIds: [],
    companyIds: [],
    indexIds: [],
    sectorCodes: [],
    languages: languageArr,
    sentiment: `${mood}`,
    topicType: `${topics}`,
    sectionType: `${section}`,
  };

  // fetch data based on selectedFeedMenu if is 'feedItems' or 'visualitics'
  const updateContent = () => {
    // construct media type
    const mediaTypes = media === "all" ? ["NEWS", "PR"] : [`${media}`];

    // call appropriate api based on the type of data we want
    if (selectedFeedMenu === "visualitics") {
      //  modify request body
      const modifiedRequestBodyData = {
        ...requestBodyData,
        aggregateType: visualiticsData.categoryName,
        topNum: 50,
        afterTime: visualiticsData.afterTime,
      };

      // call the API endpoint
      fetchLatestOrTrendingVisualitics({
        sectionName: visualiticsData.sectionName,
        modifiedRequestBodyData,
        selectedData,
        abortControllerRef,
        stateData: visualiticsState,
        stateDataUpdateFunction: setVisualiticsState,
        isFromCubeMode: true,
      });
    } else {
      //modify request body
      const modifiedRequestBodyData = {
        ...requestBodyData,
        limit: 50,
        freeTextQuery: "",
        mediaTypes,
        sources: [],
        includeSectionOrders: true,
        checkHasSummary: true,
        matchingTypes: [],
      };

      // api call to get feed items
      searchFeedItems({
        modifiedRequestBodyData,
        selectedData,
        abortControllerRef,
        stateData: feedState,
        stateDataUpdateFunction: setFeedState,
        isFromCubeMode: true,
      });
    }
  };

  // get data and update whenever necessary,
  //also abort http requests when component unmounts
  useEffect(() => {
    const abortControllerRefCopy = abortControllerRef;
    updateContent();
    return () => {
      return abortControllerRefCopy.current?.abort();
    };
  }, [
    mood,
    media,
    section,
    topics,
    language,
    selectedFeedMenu,
    visualiticsData.categoryName,
    visualiticsData.sectionName,
    visualiticsData.afterTime,
    editListContent[faceName],
    editListContent["keywords"],
  ]);

  //decide which layout to show based on the selectedFeedMenu
  const contents = useMemo(
    () =>
      selectedFeedMenu === "visualitics" ? (
        <Visualitics visualiticsProps={{ visualiticsState }} />
      ) : (
        <FeedItems feedItemsProps={{ feedState }} />
      ),
    [selectedFeedMenu, feedState, visualiticsState]
  );

  return (
    <div
      className={[
        "absolute rounded-lg  w-full h-full  overflow-auto  scrollbar-hide ",
      ].join(" ")}
      style={{
        transform: `rotateY(${
          isFaceCurrentView ? 0 : degreesRotated
        }deg) translateZ(100px)`,
        backfaceVisibility: "hidden",
        transition: "all 0.5s",
      }}
    >
      {contents}
    </div>
  );
};

export default memo(CubeFaceContent);
