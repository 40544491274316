import { sendEmailToRecipient } from "common/api";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, db, getDoc } from "common/firebase-app";
const GrantAccess = () => {
  const { requestid } = useParams();
  const checkRequestDetails = async () => {
    try {
      // get request from requestId
      const docRef = doc(db, "users", requestid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const { code, email, fullname } = docSnap.data();
        //Send an email to Recipient about a new invitation request
        await sendEmailToRecipient({
          fullname,
          email,
          code,
        });
      } else {
        // doc.data() will be undefined in this case
        throw new Error("No such request!");
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    checkRequestDetails();
  }, [requestid]);
  return (
    <div className="w-full h-screen md:w-1/2 mx-auto lg:w-3/12  flex flex-col items-center justify-center text-white px-2">
      <h1 className="w-full text-center font-bold text-customFontThirteen text-white">
        Access Granted!
      </h1>
    </div>
  );
};

export default GrantAccess;
