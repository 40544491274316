/* eslint-disable no-undef */
(function(){
    Object.defineProperty(window,'env',{value:process.env.REACT_APP_ENVIRONMENT,enumerable:true,configurable:true})
    try{
        if(!window.loggy){
            if (window.env!=='development'){
                var log = (() => { });
                var debug = log
                var info=log
                var warn=log
                var error=log
                console.debug("Turning OFF logging because env is:",window.env)
            }else{
                debug=console.debug;
                info = console.log;
                log=console.log
                warn = console.warn;
                error =console.error;
                console.debug("Turning ON logging because env is:",window.env)
            }
            Object.defineProperty(window,'loggy',{value:{debug,info,warn,error},enumerable:true,configurable:true})
            Object.defineProperty(window.loggy,'log',{value:log,enumerable:false,configurable:true})
        }
    }catch(e){
        console.error(e);
        console.warn("Logging will not be available")
    }
})()
export default window.loggy; //no need, just think react wants to see an export