import { individualRetailInvestorContext } from "common/context";
import { useContext } from "react";
import { AiOutlineBell, AiOutlineClose } from "react-icons/ai";

export default function EditableItem({ children, itemId, listName }) {
  //get context data
  const { editListContent, individualRetailInvestorDispatch } = useContext(
    individualRetailInvestorContext
  );
  // show notifications and remove icons
  const buttons = [
    { name: "bell", icon: <AiOutlineBell size={14} /> },
    {
      name: "close",
      icon: <AiOutlineClose size={14} />,
    },
  ];

  // remove item from the list
  const removeItem = () => {
    if (listName === "events") {
      //itemId will always be 0 and 1
      // because we only got two events event_1 and event_2
      // so itemId 0 would be event_1 and itemId_1 would be event_2
      //create variable to hold item key
      const itemKey = itemId === 0 ? "event_1" : "event_2";

      //update the list by setting an empty array to the speficied itemKey
      individualRetailInvestorDispatch({
        type: "setEditListContent",
        payload: { ...editListContent, [itemKey]: [] },
      });
      return;
    }

    //delete an item from investments and watchlist
    //get all its content
    const items = editListContent[listName];

    //filter out the given item id
    const filteredItems = items.filter((item) => item.id !== itemId);

    //update the list with the new data
    individualRetailInvestorDispatch({
      type: "setEditListContent",
      payload: { ...editListContent, [listName]: filteredItems },
    });
  };

  // handle when a button is clicked
  const handleButtonClick = (buttonName) => {
    if (buttonName === "close") {
      //go ahead and remove item
      removeItem();
    }
  };
  return (
    <li className="w-full grid auto-cols-editListItem grid-rows-1 grid-flow-col gap-x-2 items-start my-2 px-0.5  ">
      {children}
      <div className="flex  items-center space-x-1 justify-self-end">
        {buttons.map(({ name, icon }) => (
          <button key={name} onClick={() => handleButtonClick(name)}>
            {icon}
          </button>
        ))}
      </div>
    </li>
  );
}
