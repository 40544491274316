import React, { useContext } from "react";
import CheckedIcon from "assets/checkedicon.svg";
import UncheckedIcon from "assets/uncheckedicon.svg";
import Select from "react-select";
import { FaChevronDown } from "react-icons/fa";
import { styleProxy } from "common/exportedFunctions";
import { clsx } from "clsx";
import { individualRetailInvestorContext } from "common/context";

// custom components for react-select package. Refer docs on their website

const CustomContainer = (props) => {
  return (
    <div ref={props.innerRef} {...props.innerProps} className="relative w-full">
      {props.children}
    </div>
  );
};
const CustomControl = (props) => {
  return (
    <div
      ref={props.innerRef}
      {...props.innerProps}
      className="w-full h-7 hover:cursor-pointer flex items-center justify-center bg-linen dark:bg-rhino  rounded-full text-customFontTwo font-semibold  p-0.5"
    >
      {props.children[0]}
    </div>
  );
};
const CustomValueContainer = ({
  innerRef,
  innerProps,
  children,
  selectProps: {
    value: { icon, label, color },
  },
}) => {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="w-full h-full flex items-center justify-center "
    >
      <div className="sr-only">{children}</div>
      <div
        className={`{${color} px-px w-full grid grid-rows-1 grid-cols-selectValue gap-x-0.5 grid-flow-col items-center  text-customFontFive font-semibold
    }`}
      >
        {icon !== "none" && (
          <div className="w-3 justify-self-end">
            <img className="w-full" src={icon} alt={label} />
          </div>
        )}
        {icon !== "none" ? (
          <div className="justify-self-center ">{label}</div>
        ) : (
          <div className="justify-self-center col-span-2 ">{label}</div>
        )}
        <div className="justify-self-end ">
          <FaChevronDown className="text-black dark:text-white " />
        </div>
      </div>
    </div>
  );
};
const CustomMenu = (props) => {
  return (
    <div
      ref={props.innerRef}
      {...props.innerProps}
      className=" mx-auto absolute w-20 z-10  bg-linen dark:bg-rhino  py-1.5 px-1  mt-1 "
    >
      {props.children}
    </div>
  );
};
const formatOptionLabel = (
  { label, icon, color, value, disabled = false },
  { selectValue: [{ value: selectedValue }] }
) => {
  return (
    <div
      className={`${color}
        w-full
        grid
        grid-rows-1
        grid-cols-dropDown
        gap-x-0.5
        grid-flow-col
         items-center
         mb-2
        text-customFontFive
        font-semibold
        hover:cursor-pointer
        hover:bg-gray-500/10 dark:hover:bg-gray-500`}
    >
      {icon !== "none" && (
        <span
          className={clsx("w-3 justify-self-start", disabled && "opacity-50")}
        >
          <img className="w-full" src={icon} alt={label} />
        </span>
      )}
      {icon !== "none" ? (
        <span className={clsx("justify-self-start ", disabled && "opacity-50")}>
          {label}
        </span>
      ) : (
        <span className={clsx("col-span-2 ", disabled && "opacity-50")}>
          {label}
        </span>
      )}
      <span className={clsx("w-2 justify-self-end ", disabled && "opacity-50")}>
        {value === selectedValue ? (
          <img className="w-full" src={CheckedIcon} alt="Checked Icon" />
        ) : (
          <img className="w-full" src={UncheckedIcon} alt="Unchecked Icon" />
        )}
      </span>
    </div>
  );
};
const CustomSelectButton = ({ options }) => {
  //get context for individual partition
  const { individualRetailInvestorDispatch } = useContext(
    individualRetailInvestorContext
  );
  const handleChange = (selected) => {
    const { category, value } = selected;
    switch (category) {
      case "mood":
        individualRetailInvestorDispatch({ type: "setMood", payload: value });

        break;
      case "language":
        individualRetailInvestorDispatch({
          type: "setLanguage",
          payload: value,
        });

        break;
      case "mode":
        individualRetailInvestorDispatch({ type: "setMode", payload: value });

        break;
      case "topics":
        individualRetailInvestorDispatch({ type: "setTopics", payload: value });

        break;
      case "media":
        individualRetailInvestorDispatch({ type: "setMedia", payload: value });

        break;
      case "section":
        individualRetailInvestorDispatch({
          type: "setSection",
          payload: value,
        });

        break;
      case "skins":
        individualRetailInvestorDispatch({ type: "setSkin", payload: value });

        break;
      default:
        break;
    }
  };
  const handleDisabledOption = (option) => {
    return option.disabled;
  };
  return (
    <Select
      options={options}
      styles={styleProxy}
      isSearchable={false}
      defaultValue={options[0]}
      onChange={handleChange}
      placeholder={false}
      formatOptionLabel={formatOptionLabel}
      isOptionDisabled={handleDisabledOption}
      components={{
        Container: CustomContainer,
        Control: CustomControl,
        ValueContainer: CustomValueContainer,
        Menu: CustomMenu,
      }}
    />
  );
};

export default CustomSelectButton;
