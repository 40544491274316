import React, { memo, useRef, useMemo, useContext, useEffect } from "react";
import { individualRetailInvestorContext } from "common/context";
import { fetchLatestOrTrendingVisualitics, searchFeedItems } from "common/api";
import Visualitics from "../Visualitics/Visualitics";
import FeedItems from "../FeedItems/FeedItems";

const FeedMode = () => {
  // get context data from IndividualRetailInvestorContent
  const {
    editListContent,
    language,
    media,
    topics,
    mood,
    section,
    currentView,
    selectedFeedMenu,
    feedData,
    visualiticsData,
    individualRetailInvestorDispatch,
  } = useContext(individualRetailInvestorContext);

  //abort controller for canceling http requests
  const abortControllerRef = useRef(null);

  // get selected data to send along in the API call
  const selectedData = useMemo(() => {
    // include results for keywords in other  views except 'latest' view
    return currentView === "latest"
      ? [...editListContent[currentView]]
      : [...editListContent[currentView], ...editListContent["keywords"]];
  }, [editListContent, currentView]);

  //prepare request body to send along in API call
  const languageArr = language === "all" ? [] : [`${language}`];
  const requestBodyData = {
    topicIds: [],
    companyIds: [],
    indexIds: [],
    sectorCodes: [],
    languages: languageArr,
    sentiment: `${mood}`,
    topicType: `${topics}`,
    sectionType: `${section}`,
  };

  // fetch data based on selectedFeedMenu if is 'feedItems' or 'visualitics'
  const updateContent = () => {
    // construct media type
    const mediaTypes = media === "all" ? ["NEWS", "PR"] : [`${media}`];

    // call appropriate api based on the type of data we want
    if (selectedFeedMenu === "visualitics") {
      //  modify request body
      const modifiedRequestBodyData = {
        ...requestBodyData,
        aggregateType: visualiticsData.categoryName,
        topNum: 50,
        afterTime: visualiticsData.afterTime,
      };

      // call the API endpoint
      fetchLatestOrTrendingVisualitics({
        sectionName: visualiticsData.sectionName,
        modifiedRequestBodyData,
        selectedData,
        abortControllerRef,
        stateData: visualiticsData,
        stateDataUpdateFunction: individualRetailInvestorDispatch,
      });
    } else {
      //modify request body
      const modifiedRequestBodyData = {
        ...requestBodyData,
        limit: 50,
        freeTextQuery: "",
        mediaTypes,
        sources: [],
        includeSectionOrders: true,
        checkHasSummary: true,
        matchingTypes: [],
        sortOrder:'Desc'
      };
      // api call to get feed items
      searchFeedItems({
        modifiedRequestBodyData,
        selectedData,
        abortControllerRef,
        stateData: feedData,
        stateDataUpdateFunction: individualRetailInvestorDispatch,
      });
    }
  };

  // get data and update whenever necessary
  useEffect(() => {
    const abortControllerRefCopy = abortControllerRef;
    updateContent();
    return () => {
      return abortControllerRefCopy.current?.abort();
    };
  }, [
    currentView,
    mood,
    media,
    section,
    topics,
    language,
    selectedFeedMenu,
    visualiticsData.categoryName,
    visualiticsData.sectionName,
    visualiticsData.afterTime,
    editListContent[currentView],
    editListContent["keywords"],
  ]);

  //clear all state data when this component unmounts
  // to prevent flickering of the screen
  useEffect(() => {
    return () => {
      individualRetailInvestorDispatch({
        type: "setFeedData",
        payload: { searchResults: [], loading: true, message: "", error: "" },
      });
    };
  }, []);

  // show either feed data or visualitics data based on selectedFeedMenu
  const content = useMemo(
    () =>
      selectedFeedMenu === "visualitics" ? <Visualitics /> : <FeedItems />,
    [selectedFeedMenu]
  );
  return content;
};

export default memo(FeedMode);
