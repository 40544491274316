import React, { useRef, useState, useContext, useCallback } from "react";
import { get, groupBy } from "lodash";
import { AsyncTypeahead, Hint, Menu } from "react-bootstrap-typeahead";
import { AiOutlineSearch } from "react-icons/ai";
import { getFilteredListData } from "common/exportedFunctions";
import { translatedSectionNames } from "common/externalData";
import { individualRetailInvestorContext } from "common/context";
import SearchBarMenuItems from "./SearchBarMenuItems";
import { useSelector } from "react-redux";
import { getSearchCompletionsArray } from "common/api";
import CompanyPopup from "../CompanyPopup/CompanyPopup";

export default function SearchBar({
  abortControllerRef,
  allowNew,
  listName,
  updateNewEventSections,
  disableSearchBar,
}) {
  // store suggestions from search results
  const [searchResults, setSearchResults] = useState([]);
  const [showCompanyPopup, setShowCompanyPopup] = useState(false);
  const [companyData, setCompanyData] = useState({});

  // get context data
  const { editListContent, currentView, individualRetailInvestorDispatch } =
    useContext(individualRetailInvestorContext);

  //get data from redux
  const { globalLanguage } = useSelector((store) => store.app);

  //store the search bar node element for resetting the input field later on
  const typeAheadRef = useRef();

  // show custom search bar input
  const renderSearchInput = useCallback(
    function renderSearchInput({
      inputRef,
      referenceElementRef,
      ...otherInputProps
    }) {
      // create placeholder variable
      let placeholder;
      // use translated text for placeholder
      if (globalLanguage === "en") {
        //show different texts for views and edit list components
        placeholder = listName ? `Edit ${listName}` : "Find anything";
      } else {
        //show different texts for views and edit list components
        placeholder = listName
          ? `Redigera ${translatedSectionNames[listName]}`
          : "Sök";
      }
      return (
        <Hint>
          <input
            {...otherInputProps}
            ref={(input) => {
              inputRef(input);
              referenceElementRef(input);
            }}
            className=" w-full outline-none  text-black bg-inherit dark:bg-rhino dark:text-white"
            placeholder={placeholder}
          ></input>
        </Hint>
      );
    },
    [globalLanguage]
  );

  //handle when an info icon (i) of company suggestion is clicked
  const openCompanyPopup = useCallback(function openCompanyPopup(suggestion) {
    //update company data
    setCompanyData(suggestion);

    //show company popup
    setShowCompanyPopup(true);
    // engagement.startTracking(false,'companyInfo'); //if we show menu we stop tracking;
  }, []);

  // close company popup
  const closeCompanyPopup = useCallback(function closeCompanyPopup() {
    // engagement.startTracking(true,'companyInfo'); //if we show menu we stop tracking;
    setShowCompanyPopup(false);
  });

  // custom method for renderMenu in react-bootstrap-typeahead package
  const renderSearchResults = useCallback(function renderSearchResults(
    results,
    menuProps,
    state
  ) {
    // Not all props are required, we ignore some of them
    const {
      newSelectionPrefix: ignore1,
      paginationText: ignore2,
      renderMenuItemChildren: ignore3,
      ...requiredMenuProps
    } = menuProps;

    //group the suggestions by their categories
    //get method by lodash gets the name of category, or if undefined return the 'keywords' text as a name
    const suggestionsByCategory = groupBy(results, (suggestion) =>
      get(suggestion, "category", "keywords")
    );
    return (
      <Menu
        {...requiredMenuProps}
        className="w-full  top-5  text-black  px-1 rounded-sm z-10 bg-white dark:bg-rhino dark:text-white "
        style={{ position: "absolute" }}
      >
        <SearchBarMenuItems
          searchBarMenuItemsProps={{
            suggestionsByCategory,
            state,
            allowNew,
            openCompanyPopup,
          }}
        />
      </Menu>
    );
  },
  []);

  //show search results, which is a list of suggestions
  const showSearchResults = (searchWord) => {
    //provide a listName or currentView as keys
    // the request is asynchronous
    getSearchCompletionsArray(
      searchWord,
      abortControllerRef,
      listName ? listName : { currentView }
    )
      .then(setSearchResults)
      .catch(console.error);
  };

  // handle selected item from the suggestions
  const handleChange = (selected) => {
    // update event sections if listName is events
    // means search bar was rendered by events component of editList
    if (listName === "events") {
      updateNewEventSections(selected);
    } else {
      // handles both search bar from editList and top nav components.
      // filters through the list, which obtained by utilizing currentView and listName
      // as editListContent keys
      // prevents creating duplicate items within the list
      const filteredData = getFilteredListData({
        selected,
        data: editListContent,
        editListContentKey: listName ?? null,
        viewContentKey: currentView,
      });

      // udate state
      individualRetailInvestorDispatch({
        type: "setEditListContent",
        payload: filteredData,
      });
    }

    // reset search bar
    typeAheadRef.current.clear();
  };

  return (
    <div className="w-full text-customFontSix text-black bg-white dark:bg-rhino rounded-full grid grid-rows-1 grid-cols-searchBar gap-x-1.5 items-center  my-2  p-1">
      <div>
        <AiOutlineSearch size={12} className="text-black  dark:text-white " />
      </div>
      <AsyncTypeahead
        ref={typeAheadRef}
        id="searchBarDropdown"
        labelKey="name"
        className="w-full bg-inherit cursor disabled:cursor-not-allowed "
        isLoading={false}
        options={searchResults}
        renderInput={renderSearchInput}
        renderMenu={renderSearchResults}
        minLength={2}
        onSearch={showSearchResults}
        onChange={handleChange}
        allowNew={allowNew}
        disabled={disableSearchBar}
      />
      {showCompanyPopup && (
        <CompanyPopup
          companyPopupProps={{
            companyData,
            closeFn: closeCompanyPopup,
            allowNew,
          }}
        />
      )}
    </div>
  );
}
