import clsx from "clsx";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineMobile } from "react-icons/ai";
import { contentContext } from "common/context";
import { useSelector } from "react-redux";
import {
  CubeModeIcon,
  DesktopIcon,
  FeedModeIcon,
} from "./../CustomIcons/CustomIcons";

// change between feed mode, cube mode or mobile & desktop view
const PhoneViewOptionsPanel = () => {
  // extract data from content context
  const { currentLayout, contentDispatch } = useContext(contentContext);

  // extract redux data
  const { globalLanguage } = useSelector((store) => store.app);

  // change between feed mode or cube mode
  const navItems = [
    {
      name: globalLanguage === "en" ? "Feed Mode" : "Flöde Mode",
      icon: <FeedModeIcon />,
      value: "feed-mode",
    },
    {
      name: "Cube Mode",
      icon: <CubeModeIcon />,
      value: "cube-mode",
    },
  ];

  const layoutControlButtons = [
    { name: "mobile", icon: <AiOutlineMobile size={24} /> },
    { name: "desktop", icon: <DesktopIcon /> },
  ];

  // change layout to either mobile or desktop view
  const toggleCurrentLayout = () => {
    contentDispatch({
      type: "setCurrentLayout",
      payload: currentLayout === "mobile" ? "desktop" : "mobile",
    });
  };

  return (
    <div className="w-full xl:w-8/12 mx-auto grid grid-cols-3 grid-rows-1 items-center gap-x-2 rounded-lg bg-white shadow-md py-2 px-8 my-2">
      {navItems.map(({ name, value, icon }) => (
        <NavLink
          key={name}
          to={`/content/${value}`}
          className={({ isActive }) =>
            clsx(
              "w-full rounded-md py-2 px-3 text-sm flex flex-col items-center justify-center space-y-1",
              isActive
                ? "bg-onahau text-black "
                : "bg-inherit text-gray-400  hover:bg-gray-300 hover:text-gray-500"
            )
          }
        >
          <span>{icon}</span>
          <span className="text-center">{name}</span>
        </NavLink>
      ))}
      <div>
        {layoutControlButtons.map((item) => (
          <button
            key={item.name}
            onClick={toggleCurrentLayout}
            className={clsx(
              "rounded-md capitalize py-2 px-3 text-sm flex flex-col items-center justify-center space-y-1 bg-inherit text-gray-500 hover:text-black",
              item.name === currentLayout ? "hidden" : "w-full"
            )}
          >
            <span>{item.icon}</span>
            <span>{item.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default PhoneViewOptionsPanel;
