import React from "react";
import { useField } from "formik";

const CustomTextInput = (props) => {
  const [field, meta] = useField(props);
  return (
    <div className="my-2.5 w-full">
      <input
        {...field}
        {...props}
        className="w-full outline-none p-4 my-2 rounded border border-solitude bg-white text-customFontFourteen text-black"
      ></input>
      {meta.touched && meta.error && (
        <p className="text-red-500 text-sm w-full">{meta.error}</p>
      )}
    </div>
  );
};

export default CustomTextInput;
