import React, { useContext } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { appContext } from "common/context";
const ProtectedRoute = () => {
  let location = useLocation();
  const { currentUser } = useContext(appContext);
  return currentUser ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

export default ProtectedRoute;
