import React, { memo, useEffect, useState, useContext, useMemo } from "react";
import "./rotatingStoriesAnimation.css";
import { individualRetailInvestorContext } from "common/context";
import CubeFaceContent from "./CubeFaceContent";

const CubeMode = () => {
  //create an array of cube faces where the items are the names of the views
  // we hide 'latest' view because a cube has only four sides.
  const cubeFaces = useMemo(
    () => ["investments", "watchlist", "event_1", "event_2"],
    []
  );

  // get context data
  const { individualRetailInvestorDispatch, currentView } = useContext(
    individualRetailInvestorContext
  );

  // const prevRotationDirection = useRef(cubeRotationDegree);
  const [zoomingInOut, setZoomingInOut] = useState(false);

  // set default view to investments if currentView is 'latest' ;
  // hide previous navigation icon if currentView is 'investments'
  // next view will automatically be hidden by TopNavBar when currentView is 'event_2'
  // this hides the 'latest' view. We only show investments, wathclist , event_1 and event_2
  const setDefaultCubeValues = () => {
    //variable to hold current view
    let currentViewName = currentView;
    //variable to hold previous icon state
    let showPrevIconInCubeMode;
    if (currentView === "latest") {
      //set currentView to investments
      currentViewName = "investments";
    }

    if (currentView === "investments") {
      //hide previous navigation icon
      showPrevIconInCubeMode = false;
    } else if (currentView !== "latest") {
      //show previous navigation icon
      showPrevIconInCubeMode = true;
    }

    //update state
    individualRetailInvestorDispatch({
      type: "setCurrentViewAndShowPrevIconCubeMode",
      payload: {
        currentView: currentViewName,
        showPreviousIconInCubeMode: showPrevIconInCubeMode,
      },
    });
  };

  //update zoom in and out of the cube
  useEffect(() => {
    // create animation effect
    setZoomingInOut(true);
    const timeout = setTimeout(() => setZoomingInOut(false), 1000);

    //set default cube values
    setDefaultCubeValues();

    //reset on unmount
    return () => {
      setZoomingInOut(false);
      clearTimeout(timeout);
      //reset state to show prev icon
      individualRetailInvestorDispatch({
        type: "setCurrentViewAndShowPrevIconCubeMode",
        payload: {
          currentView,
          showPreviousIconInCubeMode: true,
        },
      });
    };
  }, [currentView]);

  return (
    <div className="w-full h-full">
      <div
        className="w-full h-full mx-auto rounded-b-2xl "
        style={{ perspective: "600px" }}
      >
        <div
          className={`w-full h-full relative ${
            zoomingInOut ? "zoomOutAndIn" : ""
          }`}
          style={{
            transformStyle: "preserve-3d",
            transform: `translateZ(-100px) scale3d(1, 1, 1)`,
            animationDuration: "0.5s",
          }}
        >
          {cubeFaces.map((face) => {
            return (
              <CubeFaceContent
                key={face}
                faceName={face}
                allFaces={cubeFaces}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(CubeMode);
