import { useSelector } from "react-redux";
import { random } from "lodash";
import { personaData } from "common/externalData";
import { useContext } from "react";
import { contentContext } from "common/context";
export default function GeneratePortfolio() {
  //get context data
  const { contentDispatch } = useContext(contentContext);
  const { globalLanguage } = useSelector((store) => store.app);

  //personas
  const personas = [
    "first_persona",
    "second_persona",
    "third_persona",
    "fourth_persona",
    "fifth_persona",
    "sixth_persona",
  ];

  const changePersona = () => {
    //get random pesona
    const randomPersona = personas[random(0, 5)];
    //destructure persona
    const {
      [randomPersona]: {
        latest,
        investments,
        watchlist,
        event_1,
        event_2 = [],
        persona_image,
      },
    } = personaData;

    //update data
    contentDispatch({
      type: "setGeneratedPersona",
      payload: {
        latest,
        investments,
        watchlist,
        event_1,
        event_2,
        persona_image,
      },
    });
  };
  return (
    <button
      onClick={changePersona}
      className="text-black text-base font-semibold bg-onahau hover:bg-onahau/80 rounded-full py-2.5 px-7 whitespace-nowrap"
    >
      {globalLanguage === "en" ? "Generate Portfolio" : "Generera Innehav"}
    </button>
  );
}
