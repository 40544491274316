import React, { memo } from "react";
import { bodyText } from "common/exportedFunctions";
import ReactTimeAgo from "react-time-ago";
const ShrinkedContent = React.forwardRef(({ shrinkedContentProps }, ref) => {
  //destructure prop values
  const { shrinkedId, expandedId, section, companyId, releaseDate } =
    shrinkedContentProps;

  //get container ref that holds both shrinked and expanded components
  const { dynamicDivRef } = ref.current;

  // show/hide shrinked & expanded components
  // for that specific section
  const handleDynamicDiv = (params) => {
    const { shrinkedId, expandedId } = params;
    dynamicDivRef.current[shrinkedId].classList.add("hidden");
    dynamicDivRef.current[expandedId].classList.remove("hidden");
  };
  return (
    <div
      ref={(div) => (dynamicDivRef.current[shrinkedId] = div)}
      className="w-full hover:cursor-pointer  py-0.5 mb-0.5 border-b border-b-sazerac dark:border-b-ship-cove/50 grid auto-cols-auto grid-rows-1 gap-x-0.5 grid-flow-col "
    >
      <p
        onClick={() =>
          handleDynamicDiv({
            shrinkedId,
            expandedId,
          })
        }
        className="w-full line-clamp-2 text-customFontNineteen"
      >
        {bodyText(section.segments, companyId)}
      </p>
      <p className="w-full text-end text-customFontFive  font-bold">
        <ReactTimeAgo
          date={releaseDate * 1000}
          locale="en-US"
          timeStyle="round-minute"
        />
      </p>
    </div>
  );
});

export default memo(ShrinkedContent);
