import { FaEllipsisH } from "react-icons/fa";
export default function ImpressionEngagementHeader({ name }) {
  //return the heading of the stat currently displayed
  return (
    <div className="flex items-center justify-between border-b border-b-whisper-shade pb-5">
      <h1 className="font-semibold capitalize ">{name}</h1>
      <button>
        <FaEllipsisH size={18} />
      </button>
    </div>
  );
}
