import { find, isEmpty, maxBy, some, uniqBy, uniqueId } from "lodash";
import prettyMilliseconds from "pretty-ms";
import { getEarningsCallsFromApi, topicStatus } from "common/api";

// Reducer function for state update in IndividualRetailInvestorContent component
export const individualRetailInvestorReducer = (state, { type, payload }) => {
  switch (type) {
    case "setCurrentView": {
      return { ...state, currentView: payload };
    }
    case "setLanguage": {
      return { ...state, language: payload };
    }
    case "setMood": {
      return { ...state, mood: payload };
    }
    case "setMode": {
      return { ...state, mode: payload };
    }
    case "setTopics": {
      return { ...state, topics: payload };
    }
    case "setMedia": {
      return { ...state, media: payload };
    }
    case "setSkin": {
      return { ...state, skin: payload };
    }
    case "setSection": {
      return { ...state, section: payload };
    }
    case "setTranslatedTopics": {
      return { ...state, translatedTopics: payload };
    }
    case "setFeedData": {
      return { ...state, feedData: payload };
    }
    case "setVisualiticsData": {
      return { ...state, visualiticsData: payload };
    }
    case "setSelectedFeedMenu": {
      return { ...state, selectedFeedMenu: payload };
    }
    case "setSelectedOptionPhoneBottomNav": {
      return { ...state, selectedOptionPhoneBottomNav: payload };
    }
    case "setShowMainContent": {
      return { ...state, showMainContent: payload };
    }
    case "setBottomNavOptionAndShowMainContent": {
      return {
        ...state,
        selectedOptionPhoneBottomNav: payload.option,
        showMainContent: payload.showMainContent,
      };
    }
    case "setEditListContent": {
      return { ...state, editListContent: payload };
    }
    case "setSummary": {
      return { ...state, summary: payload };
    }
    case "setCurrentViewAndShowPrevIconCubeMode": {
      return {
        ...state,
        currentView: payload.currentView,
        showPreviousIconInCubeMode: payload.showPreviousIconInCubeMode,
      };
    }
    default:
      return { ...state };
  }
};

/**
 * Given a partial search term get autocomplete suggestions
 *
 * @param {string} searchWord The partial search term
 * @param {React.Ref} abortControllerRef
 * @param {string} origin  Which search bar did it originate from
 *
 * @return {object[]}  An array of search completions
 */
export const modifyCategoryName = (category) => {
  const categoryName = category.split("_")[0];
  let newCategoryName = "";
  switch (categoryName) {
    case "company":
      newCategoryName = "companies";
      break;
    case "sector":
      newCategoryName = "sectors";
      break;
    case "topic":
      newCategoryName = "topics";
      break;
    case "indices":
      newCategoryName = "indices";
      break;
    case "commodity":
      newCategoryName = "commodities";
      break;
    case "source":
      newCategoryName = "sources";
      break;
    case "keyword":
      newCategoryName = "keywords";
      break;
    default:
      break;
  }
  return newCategoryName;
};

/**
 * handles all cases when changing th views within the partitions
 * @param {*} param0
 * @returns
 */
export const getNewCurrentViewName = ({ views, currentView, direction }) => {
  // change direction from string to numerable value
  const navigateTo = direction === "previous" ? -1 : 1;

  //get index of current view
  const currentViewIndex = views.indexOf(currentView);

  //exit if currentViewIndex is -1
  if (currentViewIndex === -1) return;

  // subtract or add by one to the currentViewIndex
  // if direction is 'previous' or 'next' respectively
  const previousViewIndex = currentViewIndex + navigateTo;

  //get the new view name
  const newvViewName = views[previousViewIndex];

  return newvViewName;
};

//adds new data only if there's no duplicate
//handles addition of data from search bar
const addNewDataToList = (dataList, incomingData) => {
  //remove duplicate data
  const duplicateRemoved = dataList.filter((data) => {
    //remove duplicate data for keywords category which is checked by customOption key
    if (incomingData?.customOption && data?.customOption) return false;
    // remove data of same id to incoming data
    return data.id !== incomingData.id;
  });

  // push the incoming data to the new list
  duplicateRemoved.push(incomingData);

  // return the new list
  return duplicateRemoved;
};

// filter out duplicate data before adding new item from search bar
//and from visualitics
export const getFilteredListData = ({
  selected,
  data,
  editListContentKey,
  viewContentKey,
}) => {
  //exit if there is no content in the incoming selected item
  if (!selected.length) return;

  // get the contents of selected item
  const [incomingData] = selected;

  //make a copy of data
  const dataCopy = { ...data };

  // filter data when editListContentKey is available
  // means search bar was rendered by an editList component ( investments / watchlist)
  if (editListContentKey) {
    // add new data to list
    const newList = addNewDataToList(
      dataCopy[editListContentKey],
      incomingData
    );

    //return the new list
    return { ...dataCopy, [editListContentKey]: newList };
  }

  // handle of addition if the view is 'latest'
  if (viewContentKey === "latest") {
    // add new data to list
    const newList = addNewDataToList(dataCopy[viewContentKey], incomingData);

    // return the new list
    return { ...dataCopy, [viewContentKey]: newList };
  }

  // for other views, only allow addition of keyword
  // because their explicit items are added through editListContentKey
  const newList = addNewDataToList(dataCopy["keywords"], incomingData);

  //return the new list
  return { ...dataCopy, keywords: newList };
};

//get a new event sections list
export const getNewEventSectionsList = ({ newSection, allSectionsList }) => {
  // exit if incoming section is empty
  // or if the list has at least three sections
  if (!newSection.length || allSectionsList.length >= 3) return;

  //get the data
  const [incomingData] = newSection;

  //make a copy of allSectionsList
  const allSectionsCopy = [...allSectionsList];

  //filter out same id to avoid duplicates
  const filteredEventSections = allSectionsCopy.filter(
    (section) => section.id !== incomingData.id
  );

  //push incoming data into the filteredEventSections
  filteredEventSections.push(incomingData);

  // return the new list

  return filteredEventSections;
};

export const bodyText = (segmentsArray, companyId) => {
  return segmentsArray.map((segment) => {
    const { flags, text, company_id } = segment;
    const unqId = uniqueId("segment");
    if (flags) {
      return (
        <span key={unqId} className="bg-cream dark:text-black">
          {text}
        </span>
      );
    } else if (company_id === companyId) {
      return (
        <span key={unqId} data-company-id={company_id} className="font-bold">
          {text}
        </span>
      );
    } else {
      return <span key={unqId}>{text}</span>;
    }
  });
};

// disable search bar and prevent searching
export const turnOffSearchBar = ({
  listName,
  content,
  eventSections = null,
}) => {
  // turn off search bar when there are at least 3 items in the event sections
  // and also when there are at least 2 events
  if (listName === "events") {
    //check if event_1 and event_2 are empty
    const firstEventEmpty = !content["event_1"].length;
    const secondEventEmpty = !content["event_2"].length;

    // check if both have data
    const bothHaveData = !firstEventEmpty && !secondEventEmpty;

    //turn off if events sections are at least 3 or
    //if both event_1 and event_2 have data inside, turn off search bar
    return eventSections.length >= 3 || bothHaveData;
  }

  // turn off search bar when there are at least 10 items in other listNames
  return content[listName].length >= 10;
};

// remove default select styles
export const styleProxy = new Proxy(
  {},
  {
    get: (target, propKey) => () => {},
  }
);

/** handles converstion of time
 * Format milliseconds to string with unit, eg. 65000 => 1:05 min
 * @param number ms
 * @return string
 */
export function prettyTime(type, value) {
  // this line is to get number of clicks
  if (type !== "time") {
    return value;
  }
  const formattedTime = prettyMilliseconds(value * 1000, {
    colonNotation: true,
  }); //=> '1:35.5'
  return formattedTime;
}

export function invitationCode() {
  let i = 1;
  const numArr = [];
  while (i <= 6) {
    numArr.push(Math.floor(Math.random() * 10));
    i++;
  }
  return Number(numArr.join(""));
}

export function controlBodyScroll(action) {
  switch (action) {
    case true:
    case "on":
    case "enable":
    case "scroll":
      document.body.style.overflow = "unset";
      break;
    case "disable":
    case "off":
    case false:
    case "hidden":
    case "noscroll":
      document.body.style.overflow = "hidden";
      break;
    default:
      console.error(
        "EINVAL: Please specify 'on'/true or 'off'/false. Got:",
        action
      );
  }
}

// converts ms to human readable  time (h:m:s)
export const formatTime = (time) => {
  const formattedTime = prettyMilliseconds(time * 1000, {
    secondsDecimalDigits: 0,
    colonNotation: true,
  });
  return formattedTime.split(":");
};

export function colorScale(percent) {
  var r,
    g,
    b = 0;
  if (percent < 50) {
    r = 255;
    g = Math.round(5.1 * percent);
  } else {
    g = 255;
    r = Math.round(510 - 5.1 * percent);
  }
  var h = r * 0x10000 + g * 0x100 + b * 0x1;
  return "#" + ("000000" + h.toString(16)).slice(-6);
}

//*** A SERIES OF FUNCTIONS FOR LATEST AND TRENDING SECTION ***/
/**
 * Get an item with max value in a list
 * @param {array} itemsList - array of items
 * @param {string} criterion - property for comparison
 * @return {object}
 */
const getMaxItem = (itemsList, criterion) => {
  //find max value by absolute values;
  // will be used as reference when deciding width of
  // fellow items
  return maxBy(itemsList, (item) => Math.abs(item[criterion]));
};

/**
 * // get new mapped search results with percentage values
 * @param {*} param0
 * @returns
 */
const getModifiedResults = ({
  data,
  maxItemValue,
  itemNameKey,
  itemValueKey,
  categoryName,
  translatedTopics,
}) => {
  const mappedResults = data.map((result) => {
    //get percentage value of this entity_count
    // will be used in determining width of item
    const percentageWidth = parseInt(
      (Math.abs(result?.[itemValueKey] ?? 1) / maxItemValue) * 100
    );

    //modify the name of this result
    let name = result?.[itemNameKey]?.split("(")[0].trim();

    // get translated topic names
    if (categoryName === "topic") {
      //find for that topic from translated topics
      const topic = find(
        translatedTopics,
        (topic) => topic.topic_id === result?.entity_id
      );

      // update the topic name or back to default if undefined
      name = topic?.name ?? name;
    }

    // return updated result object
    return { ...result, percentageWidth, name };
  });

  return mappedResults;
};

/**
 *  // modify and map results to contain percentage width
 * @param {object} param0
 */
export const getDataWithPercentageWidth = ({
  data,
  itemValueKey,
  categoryName,
  translatedTopics,
  itemNameKey,
}) => {
  // get the item with max entity_trend_percentage in the list
  const result = getMaxItem(data, itemValueKey);

  // get absolute value
  const maxItemValue = Math.abs(result?.[itemValueKey]) ?? 1;

  // get new mapped search results with percentage values
  // where percentage value will be used for width
  const results = getModifiedResults({
    data,
    maxItemValue,
    itemNameKey,
    itemValueKey,
    categoryName,
    translatedTopics,
  });

  //sort results by itemValueKey
  // so as to display them as design shows.
  const sortedResults = results.sort(
    (a, b) => b[itemValueKey] - a[itemValueKey]
  );

  // slice the results
  const slicedResults = sortedResults.slice(0, 10);

  return slicedResults;
};

//handle when a bar is clicked in latest/trending of visualitics
export const visualiticsBarHandler = async ({
  selection,
  data,
  currentView,
  categoryName,
}) => {
  // handle differently based on currentView
  let filteredData = data;
  if (currentView === "latest") {
    // update 'latest' property of editListContent items and selectedFeedMenu to feeditems
    // get topics
    const topics = await topicStatus;

    //destructure values of selection
    const { entity_id: id, entity_name: name } = selection;

    //get topic from array of topics category name is 'topic'
    let selectedData = selection;
    if (categoryName === "topic") {
      //find topic
      const topic = find(topics, ({ topic_id }) => topic_id === id);

      //update selected data if topic is available
      selectedData = topic ?? selectedData;
    }

    //construct a new  object by adding and removing some fields
    const newData = {
      id,
      name,
      category: categoryName + "s", //category names are in plural for items
      type: selectedData.topic_type ?? "",
    };

    //add new data to an array to fulfill the conditions to pass in the next funciton
    const newDataArr = [newData];

    // get filtered data
    const filteredNewData = getFilteredListData({
      selected: newDataArr,
      data,
      editListContentKey: null, // no 'listName' attribute
      viewContentKey: currentView,
    });

    //update filtered data
    filteredData = filteredNewData;
  }

  //return filteredData
  return filteredData;
};

/**
 * update visible stock name counter or pause it for invisible stock name for impression data
 * @param {*} stockData - stock whose impression data need to be updated
 * @param {*} incrementOrPause - action to determine whether impression data should be incremented or paused
 * @returns {object} new stock impression time
 */

const getUpdatedStockNameFields = (stockData, incrementOrPause) => {
  // get this stock's impression time or initialize to zero
  const stockImpressionTime = stockData?.stockImpressionTime ?? 0;

  //update stockImpressionTime
  const newStockImpressionTime =
    incrementOrPause === "increment"
      ? stockImpressionTime + 1
      : stockImpressionTime;

  //return an object of updated fields
  return { newStockImpressionTime };
};

/**
 * update visible topic name counter or pause it for invisible topic name for impression data
 * @param {*} topicData - topic whose impression data need to be updated
 * @param {*} incrementOrPause - action to determine whether impression data should be incremented or paused
 * @returns {object} new topic impression time
 */

const getUpdatedTopicNameFields = (topicData, incrementOrPause) => {
  // get this topic impression time or initialize to zero
  const topicImpressionTime = topicData?.topicImpressionTime ?? 0;

  //update topicImpressionTime
  const newTopicImpressionTime =
    incrementOrPause === "increment"
      ? topicImpressionTime + 1
      : topicImpressionTime;

  //return an object of updated fields
  return { newTopicImpressionTime };
};

/**
 * update an item impression time by increasing, pausing or setting to specific value,
 * depending on the action
 * @param {*} item - an item whose impression data need to be updated
 * @param {*} updatedStore - holds the latest updated impression data from store
 * @returns {object} updated copy whose value will be set as the new impression data
 */
const updateItemCounter = (item, updatedStore) => {
  //create a copy of impressionData
  const impressionDataCopy = { ...updatedStore };

  // get all stock mentions
  const allStocks = impressionDataCopy["stock mentions"];

  //destructure the values of item
  const { action, content } = item;

  //get stock data for stocks using
  const stockData = allStocks?.[content.stockName] ?? {};

  //construct variables for topics
  const name = content?.name;
  const type = content?.type;

  //create the topic group name that matches the category in the state
  const topicGroupName = type === "EQUITY" ? "investing topics" : "esg topics";

  //get this topic's group data
  const topicGroupData = impressionDataCopy[topicGroupName];

  //get all data for this topic
  const topicData = topicGroupData?.[name] ?? {};

  //switch state to perform desired action
  switch (action) {
    case "updateVisibleStockName":
      //get updated fields
      const { newStockImpressionTime: newTime } = getUpdatedStockNameFields(
        stockData,
        "increment"
      );
      //update stock mentions with this stock's new data
      impressionDataCopy["stock mentions"] = {
        ...allStocks,
        [content.stockName]: {
          ...stockData,
          stockImpressionTime: newTime,
          documentType: content.documentType,
        },
      };
      break;

    case "updateInVisibleStockName":
      //get updated fields
      const { newStockImpressionTime } = getUpdatedStockNameFields(
        stockData,
        "pause"
      );
      //update stock mentions with this stock's new data
      impressionDataCopy["stock mentions"] = {
        ...allStocks,
        [content.stockName]: {
          ...stockData,
          stockImpressionTime: newStockImpressionTime,
          documentType: content.documentType,
        },
      };
      break;

    case "updateVisibleTopicName":
      const { newTopicImpressionTime: visibleTopicImpressionTime } =
        getUpdatedTopicNameFields(topicData, "increment");
      //update this topic's group to include the new topic data
      impressionDataCopy[topicGroupName] = {
        ...topicGroupData,
        [name]: {
          ...topicData,
          topicImpressionTime: visibleTopicImpressionTime,
        },
      };
      break;

    case "updateInVisibleTopicName":
      const { newTopicImpressionTime: invisibleTopicImpressionTime } =
        getUpdatedTopicNameFields(topicData, "pause");
      //update this topic's group to include the new topic data
      impressionDataCopy[topicGroupName] = {
        ...topicGroupData,
        [name]: {
          ...topicData,
          topicImpressionTime: invisibleTopicImpressionTime,
        },
      };
      break;

    default:
      break;
  }

  //return updated store
  return impressionDataCopy;
};

/**
 * Create a single array of items whose impression data need to be updated
 * @param {*} temporaryArticlesLists - a group of containing lists of visible stock names, topics,news articles
 * ,press release and other invisible articles from the target.
 * @param {*} updateItemsRef - a ref array that will hold the items whose impression data need to be updated
 */
export const prepareStatsToDisplay = (temporaryArticlesLists) => {
  // get the contents of temporaryArticlesLists
  const { visibleStockNamesList, visibleTopicsList, invisibleArticles } =
    temporaryArticlesLists;

  //get unique list of visible stock names
  const uniqueVisibleStockNames = uniqBy(
    visibleStockNamesList,
    (stock) => stock.stockName
  );

  //get unique list of visible topics by name
  const uniqueVisibleTopics = uniqBy(visibleTopicsList, (topic) => topic.name);

  //hold invisible stock names that are not in uniqueStockNames
  //will be used to stop the counter for that specific stock
  const invisibleStockNames = [];

  //hold invisible topic name that are not in uniqueTopics
  //will be used to stop the counter for that specific topic
  const invisibleTopicsNames = [];

  //loop through invisibleArticles, check each article for stock name and topic name,
  //then push to the appropriate list
  invisibleArticles.forEach((invisibleArticle) => {
    //get invisibleArticle contents
    const {
      stockName: invisibleStockName,
      topics: invisibleTopics,
      documentType,
    } = invisibleArticle;

    //check if stock name is included in uniqueStockNames
    const stockNameInUnique = some(uniqueVisibleStockNames, {
      stockName: invisibleStockName,
    });
    //only add to invisible stock names if stockNameInUnique is false i.e not in visible array
    if (!stockNameInUnique) {
      //push stockName to invisibleStockNames
      invisibleStockNames.push({ stockName: invisibleStockName, documentType });
    }

    //get topic names
    invisibleTopics.forEach((invisibleTopic) => {
      //check if topic name is in uniqueTopics
      const topicInUnique = uniqueVisibleTopics.some(
        (topic) => topic.name === invisibleTopic.name
      );

      if (!topicInUnique) {
        //push the topic into invisible topic names if topicInUnique is false i.e not in visible array
        invisibleTopicsNames.push(invisibleTopic);
      }
    });
  });

  //make unique invisible stock names
  const uniqueInvisibleStockNames = uniqBy(
    invisibleStockNames,
    (stock) => stock.stockName
  );

  //make unique invisible topics
  const uniqueInvisibleTopicsNames = uniqBy(
    invisibleTopicsNames,
    (topic) => topic.name
  );

  //create a single list of items mapped to their action
  const updateItems = [];

  //loop through visible stock names and push to updateItems including
  //their action
  uniqueVisibleStockNames.forEach((stock) =>
    updateItems.push({ action: "updateVisibleStockName", content: stock })
  );

  //loop through visible topic names and push to updateItems including
  //their action
  uniqueVisibleTopics.forEach((topic) =>
    updateItems.push({ action: "updateVisibleTopicName", content: topic })
  );

  //loop through invisible stokc names and push to updateItems including
  //their action
  uniqueInvisibleStockNames.forEach((stock) =>
    updateItems.push({
      action: "updateInVisibleStockName",
      content: stock,
    })
  );

  //loop through invisible topic names and push to updateItems including
  //their action
  uniqueInvisibleTopicsNames.forEach((topic) =>
    updateItems.push({ action: "updateInVisibleTopicName", content: topic })
  );

  //update a updaterList
  return updateItems;
};

/**
 * update impression data by increasing counter for visible item and pausing it for invisible item
 * Stop counter after 15 seconds if there's no change in updateItemsRef.current i.e no scroll movement
 * @param {*} impressionData
 * @param {*} updateItemsRef - stores visible and invisible items on the target area
 * @param {*} dispatch - dispatch function from redux
 * @param {*} setImpressionData - update function for impression data
 * @param {*} timerIds - stores
 */
export const updateReduxStore = ({
  impressionData,
  contentToUpdate,
  dispatch,
  setImpressionData,
}) => {
  //get the current impressionData
  let newUpdatedStore = { ...impressionData };
  //loop through updateItems and update state accordingly
  contentToUpdate.forEach((item) => {
    //update an item counter and return updated object
    // which later on will update the store
    const updatedStore = updateItemCounter(item, newUpdatedStore);

    //update local variable
    newUpdatedStore = {
      ...updatedStore,
    };
  });

  //update the overall total impression time for news articles
  let totalImpressionTimeNewsArticles = 0;

  //update the overall total impression time for press release articles
  let totalImpressionTimePressReleaseArticles = 0;

  //update the overall total impression time for earnings calls articles
  let totalImpressionTimeEarningsCallsArticles = 0;

  //update the overall total impression time for podcasts articles
  let totalImpressionTimePodcastsArticles = 0;

  //get all stock mentions, get impression time & document type of each stock,
  // increment the total time for that document type category
  Object.values(newUpdatedStore["stock mentions"]).forEach(
    ({ stockImpressionTime, documentType }) => {
      if (documentType === "NEWS") {
        ////update total time for news articles
        totalImpressionTimeNewsArticles += stockImpressionTime;
      }

      if (documentType === "PR") {
        ////update total time for press release articles
        totalImpressionTimePressReleaseArticles += stockImpressionTime;
      }

      if (documentType === "EARNINGS CALLS") {
        ////update total time for earnings calls articles
        totalImpressionTimeEarningsCallsArticles += stockImpressionTime;
      }

      if (documentType === "PODCASTS") {
        ////update total time for podcasts articles
        totalImpressionTimePodcastsArticles += stockImpressionTime;
      }
    }
  );

  // update newsType and pressRelease type
  const updatedStoreWithUpdatedDataTypes = {
    ...newUpdatedStore,
    newsType: totalImpressionTimeNewsArticles,
    pressReleaseType: totalImpressionTimePressReleaseArticles,
    earningsCallsType: totalImpressionTimeEarningsCallsArticles,
    podcastsType: totalImpressionTimePodcastsArticles,
  };

  //update impressionData with this new data
  dispatch(setImpressionData(updatedStoreWithUpdatedDataTypes));
};

/**
 * prepare the articles to update the counter for impressions charts  accordingly
 * @param {*} allMountedArticles
 * @param {*} setTemporaryArticlesLists
 */

export const prepareArticlesToUpdateCounter = (
  allMountedArticles,
  setTemporaryArticlesLists
) => {
  //get all the articles
  const allArticles = Object.values(allMountedArticles);

  const categorizedArticles = {
    visibleStockNamesList: [],
    visibleTopicsList: [],
    invisibleArticles: [],
  };

  //loop through the articles and place each article to designated groups
  allArticles.forEach((article) => {
    //check if the inView property is true and update the following accordingly
    const articleIsVisible = article.inView;

    // push article to a list of visibleSTockNamesList
    categorizedArticles.visibleStockNamesList = articleIsVisible
      ? [
          ...categorizedArticles.visibleStockNamesList,
          { stockName: article.stockName, documentType: article.documentType },
        ]
      : categorizedArticles.visibleStockNamesList;

    //push topics to visible topics list if articleIsVisible true
    categorizedArticles.visibleTopicsList = articleIsVisible
      ? [...categorizedArticles.visibleTopicsList, ...article.topics]
      : categorizedArticles.visibleTopicsList;

    //push an article to invisible list if articleIsVisible is false
    categorizedArticles.invisibleArticles = articleIsVisible
      ? categorizedArticles.invisibleArticles
      : [...categorizedArticles.invisibleArticles, article];
  });

  //update temporaryArticlesLists with this new data
  setTemporaryArticlesLists(() => ({
    ...categorizedArticles,
  }));
};

export const getUpdatedStockNumberClicks = (stockData) => {
  //get number of clicks from the stockData or initialize to zero
  const stockNumberClicks = stockData?.stockNumberClicks ?? 0;

  // increment number of clicks for this stock
  const newStockNumberClicks = stockNumberClicks + 1;

  //return the new stock number of clicks
  return newStockNumberClicks;
};

export const getUpdatedTopicNumberClicks = (
  topicType,
  engagementDataCopy,
  topicDisplayName
) => {
  //create the topic group name that matches the category in the state
  // all other will be assigned to esg,
  const topicGroupName =
    topicType === "EQUITY" ? "investing topics" : "esg topics";

  //get this topic's group data
  const topicGroupData = engagementDataCopy[topicGroupName];

  //get all data for this topic
  const topicData = topicGroupData?.[topicDisplayName] ?? {};

  // get this topic number of clicks or initialize to zero
  const topicNumberClicks = topicData?.topicNumberClicks ?? 0;

  //increment topicNumberClicks
  const newTopicNumberClicks = topicNumberClicks + 1;

  //update number of clicks for this topic
  engagementDataCopy[topicGroupName] = {
    ...topicGroupData,
    [topicDisplayName]: {
      ...topicData,
      topicNumberClicks: newTopicNumberClicks,
    },
  };
};

/**
 //update number of clicks to parent stock name and contained topic name of this section
 * 
 * @param {*} engagementData - engagement data from redux
 * @param {*} companyName - stock name
 * @param {*} topicDisplayName - topic name
 * @param {*} topicType 
 * @param {*} documentType 
 * @returns 
 */
export const getUpdatedClickCounts = ({
  engagementData,
  companyName,
  documentType,
  topicDisplayName = undefined,
  topicType = undefined,
}) => {
  //get a copy of engagement data from redux store
  const engagementDataCopy = { ...engagementData };
  // get all stock mentions
  const allStocks = engagementDataCopy["stock mentions"];

  //get all data for this parent's stock name if present
  const stockData = allStocks?.[companyName] ?? {};

  //get updated number of stock name clicks
  const newStockNumberClicks = getUpdatedStockNumberClicks(stockData);

  //update the topic area of engagementDataCopy directly if topicDisplayName is present
  if (
    topicDisplayName !== "NONE" &&
    topicDisplayName !== "Quote" &&
    topicDisplayName !== undefined
  ) {
    getUpdatedTopicNumberClicks(
      topicType,
      engagementDataCopy,
      topicDisplayName
    );
  }

  //update the number of clicks to the document_type
  //update the number of clicks to the stock name
  const newUpdatedEngagementData = {
    ...engagementDataCopy,
    newsType:
      documentType === "NEWS"
        ? engagementDataCopy.newsType + 1
        : engagementDataCopy.newsType,
    pressReleaseType:
      documentType === "PR"
        ? engagementDataCopy.pressReleaseType + 1
        : engagementDataCopy.pressReleaseType,
    earningsCallsType:
      documentType === "EARNINGS CALLS"
        ? engagementDataCopy.earningsCallsType + 1
        : engagementDataCopy.earningsCallsType,
    podcastsType:
      documentType === "PODCASTS"
        ? engagementDataCopy.podcastsType + 1
        : engagementDataCopy.podcastsType,
    "stock mentions": {
      ...allStocks,
      [companyName]: {
        ...stockData,
        stockNumberClicks: newStockNumberClicks,
      },
    },
  };

  //return newUpdatedEngagementData
  return newUpdatedEngagementData;
};

//earnings call reducer function
export const earningsCallReducer = (state, { type, payload }) => {
  switch (type) {
    case "setFilteredEarningsCalls": {
      return { ...state, filteredEarningsCalls: payload };
    }
    case "setSearchWord": {
      return { ...state, searchWord: payload };
    }
    case "setData": {
      return { ...state, data: { ...state.data, ...payload } };
    }
    case "setIndividualEarningCall": {
      return {
        ...state,
        individualEarningCall: { ...state.individualEarningCall, ...payload },
      };
    }
    default:
      return { ...state };
  }
};

//get a list of earnings calls through API call
export const getEarningsCallsList = async (
  earningCallDispatch,
  abortControllerRef
) => {
  try {
    //set loading to true
    earningCallDispatch({
      type: "setData",
      payload: { loading: true, error: "", message: "" },
    });

    // call the api method and get all the earnings call
    const allEarningsCalls = await getEarningsCallsFromApi({
      abortControllerRef,
    });
    // update the earnings calls state and loading
    earningCallDispatch({
      type: "setData",
      payload: {
        earningsCall: allEarningsCalls,
        loading: false,
        error: "",
        message: `${allEarningsCalls.length ? "" : "No Results Found!"}`,
      },
    });

    // by default, the filtered earnings calls should contain all earnings call
    earningCallDispatch({
      type: "setFilteredEarningsCalls",
      payload: allEarningsCalls,
    });
  } catch (error) {
    //get the error message
    const { message } = error;

    //update the state only when the error message is not due to cancellations,
    //otherwise, during cancellations, initial state will be persisted
    if (message !== "canceled") {
      earningCallDispatch({
        type: "setData",
        payload: {
          earningsCalls: [],
          loading: false,
          message: "",
          error: message,
        },
      });
      earningCallDispatch({
        type: "setFilteredEarningsCalls",
        payload: [],
      });
    }
  }
};

// handler for filtering earnings calls based on searchword
export const searchEarningsCall = (
  searchWord,
  earningCallDispatch,
  earningsCall
) => {
  //set to all earnings call if no searchWord is provided
  if (!searchWord) {
    earningCallDispatch({
      type: "setFilteredEarningsCalls",
      payload: [...earningsCall],
    });
    return;
  }

  //get a copy of all earnings calls
  const earningsCallCopy = [...earningsCall];

  //filter out the calls by title based on searchWord
  const filteredCalls = earningsCallCopy.filter((earningCall) =>
    earningCall.title.toLowerCase().includes(searchWord.toLowerCase())
  );

  //update filtered calls if we got any results
  if (filteredCalls.length > 0) {
    earningCallDispatch({
      type: "setFilteredEarningsCalls",
      payload: [...filteredCalls],
    });

    //clear out any messages warnings from previous search
    earningCallDispatch({ type: "setData", payload: { message: "" } });
  } else {
    //if no any search results,set filtered calls back to all calls
    earningCallDispatch({
      type: "setFilteredEarningsCalls",
      payload: [...filteredCalls],
    });

    // update message warning
    earningCallDispatch({
      type: "setData",
      payload: { message: "No Results Found!" },
    });
  }
};

//update the impression time for this earning call
export const updateImpressionDataEarningCallOrPodcast = ({
  partitionName,
  topicsWithDetails = null,
  contentToUpdate,
  earningCallOrPodcast,
}) => {
  //if there's a param, that's a topic coming from looping the the transcript
  //add it to the  list used to update impression time
  //create a copy of contents to update
  const contentsToUpdateCopy = [...contentToUpdate];

  //check params contain topic
  if (topicsWithDetails?.length > 0) {
    topicsWithDetails.forEach((topic) => {
      contentsToUpdateCopy.push({
        action: "updateVisibleTopicName",
        content: {
          id: topic.topic_id,
          name: topic.topic_name,
          type: topic.topic_type,
        },
      });
    });
  }

  //check if earning call / podcast is not available
  const noEarningCallOrPodcast = isEmpty(earningCallOrPodcast);

  //update when partion name is partitionTwo
  // and when earning call is available
  if (partitionName === "partitionTwo" && !noEarningCallOrPodcast) {
    //get company name, document_type
    const { company_name, document_type } = earningCallOrPodcast;
    //add this company name to the list whose impression time need to be updated
    contentsToUpdateCopy.push({
      action: "updateVisibleStockName",
      content: { stockName: company_name, documentType: document_type },
    });

    //make this array unique by topic name and stockName
    const uniquArray = uniqBy(
      contentsToUpdateCopy,
      ({ content }) => content?.name ?? content?.stockName
    );
    // update the contentsToUpdateRef
    return [...uniquArray];
  }
};
