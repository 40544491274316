import { podcastContext } from "common/context";
import * as lodash from "lodash";
import React, { useContext, useMemo, useRef } from "react";
import EditablePodcastSentence from "./EditablePodcastSentence";
import { AiOutlineSave } from "react-icons/ai";

const EditPodCastTranscript = ({ editPodCastTranscriptProps }) => {
  const { podcasts, currentPodcastPlaying, podcastDispatch } =
    useContext(podcastContext);
  const { editPodcastTranscript, setEditPodcastTranscript } =
    editPodCastTranscriptProps;
  // get new transcript when podcast changes or transcript changes
  const transcript = useMemo(
    () => podcasts[currentPodcastPlaying]?.transcript ?? [],
    [currentPodcastPlaying, podcasts[currentPodcastPlaying]]
  );
  // store temporarily all editable "EditablePodcastSentence" without re-rendering
  const allStatesRef = useRef({});
  const handleStateChange = (componentId, state) => {
    allStatesRef.current[componentId] = state;
  };

  const textContent = useMemo(() => {
    return transcript.map((obj) => {
      const {
        sentence,
        start: startTime,
        end: endTime,
      } = obj;
      const uniqId = lodash.uniqueId("editable-sentence");
      return (
        <EditablePodcastSentence
          key={uniqId}
          onStateChange={(state) => handleStateChange(uniqId, state)}
          editablePodcastSentenceProps={{
            sentence,
            startTime,
            endTime,
            uniqId,
          }}
        />
      );
    });
  }, [currentPodcastPlaying]);
  const updatePodcastTranscript = () => {
    // get updated values of hours, mins, secs & sentence for each 'EditablePodcastSentence'
    // allStatesRef.current is an object with uniqueId as keys for each 'EditablePodcastSentence'
    const newTranscript = Object.keys(allStatesRef.current).map((uniqueId) => {
      const {
        "start-hours": startHours,
        "start-minutes": startMinutes,
        "start-seconds": startSeconds,
        "end-hours": endHours,
        "end-minutes": endMinutes,
        "end-seconds": endSeconds,
        text: sentence,
      } = allStatesRef.current[uniqueId];

      //convert to numbers(+) and convert hours, minutes & seconds to  seconds
      const newStartTime =
        +startHours * 3600 + +startMinutes * 60 + +startSeconds;
      const newEndTime = +endHours * 3600 + +endMinutes * 60 + +endSeconds;
      return {
        start: newStartTime,
        end: newEndTime,
        sentence,
      };
    });
    // update podcasts obj
    podcastDispatch({
      type: "setPodcasts",
      payload: {
        ...podcasts,
        [currentPodcastPlaying]: {
          ...podcasts[currentPodcastPlaying],
          transcript: newTranscript,
        },
      },
    });
    // remove edit mode
    setEditPodcastTranscript(!editPodcastTranscript);
  };
  return (
    <div className="absolute top-0 inset-x-0 w-full h-full p-2 px-2 bg-inherit">
      <div className="w-full flex justify-between ">
        <h1 className="w-full text-center font-bold">Edit Transcript</h1>
        <button
          onClick={updatePodcastTranscript}
          className="p-1 absolute  top-0 right-8 bg-gray-200 rounded-md mr-2"
        >
          <AiOutlineSave className="text-lg hover:scale-125" />
        </button>
      </div>
      <div className="w-full my-2 h-full overflow-auto scrollbar-hide">
        {textContent.length > 0 ? (
          textContent
        ) : (
          <p className="w-full text-center italic capitalize text-slate-500">
            No transcripts to show.
          </p>
        )}
      </div>
    </div>
  );
};

export default EditPodCastTranscript;
