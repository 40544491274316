import { personaData } from "./externalData";

const {
  first_persona: {
    latest,
    investments,
    watchlist,
    event_1,
    event_2 = [],
    persona_image,
  },
} = personaData;

// initial state used in IndividualRetailInvestorContent component

const today = Date.now() / 1000 - 24 * 60 * 60; // default to past 24 hours for period
export const individualRetailInvestorData = {
  language: "all",
  mood: "all",
  skin: "beige",
  mode: "normal",
  topics: "all",
  translatedTopics: [],
  media: "all",
  section: "all",
  showMainContent: true,
  currentView: "investments",
  showPreviousIconInCubeMode: true,
  selectedFeedMenu: "feeditems",
  selectedOptionPhoneBottomNav: "stories",
  editListContent: {
    latest,
    investments,
    watchlist,
    persona_image,
    event_1,
    event_2,
    keywords: [],
  },

  feedData: {
    searchResults: [],
    postOrigin: "posts",
    loading: false,
    message: "",
    error: "",
  },
  visualiticsData: {
    searchResults: [],
    afterTime: `${today}`,
    categoryName: "company",
    sectionName: "latest",
    period: "today",
    loading: false,
    message: "",
    error: "",
  },
  summary: {
    summaryTitle: "",
    summaryBody: "",
    releaseDate: "",
    sourceName: "",
    showSummary: false,
  },
};
