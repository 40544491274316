import React, { useContext } from "react";
import ReactTimeAgo from "react-time-ago";
import { animated, useSpring } from "@react-spring/web";
import { individualRetailInvestorContext } from "common/context";
const CompanyHeader = ({ companyInfo }) => {
  //destructure props values
  const {
    imageUrl,
    sourceName,
    companyName,
    companyId,
    companiesArray,
    releaseDate,
    hiddenSourceUrl,
    headline,
  } = companyInfo;

  //get context data
  const { currentView } = useContext(individualRetailInvestorContext);

  // construct necessary variables
  const exchangeSymbol = companiesArray.filter(
    (company) => company.id === companyId
  )[0]?.exchange_symbol;
  const ticker = exchangeSymbol ? exchangeSymbol.split(":")[1] : null;

  //create animations
  const styles = useSpring({
    from: { scale: 1.5 },
    to: { scale: 1 },
    delay: 1000,
    config: { duration: 15000 },
  });

  return (
    <div className="w-full">
      {imageUrl && (
        <div className="w-full relative h-companyHeaderHeight overflow-hidden">
          <animated.div
            className="w-full h-full  bg-black bg-cover bg-no-repeat bg-center"
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)),url(${imageUrl})`,
              ...styles,
            }}
          />
          <div className="absolute inset-0 flex flex-col justify-between items-end">
            <div className="font-semibold text-customFontFive  whitespace-nowrap py-1 px-2 bg-black/40  text-white text-center">
              <ReactTimeAgo
                date={releaseDate * 1000}
                locale="en-US"
                timeStyle="round-minute"
              />
            </div>
            <div className="text-end p-0.5">
              <a
                href={`${process.env.REACT_APP_BASE_URL}/frontend-api/relevance/follow-source-url?accessToken=${process.env.REACT_APP_ACCESS_TOKEN}&data=${hiddenSourceUrl}`}
                target="_blank"
                rel="noreferrer"
                className="text-customFontSix font-medium cursor-pointer underline text-white"
              >
                {sourceName}
              </a>
            </div>
          </div>
        </div>
      )}
      <div className="w-full p-1">
        {currentView === "latest" && (
          <h1 className="text-customFontSix capitalize my-1 font-bold truncate">
            {companyName} {ticker && `(${ticker})`}
          </h1>
        )}
        <h2 className=" text-customFontOne font-medium line-clamp-3 ">
          {headline}
        </h2>
      </div>
    </div>
  );
};

export default CompanyHeader;
