import React from "react";
import FeedMenu from "./FeedMenu";
import ContentMood from "./ContentMood";
import Languages from "./Languages";
import { AiOutlineClose } from "react-icons/ai";
import SkinFilter from "./SkinFilter";
import SectionsFilter from "./SectionsFilter";
import MediaFilter from "./MediaFilter";
import TopicsFilter from "./TopicsFilter";
import ContentMode from "./ContentMode";

export default function HamburgerMenu({ toggleHamburgerMenu }) {
  return (
    <div className="w-full h-full z-20 absolute top-0 inset-x-0">
      <div
        onClick={toggleHamburgerMenu}
        className="h-full w-full  bg-black/60 rounded-3xl"
      ></div>
      <div className="w-full absolute top-0 pt-4 pb-2 px-0.5 rounded-t-3xl bg-sazerac dark:bg-nero  ">
        <button
          className="w-full flex items-center justify-end mb-2"
          onClick={toggleHamburgerMenu}
        >
          <AiOutlineClose size={16} className="mx-1 hover:scale-110" />
        </button>
        <div className=" w-full grid grid-cols-3 gap-y-2 gap-x-1 items-center">
          <FeedMenu />
          <ContentMode />
          <ContentMood />
          <Languages />
          <SkinFilter />
          <TopicsFilter />
          <MediaFilter />
          <SectionsFilter />
        </div>
      </div>
    </div>
  );
}
