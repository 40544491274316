import React, { useContext, useState } from "react";
import { appContext } from "common/context";
import ContentOffered from "./ContentOffered";
import { useSelector } from "react-redux";

const Footer = () => {
  const { currentUser } = useContext(appContext);
  const { globalLanguage } = useSelector((store) => store.app);
  const [showContentOffered, setShowContentOffered] = useState(false);
  const footerContentLinks = [
    {
      name: globalLanguage === "en" ? "API Documentation" : "API Dokumentation",
      href: "https://texelio.stoplight.io/docs/texelio-api/8e768e6831f6c-texelio-api-overview",
    },
    { name: globalLanguage === "en" ? "Content Coverage" : "Content" },
    {
      name: globalLanguage === "en" ? "Contact Sales" : "Kontakta Sälj ",
      href: "https://www.texelio.com/demo",
    },
  ];
  const toggleContentOfferedHandler = () => {
    setShowContentOffered(!showContentOffered);
  };
  return (
    <div className="fixed inset-x-0 bottom-0 z-50 bg-inherit text-center text-customFontTwelve text-white p-2">
      {currentUser ? (
        <div className="w-full md:w-10/12 lg:w-5/12 mx-auto flex flex-col md:flex-row justify-center items-center md:justify-between my-2">
          {footerContentLinks.map((link) => {
            if (link.name === "Content Coverage" || link.name === "Content") {
              return (
                <button
                  key={link.name}
                  onClick={toggleContentOfferedHandler}
                  className="my-2 cursor-pointer"
                >
                  {link.name}
                </button>
              );
            }
            return (
              <a
                className="my-2 cursor-pointer"
                key={link.name}
                href={link.href}
                target="_blank"
                rel="noreferrer"
              >
                {link.name}
              </a>
            );
          })}
          <ContentOffered
            contentOfferedProps={{
              showContentOffered,
              toggleContentOfferedHandler,
            }}
          />
        </div>
      ) : (
        <div className="w-full  text-center">
          <p>
            Texelio AB Copyright {new Date().getFullYear()} © All Rights
            Reserved
          </p>
        </div>
      )}
    </div>
  );
};

export default Footer;
