import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AudioPlayer from "components/AudioPlayer/AudioPlayerWrapper";
import demoData from "./demoData.js";
import Transcript from "components/AudioPlayer/Transcript";
import {
  updateImpressionDataEarningCallOrPodcast,
  updateReduxStore,
} from "common/exportedFunctions.js";
import { individualRetailInvestorContext } from "common/context.js";
import { useDispatch, useSelector } from "react-redux";
import { setImpressionData } from "features/app/appSlice";
import { isEmpty } from "lodash";

export default function CompanyMedia({ name }) {
  //store the contents to update redux store
  const [contentToUpdate, setContentsToUpdate] = useState([]);

  // keep track of whether to do redux update or not
  const [isUpdating, setIsUpdating] = useState(false);

  //get the company media data
  // this could be earning call or podcast
  // the only podcast is carnegie
  const earningCallOrPodcast = demoData?.[name];

  //get the name of the partition rendering this component
  const { partitionName } = useContext(individualRetailInvestorContext);

  //get data from redux store
  const { impressionData } = useSelector((store) => store.app);

  //store audio player node element
  const audioPlayerRef = useRef();

  // track if the audio is playing or not
  const [isPlaying, setIsPlaying] = useState(false);

  //get dispatch function from redux
  const dispatch = useDispatch();

  //set timout to 15 mins whenever contentToUpdate changes
  useEffect(() => {
    //store the timout id
    let timeout;
    //updates should only happen in second partition
    if (partitionName === "partitionTwo" && contentToUpdate.length > 0) {
      //set is updateing to true
      setIsUpdating(true);

      //set timout to 15 secs
      timeout = setTimeout(() => {
        setIsUpdating(false);
      }, 15000);
    }

    //cleanup logic
    return () => {
      //clear timeout
      clearTimeout(timeout);
    };
  }, [contentToUpdate]);

  //update impression data for each item isUpdating is true changes
  useEffect(() => {
    //store interval id
    let visibilityCounter;
    if (isUpdating && partitionName === "partitionTwo") {
      //update or stop time counter every one second depending on the action
      visibilityCounter = setInterval(() => {
        //call update redux store every one second
        updateReduxStore({
          impressionData,
          contentToUpdate,
          dispatch,
          setImpressionData,
        });
      }, 1000);
    }
    return () => {
      //clear interval
      clearInterval(visibilityCounter);
    };
  }, [isUpdating, contentToUpdate, impressionData]);

  //record impression data for this earning call whenever it's available
  useEffect(() => {
    if (!isEmpty(earningCallOrPodcast) && partitionName === "partitionTwo") {
      //get new list of contents to update
      const contents = updateImpressionDataEarningCallOrPodcast({
        partitionName,
        contentToUpdate,
        earningCallOrPodcast,
      });
      //update the local state
      setContentsToUpdate([...contents]);
    }
  }, [earningCallOrPodcast]);

  //handlet topics after looping through transcript
  const addNewTopicsForImpressionTime = useCallback(
    (topicsWithDetails) => {
      if (!isEmpty(earningCallOrPodcast) && partitionName === "partitionTwo") {
        //update it's impression time
        const contents = updateImpressionDataEarningCallOrPodcast({
          partitionName,
          topicsWithDetails,
          contentToUpdate,
          earningCallOrPodcast: earningCallOrPodcast,
        });

        //update the local state
        setContentsToUpdate([...contents]);
      }
    },
    [earningCallOrPodcast]
  );

  return (
    <div className="CompanyMedia">
      {Object.keys(earningCallOrPodcast).length ? (
        <div className="bg-inherit w-full py-1 text-black">
          <div className="w-full bg-linen dark:bg-rhino rounded-md mb-1 text-customFontTwo pt-1 px-1 ">
            <h1 className="w-full my-1 underline underline-offset-2 text-center truncate font-semibold capitalize dark:text-white ">
              {earningCallOrPodcast.title}
            </h1>
            <AudioPlayer
              {...{
                isPlaying,
                setIsPlaying,
                audioUrl: earningCallOrPodcast.audioUrl,
              }}
              ref={audioPlayerRef}
            />
          </div>
          <div className="h-popupTranscriptHeight bg-linen dark:bg-rhino overflow-auto scrollbar-hide w-full  py-2 pl-1 pr-0.5 text-black my-1  rounded-md">
            <Transcript
              transcriptProps={{
                audioPlayerRef,
                setIsPlaying,
                audioText: earningCallOrPodcast.audioText,
                companyName: earningCallOrPodcast.company_name,
                documentType: earningCallOrPodcast.document_type,
                addNewTopicsForImpressionTime,
              }}
            />
          </div>
        </div>
      ) : (
        <div>
          <p>
            No content in demo for this company. Try &#39;Apple&#39; or
            &#39;Carnegie&#39;.
          </p>
        </div>
      )}
    </div>
  );
}
