import { MenuItem, Highlighter } from "react-bootstrap-typeahead";
import { BiInfoCircle } from "react-icons/bi";

export default function SearchBarMenuItems({ searchBarMenuItemsProps }) {
  //destructure prop values
  const { suggestionsByCategory, state, allowNew, openCompanyPopup } =
    searchBarMenuItemsProps;

  //create unique key
  var uniqKey = 0;

  //return menu item
  return (
    <>
      {Object.entries(suggestionsByCategory).map(
        ([categoryName, suggestions]) => (
          <div
            key={categoryName}
            className="SearchBar-result-segment my-2 w-full  flex flex-col"
          >
            <h1 className="my-px w-full font-bold capitalize">
              {categoryName}:
            </h1>
            {suggestions.slice(0, 3).map((suggestion) => (
              <div
                key={++uniqKey}
                className="w-full grid grid-cols-optionLabel grid-rows-1 grid-flow-col gap-x-1 items-center"
              >
                <MenuItem
                  className="w-full grid grid-cols-menuItem grid-rows-1 grid-flow-col gap-x-0.5 items-center my-2  hover:bg-gray-500/10 dark:hover:bg-gray-500"
                  key={uniqKey}
                  option={suggestion}
                  position={uniqKey}
                >
                  <div className="w-3 mr-0.5">
                    {suggestion.logo && (
                      <img
                        className="w-full"
                        src={suggestion.logo}
                        alt="Logo"
                      />
                    )}
                  </div>
                  <div className="truncate">
                    <Highlighter search={state.text}>
                      {suggestion.name}
                    </Highlighter>
                  </div>
                </MenuItem>
                {categoryName === "companies" && allowNew && (
                  <button onClick={() => openCompanyPopup(suggestion)}>
                    <BiInfoCircle size={12} className={" dark:text-white"} />
                  </button>
                )}
              </div>
            ))}
          </div>
        )
      )}
    </>
  );
}
