import React, { useContext, useEffect, useState } from "react";
import TexelioLogo from "assets/texeliologo.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { auth, signOut } from "common/firebase-app";
import { appContext } from "common/context";
import LanguageFilter from "./LanguageFilter";
import { BiUser } from "react-icons/bi";
const NavBar = () => {
  const [currentPage, setCurrentPage] = useState("");
  const { currentUser } = useContext(appContext);
  const navigate = useNavigate();
  let location = useLocation();
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };
  const updateLinks = () => {
    const currentPlace = location.pathname.split("/")[1];
    setCurrentPage(currentPlace);
  };
  useEffect(() => updateLinks(), [location]);
  return (
    <div className=" w-full  fixed top-0 left-0 bg-inherit z-50 ">
      <div className="max-w-screen-3xl mx-auto flex items-center justify-between px-6 py-3 text-white">
        <div className="w-14">
          <a href="https://www.texelio.com/" rel="noreferrer">
            <img className="w-full" src={TexelioLogo} alt="Texelio" />
          </a>
        </div>
        {currentUser && (
          <div className="flex items-center space-x-6 text-base capitalize">
            <div className="">
              <LanguageFilter />
            </div>
            <Link
              to={`${currentPage === "podcasts" ? "/content" : "/podcasts"}`}
              className="capitalize hover:text-onahau   rounded-full  py-1.5 px-2.5"
            >
              {currentPage === "podcasts" ? "CDD" : "Podcasts"}
            </Link>
            <button
              onClick={handleLogout}
              className="py-1.5 px-2 flex items-center space-x-2 bg-midnight-blue hover:text-onahau  border border-gainsboro/20 rounded-full "
            >
              <span className="w-8 h-8 p-0.5 rounded-full bg-gainsboro/20 inline-flex items-center justify-center">
                <BiUser className="text-lg" />
              </span>
              <span>Log Out</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavBar;
