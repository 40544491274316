import { useMemo, memo } from "react";
import { AiOutlineClose } from "react-icons/ai";
//holds event when creating it
export default memo(function NewEventEditArea({ newEventEditAreaProps }) {
  //destructure the props
  const { newEventSections, removeNewEventSection, addNewEvent } =
    newEventEditAreaProps;

  //loop through newEventSections and return respective node
  const editableEventParts = useMemo(() => {
    //map through the newEventSections
    return newEventSections.map((eventSection, index) => {
      //get the first part of the each event section
      const name = eventSection.name.split("(")[0];

      // return the react node with the ability to delete the section
      return (
        <div
          key={eventSection.id}
          className="w-full flex items-center justify-start my-1 "
        >
          <p className="w-full bg-black/10 truncate ">{name}</p>
          <button
            onClick={() => removeNewEventSection(eventSection.id)}
            className="bg-gray-300 p-0.5"
          >
            <AiOutlineClose className="text-sm text-black hover:scale-110" />
          </button>
        </div>
      );
    });
  }, [newEventSections]);

  //hide this edit area if there are no any new event sections
  if (!newEventSections.length) return null;

  return (
    <div className="w-full rounded-sm my-2 p-1 border text-customFontTwo text-black-russian dark:text-white border-black ">
      <h2 className="my-1 text-customFontOne font-semibold">
        Event combination
      </h2>
      {editableEventParts}
      <hr className="w-full my-0.5" />
      <button
        onClick={addNewEvent}
        className=" my-1 rounded-full  py-1 px-1.5 text-xs bg-onahau text-black-russian hover:ring-1 hover:ring-black whitespace-nowrap "
      >
        Add Event
      </button>
    </div>
  );
});
