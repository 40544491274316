import React, { useContext } from "react";
import { CgMenu } from "react-icons/cg";
import { FiTrendingUp } from "react-icons/fi";
import { clsx } from "clsx";
import { individualRetailInvestorContext } from "common/context";
const FeedMenu = () => {
  // get context data
  const { selectedFeedMenu, individualRetailInvestorDispatch } = useContext(
    individualRetailInvestorContext
  );

  // navigation buttons to show visualitics and feed data
  const Links = [
    { action: "visualitics", image: <FiTrendingUp size={12} /> },
    { action: "feeditems", image: <CgMenu size={12} /> },
  ];

  // handle button click
  const handleButtonClick = (action) => {
    individualRetailInvestorDispatch({
      type: "setSelectedFeedMenu",
      payload: action,
    });
  };
  return (
    <div className="FeedMenu bg-linen dark:bg-rhino grid grid-cols-2 grid-rows-1 py-1 px-1.5 rounded-full h-7">
      {Links.map(({ action, image }) => (
        <button
          key={action}
          onClick={() => handleButtonClick(action)}
          className={clsx(
            "rounded-full flex justify-center  text-allPorts  items-center p-0.5 outline-none ",
            selectedFeedMenu === action
              ? "bg-onahau dark:bg-turquoise dark:text-black"
              : "dark:text-white hover:bg-gray-300 hover:text-gray-500"
          )}
        >
          {image}
        </button>
      ))}
    </div>
  );
};

export default FeedMenu;
