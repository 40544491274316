import React, { useContext } from "react";
import { individualRetailInvestorContext } from "common/context";
import { clsx } from "clsx";
const VisualiticsTimeBtns = () => {
  // get context data
  const { visualiticsData, individualRetailInvestorDispatch } = useContext(
    individualRetailInvestorContext
  );

  // create periods
  const periods = [
    { name: "today", value: "24h" },
    { name: "last week", value: "7d" },
    { name: "last month", value: "30d" },
  ];

  // updat period and after time
  const updatePeriod = (selectedPeriod) => {
    let afterTime;
    switch (selectedPeriod) {
      case "today":
        afterTime = Date.now() / 1000 - 24 * 60 * 60;
        break;
      case "last week":
        afterTime = Date.now() / 1000 - 7 * 24 * 60 * 60;
        break;
      case "last month":
        afterTime = Date.now() / 1000 - 30 * 24 * 60 * 60;
        break;
      default:
        // default to past 24 hours
        afterTime = Date.now() / 1000 - 24 * 60 * 60;
        break;
    }
    individualRetailInvestorDispatch({
      type: "setVisualiticsData",
      payload: {
        ...visualiticsData,
        period: selectedPeriod,
        afterTime: `${afterTime}`,
      },
    });
  };
  return (
    <div className="w-3/5 mx-auto my-1.5 rounded-full p-1 bg-sazerac dark:bg-rhino  font-semibold text-black-russian text-customFontSix grid grid-cols-3 grid-rows-1 items-center ">
      {periods.map(({ name, value }) => (
        <button
          key={name}
          onClick={() => updatePeriod(name)}
          className={clsx(
            "w-full rounded-full py-1 px-1.5 text-center",
            visualiticsData.period === name
              ? " bg-onahau dark:bg-turquoise dark:text-black "
              : " dark:text-white"
          )}
        >
          {value}
        </button>
      ))}
    </div>
  );
};

export default VisualiticsTimeBtns;
