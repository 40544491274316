import React from "react";
import EnglandFlag from "assets/englandflag.png";
import SwedenFlag from "assets/swedishflag.jpg";
import Select from "react-select";
import { styleProxy } from "common/exportedFunctions";
import { useDispatch } from "react-redux";
import { setGlobalLanguage } from "features/app/appSlice";
import { FaChevronDown } from "react-icons/fa";
const languages = [
  {
    label: "Eng",
    value: "en",
    icon: EnglandFlag,
  },
  {
    label: "Swe",
    value: "sv",
    icon: SwedenFlag,
  },
];

const formatOptionLabel = ({ label, icon }) => {
  return (
    <div className="p-1 flex items-center space-x-1 text-white hover:cursor-pointer hover:bg-minsk">
      <span className="w-5 ">
        <img className="w-full" src={icon} alt={label} />
      </span>
      <span className="">{label}</span>
    </div>
  );
};

const CustomContainer = (props) => {
  return (
    <div ref={props.innerRef} {...props.innerProps} className="relative w-full ">
      {props.children}
    </div>
  );
};

const CustomControl = (props) => {
  return (
    <div
      ref={props.innerRef}
      {...props.innerProps}
      className="w-full flex items-center justify-center  rounded-full  hover:cursor-pointer hover:text-onahau "
    >
      {props.children[0]}
    </div>
  );
};

const CustomValueContainer = ({
  innerRef,
  innerProps,
  children,
  selectProps: {
    value: { icon, label },
  },
}) => {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="relative w-full px-1 flex items-center justify-center"
    >
      <div className="sr-only">{children}</div>
      <div
        className={[
          "w-full",
          "grid",
          " grid-rows-1",
          "grid-cols-selectValue",
          "gap-x-2",
          "grid-flow-col",
          "items-center",
        ].join(" ")}
      >
        <span className="w-6 h-6 overflow-hidden rounded-full flex items-center justify-center  ">
          <img className="w-full h-full" src={icon} alt={label} />
        </span>
        <span className="flex items-center justify-center  text-center">
          {label}
        </span>
        <div >
          <FaChevronDown  />
        </div>
      </div>
    </div>
  );
};
const CustomMenu = (props) => {
  return (
    <div
      ref={props.innerRef}
      {...props.innerProps}
      className={[
        "absolute top-16 w-24 mx-auto px-1 z-10 bg-midnight-blue-shade rounded-md",
      ].join(" ")}
    >
      {props.children}
    </div>
  );
};
const LanguageFilter = () => {
  const dispatch = useDispatch();
  const handleChange = (selected) => {
    const { value } = selected;
    dispatch(setGlobalLanguage(value));
  };
  return (
    <Select
      options={languages}
      styles={styleProxy}
      isSearchable={false}
      defaultValue={languages[0]}
      onChange={handleChange}
      placeholder={false}
      formatOptionLabel={formatOptionLabel}
      components={{
        Container: CustomContainer,
        Control: CustomControl,
        ValueContainer: CustomValueContainer,
        Menu: CustomMenu,
      }}
    />
  );
};

export default LanguageFilter;
