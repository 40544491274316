import React, { useMemo } from "react";
import Chart from "./Chart/Chart";
import ImpressionEngagementContainer from "components/PlatformOwners/ImpressionEngagementStats/ImpressionEngagementContainer";
import ImpressionEngagementHeader from "./ImpressionEngagementHeader";
import Stats from "./Stats/Stats";
import { useSelector } from "react-redux";

const ImpressionEngagementStats = () => {
  //store total percentage conversion
  // const [totalConversion, setTotalConversion] = useState(0);

  //get redux data
  const { impressionData, engagementData } = useSelector((store) => store.app);

  //compute the conversion percentages for each category
  //news conversion
  //DONT DIVIDE BY ZERO
  const newsConversion =
    (engagementData.newsType / (impressionData.newsType || 1)) * 100;

  //pr conversion
  const pressReleaseConversion =
    (engagementData.pressReleaseType / (impressionData.pressReleaseType || 1)) *
    100;

  //earnings calls conversion
  const earningsCallsConversion =
    (engagementData.earningsCallsType /
      (impressionData.earningsCallsType || 1)) *
    100;

  //podcast conversion
  const podcastsConversion =
    (engagementData.podcastsType / (impressionData.podcastsType || 1)) * 100;

  // name the stats to display
  const stats = useMemo(
    () => [
      {
        name: "1. User Impressions (Time)",
        chartContent: {
          mediaTypes: [
            { name: "news", value: impressionData.newsType },
            { name: "press release", value: impressionData.pressReleaseType },
            { name: "earnings calls", value: impressionData.earningsCallsType },
            { name: "podcasts", value: impressionData.podcastsType },
          ],
          stockMentions: impressionData["stock mentions"],
          unit: "time",
        },
        statsContent: {
          esgTopics: impressionData["esg topics"],
          investingTopics: impressionData["investing topics"],
          stockMentions: impressionData["stock mentions"],
          unit: "time",
        },
      },
      {
        name: "2. User Engagements (Clicks)",
        chartContent: {
          mediaTypes: [
            { name: "news", value: engagementData.newsType },
            { name: "press release", value: engagementData.pressReleaseType },
            { name: "earnings calls", value: engagementData.earningsCallsType },
            { name: "podcasts", value: engagementData.podcastsType },
          ],
          stockMentions: engagementData["stock mentions"],
          unit: "clicks",
        },
        statsContent: {
          esgTopics: engagementData["esg topics"],
          investingTopics: engagementData["investing topics"],
          stockMentions: engagementData["stock mentions"],
          unit: "clicks",
        },
      },
      {
        name: "3. User Conversion (%)",
        chartContent: {
          mediaTypes: [
            {
              name: "news",
              value: newsConversion,
            },
            {
              name: "press release",
              value: pressReleaseConversion,
            },
            {
              name: "earnings calls",
              value: earningsCallsConversion,
            },
            {
              name: "podcasts",
              value: podcastsConversion,
            },
          ],
          stockMentions:
            newsConversion +
            pressReleaseConversion +
            earningsCallsConversion +
            podcastsConversion,
          unit: "conversion",
        },
        statsContent: {
          esgTopics: {
            engagement: engagementData["esg topics"],
            impression: impressionData["esg topics"],
          },
          investingTopics: {
            engagement: engagementData["investing topics"],
            impression: impressionData["investing topics"],
          },
          stockMentions: {
            engagement: engagementData["stock mentions"],
            impression: impressionData["stock mentions"],
          },
          unit: "conversion",
        },
      },
    ],
    [impressionData, engagementData]
  );


  //THESE COMPONENTS HANDLE BOTH IMPRESSION , ENGAGEMENT DATA AND CONVERSION DATA. WILL BE RENDERED DEPENDING ON DATA THEY RECEIVE
  // SO ARE THE FUNCTIONS WITHIN THE COMPONENTS

  return (
    <div className="w-full grid grid-cols-1 2xl:grid-cols-2 2xl:grid-rows-1 3xl:grid-cols-3 gap-5 justify-center p-5">
      {stats.map(({ name, chartContent, statsContent }) => (
        <ImpressionEngagementContainer key={name}>
          <ImpressionEngagementHeader name={name} />
          <Chart {...chartContent} />
          <Stats {...statsContent} />
        </ImpressionEngagementContainer>
      ))}
    </div>
  );
};

export default ImpressionEngagementStats;
