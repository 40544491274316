import React, { memo } from "react";
import { contentOffered } from "common/externalData";
import Modal from "react-modal";
import { useDisableBodyScroll } from "common/hooks";
import { uniqueId } from "lodash";
import { clsx } from "clsx";
import { FiChevronDown } from "react-icons/fi";
Modal.setAppElement("#root");
const ContentOffered = ({ contentOfferedProps }) => {
  const { showContentOffered, toggleContentOfferedHandler } =
    contentOfferedProps;
  // prevent scroll if show summary is true
  useDisableBodyScroll(showContentOffered);
  const contentOfferedComponent = (modelProps) => {
    console.log(modelProps);
    return (
      <div
        {...modelProps}
        className="w-full lg:w-10/12 mx-auto bg-white"
        style={{ ...modelProps.style, borderRadius: "18px" }}
      >
        <div className="w-full p-7 grid grid-cols-4 grid-rows-1 gap-x-5 text-sm">
          {Object.keys(contentOffered).map((category) => {
            const categoryData = contentOffered[category];
            return (
              <div key={category} className="w-full">
                <div
                  className="py-0.5 pl-2.5 mb-9"
                  style={{ borderLeft: `4px solid ${categoryData.groupColor}` }}
                >
                  <h1 className="w-full whitespace-nowrap capitalize text-base font-bold">
                    {category}
                  </h1>
                </div>
                <div className="w-full ">
                  {Object.keys(categoryData).map((subTitle) => {
                    if (subTitle === "groupColor" || subTitle === "childColor")
                      return null;
                    return (
                      <div
                        key={subTitle}
                        className={clsx(
                          "mb-5 p-5 rounded-[14px] shadow-lg border border-whisper-shade",
                          categoryData.childColor
                        )}
                      >
                        <div
                          className={clsx(
                            "w-full flex items-center justify-between pb-4 border-b ",
                            category === "API Parameters"
                              ? "border-b-[#CAB9B9]"
                              : "border-b-whisper-shade"
                          )}
                        >
                          <h2 className="w-full  font-bold my-1">{subTitle}</h2>
                          <div>
                            <FiChevronDown size={20} />
                          </div>
                        </div>
                        <ul className="list-disc list-inside ">
                          {categoryData[subTitle].map((subData) => {
                            const dataKey = uniqueId("subdata");
                            const { name, list } = subData;
                            return (
                              <li key={dataKey} className="my-4">
                                {name}
                                {list?.length > 0 && (
                                  <ul className="list-disc list-inside pl-4">
                                    {list.map((listItem) => {
                                      const listItemKey = uniqueId("listItem");
                                      return (
                                        <li key={listItemKey} className="my-4">
                                          {listItem}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={showContentOffered}
      onRequestClose={toggleContentOfferedHandler}
      contentElement={contentOfferedComponent}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "50",
        },
      }}
    />
  );
};

export default memo(ContentOffered);
