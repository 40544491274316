import axios from "axios";
import * as lodash from "lodash";
import {
  db,
  doc,
  getDownloadURL,
  ref,
  setDoc,
  storage,
  updateDoc,
  uploadBytesResumable,
} from "./firebase-app";
import { modifyCategoryName } from "./exportedFunctions";

const api = {
  getAutoCompleteSuggestions,
  searchCompanyInfo,
  topicStatus,
  revealHiddenSection,
  getHiddenSection,
  toggleSummary,
  translatedTopicsFunc,
  searchFeedItems,
  fetchLatestOrTrendingVisualitics,
  sendEmailToAdmin,
};
export default api;

/**
 * Get the access token needed to make an API request
 * @return {string}
 */
function getToken() {
  return process.env.REACT_APP_ACCESS_TOKEN;
}

/**
 * Get the base url of the API (all you have to do is add the endpoint to it)
 * @return {string}
 */
function getApiUrl() {
  return `${process.env.REACT_APP_BASE_URL}/frontend-api/`;
}

/**

 * common method to call all majority api endpoints
 * @param {*} param0
 * @returns {Promise}
 */
export const callEndpoint = async ({
  endpoint = null,
  podcastEndpoint = null,
  method = null,
  params = {},
  headers = null,
  data = null,
  abortControllerRef = {},
}) => {
  try {
    abortControllerRef.current = new AbortController();
    const response = await axios({
      data: data ?? { ...data },
      method: method ?? "get", // defaults to 'get' if no method provided
      url: podcastEndpoint ?? getApiUrl() + endpoint,
      params: { ...params, accessToken: getToken() },
      headers: headers ?? {
        "Content-Type": "application/json",
      }, // defaults to 'application/json'
      signal: abortControllerRef.current.signal,
    });
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error };
  }
};

/**
 * Send an email to anyone in the world from our address
 *
 * @BUGBUG palun 2023-03-13:
 * DANGEROUS DESIGN.You CANNOT put a token which allows sending of emails on our behalf
 * on a frontend! Anyone on the internet can pose as us and use this to send emails.
 *
 * @param {object} to           The recipient of the email
 * @param {string} to.name
 * @param {string} to.email
 * @param {string} subject      The email subject
 * @param {string} textContent  The text in the email
 * @param {number} templateId   Which email template the forwarder should use (stored there)
 * @param {object} params       Contains props like EMAIL, CODE, USERNAME etc..
 *
 *
 * @return {void}
 */
function sendEmail(to, subject, textContent, templateId, params) {
  axios({
    method: "POST",
    url: process.env.SENDINBLUE_URL,
    headers: {
      Accept: "application/json",
      "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
      "Content-Type": "application/json",
    },
    data: {
      sender: {
        name: "Hello Texelio",
        email: "hello@texelio.com ",
      },
      to: [to],
      subject,
      textContent,
      templateId,
      params,
    },
  });
}

/**
 * @param {object} requestBody
 * @param {object|array} selectedData
 *
 * @return {object}
 */
function prepareRequestBody(requestBody, selectedData) {
  const requestBodyCopy = { ...requestBody };
  //palun: allow for non-array to be passed in since that may not always be the case, ie.
  //       when populating company info
  if (!Array.isArray(selectedData)) selectedData = [selectedData];

  selectedData.forEach((dataObj) => {
    let category = "";
    dataObj?.customOption
      ? (category = "customOption")
      : (category = dataObj.category);
    switch (category) {
      case "companies":
        requestBodyCopy.companyIds = [
          ...requestBodyCopy.companyIds,
          dataObj.id,
        ];
        break;
      case "sectors":
        requestBodyCopy.sectorCodes = [
          ...requestBodyCopy.sectorCodes,
          dataObj.id,
        ];
        break;
      case "topics":
        requestBodyCopy.topicIds = [...requestBodyCopy.topicIds, dataObj.id];
        break;
      case "indices":
        requestBodyCopy.indexIds = [...requestBodyCopy.indexIds, dataObj.id];
        break;
      case "sources":
        requestBodyCopy.sources = [...requestBodyCopy.sources, dataObj.id];
        break;
      case "customOption":
        requestBodyCopy.freeTextQuery = dataObj.name;
        requestBodyCopy.matchingTypes = ["NoneTopic"];
        // key to automatically render freeText card;
        requestBodyCopy.isFreeText = true;
        break;
      default:
        break;
    }
  });

  return requestBodyCopy;
}

/**

 * get suggestions as you type in the searchbar
 * @param {string} query - word to search
 * @param {*} abortControllerRef - used to cancel request
 * @returns {*} returns suggestions

 */
export async function getAutoCompleteSuggestions(query, abortControllerRef) {
  const { data = null } = await callEndpoint({
    endpoint: "suggestions",
    params: {
      query: `${query}`,
      limit: 20,
    },
    abortControllerRef,
  });
  return data;
}

export const getSearchCompletionsArray = async (
  searchWord,
  abortControllerRef,
  originSearchBar
) => {
  //get autocomplete suggestion
  const suggestionsObject = await getAutoCompleteSuggestions(
    searchWord,
    abortControllerRef
  );

  // create an array where the items are the categories
  const categories = Object.keys(suggestionsObject);

  //filter out some categories based on current view
  const filteredCategories = () => {
    //return only keywords suggestions if the current view is not 'latest'
    if (
      originSearchBar?.currentView &&
      originSearchBar?.currentView !== "latest"
    ) {
      return categories.filter(
        (category) => category === "keyword_suggestions"
      );
    }
    //if listName was provided instead, return only company suggestions for investments & watchlist
    if (originSearchBar === "investments" || originSearchBar === "watchlist") {
      return categories.filter(
        (category) => category === "company_suggestions"
      );
    }

    // if the current view is 'latest' or if listName is 'events'
    // return all categoiries suggestions
    return categories;
  };

  // array that holds a list of suggestions which are modified in such a way that
  // each suggestion includes a category name ie keyword, company, topic etc
  const suggestionsWithCategories = [];

  //loop through the suggestions and modify each suggestion to an object that includes
  //category name and push it to the suggestionsWithCategories
  filteredCategories().forEach((category) => {
    // modify category name
    const categoryName = modifyCategoryName(category);

    //get the contents of each category, loop through and for each item,
    //transform it to a new object
    suggestionsObject[category].forEach((suggestion) => {
      // new suggestion object placeholder
      const newSuggestion = {};

      //destructure the values of suggestion
      const {
        label,
        id,
        topic_type = undefined,
        logo_url = undefined,
      } = suggestion;

      // prevent addition of 'quote' topic in the suggestions
      if (categoryName === "topics" && label === "Quote") return;

      //for all other cases, add the properties to newSuggestion object
      newSuggestion.category = categoryName;
      newSuggestion.name = label;
      newSuggestion.id = id;
      newSuggestion.type = topic_type ?? "";
      newSuggestion.logo = logo_url ?? "";

      // push the newly created suggestion to the suggestionsList
      suggestionsWithCategories.push(newSuggestion);
    });
  });

  // return the suggestions
  return suggestionsWithCategories;
};

/**

 * search for company info
 * @param {*} modifiedRequestBodyData
 * @param {*} companyInfoData
 * @param {*} abortControllerRef
 * @param {*} apiCompanyInfo
 * @param {*} setApiCompanyInfo
 * @returns nothing

 */
export async function searchCompanyInfo(
  modifiedRequestBodyData,
  companyInfoData,
  abortControllerRef,
  apiCompanyInfo,
  setApiCompanyInfo
) {
  const requestBody = prepareRequestBody(
    modifiedRequestBodyData,
    companyInfoData
  );
  // update loading
  setApiCompanyInfo({
    ...apiCompanyInfo,
    loading: true,
    message: "",
    error: "",
  });
  // if success is true, data will be available, otherwise errors
  const {
    success,
    data = null,
    error = null,
  } = await callEndpoint({
    endpoint: "relevance-card",
    method: "post",
    data: requestBody,
    abortControllerRef,
  });
  //update state accordingly
  setApiCompanyInfo({
    ...apiCompanyInfo,
    searchResults: success ? data.articles : [],
    loading: error?.message === "canceled" ? true : false, // keep loading if request was cancelled due to re-rendering
    message: success
      ? `${data.articles.length ? "" : "No Results Found!"}`
      : "",
    error: success ? "" : error.message !== "canceled" && error.message,
  });
}

/**
 * @typedef {Array<TopicCard>} TopicArray
 */
/**
 * @typedef {Object} TopicCard
 * @property {string} topic_id
 * @property {string} topic_name
 * @property {string} topic_type
 * @property {boolean} enabled
 */

/**

 * Get a list of all available topics
 * 
 * @endpoint /topic-status   {@link https://texelio.stoplight.io/docs/texelio-api/254f5914c9b34-get-topic-status docs}
 * 
 * @return {Promise<TopicArray,string>}

 */
export async function topicStatus() {
  const { success, data = null } = await callEndpoint({
    endpoint: "topic-status",
  });
  return success ? data.topics : [];
}

/**
 * Fetch the hidden part of a section from the server and set it directly on a
 * child element of a <Section>
 *
 * @param {string} hiddenSectionToken   A long alphanumeric token specific to a section
 * @param {string} childId              Id of child in $ref
 * @param {React.RefObject} ref
 *
 * @return {void}
 * */
export async function revealHiddenSection(hiddenSectionToken, childId, ref) {
  try {
    const hiddenSections = await getHiddenSection(hiddenSectionToken);
    ref.current[childId].innerHTML = sectionsToHtml(hiddenSections);
  } catch (e) {
    console.error("Failed to reveal hidden section:", e);
  }
}

function sectionsToHtml(sections) {
  return sections
    .map(({ flags, text }) => {
      if (flags) text = `<span class='bg-cream dark:text-black'>${text}</span>`;
      return text;
    })
    .join("");
}
/**

 * - get hidden section
 * @param {*} hiddenSectionToken
 * @returns {Promise}
 */
export async function getHiddenSection(hiddenSectionToken) {
  const { data = null } = await callEndpoint({
    method: "post",
    endpoint: "relevance/reveal-section",

    headers: {
      "Content-Type": "text/plain",
    },
    data: hiddenSectionToken,
  });
  return data?.end;
}

/**
 * API call to get summary data
 * @param {*} param0
 */

export async function toggleSummary({
  sectionSummaryOrder,
  hiddenArticleKey,
  individualRetailInvestorDispatch,
  companyName,
  releaseDate,
  sourceName,
}) {
  //call api and get data
  const { data = null } = await callEndpoint({
    endpoint: "summary/document_section",
    params: {
      hiddenKey: hiddenArticleKey,
      sectionOrder: sectionSummaryOrder,
    },
  });

  //update summary
  individualRetailInvestorDispatch({
    type: "setSummary",
    payload: {
      summaryTitle: companyName,
      summaryBody: data,
      releaseDate,
      sourceName,
      showSummary: true,
    },
  });
}

/**
 *- get translated topics
 * @param {*} languageCode
 * @returns {Promise}
 */
export async function translatedTopicsFunc(languageCode) {
  const { success, data = null } = await callEndpoint({
    endpoint: "topic-language",
    params: {
      languageCode,
    },
  });
  return success ? { data } : { data: { topics: [] } };
}

/**
 * get feed items for feed-mode and cube-mode
 * @param {*} modifiedRequestBodyData
 * @param {*} selectedData
 * @param {*} abortControllerRef
 * @param {object} stateData - data from the component
 * @param {function} stateDataUpdateFunction - update function for state data
 * @param {boolean} isFromCubeMode - specifies the component calling this api
 *
 */
export async function searchFeedItems({
  modifiedRequestBodyData,
  selectedData,
  abortControllerRef,
  stateData,
  stateDataUpdateFunction,
  isFromCubeMode = false,
}) {
  // update loading considering isFromCubeMode arg
  const loadingStateUpdatedData = {
    ...stateData,
    searchResults: [],
    loading: true,
    message: "",
    error: "",
  };
  if (isFromCubeMode) {
    // stateDataUpdateFunction === setFeedState
    stateDataUpdateFunction({ ...loadingStateUpdatedData });
  } else {
    //stateDataUpdateFunction === individualRetailInvestorDispatch
    stateDataUpdateFunction({
      type: "setFeedData",
      payload: { ...loadingStateUpdatedData },
    });
  }

  //prepare request body to send along API call
  const requestBody = prepareRequestBody(modifiedRequestBodyData, selectedData);

  //checks if request body has isFreeText property
  // which indicates we will render FreeText component
  //if that property is true
  const isFreeTextFunc = () => {
    const isFreeTextData = requestBody?.isFreeText;
    delete requestBody.isFreeText; // cuz isFreeText property is not part of requestBody
    return isFreeTextData;
  };

  // if success is true, data will be available, otherwise errors
  const {
    success,
    data = null,
    error = null,
  } = await callEndpoint({
    endpoint: "relevance-card",
    method: "post",
    data: requestBody,
    abortControllerRef,
  });

  //final update of data
  const finalUpdatedData = {
    ...stateData,
    searchResults: success ? data.articles : [],
    loading: error?.message === "canceled" ? true : false, // keep loading if request was cancelled due to re-rendering
    postOrigin: isFreeTextFunc() ? "freeText" : "posts",
    message: success
      ? `${data.articles.length ? "" : "No Results Found!"}`
      : "",
    error: success ? "" : error.message !== "canceled" && error.message,
  };

  if (isFromCubeMode) {
    // stateDataUpdateFunction === setFeedState
    stateDataUpdateFunction({ ...finalUpdatedData });
  } else {
    //stateDataUpdateFunction === individualRetailInvestorDispatch
    stateDataUpdateFunction({
      type: "setFeedData",
      payload: { ...finalUpdatedData },
    });
  }
}

/**
 * Fetch statistics regarding the news itself, ie. how much xyz was there in the last ### days, or
 * how has this changed (ie. what is trending)
 * 
 * This function handles requests for both feed-mode and cube-mode

 * @param {object} modifiedRequestBodyData
 * @param {object|array} selectedData
 * @param {object} abortControllerRef
 * @param {object} stateData - data from the component
 * @param {function} stateDataUpdateFunction - update function for state data
 * @param {boolean} isFromCubeMode - specifies the component calling this api
 *
 * @return {void}
 */
export async function fetchLatestOrTrendingVisualitics({
  sectionName,
  modifiedRequestBodyData,
  selectedData,
  abortControllerRef,
  stateData,
  stateDataUpdateFunction,
  isFromCubeMode = false,
}) {
  // remove items that have source as category
  const selectedDataCopy = lodash.filter(
    selectedData,
    (obj) => obj.category !== "sources"
  );

  //prepare request body to send along with API call
  const requestBody = prepareRequestBody(
    modifiedRequestBodyData,
    selectedDataCopy
  );

  //Determine which endpoint to use
  const endpoint = "mentions" + (sectionName === "latest" ? "" : "/trending");

  // update loading considering isFromCubeMode arg
  const loadingStateUpdatedData = {
    ...stateData,
    searchResults: [],
    loading: true,
    message: "",
    error: "",
  };
  if (isFromCubeMode) {
    // stateDataUpdateFunction === setVisualiticsState
    stateDataUpdateFunction({ ...loadingStateUpdatedData });
  } else {
    //stateDataUpdateFunction === individualRetailInvestorDispatch
    stateDataUpdateFunction({
      type: "setVisualiticsData",
      payload: { ...loadingStateUpdatedData },
    });
  }

  // if success is true, data will be available, otherwise errors
  const {
    success,
    data = null,
    error = null,
  } = await callEndpoint({
    endpoint,
    method: "post",
    data: requestBody,
    abortControllerRef,
  });

  //Depending on the endpoint the data will have been returned in different prop names
  const dataValues =
    sectionName === "latest"
      ? data?.entity_mentions
      : data?.entity_trend_mentions;

  //final update of data
  const finalUpdatedData = {
    ...stateData,
    searchResults: success ? dataValues : [],
    loading: error?.message === "canceled" ? true : false, // keep loading if request was cancelled due to re-rendering
    message: success ? `${dataValues.length ? "" : "No Results Found!"}` : "",
    error: success ? "" : error.message !== "canceled" && error.message,
  };

  if (isFromCubeMode) {
    // stateDataUpdateFunction === setVisualiticsState
    stateDataUpdateFunction({ ...finalUpdatedData });
  } else {
    //stateDataUpdateFunction === individualRetailInvestorDispatch
    stateDataUpdateFunction({
      type: "setVisualiticsData",
      payload: { ...finalUpdatedData },
    });
  }
}

/**
 * Send an email to Texelio asking for access
 *
 * @BUGBUG palun 2023-03-13:
 * DANGEROUS DESIGN. You CANNOT put a token which allows sending of emails on our behalf
 * on a frontend! Anyone on the internet can pose as us and use this to send emails.
 *
 * @param {object} params
 * @param {string} params.fullname
 * @param {string} params.email
 * @param {string} params.title
 * @param {string} params.code
 * @param {string} params.id
 *
 * @return {void}
 */
export function sendEmailToAdmin({
  fullname: USERNAME,
  email: EMAIL,
  title: TITLE,
  code: CODE,
  id: ID,
}) {
  sendEmail(
    { name: "ceo@texelio.comelio", email: "ceo@texelio.com" },
    "New Access Code Request",
    "Please approve the following request",
    3,
    { USERNAME, EMAIL, TITLE, CODE, ID }
  );
}

/**
 * Send an email to the user telling him he's been granted access
 *
 * @param {object} params
 * @param {string} params.fullname
 * @param {string} params.email
 * @param {string} params.code
 *
 * @return {void}
 */
export function sendEmailToRecipient({
  fullname: name,
  email: EMAIL,
  code: CODE,
}) {
  sendEmail(
    { name, email: EMAIL },
    "Texelio Access Code",
    "Access to Texelio granted!",
    5,
    { EMAIL, CODE }
  );
}

//get a list of all earnings calls from the backend
export const getEarningsCallsFromApi = async ({ abortControllerRef }) => {
  // store abort controller into current property
  abortControllerRef.current = new AbortController();

  // call api and get results
  const results = await axios({
    method: "post",
    url: process.env.REACT_APP_EARNINGS_CALLS_LIST_URL,
    headers: {
      "Content-Type": "application/json",
    },
    data: {},
    signal: abortControllerRef.current.signal,
  });

  //return data from the results
  return results.data;
};

//get the contents of earning call from the backend, such as audio url etc,
//through an id
export const getIndividualEarningCall = async ({
  data,
  setData,
  abortControllerRef,
  earningCallId,
}) => {
  try {
    //set loading to true
    setData({ ...data, loading: true, error: "", message: "" });

    //store abort controller
    abortControllerRef.current = new AbortController();

    //call api and get the results
    const results = await axios({
      method: "post",
      url: process.env.REACT_APP_SINGLE_EARNINGS_CALL_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: { id: earningCallId },
      signal: abortControllerRef.current.signal,
    });

    //update the state from the results data
    setData({
      ...data,
      earningCall: { ...results.data },
      loading: false,
      error: "",
      message: `${Object.keys(results.data).length ? "" : "No Results Found!"}`,
    });
  } catch (error) {
    //get the error message
    const { message } = error;

    //update the state only when error message is not due to cancellation.
    //otherwise, old state is persisted
    if (message !== "canceled") {
      setData({
        ...data,
        earningCall: [],
        loading: false,
        message: "",
        error: message,
      });
    }
  }
};

/**
 * upload audiourl from firebase to gcp
 * @param {string} audioUrl
 * @returns {Promise<object>}   bucket_paths
 */
const getBucketPaths = async (audioUrl) => {
  const { data = null } = await callEndpoint({
    podcastEndpoint: process.env.REACT_APP_UPLOAD_AUDIO_GCP_URL,
    method: "post",
    data: {
      input_audio_path: audioUrl,
    },
  });
  return data;
};

/**
 * start processing transcript
 * @param {object} Object containing audio_bucket_path from gcp and abortControllerRef to cancel request
 * @returns {Promise<undefined>}  processing
 */
const startProcessingTranscript = async ({
  bucket_paths,
  abortControllerRef,
}) => {
  await callEndpoint({
    podcastEndpoint: process.env.REACT_APP_PROCESS_TRANSCRIPT_URL,
    method: "post",
    data: { ...bucket_paths, title: "title", id: "2", language: "sv" },
    abortControllerRef,
  });
  return;
};

/**
 * get transcript
 * @param {object} Object containing audio_bucket_path from gcp and abortControllerRef to cancel request
 * @returns {Promise<array>}  transcript
 */
const getPodcastTranscriptionText = async ({
  bucket_paths,
  abortControllerRef,
}) => {
  const { data = null } = await callEndpoint({
    podcastEndpoint: process.env.REACT_APP_GET_TRANSCRIPT_URL,
    method: "post",
    data: { ...bucket_paths, title: "title", id: "2", language: "sv" },
    abortControllerRef,
  });
  console.log("getting transcript...", data);
  if (data) {
    // If transcript is found, return the data
    return data;
  } else {
    // If transcript is not found, recursively call the function after 2 minutes
    await new Promise((resolve) => setTimeout(resolve, 2 * 60 * 1000));
    return await getPodcastTranscriptionText({
      bucket_paths,
      abortControllerRef,
    });
  }
};

// generates podcast transcript
export const generatePodcastTranscript = async ({
  podcastDispatch,
  podcasts,
  name,
  audio_url,
  abortControllerRef,
}) => {
  // update isUploading to false  and audio_url to audioUrl ot local podcasts
  // set isgeneratingTranscript to true
  podcastDispatch({
    type: "setPodcasts",
    payload: {
      ...podcasts,
      [name]: {
        name,
        audio_url,
        isPodcastUploading: false,
        isGeneratingTranscript: true,
      },
    },
  });
  // upload url to gcp to get bucket_paths object
  const bucket_paths = await getBucketPaths(audio_url);
  // start processing transcript
  await startProcessingTranscript({
    bucket_paths,
    abortControllerRef,
  });
  // generate transcript
  const data = await getPodcastTranscriptionText({
    bucket_paths,
    abortControllerRef,
  });
  // get transcript. Initailize to empty array in case a request is cancelled in intermediate state
  const transcript = data?.transcript ?? [];
  //date of transcription
  const date = new Date().toLocaleDateString(); // '3/16/2023'
  //update podcast with it's transcript
  podcastDispatch({
    type: "setPodcasts",
    payload: {
      ...podcasts,
      [name]: {
        name,
        audio_url,
        transcript,
        isPodcastUploading: false,
        isGeneratingTranscript: false,
        transcriptionDate: date,
      },
    },
  });
  // update firestore copy
  await updateDoc(doc(db, "podcasts", name), {
    transcript,
    transcriptionDate: date,
  });
};

/**
 * uploads podcast to firebase  & calls generatePodcastTranscript
 * @param {object} object containing various parameters
 * @returns {Promise<undefined>} doesn't return anything
 */
export const uploadPodcastFirebase = async ({
  file,
  progressBarsRef,
  podcastDispatch,
  podcasts,
  abortControllerRef,
}) => {
  // create reference to full path of podcast
  const podcastRef = ref(storage, file.path);
  // this uploads to firebase storage
  const uploadTask = uploadBytesResumable(podcastRef, file);
  // Register three observers:
  // 1. 'state_changed' observer, called any time the state changes
  // 2. Error observer, called on failure
  // 3. Completion observer, called on successful completion
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      // update progress bar
      progressBarsRef.current[file.name].value = progress;
      // update percentage
      progressBarsRef.current[file.name].nextSibling.innerHTML = `${progress}%`;
    },
    (error) => {
      // Handle unsuccessful uploads
    },
    async () => {
      // Handle successful uploads on complete
      const {
        metadata: { name },
        ref,
      } = uploadTask.snapshot;
      // get download url
      const audio_url = await getDownloadURL(ref);

      // store podcast url to firestore
      await setDoc(doc(db, "podcasts", name), { name, audio_url });
      // start generating transcript
      await generatePodcastTranscript({
        podcastDispatch,
        podcasts,
        name,
        audio_url,
        abortControllerRef,
      });
    }
  );
  return;
};
