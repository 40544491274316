import React, { memo, useContext, useEffect, useMemo } from "react";
import { groupBy, uniqueId, uniqBy } from "lodash";
import { individualRetailInvestorContext } from "common/context";
import Section from "./Section";
import CompanyHeader from "./CompanyHeader";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { setAllMountedArticles } from "features/app/appSlice";
import { clsx } from "clsx";

const Post = ({ articleContent }) => {
  //destructure props values
  const {
    image_url: imageUrl,
    source_name: sourceName,
    primary_concept: { name: companyName, id: companyId },
    companies: companiesArray,
    created_ts: releaseDate,
    hidden_key: hiddenArticleKey,
    topics,
    headline,
    sections,
    document_id: documentId,
    document_type: documentType,
    hidden_source_url: hiddenSourceUrl,
  } = articleContent;

  //get data from redux store
  const { allMountedArticles } = useSelector((store) => store.app);

  // get context data
  const { skin, trackVisibilityAreaRef, partitionName } = useContext(
    individualRetailInvestorContext
  );

  //get dispatch function from redux
  const dispatch = useDispatch();

  //track this article whenever it enters and leaves the track visibility area
  const { ref, inView } = useInView({
    root: trackVisibilityAreaRef.current, //our root
    threshold: 0.2, // is considered in view if 20% of it's body is in the visible area
  });

  //remove topics with no name and those named quote
  const filteredTopics = topics.filter(
    (topic) => topic.name !== "NONE" && topic.name !== "Quote"
  );

  //group topics by id
  const groupedTopics = useMemo(() => groupBy(filteredTopics, "id"), [topics]);

  // make all sections unique
  const uniqueSections = useMemo(() => {
    //map through the sections to includea single topic_id instead of array
    const sectionsWithSingleTopic = sections.map((section) => ({
      ...section,
      topic_id: section.topic_ids[0],
    }));

    //make the sections unique by their topic_id
    return uniqBy(sectionsWithSingleTopic, "topic_id");
  }, [sections]);

  //section dependencies
  const sectionDependencies = {
    groupedTopics,
    partitionName,
    hiddenArticleKey,
    companyName,
    companiesArray,
    releaseDate,
    sourceName,
    companyId,
    documentType,
  };

  //create out sections
  const sectionsArray = useMemo(
    () =>
      uniqueSections.slice(0, 3).map((section) => {
        const uniqId = uniqueId("section");
        return (
          <Section key={uniqId} section={section} {...sectionDependencies} />
        );
      }),
    [uniqueSections]
  );

  //Create an object to contain the stockName,topics,documentType and inView
  const articleObject = {
    stockName: companyName,
    topics: filteredTopics,
    documentType,
    inView,
  };

  //push this article to a global object that contains all articles with their
  //update inView properties

  //make a coppy of the global container for articles
  const globalArticlesContainerCopy = { ...allMountedArticles };

  //update counter when inView is true and stop update when inView is false
  //stop counter when there's no change inView after 15 seconds
  useEffect(() => {
    //track articles only within second mobile view
    if (partitionName === "partitionTwo") {
      //add the article or update if it exists/
      //documentId is the key for each article
      globalArticlesContainerCopy[documentId] = articleObject;

      //update the global article container
      dispatch(setAllMountedArticles(globalArticlesContainerCopy));
    }
  }, [inView]);

  // //clean up by setting inView to false when this article unmounts
  useEffect(() => {
    return () => {
      //track articles only within second mobile view
      if (partitionName === "partitionTwo") {
        //change the inview to false
        const newArticleObject = { ...articleObject, inView: false };

        //add it to created object
        globalArticlesContainerCopy[documentId] = newArticleObject;

        //update the global article container
        dispatch(setAllMountedArticles(globalArticlesContainerCopy));
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      className={clsx(
        "w-full pb-1  mb-2.5  dark:bg-rhino dark:text-white",
        skin === "white" ? "bg-white-smoke-shade" : "bg-linen"
      )}
    >
      <CompanyHeader
        companyInfo={{
          imageUrl,
          sourceName,
          companyName,
          companyId,
          companiesArray,
          releaseDate,
          hiddenSourceUrl,
          headline,
        }}
      />
      {sectionsArray}
    </div>
  );
};

export default memo(Post);
