import FirstPersonaImage from "assets/firstpersonaimage.svg";
import SecondPersonaImage from "assets/secondpersonaimage.svg";
import ThirdPersonaImage from "assets/thirdpersonaimage.svg";
import FourthPersonaImage from "assets/fourthpersonaimage.svg";
import FifthPersonaImage from "assets/fifthpersonaimage.svg";
import SixthPersonaImage from "assets/sixthpersonaimage.svg";
export const contentOffered = {
  "content service": {
    groupColor: "#008001",
    childColor: "bg-twilight",
    "Text News Sources": [
      {
        name: " 500+ public sources  for reading",
        list: ["FinWire", "Alliance News"],
      },
      { name: "10k+ sources for analytics" },
    ],

    "Text Press Release": [
      { name: "Cision" },
      { name: "GlobeNewswire" },
      { name: "Oslo Stock Exchange" },
      { name: " beQuoted" },
      { name: " EQS MFN " },
      { name: "  Nordic IR" },
    ],
    "Audio Earnings Calls": [
      { name: " Listen to Earnings Calls + Q&A" },
      { name: "  Follow key topics in transcripts" },
      { name: " Search in transcripcs" },
      { name: " 6,000+ stocks globally" },
      { name: " Calendar Reminder" },
    ],

    " Content Language ": [
      { name: "90% English Language" },
      { name: "10% Swedish Language" },
    ],

    " Other* ": [
      { name: "Twitter Feed Integration" },
      { name: "SEC-filing API" },
      { name: " Finansinpektionen API" },
      { name: " ESMA-API" },
    ],
  },
  "Instrument Service": {
    groupColor: "#FE5833",
    childColor: "bg-slight-blue",
    "  Company Directory": [
      {
        name: " 20,000 equities ",
        list: [
          "6,000 with earnings calls",
          "20 Global Exchanges",
          "Automapping new IPO-listings",
        ],
      },
      { name: "2k+ Real Estate Private Companies" },
      { name: "Automapping Private companies" },
    ],

    Indicies: [
      { name: "S&P 500" },
      { name: "FTSE100" },
      { name: "Nikkei 225" },
      { name: " Nasdaq 100" },
      { name: " OBX 25 Oslo" },
      { name: " CAC 40" },
      { name: "DAX 40" },
      { name: " OMX 30 Stockholm" },
      { name: " OMX 20 Copenhagen" },
      { name: " OMX 25 Helsinki" },
      { name: " MOEX*" },
      { name: " Nifty 50*" },
    ],
    Commodities: [
      { name: "Gold" },
      { name: "Silver" },
      { name: "Platinum" },
      { name: "Palladium" },
      { name: " Copper" },
      { name: " Crude Oil WTI" },
      { name: "Gasoline RBOB" },
      { name: "NY Harbor ULSD" },
      { name: "Natural Gas" },
      { name: "Nickel" },
      { name: "Corn" },
      { name: " Soybean Oil" },
      { name: " Wheat" },
      { name: "Canola" },
      { name: " Coffee" },
    ],
  },
  "Contextual Topics": {
    groupColor: "#FFA500",
    childColor: "bg-white",
    "Investing Topics": [
      { name: "Acquisitions" },
      { name: "Bonds" },
      { name: "Buyback" },
      { name: "CapEx" },
      { name: "Debt" },
      { name: "Demand Decline" },
      { name: "Demand Increase" },
      { name: "Divestment" },
      { name: "Dividend" },
      { name: "Earnings" },
      { name: "Free cash flow" },
      { name: "Guidance" },
      { name: "IPO" },
      { name: "Merger" },
      { name: "Price Increase" },
      { name: "Price Loss" },
      { name: "Profit Warning" },
      { name: "Profitability" },
      { name: "Profits" },
      { name: "Revenue Growth" },
      { name: "Revenue Loss" },
      { name: "Stock Volatility" },
      { name: "Supply Shortage" },
      { name: "Working Capital" },
    ],
    "ESG Topics": [
      { name: "Animal Rights" },
      { name: "Biodiversity Impact" },
      { name: "Board Composition" },
      { name: "Business Ethics" },
      { name: "Carbon Emissions" },
      { name: "Clean Energy Usage" },
      { name: "Customer Privacy" },
      { name: "Cyber Security" },
      { name: "Diversity & Inclusion" },
      { name: "Executive Compensation" },
      { name: "Fossil Fuel Usage" },
      { name: "Human Rights" },
      { name: "Industry Waste" },
      { name: "Labour rights" },
      { name: "Supply Chain" },
      { name: "Water Management" },
    ],
    "Other Topics": [
      { name: "Court Ruling" },
      { name: "Government Contracts" },
      { name: "Layoffs" },
      { name: "Management Change" },
      { name: "Product Recall" },
      { name: "Rumours" },
      { name: "Partnership Agreement" },
    ],
  },
  "API Parameters": {
    groupColor: "#C2EDFF",
    childColor: "bg-fair-pink",
    "Find & Search ": [
      {
        name: " Feed Automapping ",
        list: ["Investments", "Watchlist", "Events"],
      },
      { name: "Free text search" },
    ],
    "Sentiment Filters": [
      {
        name: "Positive",
      },
      { name: "Negative" },
      { name: "Neutral" },
      { name: "No Sentiment" },
    ],

    "News Analytics 24H/7D/30D": [
      {
        name: "All Topics",
      },
      { name: "All Instruments" },
    ],
    Events: [
      {
        name: "Email Report Service",
      },
      { name: "Push Notifications" },
      { name: "Legal Event Calendar " },
    ],
  },
};
export const personaData = {
  first_persona: {
    persona_image: FirstPersonaImage,
    latest: [],
    investments: [
      {
        category: "companies",
        id: "7dea8e4d-85df-4629-bdc0-aafdb72abbf8",
        name: "Tesla Inc",
        type: "",
      },
      {
        category: "companies",
        id: "590d0513-692a-473b-99d2-8c89fa90e031",
        name: "Apple Inc",
        type: "",
      },
      {
        category: "companies",
        id: "293d29fe-e652-443e-a252-e6cd15942e5c",
        name: "Amazon.com Inc",
        type: "",
      },
      {
        category: "companies",
        id: "d631715c-359e-450f-affc-a02556492577",
        name: "Microsoft Corp",
        type: "",
      },
      {
        category: "companies",
        id: "e5da7c94-7c31-4f55-8bb7-65c77fc6b95c",
        name: "Twitter Inc",
        type: "",
      },
      {
        category: "companies",
        id: "b7657ce8-0685-4ac4-93ca-a09ceecf7937",
        name: "JPMorgan Chase & Co",
        type: "",
      },
      {
        category: "companies",
        id: "c98d6657-bf72-475e-8fd1-fa1927aea89e",
        name: "Walmart Inc",
        type: "",
      },
      {
        category: "companies",
        id: "60e70dee-4ff4-4a0f-bc79-e19f428071ac",
        name: "Meta Platforms Inc",
        type: "",
      },
    ],
    watchlist: [
      {
        category: "companies",
        id: "858118d1-7df6-4524-aad5-5bf6562dbbbf",
        name: "Electronic Arts Inc",
        type: "",
      },
      {
        category: "companies",
        id: "4a29c91b-699a-4e4d-a0a2-1c92bc683750",
        name: "Hexagon Purus ASA",
        type: "",
      },
      {
        category: "companies",
        id: "7e334742-1418-4a9e-a629-c54b166a3d87",
        name: "Pioneer Natural Resources Co",
        type: "",
      },
      {
        category: "companies",
        id: "d5aa46ab-582e-4e83-a89d-4ff8d9f8144c",
        name: "ABB Ltd",
        type: "",
      },
      {
        category: "companies",
        id: "32c607ec-b5fb-412a-9ccc-b4c769032856",
        name: "3M",
        type: "",
      },
      {
        category: "companies",
        id: "09135c35-4294-413f-90cb-e374a9ca0854",
        name: "Lyft Inc",
        type: "",
      },
      {
        category: "companies",
        id: "85c29d10-0118-4faa-8398-ded40cec5f0f",
        name: "Visa Inc",
        type: "",
      },
    ],
    event_1: [
      {
        category: "companies",
        id: "993e412d-7265-4d0e-97b2-c5e990ca8fe5",
        name: "Dividend Sweden AB (DIVI B)",
        type: "",
      },
      {
        category: "topics",
        name: "Biodiversity Impact",
        id: "ddf82711-9c6e-4b2a-8045-c645f7fb389d",
        type: "ESG",
      },
      {
        category: "sectors",
        name: "Diversified Financial Services",
        id: "10350",
        type: "",
      },
    ],
  },
  second_persona: {
    persona_image: SecondPersonaImage,
    latest: [],
    investments: [
      {
        category: "companies",
        id: "58036b79-ce6c-4d79-af64-24c9ad1fcf03",
        name: "Rivian",
        type: "",
      },
      {
        category: "companies",
        id: "6f346195-126c-4a55-a542-5a64241d1e1b",
        name: "Deutsche Bank",
        type: "",
      },
      {
        category: "companies",
        id: "ea880dc0-7568-4127-871c-df0795183732",
        name: "IBM Corp.",
        type: "",
      },
      {
        category: "companies",
        id: "da5bdc86-a3be-4474-868b-7d809a741b4d",
        name: "Activision Blizzard Inc",
        type: "",
      },
      {
        category: "companies",
        id: "c0db53d3-8238-4951-8111-f39b2f610a35",
        name: "General Electric Co",
        type: "",
      },
      {
        category: "companies",
        id: "17bef6df-c596-433a-b5a1-82468629b322",
        name: "Advanced Micro Devices Inc",
        type: "",
      },
    ],
    watchlist: [
      {
        category: "companies",
        id: "195ab799-4af5-4859-b792-274098877cc1",
        name: "Toyota Motor Corp",
        type: "",
      },
      {
        category: "companies",
        id: "133584b2-3c5a-417a-a314-2565b499d627",
        name: "The Bank Of Japan",
        type: "",
      },
      {
        category: "companies",
        id: "65cc814d-1967-44a4-9e0e-f919d01dcbe1",
        name: "Bank of America Corporation",
        type: "",
      },
      {
        category: "companies",
        id: "9a6f5d2a-17b1-45ff-91f7-51eb5c617031",
        name: "Nordea",
        type: "",
      },
      {
        category: "companies",
        id: "686d6a89-04ae-4dd9-b912-c08d9d3ab459",
        name: "TSR Inc",
        type: "",
      },
      {
        category: "companies",
        id: "51d0a5fb-d1f6-487b-a4e8-ceb493393079",
        name: "Exxon Mobil Corp",
        type: "",
      },
      {
        category: "companies",
        id: "dd31ba8c-bcb2-4baf-b551-2de8d9231973",
        name: "Pfizer Inc",
        type: "",
      },
      {
        category: "companies",
        id: "7d972a74-8dd1-4673-a53b-ca38e4cc237a",
        name: "Wells Fargo & Co",
        type: "",
      },
      {
        category: "companies",
        id: "75a0ea1e-c73d-419e-b098-c474a436d040",
        name: "McDonald's Corporation",
        type: "",
      },
      {
        category: "companies",
        id: "1750a57c-e6ec-4484-b1f1-69e0d7276c81",
        name: "GameStop Corp",
        type: "",
      },
    ],

    event_1: [
      {
        category: "companies",
        name: "Cartesian Growth Corp II (RENEU)",
        id: "63143a2e-af16-4eaa-853d-d571d9767e9a",
        type: "",
      },
      {
        category: "companies",
        name: "Natural Health Trends Corp (NHTC)",
        id: "5364608b-d75a-48b6-b43c-1585d6709b51",
        type: "ESG",
      },
      {
        category: "topics",
        name: "Animal Rights",
        id: "a201431a-0b2a-4e44-98a5-2173b0352fbc",
        type: "ESG",
      },
    ],
  },
  third_persona: {
    persona_image: ThirdPersonaImage,
    latest: [],
    investments: [
      {
        category: "companies",
        id: "1e58a349-f48d-4d38-9910-f95cc29d571f",
        name: "Volvo AB",
        type: "",
      },
      {
        category: "companies",
        id: "26f7a7e1-db73-45a1-a4bd-02b44f66fae0",
        name: "National Grid PLC",
        type: "",
      },
      {
        category: "companies",
        id: "fd44b8e4-fabe-48a5-bf5a-1ae66d2efd31",
        name: "Procter & Gamble Co",
        type: "",
      },
      {
        category: "companies",
        id: "3f36328f-083b-41f3-acb2-63a118014207",
        name: "Broadcom Inc",
        type: "",
      },
      {
        category: "companies",
        id: "cb4d8115-148a-4a1c-8c32-2b51c2882c6e",
        name: "Zynga Inc",
        type: "",
      },
      {
        category: "companies",
        id: "a17113fb-b94b-45b1-a96d-e39df4eedc43",
        name: "Ashtead Group PLC",
        type: "",
      },
      {
        category: "companies",
        id: "eff5b1ff-70fe-410d-aa14-a688e86e479d",
        name: "CareDx Inc",
        type: "",
      },
      {
        category: "companies",
        id: "1de14862-7f36-41a6-a98f-d94bada239c4",
        name: "Nike Inc",
        type: "",
      },
      {
        category: "companies",
        id: "ae5b6e48-7e61-42f5-aa43-88456d9670cf",
        name: "Verizon Communications Inc",
        type: "",
      },
    ],
    watchlist: [
      {
        category: "companies",
        id: "da53d754-3121-4a4e-bc52-d3a2f57d0402",
        name: "Blackstone Inc",
        type: "",
      },
      {
        category: "companies",
        id: "aea44aa0-1fb7-4ec1-8955-b7ed375643ba",
        name: "Air Canada",
        type: "",
      },
      {
        category: "companies",
        id: "8a376569-d95f-4c47-9fd2-f3a321ab920d",
        name: "Intercontinental Exchange Inc",
        type: "",
      },
      {
        category: "companies",
        id: "e9f8e3d3-dcfc-454e-b0f8-3ca797fb9c23",
        name: "Brookfield Asset Management Inc",
        type: "",
      },
      {
        category: "companies",
        id: "94fedefc-c232-4c61-aeeb-a9033a90238a",
        name: "AstraZeneca PLC",
        type: "",
      },
      {
        category: "companies",
        id: "fbbf2877-fe0f-4b47-a1e0-b7a9d697d573",
        name: "First Solar Inc",
        type: "",
      },
      {
        category: "companies",
        id: "d79841ce-2d99-43e8-8864-79c1cd09faf9",
        name: "State Bank of India",
        type: "",
      },
    ],

    event_1: [
      {
        category: "companies",
        name: "Electrolux AB (ELUX B)",
        id: "728cb519-20e4-4d11-ba27-5ae37b638552",
        type: "",
      },
      {
        category: "sectors",
        name: "Electronics & Computer Distribution",
        id: "31120050",
        type: "",
      },
      {
        category: "sectors",
        name: "Homebuilding & Construction",
        id: "10230",
        type: "",
      },
    ],
  },
  fourth_persona: {
    persona_image: FourthPersonaImage,
    latest: [],
    investments: [
      {
        category: "companies",
        id: "33085720-dcf1-4917-b082-23196174b228",
        name: "Adobe Inc",
        type: "",
      },
      {
        category: "companies",
        id: "5d9808f1-fcbb-48c1-8eb4-300e9aeba402",
        name: "Uber Technologies Inc",
        type: "",
      },
      {
        category: "companies",
        id: "d979e484-f117-4ff0-b46b-784e1c513fe4",
        name: "UBS Group AG",
        type: "",
      },
      {
        category: "companies",
        id: "651b02e8-89cf-482f-a9c3-44947856003c",
        name: "Barclays PLC",
        type: "",
      },
      {
        category: "companies",
        id: "00ddae34-6f3b-4b22-9d5c-a1d3003e9eef",
        name: "HP Inc",
        type: "",
      },
      {
        category: "companies",
        id: "35c13e99-a22f-4775-b05b-7b723fde68e7",
        name: "Berkshire Hathaway Inc",
        type: "",
      },
      {
        category: "companies",
        id: "fc94691e-88a2-4ab7-8a28-8ef3bb1d18b4",
        name: "BP PLC",
        type: "",
      },
    ],
    watchlist: [
      {
        category: "companies",
        id: "84921170-49a7-420d-8754-fa1bb256e616",
        name: "H&M",
        type: "",
      },
      {
        category: "companies",
        id: "de63859a-3bf1-4fbc-a022-13b0668e1269",
        name: "Danske Bank A/S",
        type: "",
      },
      {
        category: "companies",
        id: "b7958bd6-10c1-4075-a50f-b0fc303a0031",
        name: "Novo Nordisk A/S",
        type: "",
      },
      {
        category: "companies",
        id: "0d1280e3-ec52-4d01-8833-a2179a9c83d5",
        name: "World Wrestling Entertainment Inc",
        type: "",
      },
      {
        category: "companies",
        id: "83802c5d-3555-4fe7-9f1f-ae8d2c6b01f2",
        name: "Dollar Tree Inc",
        type: "",
      },
      {
        category: "companies",
        id: "95b694d1-4f75-4c05-811c-73d8fca8be7c",
        name: "Fintech SA",
        type: "",
      },
      {
        category: "companies",
        id: "0f30dbd0-fe2c-48d3-97b0-286edea72f6e",
        name: "Global Ltd",
        type: "",
      },
      {
        category: "companies",
        id: "2e73eda6-33b5-4dae-8fb6-5f4a8c2e24f6",
        name: "Air New Zealand Ltd",
        type: "",
      },
      {
        category: "companies",
        id: "0758b29b-9ba5-4c57-823e-b14382a88fa8",
        name: "A. P. Moller Maersk A/S",
        type: "",
      },
    ],

    event_1: [
      {
        category: "topics",
        name: "Buyback",
        id: "67a895cf-9944-4287-b16f-e321afa62b75",
        type: "EQUITY",
      },
      {
        category: "sectors",
        name: "Electronics & Computer Distribution",
        id: "31120050",
        type: "",
      },
      {
        category: "companies",
        name: "Humanigen Inc (HGEN)",
        id: "d3c91862-61af-46df-b2c3-ef994675d831",
        type: "",
      },
    ],
  },
  fifth_persona: {
    persona_image: FifthPersonaImage,
    latest: [],
    investments: [
      {
        category: "companies",
        id: "7a0cf8bf-5d7f-40b2-8bab-c6ba234f08ca",
        name: "Canadian National Railway Co",
        type: "",
      },
      {
        category: "companies",
        id: "6e562185-5b0a-4671-a1ec-c1a0142fc96e",
        name: "Walgreens Boots Alliance Inc",
        type: "",
      },
      {
        category: "companies",
        id: "36cff673-752d-4466-87d8-726075515f49",
        name: "United Air Lines",
        type: "",
      },
      {
        category: "companies",
        id: "3467f2b3-4a24-4013-bf49-2309a7ad0631",
        name: "HSBC Holdings PLC",
        type: "",
      },
      {
        category: "companies",
        id: "cb640001-879a-4326-b05c-73860e5e8e24",
        name: "Accenture PLC",
        type: "",
      },
      {
        category: "companies",
        id: "eabaea77-e6f1-4b29-9e83-ce87886106c9",
        name: "Bank of Nova Scotia",
        type: "",
      },
      {
        category: "companies",
        id: "d64b4d32-e323-48a3-8fe2-5db6cb5533bd",
        name: "Royal Bank of Canada",
        type: "",
      },
      {
        category: "companies",
        id: "5787c711-bbfd-4b93-a3c5-8160de427be5",
        name: "SAP SE",
        type: "",
      },
      {
        category: "companies",
        id: "c9477f0c-f8f4-43a8-9a83-e0435ed70ad0",
        name: "PayPal Holdings Inc",
        type: "",
      },
    ],
    watchlist: [
      {
        category: "companies",
        id: "3a0ec10f-0c4e-4d82-bc2e-2310d2fb580e",
        name: "Alibaba Group Holding Ltd",
        type: "",
      },
      {
        category: "companies",
        id: "902dfa0d-1bdf-432e-9395-ed036fc0cc4c",
        name: "Boeing Co",
        type: "",
      },
      {
        category: "companies",
        id: "b3e3881b-e560-42bd-a51a-433e34df90aa",
        name: "Volkswagen AG",
        type: "",
      },
      {
        category: "companies",
        id: "1c33472a-07ba-4517-a3be-708dc2832927",
        name: "China Evergrande Group",
        type: "",
      },
      {
        category: "companies",
        id: "3ccedb1c-72c2-420c-8820-7c5e4d12af10",
        name: "Cisco Systems Inc",
        type: "",
      },
      {
        category: "companies",
        id: "e95a9712-c9e9-494a-9835-ed0787d21d41",
        name: "Oracle Corp",
        type: "",
      },
    ],

    event_1: [
      {
        category: "companies",
        name: "Costco (COST)",
        id: "e8270e89-9f6e-4a6a-8ceb-1adb463cb3c8",
        type: "",
      },
      {
        companies: "sectors",
        name: "Level One Bancorp Inc (LEVLP)",
        id: "f550acd9-bc0f-4b1c-8f25-95f37163664d",
        type: "",
      },
      {
        category: "companies",
        name: "TreeHouse Foods (THS)",
        id: "df6b5e18-8cee-47a9-8aa8-5a67af1f633d",
        type: "",
      },
    ],
  },
  sixth_persona: {
    persona_image: SixthPersonaImage,
    latest: [],
    investments: [
      {
        category: "companies",
        id: "9d3c40b7-d530-4466-b22b-51cdf5fe7a80",
        name: "Bilia AB",
        type: "",
      },
      {
        category: "companies",
        id: "32c607ec-b5fb-412a-9ccc-b4c769032856",
        name: "3M",
        type: "",
      },
      {
        category: "companies",
        id: "0b3bffb0-885b-4880-9982-21ca31e26135",
        name: "Gartner Inc",
        type: "",
      },
      {
        category: "companies",
        id: "75cf6fc5-bad0-4634-a976-6622e8ef1c50",
        name: "Tesco PLC",
        type: "",
      },
      {
        category: "companies",
        id: "414b85db-12d3-4d5f-bb9a-03b0a1a9245c",
        name: "Origin Energy Ltd",
        type: "",
      },
      {
        category: "companies",
        id: "2929870c-911a-46cf-b007-2f7b7c11ebf1",
        name: "Engie SA",
        type: "",
      },
      {
        category: "companies",
        id: "776d9882-b02a-45c8-8ac2-23caf0e047c2",
        name: "Virgin Galactic Holdings Inc",
        type: "",
      },
      {
        category: "companies",
        id: "939dae40-688a-4a1c-87a8-57962ae9a913",
        name: "BAE Systems PLC",
        type: "",
      },
      {
        category: "companies",
        id: "c14ed9dc-f5f9-4134-a154-eb97ee875ad9",
        name: "Capital Ltd",
        type: "",
      },
      {
        category: "companies",
        id: "b64c2c29-0725-49a9-a4f8-9d9ea28e228f",
        name: "Foot Locker Inc",
        type: "",
      },
    ],
    watchlist: [
      {
        category: "companies",
        id: "4945c8a6-e966-430c-9b62-53fa0e1a7de3",
        name: "Coinbase Global Inc",
        type: "",
      },
      {
        category: "companies",
        id: "9447c7ad-e074-4b3d-a6cc-59a3d4216753",
        name: "Chevron Corp",
        type: "",
      },
      {
        category: "companies",
        id: "0563d6c6-c6b4-46ca-9e6b-dfd52362ad2b",
        name: "Target Corp",
        type: "",
      },
      {
        category: "companies",
        id: "9d573f6e-8670-48c1-ab11-1c644d896fd6",
        name: "Credit Suisse Group AG",
        type: "",
      },
      {
        category: "companies",
        id: "195ab799-4af5-4859-b792-274098877cc1",
        name: "Toyota Motor Corp",
        type: "",
      },
      {
        category: "companies",
        id: "133584b2-3c5a-417a-a314-2565b499d627",
        name: "The Bank Of Japan",
        type: "",
      },
      {
        category: "companies",
        id: "65cc814d-1967-44a4-9e0e-f919d01dcbe1",
        name: "Bank of America Corporation",
        type: "",
      },
      {
        category: "companies",
        id: "9a6f5d2a-17b1-45ff-91f7-51eb5c617031",
        name: "Nordea",
        type: "",
      },
    ],

    event_1: [
      {
        category: "topics",
        name: "Exchange Rates",
        id: "59416a10-b8b6-4907-9311-91f28c5febf9",
        type: "EQUITY",
      },
      {
        companies: "topics",
        name: "Quote",
        id: "de60d2d9-2b11-4299-b508-f211604cc9bb",
        type: "EQUITY",
      },
      {
        category: "sectors",
        name: "Airlines",
        id: "31080020",
        type: "",
      },
    ],
  },
};

export const translatedSectionNames = {
  latest: "Senaste Nytt",
  investments: "Innehav",
  watchlist: "Bevakningar",
  events: "Sparade Events",
  event_1: "Sparade Events 1",
  event_2: "Sparade Events 2",
};
