import React, { useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";
import ReactTimeAgo from "react-time-ago";
import { individualRetailInvestorContext } from "common/context";
const Summary = () => {
  //get summary data from context
  const { summary, individualRetailInvestorDispatch } = useContext(
    individualRetailInvestorContext
  );

  //handle summary on button clicks
  const toggleSummary = () => {
    //hide summary
    individualRetailInvestorDispatch({
      type: "setSummary",
      payload: { ...summary, showSummary: false },
    });
  };

  //destructure summary properties
  const { summaryTitle, summaryBody, releaseDate, sourceName, showSummary } =
    summary;

  //hide summary if showSummary is false
  if (!showSummary) return null;

  //return summary component
  return (
    <div className="Summary w-full h-full z-10 absolute top-0 inset-x-0 flex flex-col items-center justify-center">
      <div
        onClick={toggleSummary}
        className="h-full w-full  bg-black/60  rounded-3xl"
      ></div>
      <div className="absolute bg-linen dark:bg-black w-full  p-1">
        <div className="flex items-center justify-between my-1">
          <h1 className="text-customFontOne dark:text-white font-semibold capitalize truncate">
            {summaryTitle}
          </h1>
          <button onClick={toggleSummary}>
            <AiOutlineClose size={16} className="text-black " />
          </button>
        </div>
        <p className="text-customFontNineteen dark:text-white w-full mb-1 ">
          {summaryBody}
        </p>
        <hr className="w-full border border-black/5 dark:border-white/5 mb-1" />
        <div className="flex text-customFontNine items-center justify-center flex-wrap space-x-4  w-full text-black-russian dark:text-white py-0.5">
          <ReactTimeAgo
            date={releaseDate * 1000}
            locale="en-US"
            timeStyle="round-minute"
          />
          <span>|</span>
          <span>{sourceName}</span>
          <p className="mt-0.5">Summarized by Texelio ©</p>
        </div>
      </div>
    </div>
  );
};

export default Summary;
