import React, { useEffect, useRef, memo, useReducer, useContext } from "react";
import { useMemo } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import IndividualEarningCall from "./IndividualEarningCall";
import SearchEarningsCall from "./SearchEarningsCall";
import { individualRetailInvestorContext } from "common/context";
import {
  earningsCallReducer,
  getEarningsCallsList,
  getUpdatedClickCounts,
  searchEarningsCall,
} from "common/exportedFunctions";
import { useDispatch, useSelector } from "react-redux";
import { setEngagementData } from "features/app/appSlice";

// initial earnings call state
const initialState = {
  searchWord: "",
  data: {
    earningsCall: [], //stores the list of earnings calls
    loading: false,
    message: "",
    error: "",
  },
  filteredEarningsCalls: [], // stores a list of filtered calls after using a searchWord
  individualEarningCall: {
    earningCall: "", // stores the data of a single earnings call such as it's audio and transcript
    showEarningCall: false,
  },
};

const EarningsCall = () => {
  //create state and get correspoding state values and reducer function
  const [
    {
      searchWord,
      filteredEarningsCalls,
      individualEarningCall,
      data: { earningsCall, loading, message, error },
    },
    earningCallDispatch,
  ] = useReducer(earningsCallReducer, initialState);

  //get engagment data from redux store
  const { engagementData } = useSelector((store) => store.app);

  //get partitionName rendering this component
  const { partitionName } = useContext(individualRetailInvestorContext);

  // abort controller to cancel http request when component unmounts
  const abortControllerRef = useRef(null);

  //get a dispatch function
  const dispatch = useDispatch();

  //show a that specific earning call if it is clicked from the list
  const handleIndividualCall = (earningCall) => {
    //load this earning call including it's transcript
    earningCallDispatch({
      type: "setIndividualEarningCall",
      payload: { earningCall, showEarningCall: true },
    });
  };

  //update click count whenever individualEarningCall changes
  useEffect(() => {
    //record engagement data (click count) if the earning call was rendered by  partitionTwo
    if (
      partitionName === "partitionTwo" &&
      individualEarningCall.showEarningCall === true
    ) {
      //destructure earning call
      const { company_name, document_type } = individualEarningCall.earningCall;
      // get new updated engagement data
      const newUpdatedEngagementData = getUpdatedClickCounts({
        engagementData,
        companyName: company_name,
        documentType: document_type,
      });

      //update the engagement data on redux store
      dispatch(setEngagementData(newUpdatedEngagementData));
    }
  }, [individualEarningCall]);

  // get a list of all earnings calls upon landing
  useEffect(() => {
    const abortControllerRefCopy = abortControllerRef;
    getEarningsCallsList(earningCallDispatch, abortControllerRef);
    return () => abortControllerRefCopy.current?.abort();
  }, []);

  //search the earning calls everytime searchWord changes
  useEffect(() => {
    searchEarningsCall(searchWord, earningCallDispatch, earningsCall);
  }, [searchWord]);

  //create a earnings calls as list items
  const content = useMemo(() => {
    return filteredEarningsCalls.map((earningCall) => {
      return (
        <li
          onClick={() => handleIndividualCall(earningCall)}
          key={earningCall.id}
          className="cursor-pointer tex mb-2 font-semibold hover:bg-linen/50 dark:text-white dark:hover:bg-aluminium"
        >
          {earningCall.title}
        </li>
      );
    });
  }, [filteredEarningsCalls]);

  //return the list of earnings calls if available
  // or return message, loading and errors
  const results = content?.length ? (
    <div className="w-full bg-inherit">
      <ol className="w-full my-1 list-decimal list-inside">{content}</ol>
    </div>
  ) : (
    <div className="w-full bg-inherit">
      {loading && (
        <div className="w-full text-center">
          <BeatLoader size={10} color="#40464F" />
        </div>
      )}
      {message && (
        <p className="w-full text-customFontSix text-center text-black dark:text-white">
          {message}
        </p>
      )}
      {error && (
        <p className="w-full text-customFontSix text-red-500 text-center">
          {error}
        </p>
      )}
    </div>
  );

  return (
    <div className="w-full h-full relative bg-inherit  rounded-t-3xl overflow-auto scrollbar-hide text-customFontTwo pt-6 ">
      <div className="w-full sticky top-0 bg-inherit pb-1 px-2">
        <h1 className="text-center mb-1  font-semibold dark:text-white text-customFontOne uppercase">
          Earnings Calls
        </h1>
        <SearchEarningsCall
          SearchEarningsCallProps={{
            searchWord,
            earningCallDispatch,
          }}
        />
      </div>
      <div className="w-full h-earningsCallHeight pb-2 bg-linen dark:bg-nero px-2 overflow-y-auto scrollbar-hide">
        {results}
      </div>
      {individualEarningCall.showEarningCall && (
        <IndividualEarningCall
          individualEarningCallProps={{
            individualEarningCall,
            earningCallDispatch,
          }}
        />
      )}
    </div>
  );
};

export default memo(EarningsCall);
