import React, { memo, useState, useEffect, useMemo, useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { controlBodyScroll } from "common/exportedFunctions";
import Media from "./CompanyMedia/CompanyMedia";
import Overview from "./CompanyOverview";
import Moods from "./CompanyMoods";
import News from "./CompanyNews";
import Press from "./CompanyPress";
import { individualRetailInvestorContext } from "common/context";

function CompanyPopup({ companyPopupProps }) {
  //get props values,
  const { companyData, closeFn } = companyPopupProps;

  //get context data
  const { individualRetailInvestorDispatch } = useContext(
    individualRetailInvestorContext
  );

  //keep of track of current selected tab
  const [selectedTab, switchToTab] = useState("Media");

  //crate an object of tabs
  const tabs = { Overview, News, Press, Media, Moods };

  //show content of selected tab
  const tabContent = useMemo(
    function renderChildContent() {
      const Tab = tabs[selectedTab];
      return <Tab {...companyData} />;
    },
    [selectedTab, companyData]
  );

  //set some defaults when body mounts
  useEffect(function whenShowingCompanyPopup() {
    controlBodyScroll("noscroll"); //this will pause it when component loads (mounted)

    return function whenClosingCompanyPopup() {
      controlBodyScroll("scroll"); //this will resume it when component is unloaded (unmounted)
    };
  }, []);

  //hide or show  main content of individual investor content
  useEffect(() => {
    //hide main content
    individualRetailInvestorDispatch({
      type: "setShowMainContent",
      payload: false,
    });
    return () => {
      //show main content of individual investor content when this component unmounts
      individualRetailInvestorDispatch({
        type: "setShowMainContent",
        payload: true,
      });
    };
  }, []);

  return (
    <div className=" w-full z-20 h-full absolute top-0  inset-x-0 flex flex-col items-center ">
      <div
        onClick={closeFn}
        className="h-full w-full rounded-3xl bg-black/60"
      ></div>
      <div className="absolute top-7 inset-x-0 h-[87%]  text-black bg-sazerac dark:bg-aluminium rounded-md">
        <div className="w-full flex items-center justify-between py-0.5 px-1 bg-linen dark:bg-rhino">
          <div className="w-4 mr-0.5">
            {companyData.logo && (
              <img className="w-full" src={companyData.logo} alt="Logo" />
            )}
          </div>
          <h1 className="max-w-70 truncate capitalize py-1 text-customFontTwo font-semibold dark:text-white">
            {companyData.name}
          </h1>
          <button onClick={closeFn}>
            <AiOutlineClose size={16} className=" dark:text-white" />
          </button>
        </div>
        <div className="w-full px-0.5 pt-1 ">
          <div className="w-full text-customFontSix font-semibold bg-linen dark:bg-rhino  rounded-full flex justify-between items-center py-1 px-0.5 my-0.5">
            {Object.keys(tabs).map((tab) => (
              <button
                key={tab}
                onClick={switchToTab.bind(null, tab)}
                className={[
                  "w-full text-center rounded-full p-1 capitalize whitespace-nowrap",
                  selectedTab === tab
                    ? "bg-onahau dark:bg-turquoise"
                    : "dark:text-white",
                ].join(" ")}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>
        <div className="w-full h-[83%] overflow-auto scrollbar-hide px-1 my-1">
          {tabContent}
        </div>
      </div>
    </div>
  );
}

export default memo(CompanyPopup);
