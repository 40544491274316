import React, { memo, useMemo, useRef, useEffect, useContext } from "react";
import { groupBy, uniqueId } from "lodash";
import { uniqBy } from "lodash";
import { individualRetailInvestorContext } from "common/context";
import ShrinkedContent from "./ShrinkedContent";
import ExpandedContent from "./ExpandedContent";

const IndividualStock = ({ individualStockProps: { stock } }) => {
  //get context
  const { translatedTopics } = useContext(individualRetailInvestorContext);

  //destructure stock info
  const {
    source_name: sourceName,
    primary_concept: { name: companyName, id: companyId },
    created_ts: releaseDate,
    hidden_key: hiddenArticleKey,
    topics,
    sections,
    hidden_source_url: hiddenSourceUrl,
  } = stock;

  //container to hold shrinked & expanded components
  const dynamicDivRef = useRef([]);

  //ref to reveal hidden text later on
  const hiddenTextRef = useRef([]);

  //ref to transfer other refs through the  component
  const ref = useRef({ dynamicDivRef, hiddenTextRef });

  //group topics of same id
  const filteredTopicsObj = useMemo(() => groupBy(topics, "id"), [topics]);

  // make sure each section is unique
  const filteredSections = useMemo(
    () =>
      uniqBy(
        sections.map((sectionObj) => ({
          ...sectionObj,
          topic_ids: sectionObj.topic_ids[0],
        })),
        "topic_ids"
      ),
    [sections]
  );

  //different sections for same stock
  const sectionsArray = useMemo(
    () =>
      filteredSections.slice(0, 3).map((section) => {
        const topicId = filteredTopicsObj[section.topic_ids][0];
        const buttonType = topicId.type;
        const buttonText =
          translatedTopics.filter(
            (topic) => topic.topic_id === section.topic_ids
          )[0]?.name ?? filteredTopicsObj[section.topic_ids][0].name;
        const unqId = uniqueId("reveal");
        const containerId = uniqueId("container");
        const shrinkedId = uniqueId("shrinked");
        const expandedId = uniqueId("expanded");
        const sectionSummaryOrder = section.section_orders[0];
        return (
          <div key={containerId} className="w-full">
            <ShrinkedContent
              ref={ref}
              shrinkedContentProps={{
                shrinkedId,
                releaseDate,
                expandedId,
                section,
                companyId,
                buttonType,
                buttonText,
              }}
            />
            <ExpandedContent
              ref={ref}
              expandedContentProps={{
                expandedId,
                releaseDate,
                section,
                unqId,
                companyId,
                buttonType,
                hiddenSourceUrl,
                sourceName,
                sectionSummaryOrder,
                hiddenArticleKey,
                companyName,
                buttonText,
              }}
            />
          </div>
        );
      }),
    [filteredSections]
  );

  //set default ... for each span
  useEffect(() => {
    Object.values(hiddenTextRef.current).forEach((span) => {
      if (span) span.innerHTML = "...";
    });
  }, []);

  return <div className="w-full">{sectionsArray}</div>;
};

export default memo(IndividualStock);
