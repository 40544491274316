import React, { useState } from "react";
import { MdModeEditOutline } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import PodCastTranscript from "./PodCastTranscript";
import EditPodCastTranscript from "./EditPodCastTranscript";
const PodCastTranscriptWrapper = () => {
  const [editPodcastTranscript, setEditPodcastTranscript] = useState(false);
  const toggleEditMode = () => {
    setEditPodcastTranscript(!editPodcastTranscript);
  };

  return (
    <div className="relative w-full h-full overflow-auto scrollbar-hide bg-white p-2 rounded-md text-customFontEleven">
      <div className="absolute top-0 right-1  z-10">
        <button
          onClick={toggleEditMode}
          className="p-1 z-10 bg-gray-200 rounded-md"
        >
          {editPodcastTranscript ? (
            <AiOutlineClose className="text-lg hover:scale-125" />
          ) : (
            <MdModeEditOutline className="text-lg hover:scale-125" />
          )}
        </button>
      </div>
      <PodCastTranscript />
      {editPodcastTranscript && (
        <EditPodCastTranscript
          editPodCastTranscriptProps={{
            editPodcastTranscript,
            setEditPodcastTranscript,
          }}
        />
      )}
    </div>
  );
};

export default PodCastTranscriptWrapper;
