import InvestorOwnerContainer from "components/CustomContainer/InvestorOwnerContainer";
import InvestorOwnerHeader from "components/CustomHeader/InvestorOwnerHeader";
import IndividualRetailInvestorContent from "./IndividualRetailInvestorContent";
import { memo } from "react";
import PhoneViewOptionsPanel from "./PhoneViewOptionsPanel";
import GeneratePortfolio from "./GeneratePortfolio";

export default memo(function RetailInvestors() {
  // two partitions of RetailInvestor section of CDD
  // named them for clarity.
  const partitions = [
    {
      name: "partitionOne",
    },
    { name: "partitionTwo" },
  ];

  return (
    <InvestorOwnerContainer>
      <InvestorOwnerHeader
        title="For Retail Investors"
        otherItem={<GeneratePortfolio />}
      />
      <div className="w-full mx-auto py-5 px-10 ">
        <PhoneViewOptionsPanel />
        <div className="w-max mx-auto grid grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 justify-center gap-4 py-4 my-2">
          {partitions.map((partition) => (
            <IndividualRetailInvestorContent
              key={partition.name}
              partitionProps={partition}
            />
          ))}
        </div>
      </div>
    </InvestorOwnerContainer>
  );
});
