import React, { useContext, memo } from "react";
import { individualRetailInvestorContext } from "common/context";
import Latest from "./Latest";
import Trending from "./Trending";

const VisualiticsContent = ({ visualiticsContentProps }) => {
  // get context data
  const {
    visualiticsData: { sectionName },
  } = useContext(individualRetailInvestorContext);

  return (
    <div className="w-full overflow-auto scrollbar-hide py-1 px-2 bg-linen dark:bg-rhino text-black dark:text-white font-semibold rounded-sm">
      {sectionName === "latest" ? (
        <Latest latestProps={visualiticsContentProps} />
      ) : (
        <Trending trendingProps={visualiticsContentProps} />
      )}
    </div>
  );
};

export default memo(VisualiticsContent);
