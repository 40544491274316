import React, { useContext, memo, useEffect, useRef } from "react";
import { BsPlayFill, BsPauseFill } from "react-icons/bs";
import { AiOutlineDelete, AiOutlineCheck } from "react-icons/ai";
import { podcastContext } from "common/context";
// import { db, deleteDoc, doc } from "common/firebase-app";
import * as firebase from "common/firebase-app";
import PropagateLoader from "react-spinners/BeatLoader";
// import { generatePodcastTranscript } from "common/api";
import * as api from "common/api";
const Podcast = React.forwardRef(({ podcastProps }, progressBarsRef) => {
  const {
    podcasts,
    podcastDispatch,
    currentPodcastPlaying,
    isPlaying,
    setIsPlaying,
  } = useContext(podcastContext);
  const {
    podcast: {
      isPodcastUploading = false, // determines if it's currently uploading or not
      isGeneratingTranscript = false, // determines if it's generating transcript
      name: podcastName,
      audio_url: audioUrl,
      transcript: podcastTranscript = [],
      transcriptionDate = "",
    },
  } = podcastProps;

  const modifiedName = podcastName?.split(".")[0];
  // determines if currentPodcastPlaying is set to this podcast name
  const isPodcastPlaying =
    podcasts[currentPodcastPlaying]?.name === podcastName;

  const abortControllerRef = useRef(null);
  const removePodCast = async () => {
    const podcastsCopy = { ...podcasts };
    delete podcastsCopy[podcastName];
    // update local copy of podcasts
    podcastDispatch({
      type: "setPodcasts",
      payload: {
        ...podcastsCopy,
      },
    });
    // delete from  firestore too
    await firebase.deleteDoc(
      firebase.doc(firebase.db, "podcasts", podcastName)
    );
  };
  const togglePodcastPlayPause = (action) => {
    if (action === "play") {
      setIsPlaying(true);
      // set as currnt playing podcast
      podcastDispatch({
        type: "setCurrentPodcastPlaying",
        payload: podcastName,
      });

      return;
    }
    setIsPlaying(false);
  };
  const generateTranscript = async () => {
    await api.generatePodcastTranscript({
      podcastDispatch,
      podcasts,
      name: podcastName,
      audio_url: audioUrl,
      abortControllerRef,
    });
  };
  useEffect(() => {
    const abortControllerRefCopy = abortControllerRef;
    // resume generating transcript if and only if no transcript and is not uploading
    if (!podcastTranscript.length && !isPodcastUploading) {
      generateTranscript();
    }
    return () => abortControllerRefCopy.current?.abort();
  }, []);
  return (
    <div
      className={`${isPodcastUploading ? "opacity-50" : "opacity-100"} ${
        isPodcastPlaying ? "bg-slate-300" : "bg-gray-200"
      } w-full my-2 rounded-full px-2 py-2.5 hover:bg-slate-400`}
    >
      <div className={`w-full grid grid-cols-podcastCol gap-x-2 items-center `}>
        <div
          className={`${
            isPodcastUploading || isGeneratingTranscript
              ? "bg-gray-500/50"
              : "bg-green-500"
          } w-7 h-7  rounded-full flex items-center justify-center`}
        >
          {isPodcastPlaying && isPlaying ? (
            <button
              onClick={() => togglePodcastPlayPause("pause")}
              className="w-full disabled:cursor-not-allowed disabled:opacity-50 "
              disabled={isPodcastUploading || isGeneratingTranscript}
            >
              <BsPauseFill className="text-xl mx-auto" />
            </button>
          ) : (
            <button
              onClick={() => togglePodcastPlayPause("play")}
              className="w-full disabled:cursor-not-allowed disabled:opacity-50"
              disabled={isPodcastUploading || isGeneratingTranscript}
            >
              {" "}
              <BsPlayFill className="text-xl mx-auto" />
            </button>
          )}
        </div>
        <p
          onClick={() => togglePodcastPlayPause("play")}
          className="w-full capitalize truncate cursor-pointer"
        >
          {modifiedName}
        </p>
        <div className="text-xs italic">
          {isGeneratingTranscript ? (
            <div className="flex items-center text-blue-500">
              <span className="mr-0.5 ">Transcribing</span>
              <span>
                <PropagateLoader size={5} />
              </span>
            </div>
          ) : (
            podcastTranscript.length > 0 && (
              <div className=" text-green-500 italic flex flex-col items-center justify-center text-xs">
                <AiOutlineCheck />
                <span className="mt-0.5">{transcriptionDate}</span>
              </div>
            )
          )}
        </div>
        {!isPodcastPlaying && (
          <button
            onClick={removePodCast}
            className="w-full disabled:cursor-not-allowed disabled:opacity-50"
            disabled={isPodcastUploading}
          >
            <AiOutlineDelete className="text-xl" />
          </button>
        )}
      </div>
      {isPodcastUploading && (
        <div className="w-full text-xs grid grid-cols-podcastUploadProgress grid-rows-1 gap-x-1 items-center py-1 px-1">
          <span className="italic ">Uploading</span>
          <progress
            ref={(progressEl) =>
              (progressBarsRef.current[podcastName] = progressEl)
            }
            className="w-full h-1"
            value="0"
            max="100"
          ></progress>
          <span className="w-7 justify-self-end text-end">0%</span>
        </div>
      )}
    </div>
  );
});

export default memo(Podcast);
