import { PieChart, Pie, Cell } from "recharts";
export default function PieChartData({ unitName, total, chartData }) {
  //hide this component if there's no chartData
  if (!chartData.length) return null;

  //return pie chart with user info
  return (
    <div className="relative w-min mx-auto flex items-center justify-center ">
      <PieChart width={150} height={150}>
        <Pie
          data={chartData}
          dataKey="value"
          cx="50%"
          cy="50%"
          outerRadius={75}
          labelLine={false}
        >
          {chartData.map(({ name, color }) => (
            <Cell key={name} fill={color} />
          ))}
        </Pie>
      </PieChart>
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 p-1 rounded-full  bg-white flex items-center justify-center">
        <div className="w-28 h-28 mx-auto flex flex-col items-center justify-center space-y-0.5 border-4 border-white-smoke rounded-full p-1 ">
          <h2 className="text-sm text-space-shuttle capitalize">
            {unitName === "conversion" ? unitName : `user ${unitName}`}
          </h2>
          <p className="text-2xl font-semibold whitespace-nowrap">
            {unitName === "conversion" ? `${total.toFixed(1)}%` : total}
          </p>
        </div>
      </div>
    </div>
  );
}
