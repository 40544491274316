import { memo } from "react";
import StatsSection from "./StatsSection";

export default memo(function Stats({
  esgTopics,
  investingTopics,
  stockMentions,
  unit,
}) {
  // create various sections of stats
  const statsSections = [
    {
      name: "Stock Mentions",
      data: stockMentions,
      bgColor: "#E0E4ED",
      textColor: "#000000",
    },
    {
      name: "Investing Topics",
      data: investingTopics,
      bgColor: "#EBF9FF",
      textColor: "#257798",
    },
    {
      name: "ESG Topics",
      data: esgTopics,
      bgColor: "#D6FFDA",
      textColor: "#2CB439",
    },
  ];

  //return the various sections
  return (
    <div className="w-full  divide-y-2 divide-whisper-shade">
      {statsSections.map((section) => {
        //destructure the values of section
        const { name, data, bgColor, textColor } = section;
        return (
          <StatsSection
            key={name}
            name={name}
            data={data}
            unitName={unit}
            bgColor={bgColor}
            textColor={textColor}
          />
        );
      })}
    </div>
  );
});
