// feed-mode icon
export const FeedModeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21 16.25H15C14.59 16.25 14.25 15.91 14.25 15.5C14.25 15.09 14.59 14.75 15 14.75H21C21.41 14.75 21.75 15.09 21.75 15.5C21.75 15.91 21.41 16.25 21 16.25Z"
        fill="currentColor"
      />
      <path
        d="M21 20.25H15C14.59 20.25 14.25 19.91 14.25 19.5C14.25 19.09 14.59 18.75 15 18.75H21C21.41 18.75 21.75 19.09 21.75 19.5C21.75 19.91 21.41 20.25 21 20.25Z"
        fill="currentColor"
      />
      <path
        d="M22 8.52V3.98C22 2.57 21.36 2 19.77 2H15.73C14.14 2 13.5 2.57 13.5 3.98V8.51C13.5 9.93 14.14 10.49 15.73 10.49H19.77C21.36 10.5 22 9.93 22 8.52Z"
        fill="currentColor"
      />
      <path
        d="M10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52Z"
        fill="currentColor"
      />
      <path
        d="M10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z"
        fill="currentColor"
      />
    </svg>
  );
};

//cube mode icon
export const CubeModeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9 22H15C16.66 22 18 20.66 18 19V5C18 3.34 16.66 2 15 2H9C7.34 2 6 3.34 6 5V19C6 20.66 7.34 22 9 22Z"
        fill="currentColor"
      />
      <path
        d="M4.5 5.92996V18.07C4.5 18.39 4.21 18.62 3.9 18.56C2.42 18.29 2 17.43 2 15.33V8.66996C2 6.56996 2.42 5.70996 3.9 5.43996C4.21 5.37996 4.5 5.60996 4.5 5.92996Z"
        fill="currentColor"
      />
      <path
        d="M22 8.66996V15.33C22 17.43 21.58 18.29 20.1 18.56C19.79 18.62 19.5 18.38 19.5 18.07V5.92996C19.5 5.60996 19.79 5.37996 20.1 5.43996C21.58 5.70996 22 6.56996 22 8.66996Z"
        fill="currentColor"
      />
    </svg>
  );
};

//desktop icon
export const DesktopIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.56 2H6.41C3.98 2 2 3.98 2 6.41V12.91V13.11C2 15.55 3.98 17.52 6.41 17.52H10.25C10.8 17.52 11.25 17.97 11.25 18.52V19.49C11.25 20.04 10.8 20.49 10.25 20.49H7.83C7.42 20.49 7.08 20.83 7.08 21.24C7.08 21.65 7.41 22 7.83 22H16.18C16.59 22 16.93 21.66 16.93 21.25C16.93 20.84 16.59 20.5 16.18 20.5H13.76C13.21 20.5 12.76 20.05 12.76 19.5V18.53C12.76 17.98 13.21 17.53 13.76 17.53H17.57C20.01 17.53 21.98 15.55 21.98 13.12V12.92V6.42C21.97 3.98 19.99 2 17.56 2Z"
        fill="currentColor"
      />
    </svg>
  );
};

//portfolio icon
export const PortfolioIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.44935 2.1199C6.0455 2.1199 5.70192 2.40053 5.60548 2.77878H8.84833C8.75189 2.40053 8.40832 2.1199 8.00447 2.1199H6.44935ZM9.76427 2.77787H10.9577C12.2235 2.77787 13.2543 3.82111 13.2543 5.10228C13.2543 5.10228 13.2181 5.64464 13.206 6.39993C13.2048 6.45971 13.1759 6.51828 13.1283 6.55367C12.8384 6.76781 12.5731 6.94473 12.549 6.95693C11.5484 7.62802 10.3857 8.10022 9.14705 8.33511C9.06628 8.35097 8.98671 8.30887 8.94573 8.23688C8.59854 7.63534 7.94997 7.24367 7.22665 7.24367C6.50816 7.24367 5.85357 7.63107 5.49613 8.23322C5.45454 8.30399 5.37618 8.34487 5.29601 8.32962C4.06759 8.09412 2.90486 7.62253 1.91031 6.96303L1.33166 6.56038C1.28344 6.52987 1.2533 6.47497 1.2533 6.41396C1.23522 6.10282 1.20508 5.10228 1.20508 5.10228C1.20508 3.82111 2.2358 2.77787 3.5016 2.77787H4.68903C4.80356 1.89325 5.54495 1.20386 6.44909 1.20386H8.00421C8.90836 1.20386 9.64975 1.89325 9.76427 2.77787ZM13.0493 7.72082L13.0252 7.73303C11.8076 8.55054 10.3429 9.09351 8.80589 9.31924C8.5889 9.34974 8.37191 9.20942 8.31163 8.9898C8.17902 8.48953 7.75106 8.16008 7.23872 8.16008H7.23269H7.22063C6.70829 8.16008 6.28033 8.48953 6.14772 8.9898C6.08745 9.20942 5.87045 9.34974 5.65346 9.31924C4.11642 9.09351 2.65171 8.55054 1.43414 7.73303C1.42811 7.72693 1.36783 7.69032 1.31961 7.72082C1.26536 7.75133 1.26536 7.82454 1.26536 7.82454L1.30756 10.936C1.30756 12.2171 2.33225 13.2543 3.59805 13.2543H10.8553C12.1211 13.2543 13.1458 12.2171 13.1458 10.936L13.194 7.82454C13.194 7.82454 13.194 7.75133 13.1397 7.72082C13.1096 7.70252 13.0734 7.70862 13.0493 7.72082ZM7.67887 10.2768C7.67887 10.533 7.47996 10.7344 7.2268 10.7344C6.97967 10.7344 6.77473 10.533 6.77473 10.2768V9.4898C6.77473 9.23967 6.97967 9.03224 7.2268 9.03224C7.47996 9.03224 7.67887 9.23967 7.67887 9.4898V10.2768Z"
        fill="currentColor"
      />
    </svg>
  );
};

//stories  icon
export const StoriesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.20508 3.01123C1.20508 2.01305 2.01427 1.20386 3.01246 1.20386H11.4469C12.4451 1.20386 13.2543 2.01305 13.2543 3.01123V11.4457C13.2543 12.4438 12.4451 13.253 11.4469 13.253H3.01245C2.01427 13.253 1.20508 12.4438 1.20508 11.4457V3.01123ZM6.86399 3.61417C6.86399 3.36462 7.06629 3.16233 7.31583 3.16233H11.533C11.7826 3.16233 11.9849 3.36462 11.9849 3.61417C11.9849 3.86372 11.7826 4.06602 11.533 4.06602H7.31583C7.06629 4.06602 6.86399 3.86372 6.86399 3.61417ZM7.31583 8.58462C7.06629 8.58462 6.86399 8.78692 6.86399 9.03646C6.86399 9.28601 7.06629 9.48831 7.31583 9.48831H11.533C11.7826 9.48831 11.9849 9.28601 11.9849 9.03646C11.9849 8.78692 11.7826 8.58462 11.533 8.58462H7.31583ZM6.86399 5.4216C6.86399 5.17205 7.06629 4.96976 7.31583 4.96976H9.42444C9.67399 4.96976 9.87629 5.17205 9.87629 5.4216C9.87629 5.67115 9.67399 5.87345 9.42444 5.87345H7.31583C7.06629 5.87345 6.86399 5.67115 6.86399 5.4216ZM7.31583 10.3916C7.06629 10.3916 6.86399 10.5939 6.86399 10.8434C6.86399 11.0929 7.06629 11.2952 7.31583 11.2952H9.42444C9.67399 11.2952 9.87629 11.0929 9.87629 10.8434C9.87629 10.5939 9.67399 10.3916 9.42444 10.3916H7.31583ZM2.49581 3.61403C2.49581 3.11494 2.9004 2.71035 3.3995 2.71035H5.20687C5.70597 2.71035 6.11056 3.11494 6.11056 3.61403V5.42141C6.11056 5.9205 5.70597 6.3251 5.20687 6.3251H3.3995C2.9004 6.3251 2.49581 5.9205 2.49581 5.42141V3.61403ZM3.3995 8.13264C2.9004 8.13264 2.49581 8.53723 2.49581 9.03632V10.8437C2.49581 11.3428 2.9004 11.7474 3.3995 11.7474H5.20687C5.70597 11.7474 6.11056 11.3428 6.11056 10.8437V9.03632C6.11056 8.53723 5.70597 8.13264 5.20687 8.13264H3.3995Z"
        fill="currentColor"
      />
    </svg>
  );
};

//Earnings call  icon
export const EarningsCallIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M7.22949 7.7122V6.66724"
        stroke="currentColor"
        strokeWidth="0.903688"
        strokeLinecap="round"
      />
      <path
        d="M10.6753 7.71289V5.38213"
        stroke="currentColor"
        strokeWidth="0.903688"
        strokeLinecap="round"
      />
      <path
        d="M8.95215 7.71239V6.15753"
        stroke="currentColor"
        strokeWidth="0.903688"
        strokeLinecap="round"
      />
      <path
        d="M12.3984 7.71245V4.57251"
        stroke="currentColor"
        strokeWidth="0.903688"
        strokeLinecap="round"
      />
      <path
        d="M12.3988 1.50354L12.1666 1.77614C10.8793 3.28048 9.15283 4.34564 7.22949 4.82521"
        stroke="currentColor"
        strokeWidth="0.903688"
        strokeLinecap="round"
      />
      <path
        d="M10.9194 1.50366H12.3985V2.97772"
        stroke="currentColor"
        strokeWidth="0.903688"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7805 11.4935C10.7805 11.6661 10.7422 11.8435 10.6607 12.0161C10.5791 12.1888 10.4737 12.3518 10.3346 12.5053C10.0996 12.7642 9.84069 12.9512 9.54818 13.0711C9.26047 13.191 8.94879 13.2533 8.61313 13.2533C8.12402 13.2533 7.60134 13.1382 7.0499 12.9033C6.49846 12.6683 5.94701 12.3518 5.40036 11.9538C4.84892 11.551 4.32624 11.1051 3.82755 10.6112C3.33364 10.1125 2.88769 9.58979 2.48969 9.04314C2.09649 8.49649 1.78001 7.94984 1.54984 7.40799C1.31967 6.86134 1.20459 6.33867 1.20459 5.83997C1.20459 5.5139 1.26213 5.20221 1.37722 4.9145C1.4923 4.622 1.67452 4.35347 1.92866 4.11371C2.23555 3.81161 2.57121 3.66296 2.92606 3.66296C3.06032 3.66296 3.19458 3.69173 3.31446 3.74928C3.43914 3.80682 3.54943 3.89313 3.63574 4.01781L4.74822 5.58583C4.83453 5.70571 4.89687 5.81599 4.94003 5.92149C4.98318 6.02219 5.00716 6.12289 5.00716 6.21399C5.00716 6.32908 4.97359 6.44416 4.90646 6.55445C4.84412 6.66474 4.75301 6.77982 4.63793 6.89491L4.2735 7.27373C4.22075 7.32647 4.19677 7.38881 4.19677 7.46553C4.19677 7.50389 4.20157 7.53746 4.21116 7.57582C4.22555 7.61418 4.23993 7.64295 4.24952 7.67172C4.33583 7.82996 4.48448 8.03616 4.69547 8.28551C4.91125 8.53485 5.14142 8.789 5.39077 9.04314C5.64971 9.29729 5.89906 9.53225 6.1532 9.74803C6.40255 9.95902 6.60874 10.1029 6.77178 10.1892C6.79576 10.1988 6.82453 10.2132 6.85809 10.2275C6.89645 10.2419 6.93482 10.2467 6.97797 10.2467C7.05949 10.2467 7.12183 10.218 7.17457 10.1652L7.53901 9.80557C7.65889 9.6857 7.77397 9.59459 7.88426 9.53704C7.99455 9.46991 8.10484 9.43635 8.22472 9.43635C8.31582 9.43635 8.41173 9.45553 8.51722 9.49868C8.62272 9.54184 8.733 9.60418 8.85288 9.6857L10.4401 10.8126C10.5648 10.8989 10.6511 10.9996 10.7038 11.1194C10.7518 11.2393 10.7805 11.3592 10.7805 11.4935Z"
        fill="currentColor"
      />
    </svg>
  );
};
