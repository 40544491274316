import React, { useMemo, useState, useEffect, memo, useContext } from "react";
import { groupBy, sortBy, uniqueId } from "lodash";
import BeatLoader from "react-spinners/BeatLoader";
import { useCallback } from "react";
import { individualRetailInvestorContext } from "common/context";
import AlphaContent from "./AlphaMode/AlphaContent";
import Post from "./Post/Post";
import FreeTextPost from "./FreeTextPost";
import LoadMore from "components/Buttons/LoadMore";
import { useDispatch, useSelector } from "react-redux";
import { setImpressionData } from "features/app/appSlice";
import {
  prepareArticlesToUpdateCounter,
  prepareStatsToDisplay,
  updateReduxStore,
} from "common/exportedFunctions";

// will handle display of data for feed mode and cube mode
//presence of props indicates that the component was rendered by CubeMode
const FeedItems = ({ feedItemsProps = null }) => {
  //store the contents to update redux store
  const [contentToUpdate, setContentsToUpdate] = useState([]);

  // keep track of whether to do redux update or not
  const [isUpdating, setIsUpdating] = useState(false);

  //an object that contains different lists of articles
  //based on certain properties
  const [temporaryArticlesLists, setTemporaryArticlesLists] = useState({
    visibleStockNamesList: [],
    visibleTopicsList: [],
    invisibleArticles: [],
  });

  //get data from redux store
  const { impressionData, allMountedArticles } = useSelector(
    (store) => store.app
  );

  //set default number of news articles to display
  const [numberOfContent, setNumberOfContent] = useState(15);

  //get values from context
  const { mode, feedData, partitionName } = useContext(
    individualRetailInvestorContext
  );

  //get dispatch function from redux
  const dispatch = useDispatch();

  // use feedItemsProps if available because it indicates the data is from cube mode
  // otherwise use feedData from  context
  const { searchResults, loading, postOrigin, error, message } =
    feedItemsProps?.feedState ?? feedData;

  //display alpha mode content when selected mode is alpha
  const getAlphaContent = () => {
    const groupedCompanies = groupBy(
      searchResults,
      ({ primary_concept: { id } }) => id
    );
    const alphaContents = Object.keys(groupedCompanies).map((stockId) => (
      <AlphaContent
        key={stockId}
        alphaComponentProps={{
          stockInfo: groupedCompanies[stockId],
          id: stockId,
        }}
      />
    ));
    return alphaContents;
  };

  // decide what to render based on post origin
  const content = useMemo(() => {
    // check post origing
    if (postOrigin === "posts") {
      //check mode whether normal or alpha
      if (mode === "alpha") return getAlphaContent();
      return sortBy(searchResults, ({ primary_concept: { name } }) => name).map(
        (result) => {
          const unqId = uniqueId("result");
          return <Post key={unqId} articleContent={result} />;
        }
      );
    } else {
      // return free text otherwise
      return sortBy(searchResults, ({ primary_concept: { name } }) => name).map(
        (result) => {
          const unqId = uniqueId("result");
          return <FreeTextPost key={unqId} articleContent={result} />;
        }
      );
    }
  }, [searchResults, mode, postOrigin]);

  //load more data handler
  const toggleLoadMore = useCallback(() => {
    setNumberOfContent((prevNumber) => prevNumber + 15);
  }, []);

  // set default number of news articles to display when mounting and unmounting component
  useEffect(() => {
    setNumberOfContent(15);
  }, [searchResults]);

  //set timout to 15 mins whenever contentToUpdate changes
  useEffect(() => {
    //store the timout id
    let timeout;
    //updates should only happen in second partition
    if (partitionName === "partitionTwo" && contentToUpdate.length > 0) {
      //set is updateing to true
      setIsUpdating(true);

      //set timout to 15 secs
      timeout = setTimeout(() => {
        setIsUpdating(false);
      }, 15000);
    }

    //cleanup logic
    return () => {
      //clear timeout
      clearTimeout(timeout);
    };
  }, [contentToUpdate]);

  //update impression data for each item isUpdating is true changes
  useEffect(() => {
    //store interval id
    let visibilityCounter;
    if (isUpdating && partitionName === "partitionTwo") {
      //update or stop time counter every one second depending on the action
      visibilityCounter = setInterval(() => {
        //call update redux store every one second
        updateReduxStore({
          impressionData,
          contentToUpdate,
          dispatch,
          setImpressionData,
        });
      }, 1000);
    }
    return () => {
      //clear interval
      clearInterval(visibilityCounter);
    };
  }, [isUpdating, contentToUpdate, impressionData]);

  //creates a single array of items whose impressions data need to be updated
  useEffect(() => {
    //get new list of contents to update
    const contents = prepareStatsToDisplay(temporaryArticlesLists);

    //update the local state
    setContentsToUpdate([...contents]);
  }, [temporaryArticlesLists]);

  //groups different lists which contain items satisfying certain conditions
  useEffect(() => {
    prepareArticlesToUpdateCounter(
      allMountedArticles,
      setTemporaryArticlesLists
    );
  }, [allMountedArticles]);

  //show content or errors & messages appropriately
  const results = content?.length ? (
    <div className="FeedItems w-full bg-inherit">
      <>
        <div className="w-full mb-4">{content.slice(0, numberOfContent)}</div>
        <div className="w-full text-center">
          {content.length > 15 && numberOfContent < content.length ? (
            <LoadMore loadMoreProps={{ toggleLoadMore, numberOfContent }} />
          ) : (
            <button
              onClick={toggleLoadMore}
              className="rounded-full  px-4 py-1.5 mb-2 bg-onahau dark:bg-turquoise text-black-russian text-customFontSix  font-semibold"
            >
              <a
                href="https://www.texelio.com/demo"
                target="_blank"
                rel="noreferrer"
              >
                Book a Demo
              </a>
            </button>
          )}
        </div>
      </>
    </div>
  ) : (
    <>
      {loading && (
        <div className="w-full text-center">
          <BeatLoader size={10} color="#40464F" />
        </div>
      )}
      {message && (
        <p className="w-full text-customFontThree text-center text-black dark:text-white">
          {message}
        </p>
      )}
      {error && (
        <p className="w-full text-customFontThree text-red-500 text-center">
          {error}
        </p>
      )}
    </>
  );
  return results;
};

export default memo(FeedItems);
