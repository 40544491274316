import React, { useState, useEffect, memo } from "react";
import { formatTime } from "./../../common/exportedFunctions";
import EditableTimeInput from "./EditableTimeInput";
const EditablePodcastSentence = ({
  editablePodcastSentenceProps,
  onStateChange,
}) => {
  const { sentence, startTime, endTime } = editablePodcastSentenceProps;
  const formattedStartTimeArr = formatTime(startTime);
  const formattedEndTimeArr = formatTime(endTime);
  const [editableText, setEditableText] = useState({
    "start-hours": formattedStartTimeArr[formattedStartTimeArr.length - 3] ?? 0,
    "start-minutes": formattedStartTimeArr[formattedStartTimeArr.length - 2],
    "start-seconds": formattedStartTimeArr[formattedStartTimeArr.length - 1],
    "end-hours": formattedEndTimeArr[formattedEndTimeArr.length - 3] ?? 0,
    "end-minutes": formattedEndTimeArr[formattedEndTimeArr.length - 2],
    "end-seconds": formattedEndTimeArr[formattedEndTimeArr.length - 1],
    text: sentence,
  });
  const handleChange = (e) => {
    setEditableText({ ...editableText, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    onStateChange(editableText);
  }, [onStateChange, editableText]);
  return (
    <div className="w-full grid grid-cols-editPodcastSentenceCol grid-rows-1 grid-flow-col gap-x-1 p-1 mt-2 bg-gray-200 ">
      <div className="font-semibold">
        <div className="py-0.5">
          <h2 className="text-center capitalize underline mb-0.5 ">Start</h2>
          <div className="grid auto-cols-auto grid-rows-1 grid-flow-col gap-x-0.5 items-start ">
            {["start-hours", "start-minutes", "start-seconds"].map(
              (timeName) => (
                <EditableTimeInput
                  key={timeName}
                  type="text"
                  pattern="\d*"
                  maxLength="2"
                  name={timeName}
                  onChange={handleChange}
                  value={editableText[timeName]}
                  className="outline-none max-w-timeWidth border border-black "
                />
              )
            )}
          </div>
        </div>
        <div className="py-0.5">
          <h2 className="text-center capitalize underline mb-0.5 ">End</h2>
          <div className="grid auto-cols-auto grid-rows-1 grid-flow-col gap-x-0.5 items-start  ">
            {["end-hours", "end-minutes", "end-seconds"].map((timeName) => (
              <EditableTimeInput
                key={timeName}
                type="text"
                pattern="\d*"
                maxLength="2"
                name={timeName}
                onChange={handleChange}
                value={editableText[timeName]}
                className="outline-none max-w-timeWidth border border-black "
              />
            ))}
          </div>
        </div>
      </div>
      <textarea
        rows="4"
        name="text"
        value={editableText.text}
        onChange={handleChange}
        className="border border-black"
      ></textarea>
    </div>
  );
};

export default memo(EditablePodcastSentence);
