import React, { useContext } from "react";
import { individualRetailInvestorContext } from "common/context";
import { clsx } from "clsx";

const VisualiticsCategories = () => {
  // get context data
  const { visualiticsData, individualRetailInvestorDispatch } = useContext(
    individualRetailInvestorContext
  );
  // create categories
  const categories = [
    { name: "companies", value: "company" },
    { name: "topics", value: "topic" },
    { name: "sectors", value: "sector" },
    { name: "sources", value: "source" },
  ];

  //update category value
  const changeCategory = (selectedCategoryName) => {
    individualRetailInvestorDispatch({
      type: "setVisualiticsData",
      payload: { ...visualiticsData, categoryName: selectedCategoryName },
    });
  };

  return (
    <div className="w-full text-black text-customFontSix font-semibold bg-linen dark:bg-rhino  rounded-full flex justify-between items-center py-0.5 px-1">
      {categories.map(({ name, value }) => (
        <button
          key={name}
          onClick={() => changeCategory(value)}
          className={clsx(
            "w-full text-center text-black rounded-full p-1 capitalize whitespace-nowrap",
            visualiticsData.categoryName === value
              ? "bg-onahau dark:bg-turquoise"
              : "dark:text-white"
          )}
        >
          {name}
        </button>
      ))}
    </div>
  );
};

export default VisualiticsCategories;
