import React, { useEffect, useState } from "react";
import NavBar from "components/NavBar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "components/Footer";
import LandingPage from "components/LandingPage";
import Content from "components/Content/Content";
import Login from "components/Login";
import ProtectedRoute from "components/ProtectedRoute";
import { appContext } from "common/context";
import { onAuthStateChanged, auth } from "common/firebase-app";
import Podcasts from "components/PodCasts/Podcasts";
import Register from "components/Register";
import RequestAccess from "components/AccessCode/RequestAccess";
import GrantAccess from "components/AccessCode/GrantAccess";
import PasswordReset from "components/PasswordReset";
import { useDispatch } from "react-redux";
import { setNumberOfCddVisits } from "features/app/appSlice";
function App() {
  //store current user state
  const [currentUser, setCurrentUser] = useState();

  //get redux update function
  const dispatch = useDispatch();

  const getCurrentLoggedUser = () => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        //save user uid to local storage and use it to store impression and engagment data later, into local storage
        localStorage.setItem("userUid", JSON.stringify(user.uid));

        //update number of cdd visits
        dispatch(setNumberOfCddVisits(1));

        //update currentUser
        setCurrentUser(user);
      } else {
        //remove user uid from local storage
        localStorage.removeItem("userUid");

        //update current user
        setCurrentUser(null);
      }
    });
  };

  //get current logged in user
  useEffect(() => {
    getCurrentLoggedUser();
  }, []);

  return (
    <Router>
      <appContext.Provider value={{ currentUser }}>
        <div className="w-full h-screen overflow-auto flex flex-col justify-between bg-midnight-blue font-poppins">
          <NavBar />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/resetpassword" element={<PasswordReset />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/content/*" element={<Content />} />
              <Route path="/podcasts" element={<Podcasts />} />
            </Route>
            <Route path="/requestaccess" element={<RequestAccess />} />
            <Route path="/grantaccess/:requestid" element={<GrantAccess />} />
          </Routes>
          <Footer />
        </div>
      </appContext.Provider>
    </Router>
  );
}

export default App;
