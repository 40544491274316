import React, { useState, useMemo, memo } from "react";

export default memo(Moods);

function Moods({ name }) {
  const [selectedTimeScale, setTimeScale] = useState("today");

  const heatmaps = useMemo(
    () => [
      { title: "Total", number: 825, cls: "bg-totalHeatMapBg" },
      { title: "News", number: 800, cls: "bg-newsHeatMapBg" },
      { title: "Press", number: 25, cls: "bg-pressHeatMapBg" },
    ],
    []
  );

  const timeButtons = useMemo(
    () => [
      { value: "today", title: "24h" },
      { value: "last week", title: "7d" },
      { value: "last month", title: "30d" },
    ],
    []
  );


  return (
    <div className="CompanyMoods">
      <h1 className="dark:text-white">Moods</h1>
      <div className="bg-inherit w-full py-1 text-black">
        <div className="w-full bg-linen dark:bg-rhino px-1 py-2">
          {heatmaps.map(({ title, cls, number }) => {
            return (
              <div key={title} className="w-full my-2">
                <div
                  className={`w-full h-6 bg-bottom bg-cover bg-no-repeat ${cls}`}
                ></div>
                <p className="my-0.5 text-customFontSix font-semibold dark:text-white">
                  {title}: {number}
                </p>
              </div>
            );
          })}
        </div>
        <div className="w-1/2 mx-auto my-2 rounded-full p-1 bg-linen dark:bg-rhino  font-semibold text-black-russian text-customFontSix grid grid-cols-3 grid-rows-1 items-center ">
          {timeButtons.map(({ title, value }) => (
            <button
              key={title}
              onClick={setTimeScale.bind(null, value)}
              className={`w-full rounded-full p-1.5 text-center ${
                selectedTimeScale === value
                  ? " bg-onahau dark:bg-turquoise dark:text-black "
                  : " dark:text-white"
              }`}
            >
              {title}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
