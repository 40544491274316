import CustomSelectButton from "components/Buttons/CustomSelectButton";
const skins = [
  {
    label: "Beige Skin",
    value: "beige",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "skins",
  },
  {
    label: "Dark Skin",
    value: "dark",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "skins",
  },
  {
    label: "White Skin",
    value: "white",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "skins",
  },
];

const SkinFilter = () => {
  return <CustomSelectButton options={skins} />;
};

export default SkinFilter;
