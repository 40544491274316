import { getHiddenSection, toggleSummary } from "common/api";
import { individualRetailInvestorContext } from "common/context";
import { useContext, useState } from "react";
import { bodyText, getUpdatedClickCounts } from "common/exportedFunctions";
import CustomButton from "components/Buttons/CustomButton";
import { clsx } from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { setEngagementData } from "features/app/appSlice";

export default function Section({
  section,
  partitionName,
  groupedTopics,
  hiddenArticleKey,
  companyName,
  companyId,
  releaseDate,
  documentType,
  sourceName,
}) {
  //store section segments
  const [segments, setSegments] = useState(section.segments);

  //set end_hidden to true/false
  const [endHidden, setEndHidden] = useState(section.end_hidden);

  //get engagment data from redux store
  const { engagementData } = useSelector((store) => store.app);

  //get context data
  const { individualRetailInvestorDispatch, translatedTopics, skin } =
    useContext(individualRetailInvestorContext);

  //get a dispatch function
  const dispatch = useDispatch();

  //get topic type
  const topicType = groupedTopics?.[section.topic_id]?.[0]?.type;

  //construct a topic name which agrees to translation
  const topicDisplayName =
    translatedTopics.filter((topic) => topic.topic_id === section.topic_id)?.[0]
      ?.name ?? groupedTopics?.[section.topic_id]?.[0]?.name;

  // create summary order
  const sectionSummaryOrder = section.section_orders[0];

  //update number of clicks to parent stock name and contained topic name of this section
  const updateClickCount = () => {
    //get updated engagement data

    const newUpdatedEngagementData = getUpdatedClickCounts({
      engagementData,
      companyName,
      topicDisplayName,
      topicType,
      documentType,
    });

    //update the engagement data on redux store
    dispatch(setEngagementData(newUpdatedEngagementData));
  };

  //handleSummary click
  const handleSummaryClick = async (summaryData) => {
    //show summary data
    await toggleSummary(summaryData);

    //record engagement data if the action was form partition2
    if (partitionName === "partitionTwo") {
      updateClickCount();
    }
  };

  //section click handler
  const clickHandler = async function SectionClickHandler() {
    //break out if the end is not hidden
    if (!endHidden) return;

    //get the hidden segments from api
    const endingSegments = await getHiddenSection(section.hidden_text_data);

    //concatnate the hidden segments to the existing ones
    const allSegments = segments.concat(...endingSegments);

    //update the segments
    setSegments(allSegments);

    //reset hidden to true so as to remove "see more"
    setEndHidden(false);

    //record engagement data if the action was form partition2
    if (partitionName === "partitionTwo") {
      updateClickCount();
    }
  };

  return (
    <div
      className={clsx(
        "dark:bg-aluminium p-1 my-1.5 text-black dark:text-white rounded-md ",
        skin === "white" ? "bg-white" : "bg-stark-white"
      )}
    >
      <div className="w-full grid grid-cols-rowDownCol gap-x-1 grid-rows-1 py-0.5 ">
        <p className="text-red-800 dark:text-red-500 font-bold text-customFontSix">
          {sectionSummaryOrder} {`${sectionSummaryOrder > 1 ? "rows" : "row"}`}{" "}
          down:
        </p>
        {topicDisplayName !== "NONE" &&
          topicDisplayName !== "Quote" &&
          topicDisplayName !== undefined && (
            <div className="w-full flex flex-col items-end space-y-1 ">
              <CustomButton
                customButtonProps={{
                  type: topicType,
                  text: topicDisplayName,
                  isClear: false,
                  category: "topics",
                }}
              />
              {section.has_summary && (
                <button
                  onClick={() => {
                    handleSummaryClick({
                      sectionSummaryOrder,
                      hiddenArticleKey,
                      individualRetailInvestorDispatch,
                      companyName,
                      releaseDate,
                      sourceName,
                    });
                  }}
                  className="underline  dark:text-white  font-bold  text-customFontSix mx-1"
                >
                  Summarize
                </button>
              )}
            </div>
          )}
      </div>
      <div className="w-full text-customFontNineteen mt-1">
        <p
          className={`w-full text-clip overflow-hidden  ${
            endHidden ? "cursor-pointer" : ""
          } ${skin === "white" && "bg-cream-white"}`}
          onClick={clickHandler}
        >
          {bodyText(segments, companyId)}
          {endHidden ? (
            <span className="text-gray-600 dark:text-gray-300">
              ... (see more)
            </span>
          ) : (
            ""
          )}
        </p>
      </div>
    </div>
  );
}
