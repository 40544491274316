import InvestorOwnerContainer from "components/CustomContainer/InvestorOwnerContainer";
import InvestorOwnerHeader from "components/CustomHeader/InvestorOwnerHeader";
import React from "react";
import ClearStats from "./ClearStats";
import ImpressionEngagementStats from "components/PlatformOwners/ImpressionEngagementStats/ImpressionEngagementStats";
import { memo } from "react";
export default memo(function PlatformOwners() {
  return (
    <InvestorOwnerContainer>
      <InvestorOwnerHeader
        title="For Platform Owners"
        otherItem={<ClearStats />}
      />
      <ImpressionEngagementStats />
    </InvestorOwnerContainer>
  );
});
