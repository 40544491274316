import React, { useCallback, useMemo, memo, useRef, useState } from "react";
import StatSectionContent from "./StatSectionContent";
import LoadMore from "components/Buttons/LoadMore";
import { prettyTime } from "common/exportedFunctions";

export default memo(function StatsSection({
  name,
  data,
  unitName,
  bgColor,
  textColor,
}) {
  //set initial number of section content to show
  const [numberOfContent, setNumberOfContent] = useState(6);

  //container ref for scrolling contents when load more is clicked
  const containerRef = useRef();

  //load more content and scroll downwards
  const toggleLoadMore = useCallback(() => {
    setNumberOfContent((prevNumber) => prevNumber + 10);
    containerRef.current.scrollBy(0, 20);
  }, []);

  //get the total of either impression time, number of clicks or  conversion,
  //depending on the unitName
  let total = 0;

  //get total impression time if the chart is for impression data
  if (unitName === "time") {
    total = Object.values(data).reduce(
      (totalTime, currentItem) =>
        totalTime +
          currentItem?.[
            name === "Stock Mentions"
              ? "stockImpressionTime"
              : "topicImpressionTime"
          ] ?? 0,
      0
    );
  }

  //get total number of clicks if the chart is for engagement data
  if (unitName === "clicks") {
    total = Object.values(data).reduce(
      (totalClicks, currentItem) =>
        totalClicks +
          currentItem?.[
            name === "Stock Mentions"
              ? "stockNumberClicks"
              : "topicNumberClicks"
          ] ?? 0,
      0
    );
  }

  //map the items to percentages of their actual time  clicks or conversion,
  // this is neccessary for sorting purposes from high to low
  const mappedData = useMemo(() => {
    //store the new mapped data
    let newMappedData;

    //handle items that will be shown in impression and engagement sections
    if (unitName !== "conversion") {
      //map the data and assign it to newMapped data
      newMappedData = Object.keys(data).map((item) => {
        //get this item's time or clicks
        const itemTimeOrClicks =
          unitName === "time"
            ? data[item]?.[
                name === "Stock Mentions"
                  ? "stockImpressionTime"
                  : "topicImpressionTime"
              ] ?? 0
            : data[item]?.[
                name === "Stock Mentions"
                  ? "stockNumberClicks"
                  : "topicNumberClicks"
              ] ?? 0;

        //calculate the percentage time for each item
        const percentage = (itemTimeOrClicks / total) * 100;

        //return item with its percentage value
        return { item, percentage };
      });
    } else {
      //handle items that will be shown in conversion section
      //get impression and engagement data for this section
      const { engagement, impression } = data;

      //stock name or topic name in engagement, is definitely available  in impression.
      //viceversa is false because it's possible not mandatory to click an article when it appears on the screen
      //impressions however, are recorded whenever an article appears on the screen
      //therefore our basis of creating conversion data relies on the items present in the impression
      newMappedData = Object.keys(impression).map((item) => {
        //get this items impression time
        //default to 1 if there's no impression time
        const itemTime =
          impression[item]?.[
            name === "Stock Mentions"
              ? "stockImpressionTime"
              : "topicImpressionTime"
          ] || 1; // default to 1 to prevent division by zero or undefined

        //get this items number of clicks
        //default to 0 if there's no clicks time
        const itemClicks =
          engagement[item]?.[
            name === "Stock Mentions"
              ? "stockNumberClicks"
              : "topicNumberClicks"
          ] ?? 0;

        //calculate the percentage conversion
        const itemConversionPercentage = (itemClicks / itemTime) * 100;

        //update overall total for conversion
        total += Number(itemConversionPercentage);

        //return item with it's percentage conversion
        return {
          item,
          itemConversionPercentage,
        };
      });
    }

    //return the mapped data
    return newMappedData;
  }, [data]);

  //generate content to be shown i.e impression time , or number of clicks or conversion for each item
  //map through data and return section content
  const sectionContents = useMemo(
    () =>
      mappedData
        .sort((a, b) => {
          //construct width percentages for items in conversion
          const firstItemPercentage =
            a?.percentage ?? (a.itemConversionPercentage / total) * 100;
          const secondItemPercentage =
            b?.percentage ?? (b.itemConversionPercentage / total) * 100;
          return secondItemPercentage - firstItemPercentage;
        })
        .filter(
          ({ percentage = null, itemConversionPercentage = null }) =>
            percentage > 0 || itemConversionPercentage > 0
        )
        .map(({ item, percentage = null, itemConversionPercentage = null }) => (
          <StatSectionContent
            key={item}
            name={item}
            content={
              unitName === "time" //logic for impression area
                ? name === "Stock Mentions"
                  ? data[item].stockImpressionTime
                  : data[item].topicImpressionTime
                : unitName === "clicks" //logic for engagment area
                ? name === "Stock Mentions"
                  ? data[item].stockNumberClicks
                  : data[item].topicNumberClicks
                : itemConversionPercentage //logic for conversion
            }
            percentage={
              unitName !== "conversion"
                ? percentage
                : (itemConversionPercentage / total) * 100
            } //percentage will be used for determing the width size of content bar
            unitName={unitName}
          />
        )),
    [mappedData]
  );

  //include load more button logic when loading content
  const slicedSectionContents = sectionContents.length ? (
    <div className="w-full">
      <div
        ref={containerRef}
        className="w-full max-h-44 overflow-auto  my-2 px-2"
      >
        {sectionContents.slice(0, numberOfContent)}
      </div>
      {sectionContents.length > 6 &&
        numberOfContent < sectionContents.length && (
          <LoadMore loadMoreProps={{ toggleLoadMore, isFromCharts: true }} />
        )}
    </div>
  ) : (
    <p className="w-full my-3 italic text-sm text-slate-400 capitalize ">
      Data populated after interaction with feed.
    </p>
  );

  //convert to seconds if unitName is 'time', otherwise returns a value as is
  const formattedTotal = prettyTime(unitName, total);

  //return the content
  return (
    <div className="w-full py-5 ">
      <div
        style={{ backgroundColor: `${bgColor}`, color: `${textColor}` }}
        className="px-2 py-2.5 w-full flex items-center justify-between capitalize font-bold text-sm rounded-md"
      >
        <h1>{name}</h1>
        <div className="grid grid-cols-statCol grid-rows-1 items-center grid-flow-col gap-x-4 capitalize">
          <span className="capitalize">
            {unitName !== "conversion" && "Total"} {unitName}
          </span>
          <span className="text-xs w-12 py-2 px-3 bg-white rounded-full flex items-center justify-center">
            {unitName === "conversion"
              ? `${formattedTotal.toFixed(1)}%`
              : formattedTotal}
          </span>
        </div>
      </div>
      {slicedSectionContents}
    </div>
  );
});
