import React, { useContext, useEffect, useState } from "react";
import { groupBy, get, find } from "lodash";
import CustomButton from "components/Buttons/CustomButton";
import { individualRetailInvestorContext } from "common/context";
const LatestSelectedItems = () => {
  //temporalry store a list of 'latest' selected items
  const [data, setData] = useState([]);

  // get content from context
  const { editListContent, translatedTopics } = useContext(
    individualRetailInvestorContext
  );

  //handle selected 'latest' items and return custom buttoms for each button
  const handleSelectedData = () => {
    // create an index
    let index = 0;

    //group items by their categories
    const itemsByCategories = groupBy(editListContent["latest"], (element) =>
      get(element, "category", "keywords")
    );

    // create array of categories, sort theem and map each  category to return  values(items) through a custom button element
    const itemsToCustomButtons = Object.keys(itemsByCategories)
      .sort()
      .map((category) => {
        //return null if that category has no items
        const items = itemsByCategories[category];

        if (!items.length) return null;

        // return the category title and items as custom buttons
        return (
          <div key={category} className="LatestSelectedItems w-full mb-2  ">
            <h1 className="capitalize text-customFontTwo font-semibold text-black-russian dark:text-white my-1">
              {category}:
            </h1>
            <div className="flex items-center flex-wrap mx-0.5">
              {items.map((item) => {
                //destructure the properties of item
                const { name, type, category, id } = item;

                // get translated topic names
                let textName = name;
                if (category === "topics") {
                  //find for that topic from translated topics
                  const topic = find(
                    translatedTopics,
                    (topic) => topic.topic_id === id
                  );

                  // update the topic name or back to default if undefined
                  textName = topic?.name ?? textName;
                }

                //create a custom button

                const itemButton = (
                  <CustomButton
                    key={index}
                    customButtonProps={{
                      text: textName,
                      type,
                      category,
                      id,
                      isClear: true, // indicates that this item can be removed from the list
                    }}
                  />
                );

                //increment index
                index += 1;

                //return the itemButton
                return itemButton;
              })}
            </div>
          </div>
        );
      });

    //update local state to contain the items
    setData(itemsToCustomButtons);
  };

  //fetch 'latest' selected data whenever they are added or removed in the list
  useEffect(() => {
    handleSelectedData();
  }, [editListContent["latest"]]);

  // hide this component if there are no selected items
  if (!data.length) return null;

  //otherwise, return data
  return (
    <div className="w-full bg-inherit max-h-28 mb-1 overflow-auto scrollbar-hide ">
      {data}
    </div>
  );
};

export default LatestSelectedItems;
