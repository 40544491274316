import { prettyTime } from "common/exportedFunctions";

export default function StatSectionContent({
  name,
  content,
  percentage,
  unitName,
}) {
  //get impression time, number of clicks or conversion
  const data = prettyTime(unitName, content);

  //return the content section
  return (
    <div className="w-full grid grid-cols-individualBarChartCol grid-rows-1 grid-flow-col gap-x-2 my-1.5 items-center text-sm">
      <h2 className="capitalize w-28 truncate">{name}</h2>
      <div className="w-full">
        <div
          className="h-2 rounded-full bg-blue-500"
          style={{ width: `${percentage}%` }}
        />
      </div>
      <div className="w-10 lg:w-20 text-end text-gray-500">
        {unitName === "conversion" ? `${data.toFixed(1)}%` : data}
      </div>
    </div>
  );
}
