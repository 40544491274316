import CustomSelectButton from "components/Buttons/CustomSelectButton";
const sections = [
  {
    label: "All Sections",
    value: "all",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "section",
  },
  {
    label: "Title",
    value: "title",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "section",
  },
  {
    label: "Preface",
    value: "preface",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "section",
  },
  {
    label: "Body",
    value: "body",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "section",
  },
  {
    label: "About",
    value: "about",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "section",
  },
];

const SectionsFilter = () => {
  return <CustomSelectButton options={sections} />;
};

export default SectionsFilter;
