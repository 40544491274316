import React, { useEffect, useReducer } from "react";
import { contentContext } from "common/context";
import RetailInvestors from "../RetailInvestors/RetailInvestors";
import PlatformOwners from "../PlatformOwners/PlatformOwners";
import { useNavigate } from "react-router-dom";
const initialState = {
  generatedPersona: {},
  currentLayout: "mobile",
};
const reducer = (state, { type, payload }) => {
  switch (type) {
    case "setGeneratedPersona": {
      return { ...state, generatedPersona: payload };
    }
    case "setCurrentLayout": {
      return { ...state, currentLayout: payload };
    }
    default:
      return { ...state };
  }
};
const Content = () => {
  //get state data
  const [{ currentLayout, generatedPersona }, contentDispatch] = useReducer(
    reducer,
    initialState
  );

  let navigate = useNavigate();
  // show feed mode by default
  useEffect(() => {
    navigate("/content/feed-mode");
  }, []);
  return (
    <contentContext.Provider
      value={{
        currentLayout,
        generatedPersona,
        contentDispatch,
      }}
    >
      <div className="w-full min-h-screen bg-alice-blue pt-28 pb-22px">
      <div className="max-w-screen-3xl mx-auto  ">
        <div className="w-full grid grid-cols-contentCols grid-rows-1 grid-flow-col gap-x-5 px-6 pb-6 ">
          <RetailInvestors />
          <PlatformOwners />
        </div>
      </div></div>
    </contentContext.Provider>
  );
};

export default Content;
