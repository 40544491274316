import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  memo,
  useRef,
  useCallback,
} from "react";
import { individualRetailInvestorContext } from "common/context";
import {
  getNewEventSectionsList,
  turnOffSearchBar,
} from "common/exportedFunctions";
import NewEventEditArea from "./NewEventEditArea";
import EditListContentArea from "./EditListContentArea";
import SearchBar from "../SearchBar/SearchBar";

const EditList = React.forwardRef(({ content: listName }, listRefs) => {
  //store different sections of an event when creating it
  const [newEventSections, setNewEventSections] = useState([]);

  //get context data
  const { editListContent, individualRetailInvestorDispatch } = useContext(
    individualRetailInvestorContext
  );

  // abort controller to cancel http request
  const abortControllerRef = useRef(null);

  // handle new event sections as they are added
  const updateNewEventSections = (selected) => {
    // call an updater function
    const newEventSectionsList = getNewEventSectionsList({
      newSection: selected,
      allSectionsList: newEventSections,
    });

    // update the list
    setNewEventSections(() => [...newEventSectionsList]);
  };

  // handle addition of the new event
  // event can either be event_1 or event_2
  const addNewEvent = () => {
    //create a copy of event_1
    const firstEventCopy = editListContent["event_1"];

    // check if first event empty is empty
    const firstEventCopyIsEmpty = !firstEventCopy.length;

    //always add new event to event_1 if empty otherwise add to event_2
    //This is because our app only allows a maximum of two events
    const eventContainerKey = firstEventCopyIsEmpty ? "event_1" : "event_2";

    //update state
    individualRetailInvestorDispatch({
      type: "setEditListContent",
      payload: {
        ...editListContent,
        [eventContainerKey]: [...newEventSections],
      },
    });

    //reset newEventSections
    setNewEventSections([]);
  };

  // handle deletion of an event section when creating an event
  const removeNewEventSection = useCallback(
    (id) => {
      //create a copy of sections as they should be updated immutably
      const newEventsSectionCopy = [...newEventSections];

      //filter out the deleted section by id
      const filteredNewEventSections = newEventsSectionCopy.filter(
        (event) => event.id !== id
      );

      //update the sections list to contain the filtered list
      setNewEventSections(filteredNewEventSections);
    },
    [newEventSections]
  );

  //cancel http request whenever a component unmounts
  useEffect(() => {
    const abortControllerRefCopy = abortControllerRef;
    return () => abortControllerRefCopy.current?.abort();
  }, []);

  // variable to disable search bar
  const disableSearchBar = useMemo(
    () =>
      turnOffSearchBar({
        listName,
        content: editListContent,
        eventSections: newEventSections,
      }),
    [editListContent, newEventSections]
  );

  // search bar props
  const searchBarProps = {
    abortControllerRef,
    allowNew: false,
    listName,
    updateNewEventSections,
    disableSearchBar,
  };
  return (
    <div
      ref={(div) => (listRefs.current[listName] = div)}
      className="w-full p-1 bg-inherit"
    >
      <div className="w-full bg-linen rounded-full">
        <SearchBar {...searchBarProps} />
      </div>
      <NewEventEditArea
        newEventEditAreaProps={{
          removeNewEventSection,
          newEventSections,
          addNewEvent,
        }}
      />
      <EditListContentArea editListContentProps={{ listName }} />
    </div>
  );
});

export default memo(EditList);
