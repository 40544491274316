import CustomSelectButton from "components/Buttons/CustomSelectButton";
const topics = [
  {
    label: "All Topics",
    value: "all",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "topics",
  },
  {
    label: "Finance",
    value: "finance",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "topics",
  },
  {
    label: "ESG",
    value: "esg",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "topics",
  },
  {
    label: "Strategy",
    value: "strategy",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "topics",
    disabled: true,
  },
  {
    label: "Law",
    value: "law",
    icon: "none",
    color: "text-black-russian dark:text-white",
    category: "topics",
    disabled: true,
  },
];

const TopicsFilter = () => {
  return <CustomSelectButton options={topics} />;
};

export default TopicsFilter;
