import { groupBy } from "lodash";
import React, {
  memo,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { searchCompanyInfo } from "common/api";
import BeatLoader from "react-spinners/BeatLoader";
import LoadMore from "components/Buttons/LoadMore";
import AlphaContent from "components/RetailInvestors/FeedItems/AlphaMode/AlphaContent";
import { individualRetailInvestorContext } from "common/context";

export default memo(CompanyOverview);

function CompanyOverview({ ...companyInfoData }) {
  //store company info
  const [apiCompanyInfo, setApiCompanyInfo] = useState({
    searchResults: [],
    loading: true,
    message: "",
    error: "",
  });

  //set initial number of contents shown
  const [numberOfContent, setNumberOfContent] = useState(10);

  // store abort controller
  const abortControllerRef = useRef(null);

  //get context data
  const { language, mood } = useContext(individualRetailInvestorContext);

  //call api endpoint to get company info
  const updateContent = async () => {
    //create language array based on selected language
    const languageArr = language === "all" ? [] : [`${language}`];

    //modify request body data
    const modifiedRequestBodyData = {
      topicIds: [],
      companyIds: [],
      indexIds: [],
      sectorCodes: [],
      languages: languageArr,
      sentiment: `${mood}`,
      limit: 25,
      freeTextQuery: "",
      includeSectionOrders: true,
      checkHasSummary: true,
      matchingTypes: [],
    };

    //call the api
    await searchCompanyInfo(
      modifiedRequestBodyData,
      companyInfoData,
      abortControllerRef,
      apiCompanyInfo,
      setApiCompanyInfo
    );
  };

  //get contents
  useEffect(() => {
    const abortControllerRefCopy = abortControllerRef;
    updateContent();
    return () => abortControllerRefCopy.current?.abort();
  }, []);

  //destructure state values
  const { searchResults, error, message, loading } = apiCompanyInfo;

  //handle load more button
  const toggleLoadMore = useCallback(() => {
    //add 10 items when button is clicked
    setNumberOfContent((prevNumber) => prevNumber + 10);
  }, []);

  // create the content
  const content = useMemo(
    function getAlphaContent() {
      //group companies by id
      const groupedCompanies = groupBy(
        searchResults,
        ({ primary_concept: { id } }) => id
      );
      return Object.entries(groupedCompanies).map(([id, stockInfo]) => (
        <AlphaContent
          key={id || Math.floor(Math.random() * 100000)} //palun: magically wasn't always unique before... maybe because of empty stuff
          alphaComponentProps={{ stockInfo, id }}
        />
      ));
    },
    [searchResults]
  );

  if (content?.length) {
    //If there's content to show...
    const BookDemoOrLoadMoreBtn =
      content.length > 10 && numberOfContent < content.length ? (
        <LoadMore loadMoreProps={{ toggleLoadMore, numberOfContent }} />
      ) : (
        <button className="rounded-full  px-4 py-1.5 mb-2 bg-onahau dark:bg-turquoise text-black-russian text-customFontSix  font-semibold">
          <a
            href="https://www.texelio.com/demo"
            target="_blank"
            rel="noreferrer"
          >
            Book a Demo
          </a>
        </button>
      );
    return (
      <div className="CompanyOverview">
        <div className="w-full bg-inherit">
          <div className="w-full mb-4">{content.slice(0, numberOfContent)}</div>
          <div className="w-full text-center">{BookDemoOrLoadMoreBtn}</div>
        </div>
      </div>
    );
  } else {
    //if there's no content to show
    return (
      <div className="CompanyOverview">
        {loading && (
          <div className="w-full text-center">
            <BeatLoader size={10} color="#40464F" />
          </div>
        )}
        {message && (
          <p className="w-full text-customFontSix text-center text-black dark:text-white">
            {message}
          </p>
        )}
        {error && (
          <p className="w-full text-customFontSix text-red-500 text-center">
            {error}
          </p>
        )}
      </div>
    );
  }
}
