import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { clsx } from "clsx";
import { useSelector } from "react-redux";
import { translatedSectionNames } from "common/externalData";
import { useContext, useMemo } from "react";
import { individualRetailInvestorContext } from "common/context";

// contains buttons responsible for changing the current view
export default function ViewControl({ viewControlProps }) {
  // destructure view control props
  const { updateViewHandler, currentView, showPrevIcon, showNextIcon } =
    viewControlProps;

  // get redux state
  const { globalLanguage } = useSelector(({ app }) => app);

  //get context data
  const { showPreviousIconInCubeMode } = useContext(
    individualRetailInvestorContext
  );

  //This logic is for cube mode
  // hide previous button when showPreviousIconInCubeMode is false
  const shouldShowPreviousIconInCubeMode = useMemo(
    () => showPreviousIconInCubeMode,
    [showPreviousIconInCubeMode]
  );

  // go to previous / next view from current one
  const changeView = (direction) => {
    // call update function from parent to update the current view
    updateViewHandler(direction);
  };

  // translate the name if need be
  let realName =
    globalLanguage === "en" ? currentView : translatedSectionNames[currentView];

  // format the name for events to show event number
  const currentViewName = realName.split("_").join(" ");

  return (
    <div className="w-4/5 font-semibold mx-auto flex items-center justify-between space-x-1 text-customFontOne text-black-russian dark:text-white">
      <button
        onClick={() => changeView("previous")}
        className={clsx(
          (!showPrevIcon || !shouldShowPreviousIconInCubeMode) && "invisible"
        )}
      >
        <AiOutlineLeft className="text-base  hover:scale-110" />
      </button>
      <p className="uppercase  text-center">{currentViewName}</p>
      <button
        onClick={() => changeView("next")}
        className={clsx(!showNextIcon && "invisible")}
      >
        <AiOutlineRight className="text-base   hover:scale-110" />
      </button>
    </div>
  );
}
