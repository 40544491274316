import React, { useMemo, memo } from "react";
import { useLocation } from "react-router-dom";
import FeedMode from "./FeedMode/FeedMode";
import CubeMode from "./CubeMode/CubeMode";

const MainContent = React.forwardRef((props, trackVisibilityAreaRef) => {
  // get current mode ( feed mode or cube mode) from location
  let location = useLocation();
  const currentMode = location.pathname.split("/").pop();

  // show appropriate component < FeedMode or CubeMode>
  const mainContent = useMemo(
    () => (currentMode === "feed-mode" ? <FeedMode /> : <CubeMode />),
    [currentMode]
  );

  return (
    <main
      ref={trackVisibilityAreaRef}
      id="PhoneMainContent"
      className="w-full h-full overflow-auto scrollbar-hide "
    >
      {mainContent}
    </main>
  );
});

export default memo(MainContent);
