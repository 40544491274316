import React, { useContext, useEffect, useState } from "react";
import { individualRetailInvestorContext } from "common/context";
import CustomButton from "components/Buttons/CustomButton";
const OtherViewsSelectedKeywords = () => {
  //This component will only show selected keyword
  //other selections are shown in editList component

  //temporalry store a list of  selected items
  const [data, setData] = useState([]);

  // get content from context
  const { editListContent } = useContext(individualRetailInvestorContext);

  //handle keyword
  const handleSelectedData = async () => {
    //get the selected data
    const keywordArray = editListContent["keywords"];
    //update the local state
    setData(keywordArray);
  };

  //fetch 'keyword' selected data whenever they are added or removed in the list
  useEffect(() => {
    handleSelectedData();
  }, [editListContent["keywords"]]);

  // hide this component if there are no selected item
  if (!data.length) return null;

  //get the keyword object
  const keyword = data[0];
  // destructure keyword
  const { name, id } = keyword;

  return (
    <div className="w-full mb-1 bg-inherit flex items-center space-x-1">
      <h1 className="capitalize text-customFontTwo font-semibold text-black-russian dark:text-white my-1">
        keywords:
      </h1>
      <div>
        <CustomButton
          customButtonProps={{
            text: name,
            id,
            isClear: true, // indicates that this item can be removed from the list
          }}
        />
      </div>
    </div>
  );
};

export default OtherViewsSelectedKeywords;
