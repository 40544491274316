import React from "react";

export default function RightArrow({ unitName }) {
  if (unitName === "conversion") return null;
  return (
    <div className="hidden absolute inset-y-0 -right-[94px] bg-transparent 3xl:flex flex-col items-center justify-center">
      <div className="w-0 h-0 border-t-[40px] border-b-[40px] border-r-[40px] border-l-[40px] border-transparent border-l-midnight-blue" />
    </div>
  );
}
