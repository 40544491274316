import { auth, sendPasswordResetEmail } from "common/firebase-app";
import React, { useState } from "react";
import isEmail from "validator/lib/isEmail";
import { useNavigate } from "../../node_modules/react-router-dom/index";
const PasswordReset = () => {
  const [data, setData] = useState({
    email: "",
    emailError: "",
    loading: false,
    error: "",
    message: "",
  });
  const navigate = useNavigate();
  const handleChange = (e) => {
    setData({ ...data, email: e.target.value, emailError: "", error: "" });
  };
  const handleCancel = () => {
    navigate("/login");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmail(email)) {
      try {
        setData({
          ...data,
          loading: true,
          error: "",
          message: "Finding email...",
          emailError: "",
        });
        await sendPasswordResetEmail(auth, email, {
          url: "https://texelio-cdd.web.app/login",
        });
        setData({
          ...data,
          loading: false,
          error: "",
          message: "Password reset email sent!",
          emailError: "",
        });
      } catch (error) {
        setData({
          ...data,
          loading: false,
          error:
            error.code === "auth/user-not-found"
              ? "Email not associated with CDD"
              : "Something went wrong.",
          message: "",
          emailError: "",
        });
      }
    } else {
      setData({
        ...data,
        emailError: "Enter valid email address.",
        error: "",
        message: "",
        loading: false,
      });
    }
  };
  const { email, error, message, emailError } = data;
  return (
    <div className="w-full h-screen flex flex-col items-center justify-center text-white px-2">
      <h1 className="capitalize font-bold text-customFontThirteen my-1 whitespace-nowrap">
        Reset Your Password
      </h1>
      <div className="my-2 md:w-1/2 mx-auto lg:w-3/12 ">
        <form onSubmit={handleSubmit}>
          <div className="py-1">
            <input
              name="email"
              type="email"
              value={email}
              onChange={handleChange}
              placeholder="Enter email"
              className="w-full outline-none p-4 my-2 rounded border border-solitude bg-white text-customFontFourteen text-black"
            ></input>
            {emailError && (
              <p className="text-red-500 text-sm w-full">{emailError}</p>
            )}
          </div>
          <div className="w-full flex items-center justify-between my-4 ">
            <button
              className="md:text-lg text-white rounded-full py-2 px-6   bg-slate-600 cursor-pointer hover:bg-slate-500"
              onClick={(e) => {
                e.preventDefault();
                handleCancel();
              }}
            >
              Cancel
            </button>
            <button
              className="md:text-lg text-white rounded-full font-semibold  py-2 px-6  bg-summer-sky cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
              type="submit"
            >
              Send link
            </button>
          </div>
        </form>
        {error && <p className="my-2 text-center text-red-500">{error}</p>}
        {message && (
          <p className="my-2 text-center text-green-500">{message}</p>
        )}
      </div>
    </div>
  );
};

export default PasswordReset;
