import React, { useMemo } from "react";

export default function PieChartKey({ chartData, unitName }) {
  //create the data key
  const keyInfo = useMemo(() =>
    chartData.map(
      ({ name, color, value }) => {
        return (
          <li key={name} className="flex items-center space-x-1">
            <div
              className="w-3 h-3 rounded-full "
              style={{ backgroundColor: `${color}` }}
            />
            <p className="capitalize text-sm flex gap-0.5 whitespace-nowrap">
              {unitName === "conversion" && <span>{value.toFixed(1)}%</span>}
              <span></span>
              {name}
            </p>
          </li>
        );
      },
      [chartData]
    )
  );

  //hide this component if there's no chartData
  if (!chartData.length) return null;

  //return key description
  return <ul className="w-full flex flex-col space-y-2">{keyInfo}</ul>;
}
