import { uploadPodcastFirebase } from "common/api";
import { podcastContext } from "common/context";
import React, { useCallback, useContext, memo, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { AiFillFolderOpen } from "react-icons/ai";
const Dropzone = ({ dropzoneProps: { progressBarsRef } }) => {
  const { podcasts, podcastDispatch } = useContext(podcastContext);
  const abortControllerRef = useRef(null);
  const uploadPodcast = async (file) => {
    // don't upload podcast if its already contained in podcasts object
    const allPodcasts = { ...podcasts };
    if (file.name in allPodcasts) return;
    // create podcast object , key is the name and value is object of various properties for this particular podcast
    // Here isUploading set to true will be used to reduce opacity in the list as it uploads
    const updatedPodcasts = {
      ...allPodcasts,
      [file.name]: {
        audio_url: null, // this will be updated later to download url
        name: file.name,
        isPodcastUploading: true, // will be updated later when upload completes
      },
    };
    // update local podcasts object
    podcastDispatch({ type: "setPodcasts", payload: { ...updatedPodcasts } });
    // upload podcast to firebase
    await uploadPodcastFirebase({
      file,
      progressBarsRef,
      podcastDispatch,
      podcasts,
      abortControllerRef,
    });
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        uploadPodcast(file);
      });
    },
    [podcasts]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "audio/mp3": [".mp3"] },
    maxFiles: 1,
  });
  useEffect(() => {
    const abortControllerRefCopy = abortControllerRef;
    return () => abortControllerRefCopy.current?.abort();
  }, []);
  return (
    <>
      <div
        {...getRootProps({
          className: `my-4 p-2 w-full h-48 border rounded-md text-center${
            isDragActive
              ? "border-solid border-cyan-500"
              : "border-dashed border-green-500 "
          }`,
        })}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className="w-full text-center capitalize text-customFontFourteen">
            Drop the podcasts here ...
          </p>
        ) : (
          <div className="w-full text-center  text-customFontFourteen">
            <AiFillFolderOpen className="text-4xl my-2 mx-auto" />
            <p className="w-full my-2 ">
              Drag 'n' Drop one Podcast (.mp3) at a time to start uploading.
            </p>
            <p className="my-2 w-full font-semibold">OR</p>
            <button className="rounded-full py-2 px-2.5 bg-midnight-blue my-2 text-white cursor-pointer capitalize">
              Browse Podcasts
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(Dropzone);
