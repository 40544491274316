import React from "react";

export default function InvestorOwnerHeader({ title, otherItem }) {
  return (
    <div className="w-full flex items-center justify-between border-b border-b-whisper-shade h-16 px-5 text-black">
      <h1 className="capitalize text-xl font-bold">{title}</h1>
      <div>{otherItem}</div>
    </div>
  );
}
