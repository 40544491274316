import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AiOutlineClose } from "react-icons/ai";
import { getIndividualEarningCall } from "common/api";
import BeatLoader from "react-spinners/BeatLoader";
import AudioPlayer from "components/AudioPlayer/AudioPlayerWrapper";
import Transcript from "components/AudioPlayer/Transcript";
import { individualRetailInvestorContext } from "common/context";
import {
  updateImpressionDataEarningCallOrPodcast,
  updateReduxStore,
} from "common/exportedFunctions";
import { useDispatch, useSelector } from "react-redux";
import { setImpressionData } from "features/app/appSlice";
import { isEmpty } from "lodash";

const IndividualEarningCall = ({ individualEarningCallProps }) => {
  //store the contents to update redux store
  const [contentToUpdate, setContentsToUpdate] = useState([]);

  // keep track of whether to do redux update or not
  const [isUpdating, setIsUpdating] = useState(false);

  // get the prop values
  const {
    individualEarningCall: {
      earningCall: { id: earningCallId },
    },
    earningCallDispatch,
  } = individualEarningCallProps;

  //set audio player state
  const [isPlaying, setIsPlaying] = useState(false);

  //store q&a start time of the transcript
  const [questionAnswerStartTime, setQuestionAnswerStartTime] = useState(null);

  //keep earnings call state
  const [data, setData] = useState({
    earningCall: {},
    loading: false,
    message: "",
    error: "",
  });

  //get values from data state
  const { earningCall, loading, message, error } = data;

  //get the name of the partition rendering this component
  const { partitionName } = useContext(individualRetailInvestorContext);

  //get data from redux store
  const { impressionData } = useSelector((store) => store.app);

  //store audio player node element
  const audioPlayerRef = useRef();

  //store abort controller for cancelling http requests
  const abortControllerRef = useRef(null);

  //get dispatch function from redux
  const dispatch = useDispatch();

  //jump to q&a section of the transcript
  const jumpToQuestionAndAnswer = useCallback(() => {
    //update current time
    if (questionAnswerStartTime) {
      audioPlayerRef.current.audio.current.currentTime =
        questionAnswerStartTime;
      audioPlayerRef.current.audio.current.play();
      setIsPlaying(true);
    }
  }, [questionAnswerStartTime]);

  //hide earning call upon closing
  const toggleIndidualEarningsCall = () => {
    earningCallDispatch({
      type: "setIndividualEarningCall",
      payload: { earningCall: "", showEarningCall: false },
    });
  };

  //get the contents of earning call from the backend, such as audio url etc,
  //through an id
  const getEarningCall = () => {
    //get the earning call and update state.
    // state is updated within the function below
    getIndividualEarningCall({
      data,
      setData,
      abortControllerRef,
      earningCallId,
    });
  };

  //get the earning call through an id upon landing
  useEffect(() => {
    const abortControllerRefCopy = abortControllerRef;
    getEarningCall();
    return () => abortControllerRefCopy.current?.abort();
  }, [earningCallId]);

  //set timout to 15 mins whenever contentToUpdate changes
  useEffect(() => {
    //store the timout id
    let timeout;
    //updates should only happen in second partition
    if (partitionName === "partitionTwo" && contentToUpdate.length > 0) {
      //set is updateing to true
      setIsUpdating(true);

      //set timout to 15 secs
      timeout = setTimeout(() => {
        setIsUpdating(false);
      }, 15000);
    }

    //cleanup logic
    return () => {
      //clear timeout
      clearTimeout(timeout);
    };
  }, [contentToUpdate]);

  //update impression data for each item isUpdating is true changes
  useEffect(() => {
    //store interval id
    let visibilityCounter;
    if (isUpdating && partitionName === "partitionTwo") {
      //update or stop time counter every one second depending on the action
      visibilityCounter = setInterval(() => {
        //call update redux store every one second
        updateReduxStore({
          impressionData,
          contentToUpdate,
          dispatch,
          setImpressionData,
        });
      }, 1000);
    }
    return () => {
      //clear interval
      clearInterval(visibilityCounter);
    };
  }, [isUpdating, contentToUpdate, impressionData]);

  //record impression data for this earning call whenever it's available
  useEffect(() => {
    if (!isEmpty(earningCall) && partitionName === "partitionTwo") {
      //get new list of contents to update
      const contents = updateImpressionDataEarningCallOrPodcast({
        partitionName,
        contentToUpdate,
        earningCallOrPodcast: earningCall,
      });
      //update the local state
      setContentsToUpdate([...contents]);
    }
  }, [earningCall]);

  //handlet topics after looping through transcript
  const addNewTopicsForImpressionTime = useCallback(
    (topicsWithDetails) => {
      if (!isEmpty(earningCall) && partitionName === "partitionTwo") {
        //update it's impression time
        const contents = updateImpressionDataEarningCallOrPodcast({
          partitionName,
          topicsWithDetails,
          contentToUpdate,
          earningCallOrPodcast: earningCall,
        });

        //update the local state
        setContentsToUpdate([...contents]);
      }
    },
    [earningCall]
  );

  //create an array of earning call properties
  const content = useMemo(() => {
    return Object.keys(earningCall);
  }, [earningCall]);

  //construct audio player and transcript contents from the earnings calls
  const renderedData = content?.length ? (
    <div className="w-full h-full ">
      <div className="sticky top-0">
        <button
          onClick={toggleIndidualEarningsCall}
          className="w-full flex justify-end mt-4 mb-1 "
        >
          <AiOutlineClose
            size={16}
            className="text-black dark:text-white  font-bold hover:scale-125"
          />
        </button>
        <div className="w-full bg-linen dark:bg-rhino rounded-md my-1 text-customFontTwo pt-1 px-1 ">
          <h1 className="w-full my-1 underline underline-offset-2 text-center truncate font-semibold capitalize dark:text-white ">
            {earningCall.title}
          </h1>
          <AudioPlayer
            ref={audioPlayerRef}
            {...{
              isPlaying,
              setIsPlaying,
              audioUrl: earningCall.audio_url,
              audioText: earningCall.transcript,
              jumpToQuestionAndAnswer,
            }}
          />
        </div>
      </div>
      <div className="h-2/3 overflow-auto scrollbar-hide w-full bg-linen dark:bg-rhino  py-2 pl-1 pr-0.5 text-black my-1  rounded-md">
        <Transcript
          transcriptProps={{
            audioPlayerRef,
            setIsPlaying,
            audioText: earningCall.transcript,
            companyName: earningCall.company_name,
            documentType: earningCall.document_type,
            addNewTopicsForImpressionTime,
            setQuestionAnswerStartTime,
          }}
        />
      </div>
    </div>
  ) : (
    <div className="w-full pt-4">
      {loading && (
        <div className="w-full text-center">
          <BeatLoader size={10} color="#40464F" />
        </div>
      )}
      {message && (
        <p className="w-full text-customFontSiz text-center text-black dark:text-white">
          {message}
        </p>
      )}
      {error && (
        <p className="w-full text-customFontSiz text-red-500 text-center">
          {error}
        </p>
      )}
    </div>
  );

  //return rendered data
  return (
    <div className="inset-x-0 absolute top-0 h-IndividualEarningCallHeight overflow-auto scrollbar-hide bg-inherit rounded-t-3xl px-1">
      {renderedData}
    </div>
  );
};

export default IndividualEarningCall;
