import { podcastContext } from "common/context";
import React, { useContext, memo } from "react";
import Podcast from "./Podcast";

const PodCastsList = React.forwardRef((props, progressBarsRef) => {
  const { podcasts } = useContext(podcastContext);
  return (
    <div className="w-72 h-full overflow-auto scrollbar-hide bg-white p-2 rounded-md text-customFontEleven">
      <h1 className="w-full text-customFontTwenty text-center capitalize font-semibold underline my-2">
        All Podcasts
      </h1>
      {Object.keys(podcasts).length > 0 ? (
        <div className="w-full">
          {Object.keys(podcasts).map((podcastName) => {
            return (
              <Podcast
                ref={progressBarsRef}
                key={podcastName}
                podcastProps={{ podcast: podcasts[podcastName] }}
              />
            );
          })}
        </div>
      ) : (
        <p className="w-full text-center italic capitalize text-slate-500">
          No podcasts yet. Upload some.
        </p>
      )}
      
    </div>
  );
});

export default memo(PodCastsList);
