import React, { useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import CustomTextInput from "./CustomTextInput";
import {
  auth,
  collection,
  createUserWithEmailAndPassword,
  db,
  doc,
  getDocs,
  query,
  Timestamp,
  updateDoc,
  where,
} from "common/firebase-app";
import TexelioLogo from "assets/texeliologo.svg";
import { Link, useNavigate } from "react-router-dom";
import { appContext } from "common/context";
const Register = () => {
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const { currentUser } = useContext(appContext);
  const [status, setStatus] = useState({
    error: "",
    message: "",
  });
  let navigate = useNavigate();
  const initialValues = {
    code: "",
    email: "",
    password: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid Email Address").required("Required"),
    code: Yup.number().required("Required"),
    password: Yup.string()
      .min(6, "Too short! Minimum 6 characters")
      .required("Required"),
  });
  const onSubmit = async ({ email, password, code }) => {
    setStatus({
      message: "Checking Access Code & Email...",
      error: "",
    });
    try {
      // check if invitation code was provided to this email
      const q = query(
        collection(db, "users"),
        where("email", "==", email),
        where("code", "==", +code)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 0) {
        throw new Error("Invalid Access Code or Email");
      }
      // check if user is registered already with this email
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        userData = doc.data();
        userId = doc.id;
      });
      if (!userData?.uid) {
        // continue to registration
        setStatus({
          message: "Creating an account...",
          error: "",
        });
        const result = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        // update user info
        await updateDoc(doc(db, "users", userId), {
          uid: result.user.uid, // this is a unique id for every registered user
          createdAt: Timestamp.fromDate(new Date()),
        });
        setStatus({
          message: "Account created successfully!",
          error: "",
        });
        // redirect after successful registration
        setTimeout(() => {
          navigate("/content");
        }, 2000);
      } else {
        throw new Error("You are already registered. Sign in instead!");
      }
    } catch (err) {
      setStatus({
        message: "",
        error: err.message,
      });
    }
  };

  const { error, message } = status;
  let renderedComponent = (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <div className="w-14">
        <img className="w-full" src={TexelioLogo} alt="Texelio" />
      </div>
    </div>
  );
  if (showRegistrationForm) {
    renderedComponent = (
      <div className="w-full h-screen md:w-1/2 mx-auto lg:w-3/12  flex flex-col items-center justify-center text-white px-2">
        <h1 className="w-full text-center font-bold text-customFontThirteen text-white">
          Create Account
        </h1>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="w-full">
            <CustomTextInput
              name="code"
              type="number"
              placeholder="Enter Access Code"
            />
            <CustomTextInput
              name="email"
              type="email"
              placeholder="Enter Email"
            />
            <CustomTextInput
              name="password"
              type="password"
              placeholder="Enter Password"
            />
            <button
              className="text-customFontFifteen text-white font-semibold rounded-full py-4 my-2 w-full bg-summer-sky cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
              type="submit"
            >
              Signup
            </button>
          </Form>
        </Formik>
        {error && <p className="my-2 text-center text-red-500">{error}</p>}
        {message && (
          <p className="my-2 text-center text-green-500">{message}</p>
        )}
        <p className="my-2.5 w-full text-center">
          Have an account?{" "}
          <Link
            to="/login"
            className="font-semibold text-cyan-500 cursor-pointer underline"
          >
            Sign in
          </Link>
        </p>
      </div>
    );
  }

  const checkLoggedUser = () => {
    if (currentUser === undefined) {
      return;
    } else if (currentUser) {
      navigate("/content");
    } else {
      setShowRegistrationForm(true);
    }
  };
  useEffect(() => {
    checkLoggedUser();
  }, [currentUser]);
  return renderedComponent;
};
export default Register;
