import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { podcastContext } from "common/context";
import * as lodash from "lodash";
import PodcastSentence from "./PodcastSentence";

const PodCastTranscript = () => {
  const { podcasts, currentPodcastPlaying, audioPlayerRef, setIsPlaying } =
    useContext(podcastContext);
  // get new transcript when podcast changes or transcript changes
  const transcript = useMemo(
    () => podcasts[currentPodcastPlaying]?.transcript ?? [],
    [currentPodcastPlaying, podcasts[currentPodcastPlaying]]
  );
  const textRef = useRef([]);
  const currentTimeCache = useRef([0, 0]);
  const updateCurrentTime = useCallback((start) => {
    audioPlayerRef.current.audio.current.currentTime = start;
    audioPlayerRef.current.audio.current.play();
    setIsPlaying(true);
  }, []);
  function synchronizeText() {
    const currentTime = audioPlayerRef.current.audio.current.currentTime;
    const allTexts = textRef.current;
    // prevent restyling if range of currentTime hasn't changed
    const [startTimeCache, endTimeCache] = currentTimeCache.current;
    if (currentTime >= startTimeCache && currentTime < endTimeCache) {
      return;
    }
    for (const { startTime, endTime, element } of allTexts) {
      if (currentTime >= startTime && currentTime < endTime) {
        element?.classList.add("text-black");
        element?.classList.remove("text-black/50");
        element?.scrollIntoView({ behavior: "smooth", block: "nearest" });
        // cache values
        currentTimeCache.current = [startTime, endTime];
        // force re-render topic buttons
        // setButtonsReload(startTime);
      } else {
        element?.classList.add("text-black/50");
        element?.classList.remove("text-black");
      }
    }
  }

  useEffect(() => {
    const audioPlayerElement = audioPlayerRef.current?.audio?.current;
    audioPlayerElement?.addEventListener("timeupdate", synchronizeText);
    return () =>
      audioPlayerElement?.removeEventListener("timeupdate", synchronizeText);
  }, []);

  const textContent = useMemo(() => {
    //makes sure textref is empty if usememo rerenders
    textRef.current = [];
    return transcript.map((obj) => {
      const {
        sentence,
        start: startTime,
        end: endTime,
      } = obj;
      const unqId = lodash.uniqueId("sentence");
      // if (topicIdArray.length > 0) {
      //   const topicsCollectionCopy = topicsCollection;
      //   topicsCollectionCopy.push({ startTime, endTime, topicIdArray });
      //   setTopicsCollection(topicsCollectionCopy);
      // }
      return (
        <PodcastSentence
          ref={textRef}
          key={unqId}
          podcastSentenceProps={{
            sentence,
            startTime,
            endTime,
            updateCurrentTime,
          }}
        />
      );
    });
  }, [transcript]);

  const content =
    textContent.length > 0 ? (
      textContent
    ) : (
      <p className="w-full text-center italic capitalize text-slate-500">
        "No transcripts to show."
      </p>
    );
  return (
    <div className="w-full  h-full bg-inherit">
      <h1 className="w-full text-center font-bold ">Transcript</h1>
      <div className="w-full my-2 h-full overflow-auto scrollbar-hide">
        {content}
      </div>
    </div>
  );
};

export default PodCastTranscript;
