import { setImpressionEngagementData } from "features/app/appSlice";
import { AiOutlineDelete } from "react-icons/ai";
import { useDispatch } from "react-redux";

export default function ClearStats() {
  //construct dispatch funtion
  const dispatch = useDispatch();

  //construct the reset data
  const resetData = {
    impressionData: {
      newsType: 0,
      pressReleaseType: 0,
      earningsCallsType: 0,
      podcastsType: 0,
      "stock mentions": {},
      "investing topics": {},
      "esg topics": {},
    },
    engagementData: {
      newsType: 0,
      pressReleaseType: 0,
      earningsCallsType: 0,
      podcastsType: 0,
      "stock mentions": {},
      "investing topics": {},
      "esg topics": {},
    },
  };

  //reset stats
  const resetStats = () => {
    //update redux store;
    dispatch(setImpressionEngagementData({ ...resetData }));
  };
  return (
    <button
      onClick={resetStats}
      className=" float-right mr-5 hover:scale-110 opacity-40"
    >
      <AiOutlineDelete size={24} />
    </button>
  );
}
