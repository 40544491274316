import React, { useMemo } from "react";
import { prettyTime } from "common/exportedFunctions";
import PieChartData from "./PieChartData";
import PieChartKey from "./PieChartKey";
import RightArrow from "./RightArrow";

const Chart = ({ mediaTypes, unit, stockMentions }) => {
  //get the total number of either impression time, clicks or conversion
  let total;
  if (unit === "time")
    total = Object.values(stockMentions).reduce(
      (totalTime, currentStock) =>
        totalTime + currentStock?.stockImpressionTime ?? 0,
      0
    );
  if (unit === "clicks")
    total = mediaTypes.reduce(
      (totalClicks, currentMediaType) => totalClicks + currentMediaType.value,
      0
    );

  //stockMentions for conversion is arbitrary name, but the value contained is the percent total number of clicks to total impression time
  if (unit === "conversion") total = stockMentions;

  //convert this time to seconds
  const formattedTotalTimeOrClicks = prettyTime(unit, total);

  //construct allMediaTypes that stores the color of each type
  const allMediaTypes = {
    news: "#008001",
    "earnings calls": "#FFA500",
    "press release": "#FE5833",
    podcasts: "#C2EDFF",
  };

  //map through media types and return an array of  objects which includes color of each type,
  const chartData = useMemo(() =>
    mediaTypes.map(
      ({ name, value }) => {
        //get color from the allMediaTypes object
        const color = allMediaTypes[name];
        return { name, value, color };
      },
      [mediaTypes]
    )
  );
  return (
    <div className="relative w-full flex flex-wrap 2xl:grid grid-cols-chartsParentCol 2xl:grid-rows-1 2xl:grid-flow-col  gap-2 py-5 border-b border-b-whisper-shade">
      <PieChartData
        chartData={chartData}
        unitName={unit}
        total={formattedTotalTimeOrClicks}
      />
      <PieChartKey chartData={chartData} unitName={unit} />
      <RightArrow unitName={unit} />
    </div>
  );
};

export default Chart;
