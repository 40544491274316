import { createSlice } from "@reduxjs/toolkit";

//get a copy of user uid from local storage
const userUid = JSON.parse(localStorage.getItem("userUid"));

//get impression , engagement and number of visits  from local storage
const {
  impressionData = null,
  engagementData = null,
  visits = null,
} = JSON.parse(
  localStorage.getItem(`${userUid}-impression-engagement-stats`)
) ?? {};

// global state manager
const initialState = {
  globalLanguage: "en",
  allMountedArticles: {},
  visits: visits ?? 0,
  impressionData: impressionData ?? {
    newsType: 0,
    pressReleaseType: 0,
    earningsCallsType: 0,
    podcastsType: 0,
    "stock mentions": {},
    "investing topics": {},
    "esg topics": {},
  },
  engagementData: engagementData ?? {
    newsType: 0,
    pressReleaseType: 0,
    earningsCallsType: 0,
    podcastsType: 0,
    "stock mentions": {},
    "investing topics": {},
    "esg topics": {},
  },
};
const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setEditListContent: (state, { payload }) => {
      const { target = null, section, data } = payload;
      if (target) {
        state.editListContent = data;
      } else {
        state.editListContent[section] = data;
      }
    },
    setGlobalLanguage: (state, { payload }) => {
      state.globalLanguage = payload;
    },
    setImpressionData: (state, { payload }) => {
      state.impressionData = { ...payload };
    },
    setEngagementData: (state, { payload }) => {
      state.engagementData = { ...payload };
    },
    setImpressionEngagementData: (state, { payload }) => {
      state.impressionData = { ...payload.impressionData };
      state.engagementData = { ...payload.engagementData };
    },
    setAllMountedArticles: (state, { payload }) => {
      state.allMountedArticles = { ...payload };
    },
    setNumberOfCddVisits: (state, { payload }) => {
      state.visits += payload;
    },
  },
});
export const {
  setGlobalLanguage,
  setNumberOfCddVisits,
  setEditListContent,
  setImpressionData,
  setEngagementData,
  setAllMountedArticles,
  setImpressionEngagementData,
} = appSlice.actions;
export default appSlice.reducer;
