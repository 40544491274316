import React, { memo, useContext } from "react";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { bodyText } from "common/exportedFunctions";
import ReactTimeAgo from "react-time-ago";
import { individualRetailInvestorContext } from "common/context";
import { revealHiddenSection, toggleSummary } from "common/api";
import CustomButton from "components/Buttons/CustomButton";
const ExpandedContent = React.forwardRef(({ expandedContentProps }, ref) => {
  //destructure prop values
  const {
    expandedId,
    releaseDate,
    section,
    unqId,
    companyId,
    buttonType,
    hiddenSourceUrl,
    sourceName,
    sectionSummaryOrder,
    hiddenArticleKey,
    companyName,
    buttonText,
  } = expandedContentProps;

  //get summary context
  const { individualRetailInvestorDispatch } = useContext(
    individualRetailInvestorContext
  );
  //get containers and hidden text node
  const { dynamicDivRef, hiddenTextRef } = ref.current;
  return (
    <div
      ref={(div) => (dynamicDivRef.current[expandedId] = div)}
      className="w-full hidden"
    >
      <div className="w-full bg-linen py-0.5 mb-0.5 border-b border-b-sazerac dark:bg-black  dark:border-b-ship-cove/50 grid grid-cols-individualSection auto-rows-auto grid-flow-row justify-start gap-1 ">
        <div className="w-full col-span-2 text-customFontNineteen text-clip overflow-hidden">
          {section.end_hidden ? (
            <p
              className="w-full text-clip overflow-hidden cursor-pointer"
              onClick={() =>
                revealHiddenSection(
                  section.hidden_text_data,
                  unqId,
                  hiddenTextRef
                )
              }
            >
              {bodyText(section.segments, companyId)}
              <span ref={(span) => (hiddenTextRef.current[unqId] = span)} />
            </p>
          ) : (
            <p className="w-full ">{bodyText(section.segments, companyId)}</p>
          )}
        </div>
        <div className="w-full col-start-3 row-span-1 flex flex-col items-end">
          <p className="w-full text-end text-customFontFive  font-bold">
            <ReactTimeAgo
              date={releaseDate * 1000}
              locale="en-US"
              timeStyle="round-minute"
            />
          </p>
          {section.has_summary && (
            <button
              onClick={() =>
                toggleSummary({
                  sectionSummaryOrder,
                  hiddenArticleKey,
                  individualRetailInvestorDispatch,
                  companyName,
                  releaseDate,
                  sourceName,
                })
              }
              className="mt-1.5 font-bold"
            >
              <AiOutlineThunderbolt className="text-customFontFour" />
            </button>
          )}
        </div>
        <div className="w-full">
          {buttonText !== "NONE" && buttonText !== "Quote" && (
            <CustomButton
              customButtonProps={{
                type: buttonType,
                text: buttonText,
                isClear: false,
                category: "topics",
              }}
            />
          )}
        </div>
        <div className="w-full text-center col-start-3">
          <a
            href={`${process.env.REACT_APP_BASE_URL}/frontend-api/relevance/follow-source-url?accessToken=${process.env.REACT_APP_ACCESS_TOKEN}&data=${hiddenSourceUrl}`}
            target="_blank"
            rel="noreferrer"
            className="underline   text-customFontSix font-medium cursor-pointer"
          >
            {sourceName}
          </a>
        </div>
      </div>
    </div>
  );
});

export default memo(ExpandedContent);
