import { individualRetailInvestorContext } from "common/context";
import React, { useContext } from "react";
import EditListContainer from "./EditList/EditListContainer";
import EarningsCall from "./EarningsCall/EarningsCall";

export default function PortfolioAndEarningsCall() {
  //This component will shown when selectedOptionPhoneBottomNav is either portfolio or earningscall. Hidden otherwise
  //extract context values
  const { selectedOptionPhoneBottomNav } = useContext(
    individualRetailInvestorContext
  );

  //hide this component when selectedOptionPhoneBottomNav equals stories
  if (selectedOptionPhoneBottomNav === "stories") return null;

  //variable to hold either portifolio or earnings calls
  let content;

  if (selectedOptionPhoneBottomNav === "portfolio") {
    content = <EditListContainer />;
  } else {
    // add earnings call
    content = <EarningsCall />;
  }

  return (
    <div className="w-full h-portfolioAndEarningsCallHeight absolute bg-sazerac dark:bg-nero  rounded-t-3xl">
      {content}
    </div>
  );
}
