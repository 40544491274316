import React,{memo} from "react";
import prettyMilliseconds from "pretty-ms";
const PodcastSentence = React.forwardRef(
  ({ podcastSentenceProps }, textRef) => {
    const { sentence, startTime, endTime, updateCurrentTime } =
      podcastSentenceProps;
    const formattedStartTime = prettyMilliseconds(startTime * 1000, {
      secondsDecimalDigits: 0,
      colonNotation: true,
    }); // converts ms to human readable  time
    return (
      <p
        ref={(p) => textRef.current.push({ startTime, endTime, element: p })}
        className="cursor-pointer my-2"
        onClick={() => updateCurrentTime(startTime)}
      >
        <span className="font-bold mr-1">{formattedStartTime}</span>
        <span>{sentence}</span>
      </p>
    );
  }
);

export default memo(PodcastSentence);
