import React, { memo } from "react";

import { useTransition, animated } from "@react-spring/web";
import { clsx } from "clsx";
const LoadMore = ({ loadMoreProps }) => {
  //destructure props
  const { toggleLoadMore, isFromCharts = false } = loadMoreProps;
  // animate load more button
  const buttonTransition = useTransition(1, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  return buttonTransition((styles) => (
    <animated.div style={{ styles }} className="w-full text-center">
      <button
        onClick={toggleLoadMore}
        className={clsx(
          "rounded-full text-xs my-1",
          isFromCharts
            ? "bg-midnight-blue text-white px-4 py-1.5"
            : "text-black-russian bg-onahau dark:bg-turquoise py-1 px-2.5 font-semibold"
        )}
      >
        Load More
      </button>
    </animated.div>
  ));
};

export default memo(LoadMore);
